import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { Link } from '../../../partials/Link';
import { _fetchUrl } from '../../../utils/api';
import EOTable from '../../../partials/EOTable';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';

const SurveyPage = (props) => {
  const { match } = props;
  const { consultId } = match.params;

  const [surveyData, setSurveyData] = useState(null);

  useEffect(() => {
    if (!props.user || !props.user.token) {
      return;
    }

    _fetchUrl({
      method: 'GET',
      path: `poe/partner/consult/${consultId}/survey`,
      token: props.user.token
    }).then((r) => {
      if (r.status === 'ok') {
        setSurveyData(r.survey_data);
      } else {
        console.error('Error loading partners:', r.error);
      }
    });
  }, [props.user]);

  return (
    <div>
      <Link base={'site'} path={`/admin/industry`}>
        <Typography variant="subtitle1">
          Survey{' '}
          <Typography variant="body1" style={{ display: 'inline' }}>
            <span style={{ fontSize: '75%' }}>
              <ArrowBack style={{ position: 'relative', top: '5px' }} />
              Back to workflow
            </span>
          </Typography>
        </Typography>
      </Link>

      <br />

      <EOTable
        title="Customer Response"
        columns={[
          { title: 'Question', field: 'question' },
          {
            title: 'Answer',
            field: 'answer',
            render: (rowData) => (rowData.answer ? rowData.answer : 'N/a')
          }
        ]}
        data={surveyData ? [...surveyData.customer.global, ...surveyData.customer.local] : []}
        options={{
          paging: false,
          sorting: false,
          search: false
        }}
      />

      <br />

      <EOTable
        title="Expert Response"
        columns={[
          { title: 'Question', field: 'question' },
          {
            title: 'Answer',
            field: 'answer',
            render: (rowData) => (rowData.answer ? rowData.answer : 'N/a')
          }
        ]}
        data={surveyData ? [...surveyData.expert.global, ...surveyData.expert.local] : []}
        options={{
          paging: false,
          sorting: false,
          search: false
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth
});

export default connect(mapStateToProps)(withRouter(SurveyPage));
