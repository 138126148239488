import React, { Component } from 'react';
import Onboard from './';
import { connect } from 'react-redux';

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

class OnboardContainer extends Component {
  render() {
    return (
      <React.Fragment>
        <Onboard {...this.props} />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardContainer);
