import { Component } from 'react';
import { _fetchUrl } from '../../utils/api';

class BaseFileComponent extends Component {
  state = {
    submitted: false,
    error: null,
    result: false
  };
  selectFile = (fileType) => (event) => {
    this.setState({
      [fileType]: event.target.value,
      submitted: false,
      fileData: Array.from(this[fileType].files).map((file) => ({
        name: file.name,
        size: file.size
      })),
      result: false
    });
  };
  resetFile = (fileType) => () => {
    this.setState({
      [fileType]: null,
      submitted: false,
      fileData: null,
      result: false
    });
  };
  uploadFile =
    (input, fileType, token, path, postUpload = () => {}, method = 'POST') =>
    async () => {
      // Allow for alternative file upload
      if (typeof this.props.uploadFile === 'function') {
        this.setState((state) => ({ ...state, submitted: true, result: false }));
        let uploadFileResult = await this.props.uploadFile(input.files);
        this.setState((state) => ({
          ...state,
          submitted: false,
          result: !!uploadFileResult.length
        }));
        return postUpload(uploadFileResult);
      }

      const body = new FormData();

      Array.from(input.files).forEach((file) => {
        body.append('file', file);
      });

      const states = {
        submitting: { submitted: true, result: false, error: null },
        complete: { submitted: false, result: false, error: null, [fileType]: null, fileData: null }
      };

      this.setState(states.submitting, async () => {
        try {
          const response = await _fetchUrl({
            method,
            path,
            body,
            fileUpload: true,
            token
          });
          if (response.status === 'ok') {
            return this.setState({ ...states.complete, result: true }, () => {
              postUpload(response);
            });
          }
          return this.setState({ ...states.complete, error: response.message });
        } catch (error) {
          return this.setState({ ...states.complete, error });
        }
      });
    };
}

export default BaseFileComponent;
