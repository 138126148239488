import React, { useEffect, useMemo, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Done from '@material-ui/icons/Done';
import EOModal from '../../../partials/EOModal';
import Grid from '@material-ui/core/Grid';
import isMobile from '../../../utils/isMobile';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

const URL_BASE = `${process.env.REACT_APP_API_BASE_URL}/v1/partner`;

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  modal: {
    width: '500px',
    padding: '16px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  rightWrapper: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}));

const ErrorText = ({ errors }) => {
  return (
    <Grid
      style={{
        margin: '16px',
        backgroundColor: 'red',
        padding: '16px',
        border: '1px solid #cecece',
        borderRadius: '5px',
        display: errors.length ? 'block' : 'none'
      }}
      item
      xs={12}
    >
      {errors.map((errorMessage, idx) => (
        <div key={idx}>
          <Typography variant="button" color="secondary">
            {errorMessage}
          </Typography>
        </div>
      ))}
    </Grid>
  );
};

const Approve = () => {
  const { token } = useParams();

  const isMobileMemo = useMemo(() => isMobile(), []);

  const [state, setState] = useState({
    errorMessage: '',
    isError: false,
    sending: false
  });

  const [approveErrors, setApproveErrors] = useState({});
  const [approveOpen, setApproveOpen] = useState(false);
  const [approveServerErrors, setApproveServerErrors] = useState([]);
  const [data, setData] = useState({});
  const [denyErrors, setDenyErrors] = useState({});
  const [denyOpen, setDenyOpen] = useState(false);
  const [denyServerErrors, setDenyServerErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alreadyApproved, setAlreadyApproved] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (!token) return;

    window
      .fetch(`${URL_BASE}/approve/${token}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(async (res) => {
        try {
          res = await res.json();
        } catch (error) {
          console.error(error);
        }

        if (res.status !== 'ok') {
          setAlreadyApproved(
            res.timestamp
              ? `${res.status} on ${new Date(res.timestamp).toLocaleString()}.`
              : res.status
          );
        } else {
          setData(res.data);
        }

        setLoading(false);
      });
  }, [token]);

  const submit = (approved, fields) => {
    setState({ sending: true });

    return window
      .fetch(`${URL_BASE}/approve/${token}`, {
        method: 'POST',
        body: JSON.stringify({
          approved,
          ...fields
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((r) => r.json())
      .then((r) => {
        if (r.status !== 'ok') {
          if (r.errors) {
            if (approved) {
              setApproveServerErrors(Object.values(r.errors));
            } else {
              setDenyServerErrors(Object.values(r.errors));
            }

            setState({ sending: false });
          } else if (r.status === 'invalid consult state') {
            setState({ sending: false });
            setAlreadyApproved('Consult has been already approved.');
          } else {
            setState({ sending: false, isError: true, errorMessage: r.status });
          }
        } else {
          setState({ sending: false, isError: false, submitted: true });
          approved ? setApproveOpen(false) : setDenyOpen(false);
        }
      });
  };

  const handleApproveSubmit = (event) => {
    event.preventDefault();

    const approver = event.target.approverName.value.trim();

    const newErrors = {};

    if (approver === '') {
      newErrors.approverName = 'Approver Name is required!';
    }

    if (Object.keys(newErrors).length) {
      return setApproveErrors(newErrors);
    }

    submit(true, { approver });
  };

  const handleDenySubmit = (event) => {
    event.preventDefault();

    const approver = event.target.approverName.value.trim();
    const denialReason = event.target.denialReason.value.trim();
    const actionPlan = event.target.actionPlan.value.trim();
    const contactPerson = event.target.contactPerson.value.trim();

    const newErrors = {};

    if (approver === '') {
      newErrors.approverName = 'Approver Name is required!';
    }

    if (denialReason === '') {
      newErrors.denialReason = 'Reason for Denial is required!';
    }

    if (actionPlan === '') {
      newErrors.actionPlan = 'Action Plan is required!';
    }

    if (contactPerson === '') {
      newErrors.contactPerson = 'Contact Person is required!';
    }

    if (Object.keys(newErrors).length) {
      return setDenyErrors(newErrors);
    }

    submit(false, { approver, denialReason, actionPlan, contactPerson });
  };

  return (
    <Box display="flex" mt={2}>
      <Grid item sm={11} md={8} style={{ width: '100%', margin: '0px auto 30px auto' }}>
        {loading ? (
          <div style={{ textAlign: 'center', marginTop: '36px' }}>
            <CircularProgress />
          </div>
        ) : (
          <Paper style={{ padding: '40px 50px' }}>
            {state.isError ||
              (alreadyApproved && (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {state.errorMessage || alreadyApproved}
                </Alert>
              ))}

            {state.submitted && (
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ padding: '10px 25px', textAlign: 'left' }}
              >
                <div>
                  <Typography gutterBottom style={{ color: '#000' }} variant="h2">
                    Submission Complete
                    <Done
                      style={{
                        color: '#82E67E',
                        fontSize: '1em',
                        verticalAlign: 'text-bottom',
                        marginLeft: '10px',
                        marginBottom: '5px'
                      }}
                    />
                  </Typography>

                  <Typography gutterBottom variant="h5">
                    You're all set.
                  </Typography>
                </div>
              </Grid>
            )}

            {!state.isError && !state.submitted && !alreadyApproved && (
              <>
                <Grid container spacing={3} justifyContent="space-between">
                  <Grid item sm={10}>
                    <Typography variant="h4">Consult Request</Typography>
                    <br />
                    <Typography variant="body2">
                      <strong>Name:</strong> {data.customerName}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Email:</strong> {data.email}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Phone:</strong> {data.phone}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Country Code:</strong> {data.countryCode}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Admin/Clinic Phone:</strong> {data.adminPhone}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Admin Country Code:</strong> {data.adminCountryCode}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Rep:</strong> {data.rep}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Next Day or Weekend Request:</strong> {data.nextDay?.toString()}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Urgent Request Reason:</strong> {data.reason || 'N/A'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Availability:</strong> {data.availability}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Requested doctor(s):</strong> {data.doctors}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Country:</strong> {data.country}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Timezone:</strong>{' '}
                      {data?.timezone?.code == 'other'
                        ? 'Other, GMT ' + data?.timezone?.value
                        : data?.timezone?.code || ''}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Disease State / Product Category:</strong> {data.category}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Case Description:</strong> {data.caseDescription}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Patient Question:</strong> {data.patientQuestion}
                    </Typography>
                  </Grid>

                  <Grid item sm={2} className={classes.rightWrapper}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={() => setApproveOpen(true)}
                        >
                          Approve
                        </Button>
                        <EOModal
                          title={'Approve Consult'}
                          fullScreen={isMobileMemo}
                          open={approveOpen}
                          handleClose={() => setApproveOpen(false)}
                        >
                          <ErrorText key={Math.random()} errors={approveServerErrors} />

                          <form onSubmit={handleApproveSubmit} className={classes.modal}>
                            <Grid container direction="column" spacing={2}>
                              <Grid item>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  label="Approver Name"
                                  name="approverName"
                                  error={!!approveErrors.approverName}
                                  helperText={approveErrors.approverName}
                                  onChange={() =>
                                    setApproveErrors((prevErrors) => ({
                                      ...prevErrors,
                                      approverName: undefined
                                    }))
                                  }
                                />
                              </Grid>
                              <Grid item container justifyContent="flex-end">
                                <Button
                                  disabled={state.sending}
                                  variant="contained"
                                  type="submit"
                                  color="primary"
                                >
                                  Confirm Approval
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        </EOModal>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          onClick={() => setDenyOpen(true)}
                        >
                          Deny
                        </Button>
                        <EOModal
                          title={'Deny Consult'}
                          fullScreen={isMobileMemo}
                          open={denyOpen}
                          handleClose={() => setDenyOpen(false)}
                        >
                          <ErrorText key={Math.random()} errors={denyServerErrors} />

                          <form onSubmit={handleDenySubmit} className={classes.modal}>
                            <Grid container direction="column" spacing={2}>
                              <Grid item>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  label="Reason for Denial"
                                  name="denialReason"
                                  error={!!denyErrors.denialReason}
                                  helperText={denyErrors.denialReason}
                                  onChange={() =>
                                    setDenyErrors((prevErrors) => ({
                                      ...prevErrors,
                                      denialReason: undefined
                                    }))
                                  }
                                />
                              </Grid>
                              <Grid item>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  label="Action Plan"
                                  name="actionPlan"
                                  error={!!denyErrors.actionPlan}
                                  helperText={denyErrors.actionPlan}
                                  onChange={() =>
                                    setDenyErrors((prevErrors) => ({
                                      ...prevErrors,
                                      actionPlan: undefined
                                    }))
                                  }
                                />
                              </Grid>
                              <Grid item>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  label="Contact Person"
                                  name="contactPerson"
                                  error={!!denyErrors.contactPerson}
                                  helperText={denyErrors.contactPerson}
                                  onChange={() =>
                                    setDenyErrors((prevErrors) => ({
                                      ...prevErrors,
                                      contactPerson: undefined
                                    }))
                                  }
                                />
                              </Grid>
                              <Grid item>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  label="Approver Name"
                                  name="approverName"
                                  error={!!denyErrors.approverName}
                                  helperText={denyErrors.approverName}
                                  onChange={() =>
                                    setDenyErrors((prevErrors) => ({
                                      ...prevErrors,
                                      approverName: undefined
                                    }))
                                  }
                                />
                              </Grid>
                              <Grid item container justifyContent="flex-end">
                                <Button
                                  disabled={state.sending}
                                  variant="contained"
                                  type="submit"
                                  color="primary"
                                >
                                  Confirm Denial
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        </EOModal>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        )}
      </Grid>
    </Box>
  );
};

export default Approve;
