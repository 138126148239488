import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Done from '@material-ui/icons/Done';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import scrollIntoView from 'scroll-into-view-if-needed';
import { guessCountry } from '../../utils/guessCountry';
import { _fetchUrl } from '../../utils/api';
import { isEmailInvalid } from '../../utils/formValidation';
import PhoneInput, { isValidPhoneNumber } from '../shared/PhoneInput';
import { getCountries } from 'react-phone-number-input';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '24px',
    width: '100%',
    marginBottom: '64px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '8px'
    }
  }
}));

const SubmitSuccess = () => (
  <Grid
    container
    direction="column"
    alignItems="center"
    style={{ marginTop: '40px', padding: '10px 25px', textAlign: 'left' }}
  >
    <div>
      <Typography gutterBottom style={{ color: '#000' }} variant="h2">
        Submission Complete
        <Done
          style={{
            color: '#82E67E',
            fontSize: '1em',
            verticalAlign: 'text-bottom',
            marginLeft: '10px',
            marginBottom: '5px'
          }}
        />
      </Typography>

      <Typography gutterBottom variant="h5">
        You're all set.
      </Typography>
      <Typography gutterBottom variant="h5">
        If you have any questions, you can email us at{' '}
        <a href="mailto:care@expertopinion.md">care@expertopinion.md</a>.
      </Typography>
    </div>
  </Grid>
);

const GetStarted = () => {
  const classes = useStyles();

  const [step, setStep] = useState(0);
  const [data, setData] = useState([]);
  const [manufacturer, setManufacturer] = useState('');
  const [otherValue, setOtherValue] = useState('');
  const [product, setProduct] = useState('');
  const [doctorsName, setDoctorsName] = useState('');
  const [doctorsEmail, setDoctorsEmail] = useState('');
  const [doctorsMobileCountry, setDoctorsMobileCountry] = useState('');
  const [doctorsMobile, setDoctorsMobile] = useState('');
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [errorFields, setErrorFields] = useState({});

  const countries = useMemo(() => getCountries(), []);

  let history = useHistory();

  useEffect(() => {
    setDoctorsMobileCountry(guessCountry(countries));
  }, [setDoctorsMobileCountry, countries]);

  const manufacturers = useMemo(() => {
    return data.map((data) => data.name).sort();
  }, [data]);

  const products = useMemo(() => {
    return data.find((data) => data.name === manufacturer)?.products;
  }, [manufacturer]);

  const loadPartnerProducts = useCallback(() => {
    const response = _fetchUrl({
      method: 'GET',
      path: `partner/products`
    });

    response.then((res) => {
      if (res.status === 'ok') {
        setData(
          res.data
            .filter((d) => d.name !== 'CorneaGen')
            .map((d) => {
              switch (d.name) {
                case 'Alcon':
                  d.name = 'Alcon Surgical';
                  break;
                case 'Bausch + Lomb':
                  d.name = 'Bausch + Lomb Surgical';
                  break;
              }

              return d;
            })
        );
        setLoading(false);
      } else {
        setLoading(false);
        console.error('Invalid response', res);
      }
    });

    response.catch((err) => {
      setLoading(false);
      console.error('err', err);
    });
  }, []);

  useEffect(() => {
    loadPartnerProducts();
  }, []);

  const steps = document.querySelectorAll('.MuiStep-root.MuiStep-vertical');

  const scrollToTop = (step) => {
    setTimeout(() => {
      if (steps[step]) {
        scrollIntoView(steps[step], {
          behavior: 'smooth',
          block: 'start',
          scrollMode: 'if-needed'
        });
      }
    }, 500);
  };

  const handleNextStep = () => {
    if (step === 0) {
      switch (manufacturer) {
        case 'Alcon Surgical':
          history.push('/alconexpert');
          break;
        case 'Bausch + Lomb Surgical':
          history.push('/askbausch');
          break;
        case 'Bausch + Lomb Retina':
          history.push('/bauschretina');
          break;
        case 'ZEISS':
          history.push('/askus');
          break;
        case 'Glaukos':
          history.push('/glaukosguide');
          break;
        case 'Alcon Vision':
          history.push('/alconvision');
          break;
        case 'Johnson & Johnson Vision':
          history.push('/askjjv');
          break;
      }
    }

    scrollToTop(step + 1);
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    scrollToTop(step - 1);
    setStep(step - 1);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    const errors = {};

    if (isEmailInvalid(doctorsEmail)) {
      errors.doctorsEmail = 'Email is invalid';
    }

    if (!isValidPhoneNumber(doctorsMobile)) {
      errors.doctorsMobile = 'Mobile number is invalid';
    }

    // No empty fields
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
      return setErrorFields({ ...errorFields, ...errors });
    }

    const response = _fetchUrl({
      method: 'POST',
      path: `partner/get-started`,
      body: {
        manufacturer: manufacturer === 'Other' ? `${manufacturer} - ${otherValue}` : manufacturer,
        product,
        doctorsName,
        doctorsEmail,
        doctorsMobile
      }
    });

    response.then((res) => {
      if (res.status === 'ok') {
        setSubmitSuccess(true);
      } else {
        console.error('Invalid response', res);
      }
    });

    response.catch((err) => {
      console.error('err', err);
    });

    response.finally(() => {
      setIsSubmitting(false);
    });
  };

  return submitSuccess ? (
    <SubmitSuccess />
  ) : (
    <div className={classes.content}>
      <Typography variant="h4" gutterBottom>
        Speak to an Expert Peer
      </Typography>
      <Typography variant="body1" style={{ marginBottom: '16px' }}>
        This service is for Medical Practitioners referred by a product manufacturer representative.
        Fill out the form below to connect to an Expert with product-specific knowledge. For our
        partner companies this service is offered at no charge for specific scenarios.
      </Typography>
      {loading ? (
        <CircularProgress style={{ color: 'primary' }} />
      ) : (
        <Stepper
          style={{ padding: '8px 0px', background: 'inherit' }}
          activeStep={step}
          orientation="vertical"
        >
          <Step>
            <StepLabel>Select your product manufacturer</StepLabel>
            <StepContent>
              <Grid item xs={12} md={5}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    color="primary"
                    name="gender1"
                    value={manufacturer}
                    onChange={(e) => {
                      setManufacturer(e.target.value);
                    }}
                  >
                    {manufacturers.map((m, idx) => (
                      <FormControlLabel
                        key={idx}
                        value={m}
                        control={<Radio color="primary" />}
                        label={m}
                      />
                    ))}
                    <FormControlLabel
                      value="Other"
                      control={<Radio color="primary" />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
                {manufacturer === 'Other' && (
                  <TextField
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={otherValue}
                    onChange={(e) => {
                      setOtherValue(e.target.value);
                    }}
                    label={'Manufacturer'}
                    required
                  />
                )}
                <Box mt={2}>
                  <Button
                    id="precheck_next"
                    color="primary"
                    variant="contained"
                    onClick={handleNextStep}
                    disabled={!manufacturer || (manufacturer == 'Other' && !otherValue)}
                  >
                    Next
                  </Button>
                </Box>
              </Grid>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Select your product/category</StepLabel>
            <StepContent>
              {manufacturer === 'Other' ? (
                <Grid item xs={12} md={5}>
                  <TextField
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={product}
                    onChange={(e) => {
                      setProduct(e.target.value);
                    }}
                    label={'Other'}
                    required
                  />
                </Grid>
              ) : (
                <Grid item xs={12} md={5}>
                  <FormControl
                    style={{ width: '100%' }}
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Select
                      fullWidth
                      value={product}
                      onChange={(e) => {
                        setProduct(e.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em></em>
                      </MenuItem>
                      {products?.map((p, idx) => (
                        <MenuItem key={idx} value={p}>
                          {p}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Box mt={2}>
                <Button
                  style={{ marginRight: '10px' }}
                  color="primary"
                  variant="outlined"
                  onClick={handlePrevStep}
                >
                  Previous
                </Button>
                <Button
                  id="precheck_next"
                  color="primary"
                  variant="contained"
                  onClick={handleNextStep}
                  disabled={!product}
                >
                  Next
                </Button>
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Put in your details</StepLabel>
            <StepContent>
              <Grid item xs={12} md={5}>
                <TextField
                  fullWidth
                  error={!!errorFields.doctorsName}
                  helperText={errorFields.doctorsName}
                  margin="normal"
                  variant="outlined"
                  value={doctorsName}
                  onChange={(e) => {
                    setErrorFields({ ...errorFields, doctorsName: false });
                    setDoctorsName(e.target.value);
                  }}
                  data-testid="doctorsName"
                  label={'Doctors Name'}
                  required
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  fullWidth
                  error={!!errorFields.doctorsEmail}
                  helperText={errorFields.doctorsEmail}
                  margin="normal"
                  variant="outlined"
                  value={doctorsEmail}
                  data-testid="doctorsEmail"
                  onChange={(e) => {
                    setErrorFields({ ...errorFields, doctorsEmail: false });
                    setDoctorsEmail(e.target.value);
                  }}
                  label={'Doctors Email'}
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                style={errorFields.doctorsMobile ? {} : { marginTop: '16px' }}
              >
                <PhoneInput
                  onChange={(val) => {
                    setErrorFields({ ...errorFields, doctorsMobile: false });
                    setDoctorsMobile(val);
                  }}
                  country={doctorsMobileCountry}
                  value={doctorsMobile}
                  data-testid="doctorsPhone"
                  setCountry={setDoctorsMobileCountry}
                  error={!!errorFields.doctorsMobile}
                  helperText={errorFields.doctorsMobile}
                  label={'Doctors Mobile Number'}
                  variant="outlined"
                  required
                />
              </Grid>
              <Box mt={2}>
                <Typography style={{ marginBottom: '8px' }} variant="body1">
                  Click Get Started and we will share a link to your Email and Mobile to schedule a
                  consult with an Expert of your choice.
                </Typography>
                <Button
                  style={{ marginRight: '8px' }}
                  color="primary"
                  variant="outlined"
                  onClick={handlePrevStep}
                >
                  Previous
                </Button>
                <Button
                  id="precheck_next"
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={!doctorsName || !doctorsEmail || !doctorsMobile}
                >
                  {isSubmitting ? <CircularProgress style={{ color: 'primary' }} /> : 'GET STARTED'}
                </Button>
              </Box>
            </StepContent>
          </Step>
        </Stepper>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  doctors: Object.keys(state.webData.doctors).reduce((acc, doctor) => {
    //Only show platform doctors
    if (
      state.webData.doctors[doctor].status &&
      state.webData.doctors[doctor].status === 'platform'
    ) {
      acc[doctor] = state.webData.doctors[doctor];
    }
    return acc;
  }, {})
});

export default connect(mapStateToProps)(GetStarted);
