import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import validator from 'email-validator';

import DocumentTitle from '../../../partials/DocumentTitle';
import { _fetchUrl } from '../../../utils/api';
import LoadingIndicator from '../../shared/LoadingIndicator';

import AddDoctor from './AddDoctor';
import DisableDoctor from './DisableDoctor';
import styles from '../_styles';

class DoctorsList extends Component {
  state = {
    loading: true,
    doctors: [],
    doctor: null,
    showAddDoctor: false,
    showInviteDoctor: false,
    inviteDoctorProcessing: false,
    showDisableDoctor: false,
    error: false,
    helperText: '',
    inviteDoctorEmail: '',
    serverError: ''
  };

  componentDidMount() {
    const states = {
      loading: { loading: true, doctors: [], error: null },
      complete: { loading: false, doctors: [], error: null }
    };

    this.setState(states.loading, async () => {
      try {
        const response = await _fetchUrl({
          method: 'GET',
          path: `poe/doctor/`,
          token: this.props.user.token
        });
        if (response.status === 'ok') {
          return this.setState({
            ...states.complete,
            doctors: response.doctors.sort((a, b) => {
              //$(x).text() < $(y).text() ? -1 : 1
              return a.id < b.id ? -1 : 1;
            })
          });
        }
        return this.setState({ ...states.complete, error: response.message });
      } catch (error) {
        return this.setState({ ...states.complete, error });
      }
    });
  }

  onChange = (e) => this.setState({ inviteDoctorEmail: e.target.value });
  showAddDoctor = () => this.setState({ showAddDoctor: true });
  showInviteDoctor = () => this.setState({ showInviteDoctor: true });
  closeAddDoctor = () => this.setState({ showAddDoctor: false });
  closeInviteDoctor = () => this.setState({ showInviteDoctor: false });
  afterAddDoctor = (newDoctorId) => {
    this.closeAddDoctor();
    this.props.history.push(`${this.props.location.pathname}/${newDoctorId}`);
  };

  showDisableDoctor = (doctor) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ showDisableDoctor: true, doctor });
  };
  closeDisableDoctor = () => {
    this.setState({ showDisableDoctor: false, doctor: null });
  };

  afterDisableDoctor = () => {
    this.closeDisableDoctor();
    this.componentDidMount();
  };

  inviteDoctor = () => {
    this.setState({ inviteDoctorProcessing: true });

    if (this.state.inviteDoctorEmail && validator.validate(this.state.inviteDoctorEmail)) {
      this.setState({ error: false });
      this.setState({ helperText: '' });

      _fetchUrl({
        method: 'POST',
        path: `poe/doctor/invite`,
        body: {
          email: this.state.inviteDoctorEmail
        },
        token: this.props.user.token
      })
        .then((r) => {
          if (r.status === 'ok') {
            this.setState({ showInviteDoctor: false });
            this.props.displayNotification({ type: 'success', message: 'Invitation sent' });
            this.setState({ inviteDoctorEmail: '' });
          } else {
            console.error('Error inviting expert', r.status);
            this.setState({ serverError: r.status });
          }
        })
        .finally(() => {
          this.setState({ inviteDoctorProcessing: false });
        });
    } else {
      this.setState({ error: true });
      this.setState({ helperText: 'Invalid email provided' });
      this.setState({ inviteDoctorProcessing: false });
    }
  };

  render() {
    const { classes, history, location, user, products } = this.props;
    const { loading, doctors, doctor, showAddDoctor, showDisableDoctor } = this.state;

    if (loading) {
      return <LoadingIndicator message="Loading Doctors..." />;
    }

    const specialties = Object.keys(products).reduce((acc, key) => {
      const specialty =
        products[key].specialty.charAt(0).toUpperCase() + products[key].specialty.slice(1);
      if (!acc.includes(specialty)) {
        acc.push(specialty);
      }
      return acc;
    }, []);

    return (
      <Fragment>
        <DocumentTitle title="Admin: Doctors" />
        <Typography variant="subtitle1">Doctors</Typography>
        <Button
          onClick={this.showAddDoctor}
          className={classes.addButton}
          variant="contained"
          color="primary"
        >
          Add Doctor
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: '10px' }}
          className={classes.addButton}
          onClick={this.showInviteDoctor}
        >
          Invite Doctor
        </Button>
        {showAddDoctor && (
          <AddDoctor
            afterAddDoctor={this.afterAddDoctor}
            handleClose={this.closeAddDoctor}
            specialties={specialties}
            token={user.token}
          />
        )}
        {showDisableDoctor && (
          <DisableDoctor
            afterDisableDoctor={this.afterDisableDoctor}
            handleClose={this.closeDisableDoctor}
            doctor={doctor}
            token={user.token}
          />
        )}
        <Modal
          open={this.state.showInviteDoctor}
          onClose={this.closeInviteDoctor}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Paper style={{ width: '500px', padding: '30px' }}>
            <Alert
              style={
                this.state.serverError
                  ? { marginBottom: '20px', marginTop: '10px' }
                  : { display: 'none' }
              }
              variant="filled"
              severity="error"
              onClose={() => {
                this.setState({ serverError: '' });
              }}
            >
              {this.state.serverError}
            </Alert>
            <Typography variant="h4">Invite Doctor</Typography>

            <TextField
              fullWidth
              onChange={this.onChange}
              value={this.state.inviteDoctorEmail}
              error={this.state.error}
              helperText={this.state.helperText}
              margin="normal"
              variant="outlined"
              label={'Email address'}
              required
            />

            <Grid container justify="flex-end">
              <Button
                disabled={this.state.inviteDoctorProcessing}
                variant="contained"
                color="primary"
                style={{ marginTop: '10px', marginBottom: '10px' }}
                onClick={this.inviteDoctor}
              >
                Invite Doctor
              </Button>
            </Grid>
          </Paper>
        </Modal>
        <Paper className={classes.paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Handle</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Specialty</TableCell>
                <TableCell>Signed Agreement (Date)</TableCell>
                <TableCell>Active</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {doctors.map((doctor) => (
                <TableRow
                  key={doctor.id}
                  hover
                  className={classes.pointer}
                  onClick={() => {
                    history.push(`${location.pathname}/${doctor.id}`.replace(/\/\/+/g, '/'));
                  }}
                >
                  <TableCell component="th" scope="row">
                    {doctor.id}
                  </TableCell>
                  <TableCell>{doctor.handle}</TableCell>
                  <TableCell>{doctor.user.email}</TableCell>
                  <TableCell>{doctor.specialty}</TableCell>
                  <TableCell>
                    {doctor.doctorAgreement &&
                      new Date(doctor.doctorAgreement.signedDate).toLocaleString()}
                  </TableCell>
                  <TableCell onClick={this.showDisableDoctor(doctor)}>
                    <Typography variant="body1">
                      {doctor.user.disabled ? 'Disabled' : 'Active'}{' '}
                      {!doctor.user.disabled && (
                        <span className={classes.formHelpText}>
                          <RemoveCircleOutline className={classes.alignIconWithText} />
                        </span>
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(DoctorsList));
