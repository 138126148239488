import PartnerManagerPortal from './PartnerManagerPortal';
import { connect } from 'react-redux';
import { authPing, displayNotification } from '../../store/actions';

const mapStateToProps = (state) => ({
  user: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  authPing: (params) => dispatch(authPing(params)),
  displayNotification: (notification) => dispatch(displayNotification(notification))
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerManagerPortal);
