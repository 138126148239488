import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';

const ExpertResponse = (props) => {
  const { complexityRating, feedback, recommendations, shareNumber, timestamp } = props;
  return (
    <Grid container direction="column" component={Paper} style={{ padding: '1rem' }} spacing={1}>
      <Typography variant="h4" align="left">
        Expert Response
      </Typography>
      <Grid item container justifyContent="flex-start">
        <Typography variant="body1">Adequacy Score</Typography>
        <Typography variant="body1">{complexityRating}</Typography>
      </Grid>
      <Divider />
      <Grid item container justifyContent="flex-start">
        <Typography variant="body1">Feedback</Typography>
        <Typography variant="body1">{feedback}</Typography>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item sm>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">Recommendations </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">{recommendations}</Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      {/* <Divider /> */}
      <Grid item container justifyContent="flex-start">
        <Typography variant="body1">Share Number </Typography>
        <Typography variant="body1">{shareNumber}</Typography>
      </Grid>
      <Divider />
      <Grid item container justifyContent="flex-start">
        <Typography variant="body1">Time Stamp</Typography>
        <Typography variant="body1">
          {' : '} {moment(timestamp).format('dddd, MMMM Do YYYY, h:mm:ss a')}
        </Typography>
      </Grid>
      <Divider />
    </Grid>
  );
};

export default ExpertResponse;
