import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import * as EmailValidator from 'email-validator';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteIcon from '@material-ui/icons/Delete';
import { _fetchUrl } from '../../../utils/api';
import { displayNotification } from '../../../store/actions';

import { Button, Box, Divider, InputAdornment, TextField, Typography } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import en from 'react-phone-number-input/locale/en.json';

import { guessCountry } from '../../../utils/guessCountry';

const useStyles = makeStyles((theme) => ({
  remove: {
    color: theme.palette.error.main,
    cursor: 'pointer'
  },
  option: {
    textTransform: 'capitalize'
  },
  textfield: {
    marginBottom: '16px'
  }
}));

const params = new URLSearchParams(window.location.search);

const CaseDetails = (props) => {
  const { caseDetails, caseDetailsIndex: index, labels, reps, countries } = props;

  const [state, setState] = useState(
    caseDetails.length
      ? caseDetails
      : [
          {
            country: '',
            patientQuestion: '',
            caseDescription: '',
            category: '',
            name: params.get('doctorName') || '',
            repEmail: '',
            formData: false,
            s3Files: false
          }
        ]
  );

  const [error, setError] = useState({});
  const [, setMetadata] = useState(null);

  const countryList = useMemo(() => {
    return [{ code: '', name: '' }, ...countries.map((code) => ({ code, name: en[code] }))].sort(
      (a, b) => (a.name > b.name ? 1 : -1)
    );
  }, [countries]);

  useEffect(() => {
    if (reps) {
      const repEmail = reps.find((rep) => rep.fullName === params.get('name'))?.email;

      if (repEmail) {
        setState([
          {
            ...state[0],
            repEmail
          }
        ]);
      }
    }
  }, [reps, params]);

  // Alternatively just set to US if we don't want to derive it from the browser language
  useEffect(() => {
    if (!state[index].country) {
      setStateValue('country', guessCountry(countryList));
    }
  }, [countryList, setStateValue]);

  const loadMetadata = async () => {
    let response = await _fetchUrl({
      path: `records/metadata`
    });

    const { status, ...metadata } = response;

    if (status === 'ok') {
      setMetadata(metadata);
    }

    return metadata;
  };

  useEffect(() => {
    loadMetadata();
  }, []);

  const classes = useStyles();

  function validate() {
    let questionMap = {
      name: 'Name',
      repEmail: 'Email',
      country: 'Country',
      category: 'Category',
      caseDescription: 'Case Description',
      patientQuestion: 'Clinical Question'
    };

    const newError = {};

    for (let idx in state) {
      let errors = {};

      for (let question in state[idx]) {
        if (!['caseDescription', 'patientQuestion'].includes(question) && idx !== '0') {
          continue;
        }

        if (questionMap[question] && !state[idx][question]) {
          errors[question] = `${questionMap[question]} is required`;
        }
      }

      // Additional validation - Email
      if (idx === '0' && !error[idx]?.repEmail && !EmailValidator.validate(state[idx]?.repEmail)) {
        errors['repEmail'] = 'Search by name, or enter in an email address';
      }

      if (Object.entries(errors).length) {
        newError[idx] = errors;
      }
    }

    if (Object.entries(newError).length) {
      setError(newError);
      return false;
    } else {
      setError({});
    }

    return true;
  }

  function handleNext() {
    if (validate(index)) {
      props.onNext({ ...state });
    }
  }

  function setStateValue(key, value, idx) {
    // Using set state callback returns state as object and not array
    state[idx || index][key] = value;
    setState([...state]);
  }

  function handleRepEmail(evt, newValue) {
    state[index]['repEmail'] = newValue.email;
    setState([...state]);
  }
  function getExampleText() {
    switch (state[0].category) {
      case 'Introducing contact lenses to patients':
        return 'I need guidance in introducing a new modality (e.g. switching from reusable to daily disposable lens) to patients?.\n\nI need guidance introducing new CL product.';
      case 'Staff Training':
        return 'Effective staff product and service training.\n\nOptimizing the in-office patient experience for Alcon product users.';
      case 'Multifocal Lenses':
        return 'Strategies for improving distance vision with patients.';
      case 'Toric Lenses':
        return 'Lens selection tips.';
      case 'Sphere Lenses':
        return 'Lens selection tips.';
      default:
        return 'How do I...';
    }
  }

  return (
    <React.Fragment>
      <TextField
        id="name"
        fullWidth
        margin="normal"
        className={classes.textfield}
        variant="outlined"
        value={state[index]?.name}
        onChange={(e) => setStateValue('name', e.target.value)}
        label="Doctor's Name"
        name="Name"
        error={error[index]?.name !== undefined && error[index]?.name !== ''}
        helperText={error[index]?.name}
        required
      />
      {reps.length === 0 ? (
        <TextField
          fullWidth
          margin="normal"
          name="RepEmail"
          variant="outlined"
          value={state[index]?.repEmail}
          onChange={(e) => setStateValue('repEmail', e.target.value)}
          label={labels.repField}
          error={error[index]?.repEmail !== undefined && error[index]?.repEmail !== ''}
          helperText={error[index]?.repEmail || labels.repFieldHelper || ''}
          required
        />
      ) : (
        <Autocomplete
          freeSolo
          id="rep"
          name="RepEmail"
          disableClearable
          classes={{
            option: classes.option
          }}
          value={reps?.find((v) => v.email === state[index]?.repEmail) || null}
          options={reps}
          getOptionLabel={(option) => option.fullName}
          onChange={handleRepEmail}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              value={state.repEmail}
              onChange={(e) =>
                setStateValue(
                  'repEmail',
                  reps.find((v) => v.fullName === e.target.value)?.email || e.target.value
                )
              }
              label={labels.repField}
              margin="normal"
              variant="outlined"
              error={error[index]?.repEmail !== undefined && error[index]?.repEmail !== ''}
              helperText={error[index]?.repEmail}
              InputProps={{
                ...params.InputProps,
                id: 'repFiled',
                type: 'search',
                // if safari doesn't honor this, we might have to try hacky solutions such as
                // https://stackoverflow.com/questions/43058018/how-to-disable-autocomplete-in-address-fields-for-safari
                autoComplete: 'new-password',
                form: {
                  autocomplete: 'off'
                },
                endAdornment: (
                  <>
                    <InputAdornment position="end" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                      <ArrowDropDownIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                )
              }}
              required
            />
          )}
        />
      )}

      <TextField
        id="category"
        select
        fullWidth
        margin="normal"
        className={classes.textfield}
        SelectProps={{ native: true }}
        variant="outlined"
        disabled={index !== 0}
        value={state[index]?.category}
        label={labels.category}
        error={error[index]?.category !== undefined && error[index]?.category !== ''}
        helperText={error[index]?.category}
        onChange={(e) => {
          setStateValue(
            'categoryChanged',
            state[0].category && state[0].category !== e.target.value
          );
          setStateValue('category', e.target.value);
        }}
        required
      >
        <option></option>

        <optgroup label="Best Practices">
          <option value="Introducing contact lenses to patients">
            Introducing contact lenses to patients
          </option>
          <option value="Retaining CL Patients">Retaining CL Patients</option>
          <option value="Staff Training">Staff Training</option>
        </optgroup>
        <optgroup label="General troubleshooting">
          <option value="Multifocal Lenses">Multifocal Lenses</option>
          <option value="Toric Lenses">Toric Lenses</option>
          <option value="Sphere Lenses">Sphere Lenses</option>
        </optgroup>
      </TextField>
      {state.map((newCase, idx) => {
        return (
          <div key={idx}>
            {!!idx && (
              <div>
                <span style={{ height: 31, marginTop: -16, marginRight: 8, float: 'left' }}>
                  <Typography variant="h5" style={{ float: 'left' }}>
                    Case {idx + 1}
                  </Typography>
                  <DeleteIcon
                    onClick={() => {
                      state.splice(idx, 1);
                      setState([...state]);
                    }}
                    style={{
                      cursor: 'pointer',
                      marginTop: 3,
                      marginLeft: 4
                    }}
                    fontSize="small"
                    color="error"
                  />
                </span>
                <span>
                  <Divider style={{ marginTop: 32, marginBottom: 24 }} />
                </span>
              </div>
            )}
            {
              <TextField
                id="description"
                fullWidth
                multiline
                rows="6"
                margin="normal"
                className={classes.textfield}
                variant="outlined"
                value={newCase.caseDescription}
                onChange={(e) => {
                  setStateValue('caseDescription', e.target.value, idx);
                  setStateValue('patientQuestion', '.', idx);
                }}
                label="Request Details"
                placeholder={getExampleText(labels.caseDescriptionExample)}
                helperText="Note: Requests may not be patient specific."
                error={
                  error[idx]?.caseDescription !== undefined && error[idx]?.caseDescription !== ''
                }
                required
              />
            }
          </div>
        );
      })}

      <Box mt={2} style={{ marginTop: '8px' }}>
        <Button
          id="cd_next"
          style={{ marginTop: 8 }}
          color="primary"
          variant="contained"
          onClick={handleNext}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  displayNotification: (notification) => dispatch(displayNotification(notification))
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseDetails);
