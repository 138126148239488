import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

const DoctorBioLink = ({ link, handleUpdateDoctorBioLink }) => {
  const [bLink, setBLink] = useState(link);

  const handleBlur = () => {
    handleUpdateDoctorBioLink('bioLink', bLink);
  };

  return (
    <FormControl fullWidth={true} margin={'normal'}>
      <Typography variant="subtitle1">Biography link</Typography>
      <Input value={bLink} onBlur={handleBlur} onChange={(event) => setBLink(event.target.value)} />
    </FormControl>
  );
};

DoctorBioLink.propTypes = {
  link: PropTypes.string.isRequired,
  handleUpdateDoctorBioLink: PropTypes.func.isRequired
};

export default DoctorBioLink;
