import { Box, Button, CircularProgress, Grid, TextField, makeStyles } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import Confirm from '../../../../utils/confirm';

const useStyles = makeStyles(() => ({
  spacing: {
    marginBottom: '8px'
  }
}));

const SuggestTime = ({ requestTime, timezone, handleBack, handleOnSubmit, loading }) => {
  const [date1, setDate1] = useState({ dow: '', time: '', text: '' });
  const [date2, setDate2] = useState({ dow: '', time: '', text: '' });
  const [date3, setDate3] = useState({ dow: '', time: '', text: '' });

  const classes = useStyles();

  const nextDay = useMemo(() => {
    if (
      date1.text.indexOf('urgent') !== -1 ||
      date2.text.indexOf('urgent') !== -1 ||
      date3.text.indexOf('urgent') !== -1
    ) {
      return true;
    }
    return false;
  }, [date1, date2, date3]);

  const ChoicePicker = ({
    id,
    index,
    date,
    time,
    onDateChange,
    onTimeChange,
    label,
    allowAllTimes
  }) => {
    const m =
      timezone?.code == 'other'
        ? moment(new Date(requestTime)).utcOffset(timezone?.value)
        : momentTimezone(new Date(requestTime)).tz(timezone.code);
    const dates = React.useMemo(() => {
      const toRet = [];
      let count = 1;

      while (toRet.length < 7) {
        let date = m.clone();
        date.add(count++, 'days');
        toRet.push({ date });
      }

      return toRet;
    }, [allowAllTimes, m]);

    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" style={{ gap: '16px' }}>
          <TextField
            id={id}
            select
            fullWidth
            margin="normal"
            className={classes.spacing}
            variant="outlined"
            SelectProps={{ native: true }}
            InputLabelProps={{
              shrink: true
            }}
            value={date}
            onChange={onDateChange}
            label={label || 'Day'}
            size="small"
          >
            <option value=""></option>
            {dates.map((a) => {
              return (
                <option
                  key={a.date.valueOf()}
                  value={a.date.format('dddd, MMMM Do')}
                >{`${a.date.format('ddd, MMM Do')}`}</option>
              );
            })}
          </TextField>

          <Box minWidth={115}>
            <TextField
              id={id + '_2'}
              select
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              SelectProps={{ native: true }}
              value={time}
              onChange={onTimeChange}
              label="Time"
              size="small"
            >
              <option value=""></option>
              {(() => {
                const elements = [];
                const taken = [];
                let current = '';

                switch (index) {
                  case 1:
                    current = date1.dow;
                    taken.push(date2.dow + date2.time);
                    taken.push(date3.dow + date3.time);
                    break;
                  case 2:
                    current = date2.dow;
                    taken.push(date1.dow + date1.time);
                    taken.push(date3.dow + date3.time);
                    break;
                  case 3:
                    current = date3.dow;
                    taken.push(date1.dow + date1.time);
                    taken.push(date2.dow + date2.time);
                    break;
                }

                [
                  '6:00 AM',
                  '7:00 AM',
                  '8:00 AM',
                  '9:00 AM',
                  '10:00 AM',
                  '11:00 AM',
                  '12:00 PM',
                  '1:00 PM',
                  '2:00 PM',
                  '3:00 PM',
                  '4:00 PM',
                  '5:00 PM',
                  '6:00 PM',
                  '7:00 PM',
                  '8:00 PM',
                  '9:00 PM'
                ].map((k, i) => {
                  let disabled = taken.includes(current + k) ? true : false;
                  elements.push(
                    <option value={k} key={i} disabled={disabled}>
                      {k}
                    </option>
                  );
                });

                return elements;
              })()}
            </TextField>
          </Box>
        </Box>
      </Box>
    );
  };

  const areDatesSet = () => {
    if (!date1.dow || !date2.dow || !date3.dow || !date1.time || !date2.time || !date3.time) {
      return false;
    }
    return true;
  };

  function handleSubmit() {
    if (!areDatesSet()) {
      Confirm('All fields are required.', false);
      return;
    }
    handleOnSubmit({
      availability: [date1, date2, date3]
    });
  }

  const setDate = (setter) => (e) => {
    const target = e.target;
    setter(() => ({
      dow: target.value,
      time: '',
      text: target.options[target.selectedIndex].text.toLowerCase()
    }));
  };

  const setTime = (setter) => (e) => {
    const target = e.target;
    setter((p) => ({ ...p, time: target.value }));
  };

  return (
    <>
      <Button
        onClick={handleBack}
        style={{ marginTop: '-10px' }}
        type="button"
        variant="outlined"
        color="primary"
        disabled={loading}
      >
        <ArrowBackIcon style={{ marginRight: '5px' }} /> Back
      </Button>

      <Box sx={{ mt: 3 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ChoicePicker
              id="slot1"
              index={1}
              allowAllTimes={nextDay}
              label="Time Slot 1*"
              date={date1.dow}
              time={date1.time}
              onDateChange={setDate(setDate1)}
              onTimeChange={setTime(setDate1)}
            />
          </Grid>
          <Grid item xs={12}>
            <ChoicePicker
              id="slot2"
              index={2}
              allowAllTimes={nextDay}
              label="Time Slot 2*"
              date={date2.dow}
              time={date2.time}
              onDateChange={setDate(setDate2)}
              onTimeChange={setTime(setDate2)}
            />
          </Grid>
          <Grid item xs={12}>
            <ChoicePicker
              id="slot3"
              index={3}
              allowAllTimes={nextDay}
              label="Time Slot 3*"
              date={date3.dow}
              time={date3.time}
              onDateChange={setDate(setDate3)}
              onTimeChange={setTime(setDate3)}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              onClick={handleSubmit}
              style={{ marginTop: '10px' }}
              type="button"
              variant="outlined"
              color="primary"
              fullWidth
              disabled={loading}
            >
              {loading ? <CircularProgress size={25} /> : 'Submit'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SuggestTime;
