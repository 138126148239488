import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  typography: {
    h2: {
      fontSize: '3.5rem'
    },
    h4: {
      fontSize: '1.25rem'
    }
  }
});
