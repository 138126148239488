import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

// import partners_acu_1x from './logos/acu@1x.png';
// import partners_acu_2x from './logos/acu@2x.png';
import partners_alcon_1x from './logos/alcon@1x.png';
import partners_alcon_2x from './logos/alcon@2x.png';
import partners_bausch_1x from './logos/bausch-lomb@1x.png';
import partners_bausch_2x from './logos/bausch-lomb@2x.png';
import partners_cornea_1x from './logos/cornea@1x.png';
import partners_cornea_2x from './logos/cornea@2x.png';
import partners_glaukos_1x from './logos/glaukos@1x.png';
import partners_glaukos_2x from './logos/glaukos@2x.png';
import partners_invantis_1x from './logos/invantis@1x.png';
import partners_invantis_2x from './logos/invantis@2x.png';
import partners_zeiss_1x from './logos/zeiss@1x.png';
import partners_zeiss_2x from './logos/zeiss@2x.png';
import partners_jjv from './logos/jjv.png';

export default ({ compact, shadow }) => {
  const useStyles = makeStyles((theme) => ({
    logos: {
      padding: '24px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '24px',
      alignItems: 'center',
      textAlign: 'center',
      ...(compact
        ? {
            // alignItems: 'center',
            // margin: '0px',
            // '& #bausch, & #zeiss, & #alcon, & #invantis, & #glaukos, & #acu, & #cornea': {
            //   margin: '15px -20px'
            // }
          }
        : {}),
      [theme.breakpoints.down('md')]: {
        // alignItems: 'center',
        // margin: '10px 0',
        // '& #bausch, & #zeiss, & #alcon, & #invantis, & #glaukos, & #cornea': {
        //   margin: '10px -10px'
        // },
        // '& #acu': {
        //   margin: '10px 30px'
        // }
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    }
  }));

  const classes = useStyles();

  return (
    <Paper style={shadow ? { width: '100%' } : { width: '100%', boxShadow: 'none' }}>
      <div className={`${classes.logos}`}>
        <img id="jjv" src={partners_jjv} alt="jjv" />
        <img
          id="bausch"
          src={partners_bausch_1x}
          srcSet={`${partners_bausch_1x} 1x, ${partners_bausch_2x} 2x`}
          alt="bausch"
        />
        <img
          id="zeiss"
          src={partners_zeiss_1x}
          srcSet={`${partners_zeiss_1x} 1x, ${partners_zeiss_2x} 2x`}
          alt="zeiss"
        />
        <img
          id="alcon"
          src={partners_alcon_1x}
          srcSet={`${partners_alcon_1x} 1x, ${partners_alcon_2x} 2x`}
          alt="alcon"
        />
        <img
          id="invantis"
          src={partners_invantis_1x}
          srcSet={`${partners_invantis_1x} 1x, ${partners_invantis_2x} 2x`}
          alt="invantis"
        />
        <img
          id="glaukos"
          src={partners_glaukos_1x}
          srcSet={`${partners_glaukos_1x} 1x, ${partners_glaukos_2x} 2x`}
          alt="glaukos"
        />
        <img
          id="cornea"
          src={partners_cornea_1x}
          srcSet={`${partners_cornea_1x} 1x, ${partners_cornea_2x} 2x`}
          alt="cornea"
        />
      </div>
    </Paper>
  );
};
