import React, { useMemo } from 'react';
import EOTable from '../../../partials/EOTable.js';

import { _fetchUrl } from '../../../utils/api';

const ManageProducts = (props) => {
  const { token, partnerId, products, setProducts } = props;

  const formattedProducts = useMemo(() => {
    return Object.keys(products).map((product) => ({
      product,
      totalDoctors: products[product].length
    }));
  }, [products]);

  const columns = [
    {
      field: 'product',
      title: 'Products'
    },
    {
      field: 'totalDoctors',
      title: 'Total Doctors',
      editComponent: () => <div></div>
    }
  ];

  let updatePartnerProduct = (data, resolve, reject) => {
    const { newName, oldName } = data;
    _fetchUrl({
      method: 'PUT',
      path: `poe/partner/${partnerId}/product/${newName}`,
      body: {
        oldName
      },
      token
    }).then((r) => {
      if (r.status === 'ok') {
        let newProducts = { ...products, [newName]: [] };

        if (oldName) {
          newProducts[newName] = newProducts[oldName];
          delete newProducts[oldName];
        }

        setProducts(() => newProducts);
        resolve();
      } else {
        reject();
        console.error('Error loading partners:', r.error);
      }
    });
  };

  let deletePartnerExpert = (productName, resolve, reject) => {
    _fetchUrl({
      method: 'DELETE',
      path: `poe/partner/${partnerId}/product/${productName}`,
      token
    }).then((r) => {
      if (r.status === 'ok') {
        setProducts((products) => {
          let newProducts = Object.assign({}, products);
          delete newProducts[productName];
          return newProducts;
        });
        resolve();
      } else {
        reject();
        console.error('Error loading partners:', r.error);
      }
    });
  };

  return (
    <React.Fragment>
      <EOTable
        style={{ margin: '25px 0' }}
        title="Manage Products"
        data={formattedProducts}
        columns={columns}
        pageSize={10}
        localization={{
          body: {
            emptyDataSourceMessage: 'Partner has no products',
            deleteTooltip: 'Remove',
            editRow: {
              deleteText: 'Are you sure you want to remove this product?'
            }
          }
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              updatePartnerProduct({ newName: newData.product }, resolve, reject);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              updatePartnerProduct(
                { newName: newData.product, oldName: oldData.product },
                resolve,
                reject
              );
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              deletePartnerExpert(oldData.product, resolve, reject);
            })
        }}
      ></EOTable>
    </React.Fragment>
  );
};

export default ManageProducts;
