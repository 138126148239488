import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

const AuditLog = ({ auditLog }) => {
  const sortedArray = auditLog.sort((a, b) => {
    return a.timestamp - b.timestamp;
  });

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Source</TableCell>
            <TableCell>Time Stamp</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedArray.map((item, index) => (
            <TableRow key={item.timestamp}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{item.action}</TableCell>
              <TableCell>{item.source}</TableCell>
              <TableCell>
                {moment(item.timestamp).format('dddd, MMMM Do YYYY, h:mm:ss a')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AuditLog;
