// provides helpers for transforming product names to url friendly

function stringToUrl(str) {
  return str.replace(/[^a-zA-Z\s]+/g, '').replace(/\s+/g, '-');
}

// creates a unique string key from the product hiearchy
export function productToUrlKey(product) {
  return `${stringToUrl(product.specialty)}/${stringToUrl(product.category)}/${stringToUrl(
    product.name
  )}`;
}

export function urlParamsToKey(specialty, category, name) {
  return `${specialty}/${category}/${name}`;
}
