import React from 'react';
import hero from './hero.png';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { Link } from '../../partials/Link';
import Partners from '../shared/Partners';
import PartnersBottom from '../shared/Partners/bottom';

import DoctorList from '../Experts/DoctorList';

import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundImage: `url(${hero})`,
    backgroundSize: 'cover',
    height: '600px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      height: '300px'
    }
  },
  sidebar: {
    padding: theme.spacing(2),
    marginRight: theme.spacing(1),
    height: '100%'
  },
  speciality: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '1.5rem',
    display: 'inline',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem'
    }
  },
  adaptiveHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem'
    }
  },
  subtitle: {
    marginTop: '-16px',
    marginBottom: '32px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem'
    }
  },
  highlight: {
    color: theme.palette.primary.main
  },
  cta: {
    display: 'flex',
    gap: '16px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  }
}));

function getName(type) {
  switch (type) {
    case 'Ophthalmology':
      return 'MDs';
    case 'Optometry':
      return 'ODs';
    default:
      return '';
  }
}

const Homepage = (props) => {
  const classes = useStyles();

  const [type, setType] = React.useState('Ophthalmology');

  const doctors = React.useMemo(() => {
    //filter the doctors object if their speciality matches the type
    if (props.doctors) {
      return Object.keys(props.doctors).reduce((acc, doctor) => {
        if (props.doctors[doctor].specialty === type.toLowerCase()) {
          acc[doctor] = props.doctors[doctor];
        }
        return acc;
      }, {});
    }

    return {};
  }, [props.doctors, type]);

  const toggleType = () => {
    setType(type === 'Ophthalmology' ? 'Optometry' : 'Ophthalmology');
  };

  return (
    <div style={{ width: '100%', marginBottom: '75px' }}>
      <div className={classes.hero}>
        <Typography
          className={classes.subtitle}
          align="center"
          color="secondary"
          variant="h2"
          gutterBottom
        >
          Speak to a peer <span className={classes.highlight}>Expert</span>
        </Typography>
        <div className={classes.cta}>
          <Link base={'site'} path="/eo-partnerships">
            <Tooltip placement="right" title="For Industry">
              <Button color="primary" variant="contained" size="large">
                ⭐ &nbsp; Partner with expert opinion
              </Button>
            </Tooltip>
          </Link>
          <Link base={'site'} path="/doctor-support">
            <Tooltip placement="right" title="For Medical Practioners">
              <Button style={{ width: '100%' }} color="secondary" variant="outlined" size="large">
                I am a DOCTOR
              </Button>
            </Tooltip>
          </Link>
        </div>
      </div>
      <Container>
        <Box
          // display="flex"
          // flexDirection="row"
          // alignItems="center"
          // justifyContent="space-between"
          py={4}
          px={1}
        >
          <Typography
            variant="h4"
            align="center"
            color="primary"
            className={classes.adaptiveHeader}
          >
            Our Partners
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="flex-start">
          <Partners />
        </Box>
      </Container>

      <Container>
        <Box py={4} px={1}>
          <Typography
            variant="h4"
            color="primary"
            align="center"
            className={classes.adaptiveHeader}
          >
            Our Expert {getName(type)}{' '}
            <Button variant="text" onClick={toggleType}>
              {type === 'Ophthalmology' ? 'View ODs' : 'View MDs'}
            </Button>
          </Typography>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="flex-start">
          <DoctorList doctors={doctors} />
        </Box>

        <div style={{ marginTop: '36px' }}>
          <PartnersBottom />
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  urls: state.webData.urls,
  products: state.webData.products,
  doctors: Object.keys(state.webData.doctors).reduce((acc, doctor) => {
    //Only show platform doctors
    if (
      state.webData.doctors[doctor].status &&
      state.webData.doctors[doctor].status === 'platform'
    ) {
      acc[doctor] = state.webData.doctors[doctor];
    }
    return acc;
  }, {})
});

export default withRouter(connect(mapStateToProps)(Homepage));
