import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

const FormSection = ({ children }) => (
  <Grid item sm={12} md={8} style={{ marginDown: '30px' }}>
    <Paper style={{ padding: '40px 50px' }}>
      <Grid container direction="column" spacing={3} style={{ marginBottom: '15px' }}>
        {children}
      </Grid>
    </Paper>
  </Grid>
);

const FormField = ({ autoFocus, label, value, onChange, type, error, helperText, multiline }) => (
  <Grid item xs={12}>
    <TextField
      error={error}
      helperText={helperText}
      autoFocus={autoFocus}
      InputLabelProps={{ shrink: true }}
      label={label}
      fullWidth
      value={value}
      onChange={onChange}
      type={type || 'text'}
      multiline={multiline}
    />
  </Grid>
);

const Payment = ({ classes, price, setPrice, submitting, handleBack, handleNext }) => {
  const [errorFields, setErrorFields] = useState({});

  //
  // @dev - errorFields will be set numerically, falsy values (primarily undefined/false) will be used to indicate no errors.
  //        Values >=1 will be used as the element key for error message.
  //
  let ERROR_MESSAGE = {
    name: ['Price field is empty']
  };

  // Make index base 1
  ERROR_MESSAGE = new Proxy(ERROR_MESSAGE, {
    get(target, prop) {
      return [undefined, ...target[prop]];
    }
  });

  return (
    <div className={classes.content}>
      <FormSection>
        <FormField
          label="Price"
          error={!!errorFields.price}
          helperText={errorFields.price ? ERROR_MESSAGE.price[errorFields.price] : ''}
          autoFocus={true}
          value={price}
          onChange={(evt) => {
            setErrorFields({ ...errorFields, price: false });
            setPrice(evt.target.value);
          }}
        />
      </FormSection>
      <Button variant="outlined" onClick={handleBack} className={classes.button}>
        Back
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleNext}
        className={classes.button}
        // Could set a different minimum
        disabled={(isNaN(parseInt(price)) && !(price * 1 > 0)) || submitting}
      >
        Submit
      </Button>
    </div>
  );
};

export default Payment;
