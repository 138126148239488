import React, { useState, useEffect, useMemo } from 'react';
import EOTable from '../../../partials/EOTable.js';
import { getCountries } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';
import { Select, MenuItem, Checkbox } from '@material-ui/core';

import { _fetchUrl } from '../../../utils/api';

const ManageCountries = (props) => {
  const { partnerId, token } = props;

  const [countries, setCountries] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [international, setInternational] = useState(false);

  useEffect(() => {
    setAllCountries(getCountries());
  }, []);

  const remainingCountries = useMemo(() => {
    return allCountries.filter(
      (country) => !countries.some((pCountry) => pCountry.code === country)
    );
  }, [countries]);

  useEffect(() => {
    _fetchUrl({
      method: 'GET',
      path: `partner/${partnerId}/countries`,
      token
    }).then((r) => {
      setCountries(r.data);
    });
  }, [partnerId, token]);

  let addCountry = (data, resolve, reject) => {
    _fetchUrl({
      method: 'POST',
      path: `poe/partner/${partnerId}/country`,
      token,
      body: {
        code: selectedCountry,
        international
      }
    }).then((r) => {
      if (r.status === 'ok') {
        setCountries((countries) => [...countries, { code: selectedCountry, international }]);
        resolve();
      } else {
        reject();
        console.error('Error loading countries:', r.error);
      }
    });
  };

  let deleteCountry = (code, resolve, reject) => {
    _fetchUrl({
      method: 'DELETE',
      path: `poe/partner/${partnerId}/country/${code}`,
      token
    }).then((r) => {
      if (r.status === 'ok') {
        setCountries((countries) => countries.filter((country) => country.code !== code));
        resolve();
      } else {
        reject();
        console.error('Error loading countries:', r.error);
      }
    });
  };

  const columns = [
    {
      field: 'code',
      title: 'Code',
      render: ({ code }) => code,
      editComponent: () => (
        <Select
          style={{ width: '100%' }}
          value={selectedCountry}
          onChange={(val) => setSelectedCountry(val.target.value)}
        >
          {remainingCountries.map((country, idx) => (
            <MenuItem key={idx} value={country}>
              {country}
            </MenuItem>
          ))}
        </Select>
      )
    },
    {
      field: 'code',
      title: 'Name',
      render: ({ code }) => en[code],
      editComponent: () => (
        <Select
          style={{ width: '100%' }}
          value={selectedCountry}
          onChange={(val) => setSelectedCountry(val.target.value)}
        >
          {remainingCountries.map((country, idx) => (
            <MenuItem key={idx} value={country}>
              {en[country]}
            </MenuItem>
          ))}
        </Select>
      )
    },
    {
      field: 'international',
      title: 'International',
      render: ({ international }) => (international ? 'Yes' : 'No'),
      editComponent: () => (
        <Checkbox
          defaultChecked={international}
          onChange={(e) => setInternational(e.target.checked)}
          color="primary"
        />
      )
    }
  ];

  return (
    <EOTable
      style={{ margin: '25px 0' }}
      title="Countries"
      data={countries}
      columns={columns}
      pageSize={10}
      localization={{
        body: {
          emptyDataSourceMessage: 'No countries. Using default countries.',
          editRow: {
            deleteText: 'Are you sure you want to remove this country?'
          }
        }
      }}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            addCountry(newData, resolve, reject);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            deleteCountry(oldData.code, resolve, reject);
          })
      }}
    ></EOTable>
  );
};

export default ManageCountries;
