import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

const DoctorStatus = ({ doctorStatus, handleUpdateDoctorStatus }) => (
  <FormControl fullWidth={true} margin={'normal'}>
    <Typography variant="subtitle1">Status</Typography>
    <Select
      native
      value={doctorStatus || ''}
      inputProps={{ name: 'status', id: 'status' }}
      onChange={handleUpdateDoctorStatus}
    >
      <option value="" disabled={true} />
      <option value={'private'}>Private (Does not appear anywhere on site)</option>
      <option value={'public'}>Public (Only appears through direct URL)</option>
      <option value={'platform'}>
        Platform (Appears through direct URL & Get an Expert Opinion)
      </option>
    </Select>
  </FormControl>
);

DoctorStatus.propTypes = {
  doctorStatus: PropTypes.string.isRequired,
  handleUpdateDoctorStatus: PropTypes.func.isRequired
};

export default DoctorStatus;
