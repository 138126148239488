import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AppleBadge from './apple-badge.png';
import GoogleBadge from './google-badge.png';

const MobileApp = () => {
  return (
    <Grid container justifyContent="center" style={{ marginTop: '50px' }}>
      <Grid item xs={12} style={{ marginBottom: '-20px' }}>
        <Typography align="center" variant="h2" gutterBottom>
          Thank you!
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: '20px' }}>
        <Typography align="center" variant="h5" gutterBottom>
          Download our mobile app and login with your email and password.
        </Typography>
      </Grid>
      <Grid item style={{ marginRight: '15px' }}>
        <a
          href="https://apps.apple.com/us/app/expertopinion-md/id1464566521"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={AppleBadge} alt="Apple App Store" />
        </a>
      </Grid>
      <Grid item>
        <a
          href="https://play.google.com/store/apps/details?id=md.expertopinion.expertopinionmd&gl=US"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={GoogleBadge} alt="Google Play Store" />
        </a>
      </Grid>
    </Grid>
  );
};

export default MobileApp;
