import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  wrapper: { textAlign: 'center' },
  message: { marginBottom: theme.spacing(2) }
});

class LoadingIndicator extends Component {
  static defaultProps = {
    delay: 500,
    message: 'Loading...'
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    delay: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired
  };

  state = { displayLoader: false };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  componentDidMount() {
    this.timer = setTimeout(() => this.setState({ displayMessage: true }), this.props.delay);
  }

  render() {
    const { classes, message } = this.props;
    const { displayMessage } = this.state;

    if (!displayMessage) {
      return null;
    }

    return (
      <div className={classes.wrapper}>
        <Typography variant="subtitle1" className={classes.message}>
          {message}
        </Typography>
        <CircularProgress size={240} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LoadingIndicator);
