import React, { useState, useEffect, useMemo } from 'react';

import { _fetchUrl } from '../../../utils/api';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import ManageExperts from './ManageExperts';
import ManageProducts from './ManageProducts';
import ManageSurveys from './ManageSurveys';
import ManageCountries from './ManageCountries';
import ManageReports from './ManageReports';
import ManageConsults from './ManageConsults';

function Slider(props) {
  let { names, selectedPartner, setSelectedPartner } = props;

  const [open, setOpen] = useState(false);

  let left = () => {
    setSelectedPartner((curr) => curr - 1);
  };
  let right = () => {
    setSelectedPartner((curr) => curr + 1);
  };
  let handleOpen = () => {
    if (!open) setOpen(true);
  };
  let handleClose = () => {
    setOpen(false);
  };
  let handleChange = (event) => {
    setSelectedPartner(event.target.value);
  };

  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'center', fontSize: '2em' }}>
        <KeyboardArrowLeftIcon
          style={{
            visibility: selectedPartner ? 'visible' : 'hidden',
            fontSize: '1.5em',
            cursor: 'pointer'
          }}
          onClick={left}
        />
        <div
          onClick={handleOpen}
          style={{ minWidth: '250px', height: '35px', marginTop: '8px', textAlign: 'center' }}
        >
          <InputLabel
            style={{ fontSize: '.9em', color: '#111', fontWeight: '400', marginTop: '1px' }}
          >
            {names[selectedPartner]}
          </InputLabel>
          <Select
            style={{ visibility: 'hidden', height: '0' }}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={selectedPartner}
            onChange={handleChange}
          >
            {names.map((name, idx) => {
              return (
                <MenuItem key={idx} value={idx}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <KeyboardArrowRightIcon
          style={{
            visibility: selectedPartner !== names.length - 1 ? 'visible' : 'hidden',
            fontSize: '1.5em',
            cursor: 'pointer'
          }}
          onClick={right}
        />
      </div>
    </React.Fragment>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

function VerticalTabs(props) {
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const partnerNames = useMemo(() => {
    return partners.map((partner) => {
      return partner.name;
    });
  }, [partners]);

  const questions = useMemo(() => {
    if (partners.length) {
      if (partners[selectedPartner].surveyQuestions) {
        return partners[selectedPartner].surveyQuestions;
      } else {
        return {};
      }
    }
  }, [selectedPartner]);

  useEffect(() => {
    _fetchUrl({
      method: 'GET',
      path: 'poe/partner',
      token: props.user.token
    }).then((r) => {
      if (r.status === 'ok') {
        setPartners(r.partners);
        setSelectedPartner(0);
        setLoaded(true);
      } else {
        console.error('Error loading partners:', r.error);
      }
    });
  }, []);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      {loaded ? (
        <div className={classes.root}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleTabChange}
            className={classes.tabs}
          >
            <Tab label="Experts" {...a11yProps(0)} />
            <Tab label="Products" {...a11yProps(1)} />
            <Tab label="Surveys" {...a11yProps(2)} />
            <Tab label="Countries" {...a11yProps(3)} />
            <Tab label="Reports" {...a11yProps(4)} />
            <Tab label="Consults" {...a11yProps(5)} />
          </Tabs>
          <Grid container>
            <Grid item xs={12}>
              <Slider
                names={partnerNames}
                selectedPartner={selectedPartner}
                setSelectedPartner={setSelectedPartner}
              />

              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TabPanel value={value} index={0}>
                <ManageExperts
                  partnerId={partners[selectedPartner].id}
                  token={props.user.token}
                  displayNotification={props.displayNotification}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ManageProducts
                  {...props}
                  partnerId={partners[selectedPartner].id}
                  token={props.user.token}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ManageSurveys
                  questions={questions}
                  partnerId={partners[selectedPartner].id}
                  products={Object.keys(partners[selectedPartner].products)}
                  token={props.user.token}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <ManageCountries
                  partnerId={partners[selectedPartner].id}
                  token={props.user.token}
                />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <ManageReports
                  displayNotification={props.displayNotification}
                  partnerId={partners[selectedPartner].id}
                  token={props.user.token}
                />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <ManageConsults
                  displayNotification={props.displayNotification}
                  partnerId={partners[selectedPartner].id}
                  token={props.user.token}
                />
              </TabPanel>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <CircularProgress />
        </div>
      )}
    </React.Fragment>
  );
}

const ManagePartner = (props) => {
  return <VerticalTabs {...props} />;
};

export default ManagePartner;
