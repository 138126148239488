export default [
  {
    name: 'doctor',
    label: `Doctor's Name`
  },
  {
    name: 'doctorType',
    label: 'Doctor Type',
    connect: true
  },
  {
    name: 'clinic',
    label: 'Clinic Name'
  },
  {
    name: 'phone',
    label: 'Clinic Phone',
    type: 'tel',
    helpText: '(999) 999-9999'
  },
  {
    name: 'address',
    label: 'Clinic Address'
  },
  {
    name: 'treatmentDate',
    label: 'Date of Visit',
    type: 'date'
  }
];
