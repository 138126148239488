import Opinion from './Opinion';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  user: state.auth
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Opinion);
