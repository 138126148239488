import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Done from '@material-ui/icons/Done';

import DocumentTitle from '../../partials/DocumentTitle';

import MedicalRecord from '../shared/MedicalRecord';

import { getMedicalRecordForm } from 'eo_utils';

const styles = (theme) => ({
  root: { margin: theme.spacing(2.5) },
  done: {
    color: '#82E67E',
    fontSize: '20em',
    display: 'block',
    margin: '0 auto'
  }
});

class MedicalRecordsUpload extends Component {
  state = {
    apiResult: false
  };

  render() {
    const { classes } = this.props;
    const { productId, medicalRecordId, signedToken } = this.props.match.params;

    const { apiResult } = this.state;

    const title = apiResult ? 'Medical Records Upload Complete' : 'Medical Records Upload';

    return (
      <div className={classes.root}>
        <DocumentTitle title={title} />
        <Typography gutterBottom variant="h4">
          {title}
        </Typography>

        {apiResult ? (
          <Fragment>
            <Done className={classes.done} />
            <Typography gutterBottom variant="subtitle1">
              Thank you for your prompt upload of patient medical records. Your upload is complete
              and you can close this browser window.
            </Typography>
            {typeof apiResult === 'string' && (
              <div
                style={{
                  border: '1px solid #c8c8c8',
                  marginTop: '20px',
                  borderRadius: '2px',
                  padding: '10px',
                  marginBottom: '30px',
                  backgroundColor: '#f5f5f5'
                }}
              >
                <Typography variant="h5" gutterBottom>
                  Error
                </Typography>
                <Typography variant="body1">{apiResult}</Typography>
              </div>
            )}

            {Array.isArray(apiResult) && (
              <div
                style={{
                  border: '1px solid #c8c8c8',
                  marginTop: '20px',
                  borderRadius: '2px',
                  padding: '10px',
                  marginBottom: '30px',
                  backgroundColor: '#f5f5f5'
                }}
              >
                <Typography variant="h5" gutterBottom>
                  Error
                </Typography>
                {apiResult.map((result, idx) => (
                  <Typography key={idx} variant="body1">
                    {result.name} - {result.message}
                  </Typography>
                ))}
              </div>
            )}
            <Typography gutterBottom variant="subtitle1">
              If you have any questions, you can email us at{' '}
              <a href="mailto:records@expertopinion.md">records@expertopinion.md</a>.
            </Typography>
          </Fragment>
        ) : (
          <Grid container>
            <Grid item md={4}>
              <MedicalRecord
                formFields={getMedicalRecordForm(productId)}
                productId={productId}
                medicalRecordId={medicalRecordId}
                signedToken={signedToken}
                afterSave={(apiResult) => this.setState({ apiResult })}
              />
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(MedicalRecordsUpload));
