import React, { useState, useEffect, useMemo } from 'react';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { _fetchUrl } from '../../../utils/api';

const FormMultiField = ({ label, value, setValue, error, errorMessage, style = {}, onChange }) => {
  return (
    <Autocomplete
      multiple
      style={{ ...{ padding: '0 12px', marginBottom: '20px' }, ...style }}
      options={[]}
      value={value}
      freeSolo
      onChange={(e, val, reason) => {
        e.preventDefault();
        e.stopPropagation();
        if (val && ['create-option', 'remove-option', 'clear'].includes(reason)) {
          onChange();
          return setValue([...val]);
        }
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          helperText={error ? errorMessage[error] : ''}
          // placeholder="Options"
          InputLabelProps={{ shrink: true }}
          label={label}
          onBlur={(e) => {
            if (e.target.value) {
              onChange();
              setValue([...value, e.target.value]);
            }
          }}
        />
      )}
    />
  );
};

const ManageConsults = ({ partnerId, token, displayNotification }) => {
  const [keywords, setKeywords] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [errorFields, setErrorFields] = useState({});

  const buttonDisabled = useMemo(() => {
    return isSubmitting;
  }, [isSubmitting]);

  // @dev - errorFields will be set numerically, falsy values (primarily undefined/false) will be used to indicate no errors.
  //        Values >=1 will be used as the element key for error message.

  let ERROR_MESSAGE = {
    keywords: []
  };

  // Make index base 1
  ERROR_MESSAGE = new Proxy(ERROR_MESSAGE, {
    get(target, prop) {
      return [undefined, ...target[prop]];
    }
  });

  useEffect(() => {
    _fetchUrl({
      method: 'GET',
      path: `poe/partner/${partnerId}/keywords`,
      token
    }).then((r) => {
      if (r.status === 'ok') {
        setKeywords(r.consultKeywords || []);
      } else {
        console.error('r', r);
      }
    });
  }, [partnerId, token]);

  const handleSubmit = async () => {
    if (Object.values(errorFields).some((val) => val)) {
      return;
    }

    setIsSubmitting(true);

    const result = await _fetchUrl({
      method: 'PUT',
      path: `poe/partner/${partnerId}/keywords`,
      token,
      body: {
        keywords
      }
    });

    setIsSubmitting(false);

    switch (result.status) {
      case 'ok':
        displayNotification({ type: 'success', message: 'Saved' });
        break;
      default:
        setErrorText(result.message);
        break;
    }
  };

  // Set box shadow to match table shadow for consistency
  return (
    <Paper
      style={{
        margin: '25px 0',
        padding: '25px',
        minHeight: '200px',
        boxShadow:
          '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
      }}
    >
      <Alert
        style={errorText ? { marginBottom: '20px', marginTop: '10px' } : { display: 'none' }}
        variant="filled"
        severity="error"
        onClose={() => {
          setErrorText('');
        }}
      >
        {errorText}
      </Alert>
      <Typography
        gutterBottom
        variant="h5"
        align="left"
        color="primary"
        style={{ marginBottom: '20px' }}
      >
        Keywords
      </Typography>
      <FormMultiField
        value={keywords}
        setValue={setKeywords}
        onChange={() => setErrorFields({ ...errorFields, keywords: false })}
        error={!!errorFields.keywords}
        errorMessage={ERROR_MESSAGE.keywords}
      />
      <Grid container justifyContent="flex-end">
        <Button
          onClick={handleSubmit}
          style={{ color: 'white', marginTop: '20px' }}
          disabled={buttonDisabled}
          type="submit"
          variant="contained"
          color="primary"
        >
          {isSubmitting ? <CircularProgress style={{ color: 'primary' }} /> : <span>Save</span>}
        </Button>
      </Grid>
    </Paper>
  );
};

export default ManageConsults;
