import React, { Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import BaseFileComponent from './BaseFileComponent';

const styles = (theme) => {
  const css = {
    pointer: { cursor: 'pointer' },
    result: {
      marginBottom: theme.spacing(2)
    },
    error: {
      color: theme.palette.error.main
    }
  };
  css.error = { ...css.error, ...css.result };
  return css;
};

class DoctorAvatarFileComponent extends BaseFileComponent {
  state = { avatar: this.props.avatar };

  postUpload = (response) => {
    document.getElementById('doctorAvatar').src = `${response.avatar}?${new Date().getTime()}`;
  };

  render = () => {
    const { fileType, classes, token, method, path, doctorName } = this.props;
    const { fileData, submitted, result, error, avatar } = this.state;

    const avatarInput = document.getElementById(`${fileType}File`);
    const avatarSrc =
      avatarInput && avatarInput.files && avatarInput.files.length
        ? URL.createObjectURL(avatarInput.files[0])
        : avatar;
    const avatarImg = (
      <img
        id="doctorAvatar"
        src={avatarSrc}
        alt={doctorName}
        style={{
          maxWidth: '280px',
          maxHeight: '280px',
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'block',
          outline: '1px solid #000'
        }}
      />
    );

    return (
      <Fragment>
        <Typography variant="subtitle1">Doctor Photo</Typography>
        {this.state[fileType] && this[fileType] && Array.from(this[fileType].files).length ? (
          <div style={{ textAlign: 'center' }}>
            {!submitted && avatarImg}
            <Button
              style={{ color: 'white', margin: '10px 0' }}
              variant="contained"
              color="primary"
              onClick={this.uploadFile(
                this[fileType],
                fileType,
                token,
                path,
                this.postUpload,
                method
              )}
              disabled={submitted || !fileData.length}
            >
              {submitted ? <CircularProgress /> : <span>Upload Photo</span>}
            </Button>
            <Typography variant="body1">Photo will be resized to 235x235</Typography>
            {!submitted && (
              <Typography
                variant="body1"
                className={classes.pointer}
                onClick={this.resetFile(fileType)}
                style={{ fontSize: '75%' }}
              >
                Reset
              </Typography>
            )}
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            {avatarImg}
            <Button
              style={{ margin: '10px 0' }}
              variant="contained"
              onClick={(e) => e.currentTarget.nextSibling.click()}
            >
              Attach Photo
            </Button>
            <input
              style={{ display: 'none' }}
              id={`${fileType}File`}
              type={'file'}
              accept=".png, .jpg, .jpeg"
              onChange={this.selectFile(fileType)}
              ref={(ref) => (this[fileType] = ref)}
            />
            {error && (
              <Typography variant="body1" className={classes.error}>
                {error}
              </Typography>
            )}
            {result && (
              <Typography variant="body1" className={classes.result}>
                File Uploaded!
              </Typography>
            )}
          </div>
        )}
      </Fragment>
    );
  };
}

export default withStyles(styles, { withTheme: true })(DoctorAvatarFileComponent);
