import { getOffsetForTimeZoneValue } from './timezones';

export const guessCountry = (countries) => {
  try {
    const loc = new Intl.Locale(window.navigator.language);

    const guess = countries.find((country) => country.code === loc.region);

    if (guess) {
      return loc.region;
    }
  } catch (e) {
    return 'US';
  }

  return 'US';
};

export const guessTimeZone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    return 'America/Los_Angeles';
  }
};

export const guessTimeZoneWithGMT = () => {
  let timezone = guessTimeZone();

  return {
    code: timezone,
    value: getOffsetForTimeZoneValue(timezone)
  };
};

export const getTimeZones = () => {
  try {
    return Intl.supportedValuesOf('timeZone');
  } catch (e) {
    return [
      'America/Anchorage',
      'America/Los_Angeles',
      'America/Boise',
      'America/Chicago',
      'America/New_York',
      'Pacific/Honolulu'
    ];
  }
};
