const OPEN_FILEVIEWER = 'OPEN_FILEVIEWER';
export const openFileViewer = (file) => ({
  type: OPEN_FILEVIEWER,
  file
});

const CLOSE_FILEVIEWER = 'CLOSE_FILEVIEWER';
export const closeFileViewer = () => ({
  type: CLOSE_FILEVIEWER
});

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case OPEN_FILEVIEWER:
      return { ...state, ...action.file };
    case CLOSE_FILEVIEWER:
      return {};
    default:
      return state;
  }
};
