"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMedicalRecordForm = exports.calculatePatientPrice = void 0;
/*
  This function takes a doctorPrice, adds our markup (50% default which yields 1/3 margin), and rounds up to the nearest ceiling (default is 25).
*/
var calculatePatientPrice = exports.calculatePatientPrice = function calculatePatientPrice(doctorPrice) {
  var markupPercent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 50;
  var ceiling = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 25;
  return Math.ceil(parseInt(doctorPrice, 10) * (1 + markupPercent / 100) / ceiling) * ceiling;
};

// Removed video support for ogg/ogv/flv due to video conversion issue
var medicalRecordForms = {
  'default': {
    name: 'Default',
    directions: [{
      variant: 'subtitle1',
      content: 'Use the form below to securely upload medical records to our HIPAA compliant secure records service. If you have questions you can always email us at <a href=\'mailto:care@expertopinion.md\'>care@expertopinion.md</a>. We\'re here for you 24/7.'
    }],
    categories: [{
      name: 'general instructions',
      directions: [],
      fields: [{
        name: 'patientFiles',
        label: 'Patient Medical Record Files',
        type: 'files',
        accept: '.png, .jpg, .jpeg, .pdf'
      }]
    }, {
      name: 'Surgery Video',
      directions: [],
      fields: [{
        name: 'videoFiles',
        label: 'Patient Survey Video Files',
        type: 'files',
        optional: true,
        accept: '.avi, .mov, .webm, .wmv, .mov, .3g2, .3gp, .mkv, .mpg, .mp4, .mpeg'
      }]
    }],
    response: {
      directions: [{
        variant: 'subtitle1',
        content: 'Use the form below to respond to the doctors questions, and optionally send information to the patient.'
      }],
      categories: [{
        name: 'General',
        fields: [{
          name: 'doctorNotes',
          label: 'Notes for the Doctor',
          type: 'text',
          rowsMax: 80,
          "private": true
        }, {
          name: 'patientNotes',
          label: 'Notes for the Patient',
          type: 'text',
          rowsMax: 80
        }]
      }]
    }
  },
  '65d95c1c-3fd4-4606-8168-f4f611f342ae': {
    name: 'Limbal Stem Cell Transplant',
    directions: [{
      variant: 'subtitle1',
      content: 'Use the form below to upload medical records to us. If you have questions you can always email us at <a href=\'mailto:care@expertopinion.md\'>care@expertopinion.md</a>. We\'re here for you 24/7.'
    }],
    categories: [{
      name: 'General',
      fields: [{
        name: 'patientName',
        label: 'Patient Name',
        type: 'text'
      }, {
        name: 'patientDob',
        label: 'Patient Date of Birth',
        type: 'date'
      }, {
        name: 'patientComplaint',
        label: 'Chief Complaint',
        type: 'text',
        rowsMax: 12
      }]
    }, {
      name: 'Medical History',
      directions: [{
        variant: 'subtitle2',
        content: 'For any fields that have not changed from the patient\'s previous visit, please indicate by answering \'No Change\'.'
      }],
      fields: [{
        name: 'pastOcularSurgeryHistory',
        label: 'Past Ocular Surgery History and Dates',
        type: 'text',
        rowsMax: 12
      }, {
        name: 'pastSurgeryHistory',
        label: 'Past (Non-Ocular) Surgery History and Dates',
        type: 'text',
        rowsMax: 12
      }, {
        name: 'currentDropsDoseAndFrequency',
        label: 'Current Eye Drops (Dose & Frequency)',
        type: 'text',
        rowsMax: 12
      }, {
        name: 'currentSystemicMedicationsDoseAndFrequency',
        label: 'Current Systemic Medications (Dose & Frequency)',
        type: 'text',
        rowsMax: 12
      }, {
        name: 'rightEyeInfo',
        label: 'OD',
        type: 'subHeader',
        fields: [{
          name: 'glassesVisionRight',
          label: 'Vision with Glasses',
          type: 'text'
        }, {
          name: 'iopRight',
          label: 'IOP',
          type: 'text'
        }, {
          name: 'eyeIrritationRight',
          label: 'Eye Irritation (0-10)',
          type: 'number',
          min: 0,
          max: 10
        }, {
          name: 'eyeRednessRight',
          label: 'Eye Redness (0-10)',
          type: 'number',
          min: 0,
          max: 10
        }, {
          name: 'anteriorChamberInflamationRight',
          label: 'Presence of Anterior Chamber Inflamation',
          type: 'select',
          options: [{
            name: '1',
            label: '1+'
          }, {
            name: '2',
            label: '2+'
          }, {
            name: '3',
            label: '3+'
          }, {
            name: '4',
            label: '4+'
          }, {
            name: 'cell',
            label: 'Cell'
          }]
        }]
      }, {
        name: 'leftEyeInfo',
        label: 'OS',
        type: 'subHeader',
        fields: [{
          name: 'glassesVisionLeft',
          label: 'Vision with Glasses',
          type: 'text'
        }, {
          name: 'iopLeft',
          label: 'IOP',
          type: 'text'
        }, {
          name: 'eyeIrritationLeft',
          label: 'Eye Irritation (0-10)',
          type: 'number',
          min: 0,
          max: 10
        }, {
          name: 'eyeRednessLeft',
          label: 'Eye Redness (0-10)',
          type: 'number',
          min: 0,
          max: 10
        }, {
          name: 'anteriorChamberInflamationLeft',
          label: 'Presence of Anterior Chamber Inflamation',
          type: 'select',
          options: [{
            name: '1',
            label: '1+'
          }, {
            name: '2',
            label: '2+'
          }, {
            name: '3',
            label: '3+'
          }, {
            name: '4',
            label: '4+'
          }, {
            name: 'cell',
            label: 'Cell'
          }]
        }]
      }, {
        name: 'sideEffects',
        label: 'Systemic Side Effects of Medications',
        type: 'checkbox',
        optional: true,
        options: [{
          name: 'chestPain',
          label: 'Chest pain'
        }, {
          name: 'constipation',
          label: 'Constipation'
        }, {
          name: 'diarrhea',
          label: 'Diarrhea'
        }, {
          name: 'dizziness',
          label: 'Dizziness'
        }, {
          name: 'fatigue',
          label: 'Fatigue'
        }, {
          name: 'fever',
          label: 'Fever'
        }, {
          name: 'headache',
          label: 'Headache'
        }, {
          name: 'malaise',
          label: 'Malaise'
        }, {
          name: 'palpitations',
          label: 'Palpitations'
        }, {
          name: 'shortnessOfBreath',
          label: 'Shortness of breath'
        }, {
          name: 'tremorsHandsFeet',
          label: 'Tremors in hands or feet'
        }]
      }, {
        name: 'otherSideEffects',
        label: 'Other Side Effects',
        type: 'text',
        rowsMax: 4,
        optional: true
      }, {
        name: 'additionaHistory',
        label: 'Additional Medical History',
        type: 'text',
        rowsMax: 12,
        optional: true
      }]
    }, {
      name: 'Labs',
      fields: [{
        name: 'lastLabsDate',
        label: 'Date of Last Completed Labs',
        type: 'date'
      }]
    }, {
      name: 'Exam',
      directions: [{
        variant: 'subtitle2',
        content: 'For any fields that have not changed from the patient\'s previous visit, please indicate by answering \'No Change\'.'
      }],
      fields: [{
        name: 'conjunctiva',
        label: 'Conjunctiva',
        type: 'text'
      }, {
        name: 'cornea',
        label: 'Cornea',
        type: 'header',
        fields: [{
          name: 'epithelium',
          type: 'subHeader',
          fields: [{
            name: 'epitheliumNormal',
            label: 'Epithelium',
            type: 'checkbox',
            optional: true,
            options: [{
              name: 'epitheliumNormalChecked',
              label: 'Normal'
            }]
          }, {
            name: 'epitheliumOther',
            label: 'Other',
            type: 'text',
            optional: true
          }]
        }, {
          name: 'stroma',
          type: 'subHeader',
          fields: [{
            name: 'stromaNormal',
            label: 'Stroma',
            type: 'checkbox',
            optional: true,
            options: [{
              name: 'stromaNormalChecked',
              label: 'Normal'
            }]
          }, {
            name: 'stromaOther',
            label: 'Other',
            type: 'text',
            optional: true
          }]
        }, {
          name: 'endothelium',
          type: 'subHeader',
          fields: [{
            name: 'endotheliumNormal',
            label: 'Endothelium',
            type: 'checkbox',
            optional: true,
            options: [{
              name: 'endotheliumNormalChecked',
              label: 'Normal'
            }]
          }, {
            name: 'endotheliumOther',
            label: 'Other',
            type: 'text',
            optional: true
          }]
        }]
      }, {
        name: 'ac',
        label: 'AC',
        type: 'text'
      }, {
        name: 'iris',
        label: 'Iris',
        type: 'text'
      }, {
        name: 'lens',
        label: 'Lens',
        type: 'text'
      }]
    }, {
      name: 'Images',
      directions: [{
        variant: 'subtitle1',
        content: 'Expert Opinion recommends the <a href="https://www.digitaleyecenter.com/product/universal-smartphone-slit-lamp-adapter/" target="_blank">Universal Smartphone Slit Lamp Adapter</a>.'
      }],
      fields: [{
        name: 'externalPhoto',
        label: 'External Photo (OU)',
        type: 'file',
        accept: '.png, .jpg, .jpeg, .pdf'
      }, {
        name: 'slitLampPhoto',
        label: 'Slit Lamp Photo (Affected Eye)',
        type: 'fileSubHeader',
        fields: [{
          name: 'slitLampPhotoRight',
          label: 'Slit Lamp Photo (OD Affected Eye)',
          type: 'file',
          accept: '.png, .jpg, .jpeg, .pdf'
        }, {
          name: 'slitLampPhotoLeft',
          label: 'Slit Lamp Photo (OS Affected Eye)',
          type: 'file',
          accept: '.png, .jpg, .jpeg, .pdf'
        }]
      }, {
        name: 'earlyFluoroscein',
        label: 'Early Fluorescein Stain Image',
        type: 'fileSubHeader',
        fields: [{
          name: 'earlyFluorosceinPrimary',
          label: 'Primary Gaze',
          type: 'fileSubHeader',
          fields: [{
            name: 'earlyFluorosceinPrimaryRight',
            label: 'Primary Gaze (OD)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }, {
            name: 'earlyFluorosceinPrimaryLeft',
            label: 'Primary Gaze (OS)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }]
        }, {
          name: 'earlyFluorosceinSuperior',
          label: 'Superior Gaze',
          type: 'fileSubHeader',
          fields: [{
            name: 'earlyFluorosceinSuperiorRight',
            label: 'Superior Gaze (OD)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }, {
            name: 'earlyFluorosceinSuperiorLeft',
            label: 'Superior Gaze (OS)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }]
        }, {
          name: 'earlyFluorosceinLeft',
          label: 'Left Gaze',
          type: 'fileSubHeader',
          fields: [{
            name: 'earlyFluorosceinLeftRightEye',
            label: 'Left Gaze (OD)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }, {
            name: 'earlyFluorosceinLeftLeftEye',
            label: 'Left Gaze (OS)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }]
        }, {
          name: 'earlyFluorosceinRight',
          label: 'Right Gaze',
          type: 'fileSubHeader',
          fields: [{
            name: 'earlyFluorosceinRightRightEye',
            label: 'Right Gaze (OD)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }, {
            name: 'earlyFluorosceinRightLeftEye',
            label: 'Right Gaze (OS)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }]
        }, {
          name: 'earlyFluorosceinInferior',
          label: 'Inferior Gaze',
          type: 'fileSubHeader',
          fields: [{
            name: 'earlyFluorosceinInferiorRight',
            label: 'Inferior Gaze (OD)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }, {
            name: 'earlyFluorosceinInferiorLeft',
            label: 'Inferior Gaze (OS)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }]
        }]
      }, {
        name: 'lateFluoroscein',
        label: 'Late Fluorescein Stain Image',
        type: 'fileSubHeader',
        fields: [{
          name: 'lateFluorosceinPrimary',
          label: 'Primary Gaze',
          type: 'fileSubHeader',
          fields: [{
            name: 'lateFluorosceinPrimaryRight',
            label: 'Primary Gaze (OD)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }, {
            name: 'lateFluorosceinPrimaryLeft',
            label: 'Primary Gaze (OS)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }]
        }, {
          name: 'lateFluorosceinSuperior',
          label: 'Superior Gaze',
          type: 'fileSubHeader',
          fields: [{
            name: 'lateFluorosceinSuperiorRight',
            label: 'Superior Gaze (OD)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }, {
            name: 'lateFluorosceinSuperiorLeft',
            label: 'Superior Gaze (OS)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }]
        }, {
          name: 'lateFluorosceinLeft',
          label: 'Left Gaze',
          type: 'fileSubHeader',
          fields: [{
            name: 'lateFluorosceinLeftRightEye',
            label: 'Left Gaze (OD)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }, {
            name: 'lateFluorosceinLeftLeftEye',
            label: 'Left Gaze (OS)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }]
        }, {
          name: 'lateFluorosceinRight',
          label: 'Right Gaze',
          type: 'fileSubHeader',
          fields: [{
            name: 'lateFluorosceinRightRightEye',
            label: 'Right Gaze (OD)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }, {
            name: 'lateFluorosceinRightLeftEye',
            label: 'Right Gaze (OS)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }]
        }, {
          name: 'lateFluorosceinInferior',
          label: 'Inferior Gaze',
          type: 'fileSubHeader',
          fields: [{
            name: 'lateFluorosceinInferiorRight',
            label: 'Inferior Gaze (OD)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }, {
            name: 'lateFluorosceinInferiorLeft',
            label: 'Inferior Gaze (OS)',
            type: 'file',
            accept: '.png, .jpg, .jpeg, .pdf'
          }]
        }]
      }]
    }],
    response: {
      directions: [],
      categories: [{
        name: 'General',
        fields: [{
          name: 'followUp',
          label: 'Follow up status',
          type: 'radio',
          options: [{
            name: 'stable',
            label: 'Everything is stable, no changes'
          }, {
            name: 'coordinate',
            label: 'Change in management required, Elizabeth to coordinate care'
          }]
        }, {
          name: 'followUpInterval',
          label: 'Next suggested follow up',
          type: 'select',
          options: [{
            name: '1-week',
            label: '1 week'
          }, {
            name: '4-6-weeks',
            label: '4-6 weeks'
          }, {
            name: '3-4-months',
            label: '3-4 months'
          }, {
            name: '6-months',
            label: '6 months'
          }, {
            name: '1-year',
            label: '1 year'
          }, {
            name: 'other',
            label: 'other'
          }]
        }, {
          name: 'providerComments',
          label: 'Comments to Provider',
          type: 'text',
          rowsMax: 80,
          "private": true
        }, {
          name: 'patientComments',
          label: 'Comments to the Patient',
          type: 'text',
          rowsMax: 80
        }]
      }]
    }
  }
};
var getMedicalRecordForm = exports.getMedicalRecordForm = function getMedicalRecordForm(productId) {
  var form = medicalRecordForms[productId] || medicalRecordForms["default"];
  return JSON.parse(JSON.stringify(form));
};
