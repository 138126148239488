import App from './App';
import { connect } from 'react-redux';
import { saveWebDataToStore } from '../../store/actions';

const mapStateToProps = (state) => ({
  fileViewer: state.fileViewer
});

const mapDispatchToProps = (dispatch) => ({
  saveWebDataToStore: (webData) => dispatch(saveWebDataToStore(webData))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
