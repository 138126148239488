import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function EOModal({
  children,
  title,
  postTitle,
  open,
  handleClose,
  keepMounted,
  disableEnforceFocus,
  fullScreen = true
}) {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen={fullScreen}
      keepMounted={keepMounted}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      disableEnforceFocus={disableEnforceFocus}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {postTitle}
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
}
