import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { authPing } from '../../../store/actions';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import FilePreViewer from '../../shared/FilePreViewer';
import { _fetchUrl } from '../../../utils/api';

const MedicalRecords = (props) => {
  const { match, authPing, user } = props;

  const [consultId, setConsultId] = useState(null);
  const [data, setData] = useState(null);
  const [content, setContent] = useState(
    <div style={{ textAlign: 'center', marginTop: 96 }}>
      <CircularProgress />
    </div>
  );

  const [state, setState] = useState({
    isError: false,
    errorMessage: ''
  });

  useEffect(() => {
    if (authPing) {
      authPing({
        type: ['D2D_EXPERT'],
        redirect: props.history.location
      });
    }
  }, [authPing]);

  useEffect(() => {
    if (match && match.params && match.params.consultId) {
      setConsultId(match.params.consultId);
    }
  }, [match]);

  useEffect(() => {
    if (!user || !user.token || !consultId) {
      return;
    }

    _fetchUrl({
      method: 'GET',
      path: `partner/consult/${consultId}`,
      token: user.token
    }).then(async (res) => {
      try {
        res = await res.json();
      } catch (error) {
        console.log(error);
      }

      if (res.status !== 'ok') {
        setState({ isError: true, errorMessage: res.status });
      } else {
        setData(res.data);
        setState({ isError: false });
      }
    });
  }, [user]);

  useEffect(() => {
    if (state.isError) {
      setContent(
        <Alert severity="error" style={{ margin: 16 }}>
          <AlertTitle>Could not display medical records</AlertTitle>
          <Typography variant="body1">
            Unfortunately, we weren't able to display medical records.
            <strong>System Response:</strong> <i>{state.errorMessage}</i>
            <p>
              If you have additional questions, reach out to{' '}
              <a href="mailto:laurie@expertopinion.md">Laurie</a> or{' '}
              <a href="mailto://aidan@expertopinion.md">Aidan</a>.
            </p>
          </Typography>
        </Alert>
      );
    } else if (data) {
      setContent(
        <div style={{ padding: '16px' }}>
          <div
            style={{
              border: '1px solid #c8c8c8',
              borderRadius: '2px',
              padding: '8px',
              marginBottom: '32px',
              backgroundColor: '#f5f5f5'
            }}
          >
            <Typography variant="h5" gutterBottom>
              Case Summary
            </Typography>

            <Typography variant="body1">Requesting Surgeon: {data.customer}</Typography>
            <Typography variant="body1">Expert Opinion Surgeon: {data.expert}</Typography>
            <Typography variant="body1">
              Consult Time: {moment(data.callTime).format('MMM Do, YYYY hh:mm a')}
            </Typography>
          </div>

          <Typography variant="h5" gutterBottom>
            VideoURL:
          </Typography>
          <a href={data.videoURL} target="_blank" rel="noreferrer">
            {data.videoURL}
          </a>

          <Typography variant="h5" gutterBottom style={{ marginTop: '32x' }}>
            Medical Record Files
          </Typography>
          <Grid container spacing={2}>
            {data.medicalRecords.map((record, idx) => (
              <Grid item key={idx}>
                <FilePreViewer file={record} />
              </Grid>
            ))}
          </Grid>

          <Typography variant="h5" gutterBottom style={{ marginTop: '32px' }}>
            Video Record Files
          </Typography>
          <Grid container spacing={2}>
            {data.videoRecords
              .filter((video) => Object.keys(video).length)
              .map((mr, idx) => {
                return (
                  <Grid item key={idx} style={{ padding: 8, width: 300, height: 200 }}>
                    {typeof mr === 'string' ? (
                      <div>{mr} - PROCESSING</div>
                    ) : (
                      <FilePreViewer width={70} file={mr} simple />
                    )}
                  </Grid>
                );
              })}
          </Grid>
        </div>
      );
    }
  }, [state]);

  return content;
};

const mapStateToProps = (state) => ({
  user: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  authPing: (params) => dispatch(authPing(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(MedicalRecords);
