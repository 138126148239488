/* global FS */

import { TrackJS } from 'trackjs';
import { push } from 'connected-react-router';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { _fetchUrl } from '../../utils/api';
import { displayNotification } from '../actions';

// Redux Selectors
const authSelector = (state) => state.auth;
const typeSelector = (state) => state.auth.type;

// Action Constants
const AFTER_LOGIN = 'AFTER_LOGIN';
const LOGOUT = 'LOGOUT';

const READ_AUTH = 'READ_AUTH';
const AUTH_PING = 'AUTH_PING';
const CHANGE_TIMEZONE = 'CHANGE_TIMEZONE';

// Helpers
const storage = localStorage;
const setAuthToStorage = (auth) => storage.setItem('EXOP_AUTH', JSON.stringify(auth));
const getAuthFromStorage = () => {
  try {
    return JSON.parse(storage.getItem('EXOP_AUTH'));
  } catch (error) {
    return null;
  }
};
const removeAuthFromStorage = () => storage.removeItem('EXOP_AUTH');

// API Methods
const authPingApi = (token) =>
  _fetchUrl({
    method: 'GET',
    path: 'auth/ping',
    token
  });

// Action Creators
export const afterLogin = (user) => {
  return {
    type: AFTER_LOGIN,
    user
  };
};
export const loadAuth = () => ({
  type: READ_AUTH
});
export const authPing = (params = {}) => ({
  type: AUTH_PING,
  userType: params.type,
  success: params.success,
  redirect: params.redirect
});
export const logout = () => ({
  type: LOGOUT
});
export const changeTimezone = (timezone) => {
  const auth = getAuthFromStorage();
  if (auth) {
    auth.timezone = timezone;
    setAuthToStorage(auth);
  }
  return {
    type: CHANGE_TIMEZONE,
    timezone
  };
};
function* handleNotAuthed(redirectUrl, message = 'You have been logged out.') {
  yield put(displayNotification({ message }));
  yield put(push(redirectUrl));
  yield put(logout());
}

// Redux Sagas
export function* handleAfterLogin(action) {
  if (process.env.REACT_APP_EO_ENV !== 'dev') {
    TrackJS.configure({ userId: action.user.userId });
    TrackJS.addMetadata('userEmail', action.user.email);
    TrackJS.addMetadata('isMobileApp', window._eo.isMobileApp);
  }
  if (window.FS) {
    FS.identify(action.user.userId, { email: action.user.email });
  }

  yield call(setAuthToStorage, {
    token: action.user.token,
    type: action.user.type,
    name: action.user.name,
    email: action.user.email,
    doctorId: action.user.doctorId,
    patientId: action.user.patientId,
    timezone: action.user.timezone
  });
}

function* handleReadAuth() {
  const auth = getAuthFromStorage();
  if (auth) {
    auth.type = Array.isArray(auth.type) ? auth.type : [auth.type];
  }

  yield put(afterLogin(auth));
}

function* handleAuthPing(params = {}) {
  yield put(loadAuth());

  const redirectUrl = `/login${params.redirect && `?redirect=${params.redirect.pathname}`}`;
  const auth = yield select(authSelector);

  if (!auth || !auth.token) {
    yield handleNotAuthed(redirectUrl);
  } else {
    try {
      const result = yield call(authPingApi, auth.token);

      if (result.status !== 'ok') {
        yield handleNotAuthed(redirectUrl);
      } else {
        const userType = yield select(typeSelector);

        if (
          params.userType &&
          params.userType.filter((value) => userType.includes(value)).length === 0
        ) {
          yield put(
            displayNotification({
              message: `You can not access that section because you are not a ${params.userType}.`
            })
          );

          let url = '/';
          if (userType.includes('DOCTOR')) {
            url = '/doctor';
          } else if (userType.includes('PATIENT')) {
            url = '/patient';
          } else if (userType.includes('EO_ADMIN')) {
            url = '/admin';
          }
          yield put(push(url));
        } else {
          if (params.success) {
            params.success(auth);
          }
        }
      }
    } catch (e) {
      yield handleNotAuthed(redirectUrl);
    }
  }
}

function* handleLogout() {
  yield call(removeAuthFromStorage);
}

export function* saga() {
  yield takeLatest(AFTER_LOGIN, handleAfterLogin);
  yield takeLatest(READ_AUTH, handleReadAuth);
  yield takeLatest(AUTH_PING, handleAuthPing);
  yield takeLatest(LOGOUT, handleLogout);
}

// Redux Store Reducers
export function reducer(state = null, action) {
  switch (action.type) {
    case AFTER_LOGIN: {
      const { user = { name: '' } } = action;

      const names = user.name.split(/(\s+)/);
      const firstName = names.length >= 1 ? names[0] : null;
      const lastName = names.length >= 1 ? names[names.length - 1] : null;

      const ret = {
        ...state,
        token: user.token,
        email: user.email,
        name: user.name,
        firstName,
        lastName,
        salutation: user.type.includes('DOCTOR') ? `Dr. ${lastName}` : firstName,
        type: user.type,
        doctorId: user.doctorId,
        patientId: user.patientId,
        timezone: user.timezone
      };

      return ret;
    }
    case LOGOUT:
      return {};
    case CHANGE_TIMEZONE:
      return {
        ...state,
        timezone: action.timezone
      };
    default:
      return state;
  }
}
