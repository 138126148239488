import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import convertTime from 'eo_convertTime';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '../../../../partials/Link';
import { guessTimeZoneWithGMT } from '../../../../utils/guessCountry';
import Confirm from '../../../../utils/confirm';
import './style.css';
import TimezoneList from '../../../shared/timezoneList';
import SuggestTime from './SuggestTime';
import { Box } from '@material-ui/core';
import { _fetchUrl } from '../../../../utils/api';

const URL_BASE = `${process.env.REACT_APP_API_BASE_URL}/v1/partner`;
const URL_TIMEZONE_BASE = `${process.env.REACT_APP_API_BASE_URL}/v1/auth`;

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  button: {
    color: theme.palette.primary.main + '!important',
    border: 'solid ' + theme.palette.primary.main
  },
  error: {
    color: theme.palette.error.main
  }
}));

const Accept = ({ match }) => {
  const classes = useStyles();

  const [token, setToken] = useState(null);
  const [data, setData] = useState(null);
  const [content, setContent] = useState(
    <div style={{ textAlign: 'center', marginTop: 96 }}>
      <CircularProgress />
    </div>
  );

  const history = useHistory();

  const [state, setState] = useState({
    isError: false,
    alreadyScheduled: false,
    errorMessage: ''
  });

  const [timezone, setTimezone] = useState({
    submitted: false,
    apiError: null
  });

  const [timezoneValue, setTimezoneValue] = useState(guessTimeZoneWithGMT);
  const [doctorTimezone, setDoctorTimezone] = useState(null);
  const [timezoneError, setTimezoneError] = useState('');
  const [showSuggestTime, setShowSuggestTime] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleSuggestTime = () => {
    setShowSuggestTime(!showSuggestTime);
  };

  const onSuggestTime = async (data) => {
    setIsLoading(true);
    const body = {
      timezone:
        timezoneValue.code === 'other' && timezoneValue.value != ''
          ? timezoneValue
          : { code: 'America/Los_Angeles', value: '' },
      token,
      ...data
    };
    await _fetchUrl({
      method: 'POST',
      path: `partner/suggest-alternate-time`,
      body: body
    })
      .then((r) => {
        console.log('rrrr', r);
        if (r.status == 'suggested_alternate_time') {
          setIsLoading(false);
          history.push('/consult/confirm/suggested_alternate_time');
        } else throw new Error(r);
      })
      .catch(async (error) => {
        console.error('error', error);
        await Confirm('Failed to update alternate time suggestions.');
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (match && match.params && match.params.token) {
      const onlyToken = match.params.token.split('&')[0];
      setToken(onlyToken);
    }
  }, [match]);

  let preferredTimes = useMemo(() => {
    if (!data?.times || !Object.keys(timezoneValue).length || timezoneValue === 'Choose a value')
      return [];

    return convertTime(data.times, data.requestedAt, data.consultTimezone, timezoneValue).map(
      (value, idx) => ({
        value,
        url: data.times[idx].url
      })
    );
  }, [timezoneValue, data]);

  useEffect(() => {
    if (!token) {
      return;
    }

    window
      .fetch(`${URL_BASE}/accept/${token}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(async (res) => {
        try {
          res = await res.json();
        } catch (error) {
          console.error(error);
        }

        if (res.status !== 'ok') {
          if (res.status === 'This consult request has already been scheduled.') {
            setState({ isError: false, alreadyScheduled: true });
          } else {
            setState({ isError: true, errorMessage: res.status });
          }
        } else {
          if (res.data.doctorTimezone) {
            setTimezoneValue(res.data.doctorTimezone);
            setDoctorTimezone(res.data.doctorTimezone);
          } else {
            const tz = guessTimeZoneWithGMT();
            setTimezoneValue(tz);
          }

          setData(res.data);
          setState({ sError: false });
        }
      });
  }, [token]);

  const confirmAndRedirect = async (event, url, msg) => {
    if (!msg) throw new Error('Message is required');
    if (!url) throw 'This consult cannot be scheduled at this time. Email care@expertopinion.md';

    event.preventDefault();

    if (!(await Confirm(msg))) {
      return;
    }

    history.push(url);
  };

  const handleSubmitChangeTimezone = useCallback(
    async (event) => {
      event.preventDefault();

      let newTimezoneError = '';

      if (
        !timezoneValue ||
        !Object.keys(timezoneValue).length ||
        timezoneValue === 'Choose a value'
      ) {
        newTimezoneError = 'Required';
      } else if (timezoneValue?.code != 'other' && moment.tz.zone(timezoneValue.code) === null) {
        newTimezoneError = 'Invalid timezone provided';
      } else if (timezoneValue?.code == 'other' && !timezoneValue?.value) {
        newTimezoneError = 'Required';
      }

      setTimezoneError(newTimezoneError);

      if (!newTimezoneError) {
        setTimezone((prevState) => ({
          ...prevState,
          submitted: true,
          apiError: null
        }));

        try {
          const response = await window.fetch(`${URL_TIMEZONE_BASE}/timezone/${token}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ timezone: timezoneValue })
          });

          const data = await response.json();

          if (data.status !== 'ok') {
            setTimezone((prevState) => ({
              ...prevState,
              submitted: false,
              apiError: data.status
            }));
          } else {
            setDoctorTimezone(timezoneValue);
            setTimezone({
              submitted: false,
              apiError: null
            });
            this.props.displayNotification({ message: 'Your timezone has been changed.' });
          }
        } catch (error) {
          setTimezone((prevState) => ({
            ...prevState,
            submitted: false,
            apiError: error.message
          }));
        }
      }
    },
    [timezoneValue, token]
  );

  useEffect(() => {
    if (state.isError) {
      setContent(
        <Alert severity="error" style={{ margin: 16 }}>
          <AlertTitle>Could not confirm your availability</AlertTitle>
          <Typography variant="body1">
            Unfortunately, we weren't able to confirm your availability. This may happen for a few
            reasons:
            <ul>
              <li>The scheduling link has expired (most common).</li>
              <li>The scheduling link was not valid (less common).</li>
            </ul>
            <strong>System Response:</strong> <i>{state.errorMessage}</i>
            <p>
              If you have additional questions, reach out to{' '}
              <a href="mailto://aidan@expertopinion.md">Aidan</a>.
            </p>
          </Typography>
        </Alert>
      );
    } else if (state.alreadyScheduled) {
      setContent(
        <Alert severity="warning" style={{ margin: 16 }}>
          <AlertTitle>This consult has already been scheduled</AlertTitle>
          <Typography variant="body1">
            Aw, snap! This consult has already been accepted by another Expert. We’ll let you know
            as soon as another opportunity comes up.
            <p>
              If you have additional questions, reach out to{' '}
              <a href="mailto:aidan@expertopinion.md">Aidan</a>.
            </p>
          </Typography>
        </Alert>
      );
    }

    if (data) {
      setContent(
        <table className="eo-md-accept body-wrap">
          <tbody>
            <tr>
              <td></td>
              <td className="container" width="600">
                <div className="content">
                  <table className="main" width="100%" cellPadding="0" cellSpacing="0">
                    <thead>
                      <tr>
                        <td className={'header ' + classes.header}>Support Call</td>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td className="content-wrap">
                          <table width="100%" cellPadding="0" cellSpacing="0">
                            <thead>
                              <tr>
                                <td className="content-block">{data?.expert},</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="content-block">
                                  Expert Opinion would like to schedule an Expert Support Call.
                                  Click a date and time below to confirm your availability. A reply
                                  within one hour is requested.
                                </td>
                              </tr>
                              <tr>
                                <td className="content-block">
                                  <b>Company:</b> {data?.partner} <br />
                                  <b>Category:</b> {data?.category} <br />
                                  <b>Notes:</b> {data?.notes}
                                </td>
                              </tr>
                              <tr>
                                <td className="content-block">
                                  <Typography variant="h6" style={{ display: 'inline' }}>
                                    Time Zone:
                                  </Typography>
                                  <TimezoneList
                                    selectedCode={timezoneValue?.code || ''}
                                    selectedGMTValue={timezoneValue?.value || ''}
                                    handleChange={setTimezoneValue}
                                  />
                                  {doctorTimezone !== timezoneValue ? (
                                    <>
                                      <Button
                                        size="small"
                                        style={{
                                          margin: showSuggestTime ? '8px 0' : '8px 0 24px 0'
                                        }}
                                        color="primary"
                                        onClick={handleSubmitChangeTimezone}
                                        disabled={timezone.submitted}
                                      >
                                        {timezone.submitted ? (
                                          <CircularProgress />
                                        ) : (
                                          <span>Save Preference</span>
                                        )}
                                      </Button>
                                      {timezone.apiError && (
                                        <Typography variant="body1" className={classes.error}>
                                          {timezone.apiError}
                                        </Typography>
                                      )}
                                    </>
                                  ) : null}
                                  <br />
                                  {!showSuggestTime ? <b>Availability:</b> : ''}
                                </td>
                              </tr>
                              <tr>
                                {showSuggestTime ? (
                                  <td className="content-block">
                                    <SuggestTime
                                      requestTime={data.requestedAt}
                                      loading={isLoading}
                                      timezone={timezoneValue}
                                      handleBack={toggleSuggestTime}
                                      handleOnSubmit={onSuggestTime}
                                    />
                                  </td>
                                ) : (
                                  <td className="content-block">
                                    {preferredTimes.map((time, idx) => {
                                      return (
                                        <Link
                                          key={idx}
                                          base={'site'}
                                          path={time.url}
                                          onClick={(e) =>
                                            confirmAndRedirect(
                                              e,
                                              time.url,
                                              `Confirm on ${time.value}?`
                                            )
                                          }
                                          className={'btn-schedule ' + classes.button}
                                        >
                                          {time.value}
                                        </Link>
                                      );
                                    })}
                                  </td>
                                )}
                              </tr>
                              <tr>
                                {!showSuggestTime ? (
                                  <td className="content-block">
                                    <Box sx={{ display: 'flex', gap: '5px' }}>
                                      <Link
                                        base={'site'}
                                        path={data?.naUrl || '#'}
                                        onClick={(e) =>
                                          confirmAndRedirect(
                                            e,
                                            data?.naUrl,
                                            'I am not available for this consult.'
                                          )
                                        }
                                      >
                                        Not available?
                                      </Link>
                                      Let us know. Or
                                      <Box
                                        component="a"
                                        sx={{ cursor: 'pointer' }}
                                        onClick={toggleSuggestTime}
                                      >
                                        suggest alternate times.
                                      </Box>
                                    </Box>
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                              </tr>
                              <tr>
                                <td className="content-block"></td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      );
    }
  }, [state, timezone, timezoneValue, timezoneError, showSuggestTime, isLoading]);

  return content;
};

export default Accept;
