import React, { useState, useEffect, useMemo } from 'react';
import EOTable from '../../../partials/EOTable.js';
import { Avatar, Select, MenuItem } from '@material-ui/core';

import { _fetchUrl } from '../../../utils/api';

const ManageExperts = (props) => {
  const { partnerId, token } = props;

  const [allExperts, setAllExperts] = useState([]);
  const [partnerExperts, setPartnerExperts] = useState([]);
  const [selectedExpert, setSelectedExpert] = useState('');

  const remainingExperts = useMemo(() => {
    return allExperts.filter((expert) =>
      partnerExperts.every((pExpert) => pExpert.id !== expert.id)
    );
  }, [partnerExperts, allExperts]);

  const selectedExpertDetails = useMemo(() => {
    const details = remainingExperts.filter((expert) => expert.id === selectedExpert);
    return details.length ? details[0] : { profile: {} };
  }, [selectedExpert]);

  const columns = [
    {
      field: 'avatar',
      title: 'Avatar',
      width: 40,
      render: ({ avatar, name }) => <Avatar alt={name} src={avatar} variant="square" />,
      editComponent: () => (
        <Avatar
          alt={selectedExpertDetails.profile.name}
          src={selectedExpertDetails.profile.avatar}
          variant="square"
        />
      )
    },
    {
      field: 'name',
      title: 'Name',
      editComponent: () => (
        <Select
          style={{ width: '100%' }}
          value={selectedExpert}
          onChange={(val) => setSelectedExpert(val.target.value)}
        >
          {remainingExperts
            .filter((expert) => !!expert.profile.name)
            .sort((a, b) => {
              return a.profile?.name?.localeCompare(b.profile.name);
            })
            .map((expert, idx) => (
              <MenuItem key={idx} value={expert.id}>
                {expert.profile.name}
              </MenuItem>
            ))}
        </Select>
      )
    },
    {
      field: 'email',
      title: 'Email',
      editComponent: () => (
        <Select
          style={{ width: '100%' }}
          value={selectedExpert}
          onChange={(val) => setSelectedExpert(val.target.value)}
        >
          {remainingExperts
            .filter((expert) => !!expert.profile.email)
            .sort((a, b) => a.profile?.email?.localeCompare(b.profile.email))
            .map((expert, idx) => (
              <MenuItem key={idx} value={expert.id}>
                {expert.profile.email}
              </MenuItem>
            ))}
        </Select>
      )
    }
  ];

  let updatePartnerExpert = (expertId, resolve, reject) => {
    _fetchUrl({
      method: 'PUT',
      path: `poe/partner/${partnerId}/expert/${expertId}`,
      token
    }).then((r) => {
      if (r.status === 'ok') {
        setPartnerExperts((experts) => [
          ...experts,
          {
            id: selectedExpertDetails.id,
            avatar: selectedExpertDetails.profile.avatar,
            name: selectedExpertDetails.profile.name,
            email: selectedExpertDetails.profile.email
          }
        ]);
        setSelectedExpert('');
        resolve();
      } else {
        reject();
        console.error('Error loading partners:', r.error);
      }
    });
  };

  let deletePartnerExpert = (expertId, resolve, reject) => {
    _fetchUrl({
      method: 'DELETE',
      path: `poe/partner/${partnerId}/expert/${expertId}`,
      token
    }).then((r) => {
      if (r.status === 'ok') {
        setPartnerExperts((experts) => experts.filter((expert) => expert.id !== expertId));
        resolve();
      } else {
        reject();
        console.error('Error loading partners:', r.error);
      }
    });
  };

  // Get all doctors
  useEffect(() => {
    _fetchUrl({
      method: 'GET',
      path: `poe/doctor`,
      token
    }).then((r) => {
      if (r.status === 'ok') {
        setAllExperts(r.doctors);
      } else {
        console.error('Error loading partners:', r.error);
      }
    });
  }, []);

  // Get partner doctors
  useEffect(() => {
    _fetchUrl({
      method: 'GET',
      path: `partner/${partnerId}?type=portal`,
      token
    }).then((r) => {
      if (r.status === 'ok') {
        setPartnerExperts(r.partner.doctors.sort((a, b) => a.name?.localeCompare(b.name)));
      } else {
        console.error('Error loading partners:', r.error);
      }
    });
  }, [partnerId]);

  return (
    <React.Fragment>
      <EOTable
        style={{ margin: '25px 0' }}
        title="Experts"
        data={partnerExperts}
        columns={columns}
        pageSize={20}
        localization={{
          body: {
            emptyDataSourceMessage: 'Partner has no experts',
            deleteTooltip: 'Remove',
            editRow: {
              deleteText: 'Are you sure you want to remove this expert?'
            }
          }
        }}
        editable={{
          onRowAdd: () =>
            new Promise((resolve, reject) => {
              updatePartnerExpert(selectedExpert, resolve, reject);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              deletePartnerExpert(oldData.id, resolve, reject);
            })
        }}
      ></EOTable>
    </React.Fragment>
  );
};

export default ManageExperts;
