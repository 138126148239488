import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import moment from 'moment';

const ApprovalDetails = ({ approver, timestamp }) => {
  return (
    <Grid container direction="column" component={Paper} style={{ padding: '1rem' }}>
      <Grid item>
        <Typography variant="h4" align="left">
          Approval
        </Typography>

        <Typography variant="subtitle1">Approver: {approver ? approver : 'N/a'}</Typography>
        <Typography variant="subtitle1" align="left">
          Timestamp: {timestamp ? moment(timestamp).format('Do MMM y') : 'N/a'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ApprovalDetails;
