const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS
});

const DISPLAY_NOTIFICATION = 'DISPLAY_NOTIFICATION';

export const displayNotification = (notification) => ({
  type: DISPLAY_NOTIFICATION,
  notification
});

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case CLEAR_NOTIFICATIONS:
      return {};
    case DISPLAY_NOTIFICATION:
      return { ...state, ...action.notification };
    default:
      return state;
  }
};
