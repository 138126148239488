// This must be the first line in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { TrackJS } from 'trackjs';

import './index.css';

import AppContainer from './components/App/AppContainer';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import trackJsLogger from './utils/trackJsLogger';
import eoApp from './store/reducers';
import sagas from './store/sagas';

const sagaMiddleware = createSagaMiddleware();

const history = createBrowserHistory();

const store = createStore(
  connectRouter(history)(eoApp),
  compose(applyMiddleware(trackJsLogger, routerMiddleware(history), sagaMiddleware))
);

sagaMiddleware.run(sagas);

ReactDOM.render(
  <Provider store={store}>
    <AppContainer history={history} />
  </Provider>,
  document.getElementById('root')
);

unregisterServiceWorker();

if (process.env.REACT_APP_EO_ENV !== 'dev') {
  TrackJS.install({
    token: process.env.REACT_APP_TRACKJS_TOKEN,
    application: process.env.REACT_APP_EO_ENV,
    version: process.env.REACT_APP_GIT_HASH,
    onError: (payload) => {
      payload.network = payload.network.filter(
        (item) => item.url.toLowerCase().indexOf('fullstory.com') === -1
      );
      return true;
    }
  });
}
