import React, { useState } from 'react';
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  getCountryCallingCode,
  getCountries
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Input from './Input';
import Select from './Select';

const PhoneForm = ({
  id,
  value,
  onChange,
  country,
  setCountry,
  defaultCountry,
  error,
  helperText,
  variant,
  label
}) => {
  const [inputFocus, setInputFocus] = useState(false);

  return (
    <form>
      <PhoneInput
        country={country || defaultCountry}
        defaultCountry={country || defaultCountry}
        value={value}
        onChange={onChange}
        addInternationalOption={false}
        label={label}
        initialValueFormat="national"
        limitMaxLength={true}
        variant={variant}
        inputComponent={Input}
        countrySelectComponent={Select}
        countrySelectProps={{
          setCountry,
          setInputFocus,
          variant
        }}
        inputFocus={inputFocus}
        setInputFocus={setInputFocus}
        inputCountry={country || defaultCountry}
        id={id}
        error={error}
        helperText={helperText}
      />
    </form>
  );
};

export default PhoneForm;

// isValidPhoneNumber is more strict but might become outdated
export { isPossiblePhoneNumber, isValidPhoneNumber, getCountryCallingCode, getCountries };
