import React, { Component, Fragment } from 'react';
import { withRouter, Switch, Redirect, Route } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import DoctorContainer from './Doctors/DoctorContainer';
import DoctorsListContainer from './Doctors/DoctorsListContainer';
import OpinionContainer from './OpinionContainer';
import OpinionsListContainer from './OpinionsListContainer';
import ProductsContainer from './Products/ProductsContainer';
//import PatientsListContainer from './PatientsListContainer';
import RedirectContainer from './RedirectContainer';

import IndustryForm from './Industry/IndustryForm';
import SurveyPage from './Industry/SurveyPage';

import OpinionsPage from './Opinions';

import DocumentTitle from '../../partials/DocumentTitle';
import { Link } from '../../partials/Link';

import styles from './_styles';

class AdminPortal extends Component {
  componentDidMount() {
    this.props.authPing({
      type: ['EO_ADMIN'],
      redirect: this.props.history.location
    });
  }

  render() {
    const { match, classes } = this.props;

    return (
      <Fragment>
        <DocumentTitle title="Admin" />
        <AppBar position="static" color="default">
          <Toolbar>
            <Link
              base={'site'}
              path={`${match.path}/industry`}
              navLink={true}
              className={classes.navLink}
            >
              <Button color="primary">D2D</Button>
            </Link>
            <Link
              base={'site'}
              path={`${match.path}/opinions`}
              navLink={true}
              className={classes.navLink}
            >
              <Button color="primary">Opinions</Button>
            </Link>
            <Link
              base={'site'}
              path={`${match.path}/doctors`}
              navLink={true}
              className={classes.navLink}
            >
              <Button color="primary">Doctors</Button>
            </Link>
            <Link
              base={'site'}
              path={`${match.path}/products`}
              navLink={true}
              className={classes.navLink}
            >
              <Button color="primary">Products</Button>
            </Link>
            <Link
              base={'site'}
              path={`${match.path}/redirects`}
              navLink={true}
              className={classes.navLink}
            >
              <Button color="primary">Redirects</Button>
            </Link>
          </Toolbar>
        </AppBar>

        <div className={classes.root}>
          <Typography gutterBottom variant="h3">
            Expert Opinion Admin Area
          </Typography>
          <Switch>
            <Redirect exact from={match.path} to={`${match.path}/industry`} />
            <Route
              exact
              path={`${match.path}/opinions`}
              render={() => <OpinionsPage {...this.props} />}
            />

            <Route
              exact
              path={`${match.path}/patients/:patientId/opinions`}
              render={() => <OpinionsListContainer {...this.props} />}
            />
            <Route
              exact
              path={`${match.path}/patients/:patientId/opinions/:opinionId`}
              render={() => <OpinionContainer {...this.props} />}
            />

            <Route
              exact
              path={`${match.path}/doctors`}
              render={() => <DoctorsListContainer {...this.props} />}
            />
            <Route
              exact
              path={`${match.path}/doctors/:doctorId`}
              render={() => <DoctorContainer {...this.props} />}
            />

            <Route
              exact
              path={`${match.path}/products`}
              render={() => <ProductsContainer {...this.props} />}
            />

            <Route
              exact
              path={`${match.path}/redirects`}
              render={() => <RedirectContainer {...this.props} />}
            />

            <Route
              exact
              path={`${match.path}/industry`}
              render={() => <IndustryForm {...this.props} />}
            />
            <Route
              exact
              path={`${match.path}/industry/survey/:consultId`}
              render={() => <SurveyPage {...this.props} />}
            />
          </Switch>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(AdminPortal));
