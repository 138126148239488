import { combineReducers } from 'redux';

import { reducer as auth } from './Auth';
import { reducer as fileViewer } from './FileViewer';
import { reducer as notification } from './Notifications';
import { reducer as webData } from './WebData';

const eoApp = combineReducers({
  auth,
  fileViewer,
  notification,
  webData
});

export default eoApp;
