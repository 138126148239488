import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import * as EmailValidator from 'email-validator';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { displayNotification } from '../../../store/actions';

import { Button, Box, InputAdornment, TextField } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import en from 'react-phone-number-input/locale/en.json';

import { guessCountry } from '../../../utils/guessCountry';

const useStyles = makeStyles((theme) => ({
  remove: {
    color: theme.palette.error.main,
    cursor: 'pointer'
  },
  option: {
    textTransform: 'capitalize'
  },
  textfield: {
    marginBottom: '16px'
  }
}));

const params = new URLSearchParams(window.location.search);

const CaseDetails = (props) => {
  const { caseDetails, caseDetailsIndex: index, labels, reps, countries } = props;

  const [state, setState] = useState(
    caseDetails.length
      ? caseDetails
      : [
          {
            country: '',
            patientQuestion: '.',
            caseDescription: 'I’d like to talk to a TECNIS Odyssey Expert about their experience.',
            category: params.get('product') || '',
            name: params.get('doctorName') || '',
            repEmail: '',
            formData: false,
            s3Files: false
          }
        ]
  );

  const [error, setError] = useState({});
  const countryList = useMemo(() => {
    return [{ code: '', name: '' }, ...countries.map((code) => ({ code, name: en[code] }))].sort(
      (a, b) => (a.name > b.name ? 1 : -1)
    );
  }, [countries]);

  useEffect(() => {
    if (reps) {
      const repEmail = reps.find((rep) => rep.fullName === params.get('name'))?.email;

      if (repEmail) {
        setState([
          {
            ...state[0],
            repEmail
          }
        ]);
      }
    }
  }, [reps, params]);

  useEffect(() => {
    if (props.products.length === 1) {
      state[0].category = props.products[0];
      setState([...state]);
    }
  }, [props.products]);

  // Alternatively just set to US if we don't want to derive it from the browser language
  useEffect(() => {
    if (!state[index].country) {
      setStateValue('country', guessCountry(countryList));
    }
  }, [countryList, setStateValue]);

  const classes = useStyles();

  function validate() {
    let questionMap = {
      name: 'Name',
      repEmail: 'Email',
      country: 'Country',
      category: 'Category',
      caseDescription: 'Case Description',
      patientQuestion: 'Clinical Question'
    };

    const newError = {};

    for (let idx in state) {
      let errors = {};

      for (let question in state[idx]) {
        if (!['caseDescription', 'patientQuestion'].includes(question) && idx !== '0') {
          continue;
        }

        if (questionMap[question] && !state[idx][question]) {
          errors[question] = `${questionMap[question]} is required`;
        }
      }

      // Additional validation - Email
      if (idx === '0' && !error[idx]?.repEmail && !EmailValidator.validate(state[idx]?.repEmail)) {
        errors['repEmail'] = 'Search by name, or enter in an email address';
      }

      if (Object.entries(errors).length) {
        newError[idx] = errors;
      }
    }

    if (Object.entries(newError).length) {
      setError(newError);
      return false;
    } else {
      setError({});
    }

    return true;
  }

  function handleNext() {
    if (validate(index)) {
      // Restore repname + category when cleared due to interportal navigation
      props.onNext({
        ...state.map((s) => {
          s.repEmail = state[0].repEmail;
          s.category = state[0].category;
          return s;
        })
      });
    }
  }

  function setStateValue(key, value, idx) {
    // Using set state callback returns state as object and not array
    state[idx || index][key] = value;
    setState([...state]);
  }

  function handleRepEmail(evt, newValue) {
    state[index]['repEmail'] = newValue.email;
    setState([...state]);
  }

  return (
    <React.Fragment>
      <TextField
        id="name"
        fullWidth
        margin="normal"
        className={classes.textfield}
        variant="outlined"
        value={state[index]?.name}
        onChange={(e) => setStateValue('name', e.target.value)}
        label="Surgeon Name"
        name="Name"
        error={error[index]?.name !== undefined && error[index]?.name !== ''}
        helperText={error[index]?.name}
        required
      />
      {reps.length === 0 ? (
        <TextField
          fullWidth
          margin="normal"
          name="RepEmail"
          variant="outlined"
          value={state[index]?.repEmail}
          onChange={(e) => setStateValue('repEmail', e.target.value)}
          label={labels.repField}
          error={error[index]?.repEmail !== undefined && error[index]?.repEmail !== ''}
          helperText={error[index]?.repEmail || labels.repFieldHelper || ''}
          required
        />
      ) : (
        <Autocomplete
          freeSolo
          id="rep"
          name="RepEmail"
          disableClearable
          classes={{
            option: classes.option
          }}
          value={reps?.find((v) => v.email === state[index]?.repEmail) || null}
          options={reps}
          getOptionLabel={(option) => option.fullName}
          onChange={handleRepEmail}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              value={state.repEmail}
              onChange={(e) =>
                setStateValue(
                  'repEmail',
                  reps.find((v) => v.fullName === e.target.value)?.email || e.target.value
                )
              }
              label={labels.repField}
              margin="normal"
              variant="outlined"
              error={error[index]?.repEmail !== undefined && error[index]?.repEmail !== ''}
              helperText={error[index]?.repEmail}
              InputProps={{
                ...params.InputProps,
                id: 'repFiled',
                type: 'search',
                // if safari doesn't honor this, we might have to try hacky solutions such as
                // https://stackoverflow.com/questions/43058018/how-to-disable-autocomplete-in-address-fields-for-safari
                autoComplete: 'new-password',
                form: {
                  autocomplete: 'off'
                },
                endAdornment: (
                  <>
                    <InputAdornment position="end" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                      <ArrowDropDownIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                )
              }}
              required
            />
          )}
        />
      )}

      <Box mt={2} style={{ marginTop: '8px' }}>
        <Button
          id="cd_next"
          style={{ marginTop: 8 }}
          color="primary"
          variant="contained"
          onClick={handleNext}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  displayNotification: (notification) => dispatch(displayNotification(notification))
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseDetails);
