import React, { useState, useMemo, useEffect } from 'react';

import {
  AppBar,
  Button,
  Hidden,
  CardMedia,
  CardContent,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  Slide,
  Typography,
  Toolbar,
  IconButton
} from '@material-ui/core';

import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import pickforme from './pickforme.jpeg';

function getLabelFromIndex(idx) {
  return ['1st Choice', '1st Alternate', '2nd Alternate'][idx];
}

export const DoctorButton = (props) => {
  const {
    classes,
    profileImg,
    name,
    disabled,
    label,
    infoLabel = 'Read BIO',
    onInfo,
    border = false
  } = props;

  return (
    <ButtonBase
      style={{
        ...{ marginRight: '8px', marginTop: '8px', alignItems: 'stretch' },
        ...(border
          ? { boxSizing: 'border-box', border: '2px solid #D9D9D9', borderRadius: '5px' }
          : null)
      }}
      disabled={disabled}
      onClick={props.selectDoctor}
    >
      <Card className={classes.card}>
        <CardMedia
          style={{ backgroundPosition: '50% 20%' }}
          className={classes.media}
          image={profileImg}
        />
        {!label ? (
          <CardContent style={{ paddingBottom: '0px', height: 'calc(100% - 150px)' }}>
            <Typography align="center" variant="body2">
              {name}
            </Typography>
            <div>
              <CheckIcon style={{ color: 'green', visibility: 'hidden' }} color="secondary" />
            </div>
          </CardContent>
        ) : (
          <CardContent>
            <Typography className={classes.typography} variant="caption">
              {label}
            </Typography>
          </CardContent>
        )}
        {!label && (
          <div
            onClick={(e) => {
              e.preventDefault();
              e.nativeEvent.stopImmediatePropagation();
              e.stopPropagation();
              onInfo();
              return true;
            }}
          >
            <Typography color="primary" variant="button">
              {infoLabel}
            </Typography>
          </div>
        )}
      </Card>
    </ButtonBase>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InfoHtml = (props) => {
  const { isInfoHtml, info } = props;

  if (!info) {
    return null;
  }

  return (
    <div>
      {isInfoHtml ? (
        <div dangerouslySetInnerHTML={{ __html: info }} />
      ) : (
        <div>
          <Typography variant="body1">
            {info
              .split('\n')
              .filter((p) => p.length)
              .map((p, idx) => (
                <p key={idx}>{p}</p>
              ))}
          </Typography>
        </div>
      )}
    </div>
  );
};

const pickForMeFunc = (
  selected,
  setSelected,
  localAutoPickedMap,
  setLocalAutoPickedMap,
  size,
  maxSize,
  doctors
) => {
  let onlyOnce = maxSize !== 3;
  let newSelected = new Set(selected);

  if (!onlyOnce) {
    for (let id of selected) {
      if (localAutoPickedMap[id]) {
        newSelected.delete(id);
      }
    }
  }

  while (newSelected.size < size) {
    let id = doctors[Math.floor(Math.random() * doctors.length)].id;

    if (!newSelected.has(id)) {
      setLocalAutoPickedMap((prev) => ({ ...prev, [id]: true }));
      newSelected.add(id);
      if (onlyOnce) {
        break;
      }
    }
  }

  setSelected(newSelected);
};

const DoctorSelect = (props) => {
  const {
    selected,
    setSelected,
    setAutoPickedMap,
    doctors,
    classes,
    pickForMe = true,
    useDoctorNameAsLabel = false,
    maxSize = 3,
    helperText
  } = props;

  const [localAutoPickedMap, setLocalAutoPickedMap] = useState({});
  const [info, setInfo] = useState(null);
  const [isAbout, setIsAbout] = useState(true);

  useEffect(() => {
    if (setAutoPickedMap) {
      setAutoPickedMap(localAutoPickedMap);
    }
  }, [localAutoPickedMap]);

  const size = useMemo(() => {
    return doctors.length > maxSize ? maxSize : doctors.length;
  }, [doctors]);

  const isInfoHtml = useMemo(() => {
    return new RegExp(/<[a-z][\s\S]*>/i).test(
      info
    ); /* This is to support legacy bios not in HTML format*/
  }, [info]);

  const selectedDoctors = useMemo(() => {
    return [...selected].map((a) => {
      return doctors.find((d) => d.id === a);
    });
  }, [selected]);

  const sortedDoctors = React.useMemo(() => {
    return doctors
      .filter((a) => {
        return !selected.has(a.id);
      })
      .sort((a, b) => {
        const [aname] = a.id.split('-').slice(-2);
        const [bname] = b.id.split('-').slice(-2);

        return aname < bname ? -1 : 1;
      });
  }, [doctors, selected]);

  const instructions = useMemo(() => {
    if (helperText) {
      return helperText;
    } else {
      if (maxSize == 3 && useDoctorNameAsLabel === false) {
        if (size >= 3) {
          return 'Click three experts in order of preference: 1st choice, 1st alternate, 2nd alternate.';
        }
        if (size === 2) {
          return 'Click experts in order of preference: 1st choice, 2nd choice.';
        }
      }
    }

    return 'Choose the expert below.';
  }, [size]);

  function toggleDoctor(id) {
    if (selected.has(id)) {
      setLocalAutoPickedMap((prev) => {
        delete prev[id];
        return prev;
      });

      selected.delete(id);
    } else if (selected.size < size) {
      setLocalAutoPickedMap((prev) => ({ ...prev, [id]: false }));

      selected.add(id);
    }

    setSelected(new Set(selected));
  }

  function closeInfo() {
    setIsAbout(true);
    setInfo(null);
  }

  return (
    <React.Fragment>
      <Hidden mdUp>
        <Dialog
          fullScreen
          open={info !== null}
          onClose={closeInfo}
          TransitionComponent={Transition}
        >
          <AppBar>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={closeInfo} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {isAbout ? 'About' : 'Details'}
              </Typography>
            </Toolbar>
          </AppBar>
          <div style={{ marginTop: '56px', padding: '14px' }}>
            <InfoHtml isInfoHtml={isInfoHtml} info={info} />
          </div>
        </Dialog>
      </Hidden>
      <Hidden smDown>
        <Dialog open={info !== null} onClose={closeInfo}>
          <DialogTitle>{isAbout ? 'About' : 'Details'}</DialogTitle>
          <DialogContent>
            <InfoHtml isInfoHtml={isInfoHtml} info={info} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={closeInfo}>
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </Hidden>
      <Grid container spacing={2} justifyContent="flex-start">
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            {instructions}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            id="selected"
            variant="body1"
            style={{ fontSize: '0.9rem', fontWeight: '500', textTransform: 'uppercase' }}
          >
            Selected Experts:
          </Typography>
          {selectedDoctors.map((doctor, idx) => (
            <DoctorButton
              label={useDoctorNameAsLabel === true ? undefined : getLabelFromIndex(idx)}
              selectDoctor={() => {
                toggleDoctor(doctor.id);
              }}
              key={doctor.id}
              doctorId={doctor.id}
              classes={classes}
              name={doctor.name}
              profileImg={doctor.avatar}
              onInfo={() => setInfo(doctor.bio)}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            style={{ fontSize: '0.9rem', fontWeight: '500', textTransform: 'uppercase' }}
          >
            Available Experts:
          </Typography>
          <div className={classes.doctorButtonRoot}>
            <div id="experts" style={{ display: 'flex', flexWrap: 'wrap' }}>
              {sortedDoctors.map((doctor) => (
                <DoctorButton
                  selectDoctor={() => {
                    toggleDoctor(doctor.id);
                  }}
                  key={doctor.id}
                  doctorId={doctor.id}
                  classes={classes}
                  name={doctor.name}
                  profileImg={doctor.avatar}
                  onInfo={() => setInfo(doctor.bio)}
                />
              ))}
              {pickForMe && (
                <DoctorButton
                  selectDoctor={() =>
                    pickForMeFunc(
                      selected,
                      setSelected,
                      localAutoPickedMap,
                      setLocalAutoPickedMap,
                      size,
                      maxSize,
                      doctors
                    )
                  }
                  classes={classes}
                  name={'Choose for me'}
                  profileImg={pickforme}
                  border={false}
                  infoLabel="MORE INFO"
                  onInfo={() => {
                    setIsAbout(false);
                    setInfo(
                      '<p>“Choose for me” populates surgeons from this list based on a random algorithm</p>'
                    );
                  }}
                />
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DoctorSelect;
