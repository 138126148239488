import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import DocumentTitle from '../../partials/DocumentTitle';
import { Link } from '../../partials/Link';

const styles = (theme) => ({
  root: {
    textAlign: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    [theme.breakpoints.down('sm')]: {
      minWidth: '300px'
    }
  },
  h1: {
    fontSize: '4rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '3.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.5rem'
    }
  },
  h4: {
    fontSize: '2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem'
    }
  },
  h5: {
    fontSize: '1.6rem',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  h6: {
    fontSize: '1.1rem',
    fontWeight: 'normal',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '-6px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '.9rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '.8rem'
    },
    '&::before': {
      content: '"\\2022"',
      fontSize: '1.7rem'
    }
  }
});

const NotFound = (props) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <DocumentTitle title="Page Not Found" />
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.h1}>
            Page Not Found
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom color="primary" variant="h4" className={classes.h4}>
            We couldn't find the page you were looking for.
          </Typography>
          <Typography gutterBottom variant="h5" className={classes.h5}>
            Fear not! You can always try these:
          </Typography>
          <Typography gutterBottom variant="h6" className={classes.h6}>
            Go to our&nbsp;
            <Link base={'site'} path="/">
              Homepage
            </Link>
          </Typography>
          <Typography gutterBottom variant="h6" className={classes.h6}>
            Check the address and try again
          </Typography>
          <Typography gutterBottom variant="h6" className={classes.h6}>
            Contact&nbsp;<a href="mailto:care@expertopinion.md">care@expertopinion.md</a>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(NotFound);
