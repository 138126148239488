import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import DocumentTitle from '../../partials/DocumentTitle';

const styles = () => ({
  root: {
    margin: '50px'
  }
});

const About = (props) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <DocumentTitle title="About Us" />
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h3">
            About Us
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom color="primary" variant="h4">
            Trusted. Independent. Accessible.
          </Typography>
          <Typography gutterBottom variant="h5">
            <p>
              Expert Opinion is driving better patient outcomes by providing unprecedented access to
              the world’s leading medical experts at the times when they are needed most.
            </p>
            <p>
              Patients and fellow surgeons have important, time-sensitive questions; questions about
              treatment plans, patient selection or outcome handling. For patients, partnering with
              Expert Opinion means a comprehensive records review and feedback from their chosen
              expert. For physicians, we deliver a 1:1 surgeon-to-surgeon consultative exchange to
              discuss case details and best next steps.
            </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom color="primary" variant="h4">
            Our Values
          </Typography>
          <p></p>
          <Typography gutterBottom variant="h5">
            <strong>Trust: </strong>Every second opinion or consultation comes directly from your
            expert of choice.
          </Typography>
          <Typography gutterBottom variant="h5">
            <strong>Independence: </strong>Receive objective, third-party advice from the world’s
            best experts.
          </Typography>
          <Typography gutterBottom variant="h5">
            <strong>Access: </strong>Patients, surgeons, and industry players are connected to
            world-renowned experts in a timely, efficient manner.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(About);
