import React from 'react';
import { withRouter } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';

import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { Link } from './Link';

const styles = (theme) => ({
  footerRoot: {
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: 'auto',
    width: '100%',
    bottom: 0
  },
  footerBlock: {
    float: 'right',
    '&:first-child': {
      float: 'left'
    }
  },
  footerLink: { color: theme.palette.common.white }
});

const FooterComponent = ({ classes }) =>
  !window._eo.isMobileApp && (
    <footer className={classes.footerRoot}>
      <Hidden smDown>
        <div className={classes.footerBlock}>
          <Typography color="secondary" variant="body1">
            Links
          </Typography>
          <Typography color="secondary" gutterBottom variant="body2">
            <Link base={'site'} path={'/'} className={classes.footerLink}>
              Home
            </Link>{' '}
            |{' '}
            <Link base={'site'} path={'/about'} className={classes.footerLink}>
              About Us
            </Link>{' '}
            |{' '}
            <Link base={'site'} path={'/terms'} className={classes.footerLink}>
              Terms of Use
            </Link>{' '}
            |{' '}
            <Link base={'site'} path={'/privacy'} className={classes.footerLink}>
              Privacy Policy
            </Link>{' '}
            |{' '}
            <Link base={'site'} path={'/contact-us'} className={classes.footerLink}>
              Contact
            </Link>
          </Typography>
        </div>
      </Hidden>
      <Hidden mdUp>
        <Typography color="secondary" variant="body1">
          Links
        </Typography>
        <Typography color="secondary" gutterBottom variant="body2">
          <Link base={'site'} path={'/about'} className={classes.footerLink}>
            About
          </Link>{' '}
          |{' '}
          <Link base={'site'} path={'/terms'} className={classes.footerLink}>
            Terms
          </Link>{' '}
          |{' '}
          <Link base={'site'} path={'/privacy'} className={classes.footerLink}>
            Privacy
          </Link>{' '}
          |{' '}
          <Link base={'site'} path={'/contact-us'} className={classes.footerLink}>
            Contact
          </Link>
        </Typography>
      </Hidden>
    </footer>
  );

export default withRouter(withWidth()(withStyles(styles, { withTheme: true })(FooterComponent)));
