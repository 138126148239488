import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

const DoctorEmail = ({ email, handleUpdateDoctorEmail }) => (
  <FormControl fullWidth={true} margin={'normal'}>
    <Typography variant="subtitle1">Email</Typography>
    <Input value={email} onChange={(event) => handleUpdateDoctorEmail(event.target.value)} />
  </FormControl>
);

DoctorEmail.propTypes = {
  email: PropTypes.string.isRequired,
  handleUpdateDoctorEmail: PropTypes.func.isRequired
};

export default DoctorEmail;
