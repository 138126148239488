import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { displayNotification } from '../../../store/actions';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import scrollIntoView from 'scroll-into-view-if-needed';
import DocumentMeta from '../../../partials/DocumentMeta';
import Confirm from '../../../utils/confirm';
import { _fetchUrl } from '../../../utils/api';
import EOModal from '../../../partials/EOModal';
import isMobile from '../../../utils/isMobile';
import { getvCard } from 'eo_vcard';
import PhoneInput, { isValidPhoneNumber } from '../../shared/PhoneInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as EmailValidator from 'email-validator';

import ContactInfo from '../shared/ContactInfo';
import DoctorSelect from '../shared/DoctorSelect';
import Availability from './Availability';
import CaseDetails from './CaseDetails';

import EoPartnerAPI from '../shared/api';

import eologo from '../shared/logo.png';

import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  Step,
  Stepper,
  StepLabel,
  StepContent,
  Paper,
  IconButton,
  TextField,
  Typography,
  withStyles,
  MuiThemeProvider,
  responsiveFontSizes,
  createTheme
} from '@material-ui/core';

import { getCountries } from 'react-phone-number-input';
import { guessCountry } from '../../../utils/guessCountry';

import PersonAddIcon from '@material-ui/icons/PersonAdd';

let theme = createTheme({
  typography: {
    fontWeightRegular: 300,
    useNextVariants: true
  },
  overrides: {
    MuiStepLabel: {
      label: {
        fontSize: '20px'
      }
    }
  },
  palette: {
    background: {
      default: '#ffffff'
    },
    primary: {
      main: '#03a9f4',
      contrastText: '#f9f9f9'
    },
    secondary: {
      main: '#f9f9f9',
      contrastText: '#03a9f4'
    }
  }
});

theme = responsiveFontSizes(theme);

const styles = {
  root: {
    backgroundColor: 'white'
  },
  logo: {
    height: '30px'
  },
  eologo: {
    // top: '15px',
    height: '30px'
    // position: 'absolute',
    // right: '10px',
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    width: '100%'
  },
  container: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: '96px'
  },
  stepper: {
    padding: '0px'
  },
  paper: {
    margin: '24px',
    padding: '24px'
  },
  title: {
    //padding: theme.spacing(1)
  },
  footer: {
    minHeight: '40px',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    bottom: '0',
    color: 'white',
    padding: '8px'
  },
  card: {
    width: '150px',
    '@media (max-width:600px)': {
      width: '130px'
    },
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  // typography: {
  //   // fontWeight: 'bolder',
  //   '@media (max-width:600px)': {
  //     fontSize: '.65rem'
  //   },
  // },
  media: {
    height: '125px'
  },
  buttonArea: {
    marginTop: '24px'
  },
  mainBody: {
    flexGrow: '1',
    marginBottom: '72px',
    padding: theme.spacing(2)
  },
  modalSpacing: {
    margin: '0 0 24px 0'
  }
};

const CircularProgressWithLabel = (props) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired
};

// Function to upload a file with progress updates using XMLHttpRequest
const uploadFile = (file, presignedUrl, fields, setProgress) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('POST', presignedUrl);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = ((event.loaded / event.total) * 100).toFixed(2);
        setProgress(parseFloat(progress)); // Update the progress
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200 || xhr.status === 204) {
        // S3 might return 204 No Content on success
        resolve(xhr.response);
      } else {
        reject(new Error(`Upload failed: ${xhr.statusText}`));
      }
    };

    xhr.onerror = () => {
      reject(new Error('Network error occurred during the upload'));
    };

    // Set up FormData object with the fields and the file
    const formData = new FormData();

    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append('file', file);

    // Send the request
    xhr.send(formData);
  });
};

const Main = (props) => {
  const [doctors, setDoctors] = useState([]);
  const [availabilityInfo, setAvailabilityInfo] = useState('');
  const [products, setProducts] = useState([]);
  const [uiLabels, setUiLabels] = useState(null);
  const [reps, setReps] = useState([]);
  const [caseDetailsIndex, setCaseDetailsIndex] = useState(0);
  const [statusMessage, setStatusMessage] = useState('');
  //const [countries, setCountries] = useState({ loaded: false, data: [] });

  const [activeStep, setActiveStep] = useState(0);
  const [contactInfo, setContactInfo] = useState({});
  const [caseDetails, setCaseDetails] = useState([]);
  const [secureUrl, setSecureUrl] = useState([]);
  const [selectedExperts, setSelectedExperts] = useState(new Set());
  const [autoPickedMap, setAutoPickedMap] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [uploadingVideoError, setUploadingVideoError] = useState(false);
  const [progress, setProgress] = useState(0);

  const history = useHistory();
  const location = useLocation();

  const isMobileMemo = useMemo(() => isMobile(), []);

  const { classes, API, syncProducts } = props;

  const steps = document.querySelectorAll('.MuiStep-root.MuiStep-vertical');

  const scrollToTop = (step, force = false) => {
    setTimeout(() => {
      if (steps[step]) {
        scrollIntoView(steps[step], {
          behavior: 'smooth',
          block: 'start',
          scrollMode: force ? 'always' : 'if-needed'
        });
      }
    }, 500);
  };

  const updateCategory = (newDetails) => {
    newDetails = Object.values(newDetails);

    if (newDetails[0].categoryChanged) {
      setSelectedExperts(new Set());
    }

    setCaseDetails(newDetails);
  };

  useEffect(() => {
    syncProducts(products);
  }, [syncProducts, products]);

  useEffect(() => {
    API.getPartnerData().then((result) => {
      if (result.status !== 'ok') {
        return Confirm(
          "Sorry, we're having difficulty connecting to our service. Try again.",
          false
        );
      }

      const { doctors, products, uiLabels, reps } = result.partner;

      if (!doctors || !products || !uiLabels) {
        //todo: handle this error condition
        return Confirm(
          "Sorry, we're having difficulty connecting to our service. Try again.",
          false
        );
      }

      setDoctors(doctors);
      setProducts(products);
      setUiLabels(uiLabels);
      setReps(reps || []);
      //setCountries({ loaded: true, data: countries });
    });
  }, [location]);

  useEffect(() => {
    if (activeStep === 4) {
      submitConsultRequest();
    }
  }, [activeStep]);

  const countries = React.useMemo(() => {
    return getCountries();
  }, []);

  const submitConsultRequest = async () => {
    setSubmitting(true);
    setStatusMessage(`Submitting Cases`);

    const secureURL = [];
    const caseDetail = caseDetails[0];

    caseDetail.multi = [];

    // Marge into single api call
    for (let i = 0; i < caseDetails.length; i++) {
      caseDetail.multi.push({
        caseDescription: caseDetails[i].caseDescription,
        patientQuestion: caseDetails[i].patientQuestion,
        formData: caseDetails[i].formData,
        s3Files: caseDetails[i].s3Files
      });

      if (i === 0) {
        delete caseDetails[i].caseDescription;
        delete caseDetails[i].patientQuestion;
        delete caseDetails[i].formData;
        delete caseDetails[i].s3Files;
      }
    }

    const r = await API.sendConsultRequest({
      experts: selectedExperts,
      autoPickedMap,
      availability: availabilityInfo,
      caseDetails: caseDetail,
      info: contactInfo,
      queryString: props.search
    });
    const failedCases = [];

    if (r.status === 'ok') {
      for (let i = 0; i < caseDetail.multi.length; i++) {
        if (!r.result[i].url) {
          failedCases.push(i);
          continue;
        }

        let s3_files = caseDetail.multi[i].s3Files;

        if (s3_files && s3_files.length) {
          setStatusMessage(`Submitting - Case ${i + 1} - Videos - 1/${s3_files.length}`);

          try {
            const signedUrlRes = await _fetchUrl({
              method: 'POST',
              // token,
              path: 'records/signed-url',
              headers: { 'Content-Type': 'application/json' },
              body: {
                medicalRecordId: r.result[i].medicalRecordId,
                signedToken: r.result[i].recordToken,
                files: s3_files.map((file, idx) => ({
                  index: idx,
                  name: file.name,
                  size: file.size,
                  type: file.type
                }))
              }
            });

            if (signedUrlRes.status === 'ok') {
              // Handle video uploads directly
              for (let idx in signedUrlRes.SIGNED_URLS) {
                if (s3_files[idx] && signedUrlRes.SIGNED_URLS[idx]) {
                  setStatusMessage(
                    `Submitting - Case ${i + 1} - Videos - ${idx * 1 + 1}/${s3_files.length}`
                  );
                  // Set cors policy
                  // https://s3.console.aws.amazon.com/s3/buckets/dev.userdata.expertopinion.md?region=us-west-2&tab=objects
                  await uploadFile(
                    s3_files[idx],
                    signedUrlRes.SIGNED_URLS[idx].url,
                    signedUrlRes.SIGNED_URLS[idx].fields,
                    setProgress
                  );
                } else console.error('Unable to get signed url for', idx);
              }
            } else if (signedUrlRes.status === 'error') {
              console.log('signedUrlRes', signedUrlRes);
            }
          } catch (e) {
            setUploadingVideoError(true);
            console.log('error posting video', e);
          }
        }
        secureURL.push(r.result[i].url);
      }
    }

    setSecureUrl(secureURL);

    if (failedCases.length) {
      Confirm(
        `There was an issue submitting cases: ${failedCases
          .map((caseIdx) => caseIdx + 1)
          .join(', ')}`,
        false
      );
    }
  };

  function checkDoctorRequirements(experts) {
    let size = experts.length > 3 ? 3 : experts.length;

    if (selectedExperts.size !== size) {
      Confirm(`Please pick ${size} experts`, false);
      return false;
    } else return true;
  }

  let experts = doctors;

  if (caseDetails[0]?.category) {
    const expertsByCategory = products[caseDetails[0].category];
    experts = doctors.filter((doctor) => expertsByCategory?.includes(doctor.id));
  }

  if (!uiLabels) {
    return null;
  }

  const SubHeader = memo(
    ({ content }) => {
      const process = (el, idx) => {
        return Array.isArray(el) ? (
          el.map(process).flat()
        ) : (
          <Typography key={idx} variant={isMobileMemo ? 'body2' : 'body1'} gutterBottom>
            {el}
          </Typography>
        );
      };

      return content.map(process);
    },
    [isMobileMemo]
  );

  return (
    <React.Fragment>
      <DocumentMeta name="robots" content="noindex" />
      <div className={classes.title}>
        <Typography variant="h4">Expert Scheduling Portal</Typography>
        <Box mt={1} mb={4} maxWidth={740}>
          <SubHeader
            content={[
              `This service connects you to an Expert with product-specific knowledge to answer pre-op,
              post-op and patient-selection questions. Supported by ${props.name} – you will not be charged.`,
              ['Bausch + Lomb', 'Bausch + Lomb Retina'].includes(props.name)
                ? [
                    props.name === 'Bausch + Lomb'
                      ? `${props.name} does not support support off-label use of our products`
                      : ``,
                    <>
                      Looking for support for{' '}
                      {props.name === 'Bausch + Lomb' ? 'Bausch Retina' : 'Bausch Surgical'}{' '}
                      products? &nbsp;
                      <Link
                        base={'site'}
                        onClick={() => {
                          setSelectedExperts(new Set());
                          setDoctors([]);
                          setProducts([]);
                          setReps([]);

                          setCaseDetails((prevCaseDetails) =>
                            prevCaseDetails.map((caseDetail) => ({
                              ...caseDetail,
                              repEmail: '',
                              category: ''
                            }))
                          );

                          setActiveStep(0);

                          history.push(
                            props.name === 'Bausch + Lomb' ? '/bauschretina' : '/askbausch'
                          );
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        Click Here
                      </Link>
                    </>
                  ]
                : null
            ]}
          />
        </Box>
      </div>

      <Box mb={1}>
        <Stepper className={classes.stepper} activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel>How can we help?</StepLabel>
            <StepContent>
              <Box maxWidth={640}>
                <CaseDetails
                  partnerName={props.name}
                  labels={uiLabels}
                  products={Object.keys(products)}
                  reps={reps}
                  classes={classes}
                  onNext={(data) => {
                    updateCategory(data);
                    scrollToTop(activeStep + 1);
                    setActiveStep(activeStep + 1);
                  }}
                  caseDetails={caseDetails}
                  caseDetailsIndex={caseDetailsIndex}
                  setCaseDetailsIndex={setCaseDetailsIndex}
                  countries={countries}
                />
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Select Experts</StepLabel>
            <StepContent>
              <Box>
                <DoctorSelect
                  pickForMe={true}
                  labels={uiLabels}
                  doctors={experts}
                  selected={selectedExperts}
                  setSelected={setSelectedExperts}
                  setAutoPickedMap={setAutoPickedMap}
                  classes={classes}
                />
                <Box mt={2} display="flex" style={{ marginTop: '32px' }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      setCaseDetails((prevCaseDetails) =>
                        prevCaseDetails.map((caseDetail) => ({
                          ...caseDetail,
                          categoryChanged: false
                        }))
                      );
                      scrollToTop(activeStep - 1);
                      setActiveStep(activeStep - 1);
                    }}
                    style={{ marginRight: '10px' }}
                  >
                    Back
                  </Button>
                  <Button
                    id="expert_next"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      if (checkDoctorRequirements(experts)) {
                        scrollToTop(activeStep + 1);
                        setActiveStep(activeStep + 1);
                      }
                    }}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Availability</StepLabel>
            <StepContent>
              <Box maxWidth={640}>
                <Availability
                  labels={uiLabels}
                  classes={classes}
                  onNext={(data) => {
                    setAvailabilityInfo(data);
                    scrollToTop(activeStep + 1, true);
                    setActiveStep(activeStep + 1);
                  }}
                  onBack={(data) => {
                    setAvailabilityInfo(data);
                    scrollToTop(activeStep - 1, true);
                    setActiveStep(activeStep - 1);
                  }}
                  availabilityInfo={availabilityInfo}
                  country={caseDetails?.[0]?.country}
                />
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>How can we contact you?</StepLabel>
            <StepContent>
              <Box maxWidth={640}>
                <ContactInfo
                  labels={uiLabels}
                  classes={classes}
                  onBack={(data) => {
                    setContactInfo(data);
                    scrollToTop(activeStep - 1, true);
                    setActiveStep(activeStep - 1);
                  }}
                  onNext={(data) => {
                    setContactInfo(data);
                    scrollToTop(activeStep + 1, true);
                    setActiveStep(activeStep + 1);
                  }}
                  contactInfo={contactInfo}
                  countries={countries}
                  defaultCountry={caseDetails?.[0]?.country}
                />
              </Box>
            </StepContent>
          </Step>
        </Stepper>
      </Box>
      {activeStep === 4 ? (
        <Paper className={classes.paper}>
          {submitting && statusMessage && !secureUrl.length && (
            <div>
              <Grid container justifyContent="space-between" style={{ marginTop: '16px' }}>
                <Grid>
                  <div>{statusMessage} </div>
                  <br />
                </Grid>
                <Grid>
                  <CircularProgressWithLabel value={progress} />
                </Grid>
              </Grid>

              <LinearProgress />
            </div>
          )}

          {secureUrl.length ? (
            <React.Fragment>
              <Typography id="success" gutterBottom variant="h6">
                Success! Request submitted.
              </Typography>
              <Typography gutterBottom variant="subtitle1">
                Requests are typically approved and scheduled within 2-4 hours.
              </Typography>
              {caseDetails.some((caseDetails) => caseDetails.s3Files) && !uploadingVideoError && (
                <Typography variant="subtitle1">Additional records?</Typography>
              )}
              {uploadingVideoError && (
                <Typography color="error" variant="subtitle1">
                  *Issue uploading video - try once more momentarily
                </Typography>
              )}
              <div style={{ padding: '8px 0px' }}>
                {secureUrl.map((url, idx) => {
                  return (
                    <div key={idx}>
                      <a href={secureUrl[idx]}>
                        <Button
                          id="upload"
                          variant="contained"
                          color="primary"
                          style={
                            secureUrl.length > 1 && idx !== secureUrl.length - 1
                              ? { marginBottom: 8 }
                              : {}
                          }
                        >
                          {secureUrl.length === 1
                            ? 'Upload Medical Records'
                            : `Upload Additional Records (Case ${idx + 1})`}
                        </Button>
                      </a>
                    </div>
                  );
                })}
              </div>
              <Typography gutterBottom variant="subtitle1">
                Submitting records is optional; the records upload link will also be emailed.
              </Typography>

              <Typography gutterBottom variant="subtitle1">
                Records will be made available to your consulting expert and {props.name}.
              </Typography>
            </React.Fragment>
          ) : (
            <Typography gutterBottom variant="h6">
              Just a moment while we submit your request.
            </Typography>
          )}
        </Paper>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

const ErrorText = ({ result }) => (
  <Grid
    style={{
      marginBottom: '24px',
      backgroundColor: 'red',
      padding: '8px',
      border: '1px solid #cecece',
      borderRadius: '5px',
      display: result ? 'block' : 'none'
    }}
    item
    xs={12}
  >
    <Typography variant="button" color="secondary">
      {Array.isArray(result) ? result.map((error, idx) => <div key={idx}>{error}</div>) : result}
    </Typography>
  </Grid>
);

const PartnerPortal = (props) => {
  const { classes, partnerId, name, options, Form, displayNotification } = props;

  const api = EoPartnerAPI(partnerId);

  const [country, setCountry] = useState('');
  const [checked, setChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [products, setProducts] = useState([]);

  const [shareData, setShareData] = useState({
    name: { error: '', value: '' },
    doctorsName: { error: '', value: '' },
    email: { error: '', value: '' },
    cell: { error: '', value: '' },
    product: { error: '', value: '' },
    note: { error: '', value: '' }
  });

  const [showModal, setShowModal] = useState(false);
  const isMobileMemo = useMemo(() => isMobile(), []);
  const countries = useMemo(() => getCountries(), []);

  const url = useMemo(() => {
    const currentUrl = new URL(window.location.href);
    // Construct the URL without query parameters
    return currentUrl.origin + currentUrl.pathname;
  }, []);

  useEffect(() => {
    setCountry(guessCountry(countries));
  }, [setCountry, countries]);

  const downloadVcf = async () => {
    const vCard = await getvCard({
      title: `${name} ⭐ EXPERT SUPPORT`,
      url: window.location
    });

    const link = document.createElement('a');
    link.setAttribute(
      'href',
      'data:text/vcard;charset=utf-8,' + encodeURIComponent(vCard.getFormattedString())
    );
    link.setAttribute('download', `${name}_consult.vcf`);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const submitShare = async () => {
    setIsSubmitting(true);
    setErrorText('');

    let hasErrors = false;

    setShareData((state) => ({
      name: { error: '', value: state.name.value },
      doctorsName: { error: '', value: state.doctorsName.value },
      email: { error: '', value: state.email.value },
      cell: { error: '', value: state.cell.value },
      product: { error: '', value: state.cell.value },
      note: { error: '', value: state.note.value }
    }));

    if (!shareData.name.value) {
      setShareData((state) => ({
        ...state,
        name: { error: 'Your name is required', value: state.name.value }
      }));
      hasErrors = true;
    }

    if (!shareData.doctorsName.value) {
      setShareData((state) => ({
        ...state,
        doctorsName: { error: "Doctor's name is required", value: state.doctorsName.value }
      }));
      hasErrors = true;
    }

    if (shareData.email.value && !EmailValidator.validate(shareData.email.value)) {
      setShareData((state) => ({
        ...state,
        email: { error: 'Invalid email provided', value: state.email.value }
      }));
      hasErrors = true;
    }

    if (!shareData.cell.value) {
      setShareData((state) => ({
        ...state,
        cell: { error: 'Mobile is required', value: state.cell.value }
      }));
      hasErrors = true;
    } else if (!isValidPhoneNumber(shareData.cell.value)) {
      setShareData((state) => ({
        ...state,
        cell: { error: 'Invalid mobile number provided', value: state.cell.value }
      }));
    }

    if (!shareData.product.value) {
      setShareData((state) => ({
        ...state,
        product: { error: 'Product is required', value: state.product.value }
      }));
      hasErrors = true;
    }

    if (hasErrors) {
      return setIsSubmitting(false);
    }

    const data = {
      name: shareData.name.value,
      doctorsName: shareData.doctorsName.value,
      email: shareData.email.value,
      cell: shareData.cell.value,
      product: shareData.product.value,
      note: shareData.note.value,
      country: country,
      partnerName: name,
      partnerId,
      url
    };

    data.url = encodeURI(
      `${data.url}?email=${data.email}&name=${data.name}&doctorName=${data.doctorsName}&cell=${data.cell}`
    );

    const result = await api.shareConsultPage(data);

    setIsSubmitting(false);

    switch (result.status) {
      case 'ok':
        displayNotification({ type: 'success', message: 'Partner Portal Shared' });
        setShareData(() => ({
          name: { error: '', value: '' },
          doctorsName: { error: '', value: '' },
          email: { error: '', value: '' },
          cell: { error: '', value: '' },
          product: { error: '', value: '' },
          note: { error: '', value: '' }
        }));
        setShowModal(false);
        break;
      default:
        setErrorText(result?.errors?.length ? result.errors : result);
        break;
    }
  };

  function onClose(evt, reason) {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }

    setShowModal(false);
  }

  const syncProducts = useCallback((p) => {
    setProducts(Object.keys(p));
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <div id="eo-portal-header" className={classes.header}>
          <Box px={2} py={1} alignItems="center" display="flex" justifyContent="space-between">
            <img className={classes.logo} src={eologo} />
            <div>
              <IconButton
                variant="contained"
                color="secondary"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <PersonAddIcon />
                  <span style={{ fontSize: '1rem' }}>SHARE</span>
                </div>
              </IconButton>
            </div>
          </Box>
          <EOModal
            title={'Share Portal'}
            disableEnforceFocus
            fullScreen={isMobileMemo}
            open={showModal}
            handleClose={onClose}
          >
            <Box
              component="div"
              style={
                isMobileMemo
                  ? {
                      padding: 24,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }
                  : { padding: 24, width: 500 }
              }
            >
              <Grid container direction="column" spacing={3} style={{ padding: '8px' }}>
                <ErrorText result={errorText} />
                <div style={{ margin: '8px 0' }}>
                  <Typography variant="body1" gutterBottom>
                    Know a Doctor in need of Expert guidance? EOMD will reach out to this doctor on
                    your behalf to connect them to an Expert. This service is private and
                    confidential.
                  </Typography>
                </div>

                <TextField
                  style={{ marginBottom: '24px', marginTop: '8px' }}
                  variant="outlined"
                  error={!!shareData.doctorsName.error}
                  helperText={shareData.doctorsName.error}
                  autoFocus={true}
                  label={"Doctor's Name *"}
                  fullWidth
                  value={shareData.doctorsName.value}
                  onChange={(e) => {
                    e.persist();
                    setShareData((state) => ({
                      ...state,
                      doctorsName: { error: state.doctorsName.error, value: e.target.value }
                    }));
                  }}
                  type={'text'}
                />

                <div style={{ marginBottom: '24px' }}>
                  <PhoneInput
                    onChange={(val) => {
                      setShareData((state) => ({
                        ...state,
                        cell: { error: state.cell.error, value: val }
                      }));
                    }}
                    country={country}
                    value={shareData.cell.value}
                    setCountry={setCountry}
                    variant="outlined"
                    error={!!shareData.cell.error}
                    helperText={shareData.cell.error}
                    label={"Doctor's Mobile *"}
                    required
                  />
                </div>

                <TextField
                  style={{ marginBottom: '24px' }}
                  required
                  variant="outlined"
                  error={!!shareData.email.error}
                  helperText={shareData.email.error}
                  autoFocus={false}
                  label={"Doctor's Email"}
                  fullWidth
                  value={shareData.email.value}
                  onChange={(e) => {
                    e.persist();
                    setShareData((state) => ({
                      ...state,
                      email: { error: state.email.error, value: e.target.value }
                    }));
                  }}
                  type={'text'}
                />

                <FormControl style={{ marginBottom: '24px' }} variant="outlined">
                  <Select
                    fullWidth
                    displayEmpty
                    value={shareData.product.value}
                    onChange={(e) => {
                      setShareData((state) => ({
                        ...state,
                        product: { error: state.product.error, value: e.target.value }
                      }));
                    }}
                    renderValue={
                      shareData.product.value !== ''
                        ? undefined
                        : () => <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Product *</span>
                    }
                  >
                    {products?.map((p, idx) => (
                      <MenuItem key={idx} value={p}>
                        {p}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  style={{ margin: '0 0 24px 0' }}
                  required
                  variant="outlined"
                  error={!!shareData.note.error}
                  helperText={shareData.note.error}
                  autoFocus={false}
                  label={'Personal note to doctor'}
                  fullWidth
                  value={shareData.note.value}
                  onChange={(e) => {
                    e.persist();
                    setShareData((state) => ({
                      ...state,
                      note: { error: state.note.error, value: e.target.value }
                    }));
                  }}
                  type={'text'}
                  multiline
                />

                <TextField
                  style={{ marginBottom: '24px' }}
                  required
                  variant="outlined"
                  error={!!shareData.name.error}
                  helperText={shareData.name.error}
                  autoFocus={false}
                  label={'Your name'}
                  fullWidth
                  value={shareData.name.value}
                  onChange={(e) => {
                    e.persist();
                    setShareData((state) => ({
                      ...state,
                      name: { error: state.name.error, value: e.target.value }
                    }));
                  }}
                  type={'text'}
                />

                <FormControlLabel
                  style={{ marginBottom: '24px' }}
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={(event) => {
                        setChecked(event.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label="I have personally spoken to this doctor and have explained this program. The doctor is expecting to hear from me."
                />

                {isSubmitting ? (
                  <CircularProgress style={{ color: 'primary' }} />
                ) : (
                  <Button
                    color="primary"
                    disabled={!checked}
                    variant="contained"
                    onClick={submitShare}
                  >
                    Share
                  </Button>
                )}
                <div style={{ margin: '8px auto 0 auto' }}>
                  <Typography variant="body1" style={{ margin: 0, padding: 0 }}>
                    Share it yourself?{' '}
                    <Link style={{ cursor: 'pointer' }} onClick={downloadVcf}>
                      Download a vCard
                    </Link>
                    .
                  </Typography>
                </div>
              </Grid>
            </Box>
          </EOModal>
        </div>
        <div className={classes.mainBody}>
          <Switch>
            <Route
              exact
              path="/:partnerId/form/:token"
              render={() => {
                return React.cloneElement(Form, { API: api });
              }}
            />
            <Route
              path="/:partnerId"
              render={() => (
                <Main
                  name={name}
                  API={api}
                  classes={classes}
                  search={props.location.search}
                  options={options}
                  syncProducts={syncProducts}
                />
              )}
            />
          </Switch>
        </div>
        <div className={classes.footer}>
          <Typography color="secondary" variant="caption">
            Copyright {new Date().getFullYear()} Expert Opinion |{' '}
            <Link variant="caption" color="secondary" href="https://expertopinion.md/terms">
              Terms & Conditions
            </Link>
          </Typography>
        </div>
      </MuiThemeProvider>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  displayNotification: (notification) => dispatch(displayNotification(notification))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(PartnerPortal)));
