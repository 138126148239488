import OpinionsList from './OpinionsList';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  user: state.auth,
  webData: state.webData
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OpinionsList);
