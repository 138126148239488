import React, { Fragment } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import BaseFileComponent from '../shared/BaseFileComponent';
import FilePreViewer from '../shared/FilePreViewer';

import { _fetchUrl } from '../../utils/api';

class VideoFileComponent extends BaseFileComponent {
  removeVideo = async () => {
    try {
      const response = await _fetchUrl({
        method: 'DELETE',
        path: this.props.path + '?name=' + this.props.file.name,
        token: this.props.token
      });
      if (response.status === 'ok') {
        this.props.postUpload(response);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  render() {
    const { classes, token, path, file } = this.props;
    const { submitted, result } = this.state;

    if (submitted || (this.videoFile && this.videoFile.files && this.videoFile.files.length)) {
      return (
        <Fragment>
          {Array.from((this.videoFile && this.videoFile.files) || []).map((file, index) => (
            <FilePreViewer
              key={index}
              file={{
                name: file.name,
                size: file.size,
                type: file.type,
                url: URL.createObjectURL(file)
              }}
            />
          ))}
          <div style={{ textAlign: 'center' }}>
            <Button
              style={{ color: 'white', margin: '10px 0' }}
              variant="contained"
              color="primary"
              onClick={this.uploadFile(
                this.videoFile,
                `videoFile`,
                token,
                path,
                this.props.postUpload
              )}
              disabled={submitted}
            >
              {submitted ? <CircularProgress /> : <span>Upload Video</span>}
            </Button>
            {!submitted && (
              <Typography
                variant="body1"
                className={classes.pointer}
                onClick={this.resetFile(`videoFile`)}
                style={{ fontSize: '75%' }}
              >
                Reset
              </Typography>
            )}
          </div>
        </Fragment>
      );
    }

    return (
      <div style={{ textAlign: 'center' }}>
        {file && file.url && (
          <FilePreViewer
            CloseIcon={() => (
              <IconButton
                className={classes.iconButton}
                aria-label="delete"
                onClick={this.removeVideo}
              >
                <DeleteIcon color="error" />
              </IconButton>
            )}
            file={file}
          />
        )}
        {!file && (
          <Button
            style={{ marginTop: '20px', marginBottom: '20px' }}
            variant="contained"
            onClick={(e) => {
              e.currentTarget.nextSibling.click();
              this.setState((state) => ({ ...state, submitting: true }));
            }}
          >
            Attach Video
          </Button>
        )}
        <input
          style={{ display: 'none' }}
          type={'file'}
          accept="video/mp4,video/x-m4v,video/*"
          onChange={this.selectFile(`videoFile`)}
          ref={(ref) => (this.videoFile = ref)}
        />
        {result && (
          <Typography variant="body1">
            File Uploaded. Please check back in 5-10 minutes for the video to finish processing.
          </Typography>
        )}
      </div>
    );
  }
}

export default VideoFileComponent;
