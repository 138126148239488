import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import Error from '@material-ui/icons/Error';

import { isPhoneInvalid, isDateInvalid } from '../../utils/formValidation';
import { _fetchUrl } from '../../utils/api';

import providerInfo from './_providerInfo';
import styles from './_styles';

class AddVisit extends Component {
  state = {
    submitted: false,
    error: null,
    formErrors: {}
  };

  componentDidMount() {
    if (this.props.patient.subscriptions.length === 1) {
      this.setState({ subscription: '0' });
    }
  }

  handleChange = (key) => (event) => {
    const value = event.target.value;

    return this.setState((prevState) => ({
      [key]: value,
      formErrors: { ...prevState.formErrors, [key]: false }
    }));
  };

  handleAddVisitSubmit = (event) => {
    event.preventDefault();
    this.setState({ formErrors: {} });

    let hasErrors = false;
    const body = {
      patientId: this.props.patient.id
    };

    const setError = (fieldName) => {
      hasErrors = true;
      this.setState((prevState) => ({
        formErrors: { ...prevState.formErrors, [fieldName]: true }
      }));
    };

    if (!this.state.subscription) {
      setError('subscription');
    } else {
      body.productId = this.props.patient.subscriptions[this.state.subscription].productId;
      body.productName = this.props.patient.subscriptions[this.state.subscription].productName;
      body.doctorId = this.props.patient.subscriptions[this.state.subscription].doctorId;
    }

    providerInfo.forEach((field) => {
      const value = this.state[field.name];

      switch (field.type) {
        case 'tel':
          if (isPhoneInvalid(value)) {
            return setError(field.name);
          }
          break;
        case 'date':
          if (isDateInvalid(value)) {
            return setError(field.name);
          }
          break;
        default:
          if (!value || !value.length) {
            return setError(field.name);
          }
      }

      body[field.name] = value;
    });

    if (!hasErrors) {
      const states = {
        submitting: { visitSubmitted: true, visitSubmittedError: false },
        complete: { visitSubmitted: false }
      };

      this.setState(states.submitting, async () => {
        try {
          const response = await _fetchUrl({
            token: this.props.token,
            method: 'POST',
            path: `poe/connect/newVisit`,
            body
          });
          if (response.status === 'ok') {
            return this.setState({ ...states.complete }, () =>
              this.props.afterAddVisit(response.newOpinionId)
            );
          }
          return this.setState({ ...states.complete, visitSubmittedError: response.error });
        } catch (visitSubmittedError) {
          return this.setState({ ...states.complete, visitSubmittedError });
        }
      });
    }
  };

  render() {
    const { classes, patient, handleClose } = this.props;
    const { submitted, error, formErrors } = this.state;

    return (
      <Dialog open={true} onClose={handleClose}>
        <form onSubmit={this.handleAddVisitSubmit}>
          <DialogTitle>Add Visit</DialogTitle>
          <DialogContent>
            <FormControl
              fullWidth={true}
              className={classes.formControl}
              error={formErrors.subscription}
            >
              <InputLabel shrink htmlFor={'subscription'}>
                {formErrors.subscription && <Error className={classes.errorIcon} />}
                Subscription{' '}
                <span className={classes.formHelpText}>
                  {formErrors.subscription && '(Required)'}
                </span>
              </InputLabel>
              <Select
                native
                value={this.state.subscription || ''}
                disabled={patient.subscriptions.length === 1}
                onChange={this.handleChange('subscription')}
                inputProps={{ name: 'subscription', id: 'subscription' }}
              >
                <option value="" disabled={true} />
                {patient.subscriptions.map((subscription, idx) => (
                  <option key={idx} value={idx}>
                    {subscription.productName}: Dr. {subscription.doctorName}
                  </option>
                ))}
              </Select>
            </FormControl>
            {this.state.subscription && (
              <Fragment>
                <Typography variant="subtitle1" className={classes.marginTop}>
                  Visit Information
                </Typography>
                {providerInfo.map((field) => (
                  <FormControl
                    key={field.name}
                    className={classes.formControl}
                    fullWidth={true}
                    error={formErrors[field.name]}
                  >
                    <InputLabel
                      shrink
                      htmlFor={field.name}
                      FormLabelClasses={{ root: classes.label }}
                    >
                      {formErrors[field.name] && <Error className={classes.errorIcon} />}
                      {field.label}
                      {field.optional ? ' (Optional)' : ''} {field.helpText}{' '}
                      {formErrors[field.name] && '(required)'}
                    </InputLabel>
                    <Input
                      id={field.name}
                      type={field.type || 'text'}
                      value={this.state[field.name] || ''}
                      multiline={field.rowsMax > 1}
                      rowsMax={field.rowsMax}
                      inputProps={{ step: 'any', min: field.min, max: field.max }}
                      onChange={this.handleChange(field.name)}
                    />
                  </FormControl>
                ))}
                <FormControl className={classes.formControl} fullWidth={true}>
                  <InputLabel shrink htmlFor="questions" FormLabelClasses={{ root: classes.label }}>
                    Questions/Areas of Concern for{' '}
                    <span style={{ whiteSpace: 'nowrap' }}>
                      Dr. {patient.subscriptions[this.state.subscription].doctorName}
                    </span>{' '}
                    (Optional)
                  </InputLabel>
                  <Input
                    id="questions"
                    type={'text'}
                    value={this.state.questions || ''}
                    multiline={true}
                    rowsMax={12}
                    onChange={this.handleChange('questions')}
                  />
                </FormControl>
                <DialogActions>
                  <div style={{ margin: '0', textAlign: 'right' }}>
                    <Button
                      className={classes.saveButton}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitted}
                    >
                      {submitted ? <CircularProgress /> : <span>Save Visit</span>}
                    </Button>
                    {error && (
                      <Typography variant="body1" className={classes.error}>
                        {error}
                      </Typography>
                    )}
                  </div>
                </DialogActions>
              </Fragment>
            )}
          </DialogContent>
        </form>
      </Dialog>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(AddVisit));
