import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import ProgressButton from '../../../partials/ProgressButton';

const Form = (props) => {
  const { API } = props;

  const [token, setToken] = useState(props.match.params.token || props.token);
  const [consultDetails, setConsultDetails] = useState(null);

  const [feedback, setFeedback] = useState({});
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({});
  const [serverError, setServerError] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [done, setDone] = useState(false);
  const [tokenError, setTokenError] = useState(false);

  useEffect(() => {
    API.getSurveyQuestions(token).then((res) => {
      if (res.status === 'ok') {
        setQuestions(res.survey);
        setLoading(false);
      } else if (res.status === 'Feedback has already been submitted') {
        setDone(true);
      } else {
        console.warn(res.status);
      }
    });

    if (!token || token === 'customer') {
      API.getConsultToken('customer').then((res) => {
        setToken(res.token);
      });
    } else if (token === 'expert') {
      API.getConsultToken('expert').then((res) => {
        setToken(res.token);
      });
    } else {
      API.getConsultDetails(token).then((res) => {
        if (res.status === 'ok') {
          setConsultDetails({ ...res });
        } else {
          setTokenError(true);
        }
      });
    }
  }, [token]);

  useEffect(() => {
    if (submittingForm === false || submitted === true) {
      return;
    }

    onSubmit();
  }, [submittingForm, submitted]);

  const onFeedback = (key, value) => {
    setFeedback({ ...feedback, [key]: value });
    setError((errors) => ({ ...errors, [key]: false }));
  };

  const submitForm = () => {
    setSubmittingForm(true);
  };

  const onSubmit = () => {
    let hasErrors = false;

    // Validate
    questions.forEach((question) => {
      if (!question.optional && !feedback[question.id]) {
        setError((errors) => ({ ...errors, [question.id]: true }));
        hasErrors = true;
      }
    });

    if (hasErrors === false) {
      API.sendConsultFeedback(token, consultDetails.type, feedback).then((r) => {
        setSubmittingForm(false);

        if (r.status === 'ok') {
          setSubmitted(true);
        } else if (r.status === 'Feedback has already been submitted') {
          setDone(true);
        } else {
          setServerError(r.status);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      });
    }
  };

  if (submitted) {
    return (
      <div style={{ padding: '24px' }}>
        <Typography variant="h2">Thank you for submitting your feedback!</Typography>
      </div>
    );
  }

  if (tokenError) {
    return (
      <div style={{ padding: '24px' }}>
        <Alert severity="error">
          <AlertTitle>Could not find survey 😔</AlertTitle>
          <Typography variant="body1">
            Unfortunately, we weren't able to find the details for this consult. This may happen for
            a few reasons:
            <ul>
              <li>The survey link has expired (most common).</li>
              <li>The survey link was not valid (less common).</li>
            </ul>
            <p>
              If you have additional questions, reach out to{' '}
              <a href="mailto://aidan@expertopinion.md">Aidan</a>.
            </p>
          </Typography>
        </Alert>
      </div>
    );
  }

  if (consultDetails === null) {
    return null;
  }

  return (
    <div style={{ padding: '24px' }}>
      <Typography variant="h4" gutterBottom>
        Post-Consult Questionnaire
      </Typography>
      <div
        style={{
          border: '1px solid #c8c8c8',
          borderRadius: '2px',
          padding: '8px',
          marginBottom: '32px',
          backgroundColor: '#f5f5f5'
        }}
      >
        <Typography variant="h5" gutterBottom>
          Case Summary
        </Typography>
        <Typography variant="body1">Requesting Doctor: {consultDetails.name}</Typography>
        <Typography variant="body1">Expert: {consultDetails.surgeon.name}</Typography>
        <Typography variant="body1">
          Consult Time: {moment(consultDetails.callTime).format('MMM Do, YYYY hh:mm a')}
        </Typography>
        <Typography variant="body1">Case Details: {consultDetails.caseDescription}</Typography>
        <Typography variant="body1">Patient Question: {consultDetails.patientQuestion}</Typography>
      </div>

      {serverError !== false ? <Alert severity="error">{serverError}</Alert> : <div></div>}

      {loading === false ? (
        <div style={{ margin: '24px 8px' }}>
          <Typography gutterBottom variant="h5">
            Questions
          </Typography>
          <Divider style={{ marginBottom: '-8px' }} />
          {questions.map((question, idx) => {
            switch (question.type) {
              case 'TEXTFIELD':
                return (
                  <div key={idx} style={{ marginTop: '32px' }}>
                    <FormLabel component="legend">
                      {idx + 1}. {question.label}
                    </FormLabel>
                    <TextField
                      fullWidth
                      multiline={question.multiline ? true : false}
                      required={question.optional ? false : true}
                      rows="4"
                      margin="normal"
                      onChange={(e) => onFeedback(question.id, e.target.value)}
                      variant="outlined"
                      value={feedback.id}
                      error={error[question.id]}
                      helperText={error[question.id] ? 'Field is required' : ''}
                    />
                  </div>
                );
              case 'RADIO':
                return (
                  <div key={idx} style={{ marginTop: '32px' }}>
                    <FormLabel component="legend">
                      {idx + 1}. {question.label}
                    </FormLabel>
                    <FormControl
                      style={{
                        marginTop: question.labelPlacement === 'top' ? '8px' : '0',
                        fontSize: '10em'
                      }}
                      component="fieldset"
                      error={error[question.id]}
                    >
                      <RadioGroup
                        style={{
                          flexDirection: question.labelPlacement === 'top' ? 'row' : 'column'
                        }}
                        aria-label={question.label}
                        name={question.id}
                        value={feedback.id}
                        onChange={(e) => {
                          onFeedback(question.id, e.target.value);
                        }}
                      >
                        {question.options.map((option, idx) => {
                          return (
                            <FormControlLabel
                              key={idx}
                              value={idx.toString()}
                              control={
                                <Radio
                                  color="primary"
                                  style={question.labelPlacement === 'top' ? { padding: '0' } : {}}
                                />
                              }
                              label={option}
                              labelPlacement={question.labelPlacement || 'end'}
                            />
                          );
                        })}
                      </RadioGroup>
                      <FormHelperText>
                        {error[question.id] ? 'Field is required' : ''}
                      </FormHelperText>
                    </FormControl>
                  </div>
                );
            }
          })}
          <ProgressButton
            disabled={submittingForm}
            color="primary"
            onClick={submitForm}
            variant="contained"
            style={{ marginTop: '32px' }}
          >
            Submit
          </ProgressButton>
        </div>
      ) : done ? (
        <Typography variant="h5">Thank you, your feedback has already been received.</Typography>
      ) : (
        <div style={{ padding: '24px' }}>Loading questions...</div>
      )}
    </div>
  );
};

export default withRouter(Form);
