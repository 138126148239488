import React, { useState, useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';

import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';

import EOTable from '../../partials/EOTable';
import SurveyResponseModal from '../shared/SurveyResponseModal';

import * as survey from '../../utils/survey';

import { _fetchUrl } from '../../utils/api';

function formatTimeForExport(timestamp) {
  return moment(timestamp).format('lll Z');
}

function recordDescription() {
  return [
    '#',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'These are the original case notes.',
    "This value is whether or not the consult adequately addressed the customer doctor's question. 1: no, 2: somewhat, 3: yes.",
    'This is the rating of the overall consult experience for the customer doctor. 1-3, 3 being highest.',
    'This is the rating of whether or not the consult experience increased the customers confidence in the brand or product, 1 is no increase, 2 is neutral, and 3 is increased confidence.',
    'These are recommendations the customer doctor has for improving the consult experience.',
    '',
    "This is the expert's rating of the complexity of the case. 1-3, 3 being most complex.",
    'These are any proactive steps the expert suggests you should take for future cases.',
    "This is the expert's recommendation made to the customer doctor regarding the case.",
    ''
  ].map((a) => `"${a}"`);
}

function recordToArray(header, record) {
  if (header) {
    return [
      '#id',
      'status',
      'request_date',
      'customer_name',
      'customer_email',
      'customer_phone',
      'rep_email',
      'medical_affairs_appover',
      'approval_date',
      'call_date (UTC)',
      'expert',
      'product',
      'notes',
      'customer_adequacy_score',
      'customer_experience_rating',
      'customer_confidence_rating',
      'customer_recommendations',
      'customer_response_date',
      'expert_case_complexity_score',
      'expert_feedback',
      'expert_recommendations',
      'expert_response_date',
      'case_description',
      'patient_question'
    ];
  }

  const arr = [
    record.id,
    record.status,
    formatTimeForExport(record.requestDetails.timestamp),
    record.requestDetails.customer.name,
    record.requestDetails.customer.email,
    record.requestDetails.customer.phone,
    record.requestDetails.rep.email,
    record.approval.approver,
    formatTimeForExport(record.approval.timestamp),
    record.callDetails.date ? formatTimeForExport(record.callDetails.date) : '',
    record.callDetails.selectedExpert.name,
    record.requestDetails.category,
    record.requestDetails.notes,

    ...survey.parseCustomerSurveyResult(record.customerResponse, formatTimeForExport),
    ...survey.parseExpertSurveyResult(record.expertResponse, formatTimeForExport),

    record.requestDetails.caseDescription,
    record.requestDetails.patientQuestion
  ];

  return arr.map((a) => {
    const b = `${a}`;
    return `"${b.replace(/"/g, '""')}"`;
  });
}

const Consults = (props) => {
  const [rawConsults, setRawConsults] = useState([]);
  const [partnerId] = useState('');
  const [surveyCache, setSurveyCache] = useState({});

  useEffect(() => {
    if (!props.user || !props.user.token) {
      return;
    }

    _fetchUrl({
      method: 'GET',
      path: 'partner/consults',
      token: props.user.token
    }).then((r) => {
      setRawConsults(
        r.consults.sort((a, b) => {
          if (a.requestDetails.timestamp > b.requestDetails.timestamp) {
            return -1;
          } else if (a.requestDetails.timestamp < b.requestDetails.timestamp) {
            return 1;
          } else {
            return 0;
          }
        })
      );
    });
  }, [partnerId, props.user]);

  const consults = useMemo(() => {
    return rawConsults.filter((c) => c.status !== 'CLOSED_DUPLICATE');
  }, [rawConsults]);

  const experts = useMemo(() => {
    return consults
      .sort((a, b) => {
        if (a.callDetails.selectedExpert.name > b.callDetails.selectedExpert.name) {
          return 1;
        } else if (a.callDetails.selectedExpert.name < b.callDetails.selectedExpert.name) {
          return -1;
        }
        return 0;
      })
      .reduce((agg, item) => {
        const ex = item.callDetails.selectedExpert;
        if (ex) {
          agg[ex.name] = ex.name;
        } else {
          agg[''] = '';
        }
        return agg;
      }, {});
  }, [consults]);

  const products = useMemo(() => {
    return consults
      .sort((a, b) => {
        if (a.requestDetails.category > b.requestDetails.category) {
          return 1;
        } else if (a.requestDetails.category < b.requestDetails.category) {
          return -1;
        }
        return 0;
      })
      .reduce((agg, item) => {
        agg[item.requestDetails.category] = item.requestDetails.category;
        return agg;
      }, {});
  }, [consults]);

  const getDateStr = (date) => {
    if (!date) {
      return '';
    }

    return moment(date).format('MMMM Do, YYYY h:mm a');
  };

  const downloadFile = () => {
    const lines = consults.map((consult) => recordToArray(false, consult).join(','));
    const text = '﻿' + [recordDescription(), recordToArray(true).join(','), ...lines].join('\n');

    const link = document.createElement('a');
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    const date = moment();
    link.setAttribute('download', `consult-report-${date.format('YYYY-MM-DD')}.csv`);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <form>
        <EOTable
          actions={[
            {
              icon: () => <GetAppIcon />,
              tooltip: 'Export Data',
              isFreeAction: true,
              onClick: () => {
                downloadFile();
              }
            }
          ]}
          columns={[
            {
              title: 'Request Date',
              field: 'requestDetails.timestamp',
              defaultSort: 'desc',
              filtering: false,
              render: (rowData) => <span>{getDateStr(rowData.requestDetails.timestamp)}</span>
            },
            { title: 'Product', field: 'requestDetails.category', lookup: products },
            {
              title: 'Status',
              field: 'status',
              sorting: false,
              lookup: {
                MA_APPROVED: 'Approved',
                NEEDS_REVIEW: 'Needs Review',
                CALL_COMPLETE: 'Call Completed',
                CALL_SCHEDULED: 'Call Scheduled',
                CONSULT_CLOSED: 'Closed',
                CONSULT_COMPLETE: 'Consult Complete',
                DENIED: 'Denied',
                PENDING_APPROVAL: 'Pending Approval',
                EXPERT_SURVEY_SUBMITTED: 'Survey Submitted'
              }
            },
            { title: 'Approver', field: 'approval.approver' },
            {
              title: 'Response Date',
              field: 'approval.timestamp',
              filtering: false,
              render: (rowData) => <span>{getDateStr(rowData.approval.timestamp)}</span>
            },
            {
              title: 'Rep',
              field: 'requestDetails.rep.email',
              filtering: false,
              render: (r) => <span>{r.requestDetails.rep.email.toLowerCase()}</span>
            },
            { title: 'Customer Doctor', field: 'requestDetails.customer.name', filtering: false },
            { title: 'Selected Expert', field: 'callDetails.selectedExpert.name', lookup: experts },
            {
              title: 'Call Date',
              field: 'callDetails.date',
              filtering: false,
              render: (rowData) => <span>{getDateStr(rowData.callDetails.date)}</span>
            },
            {
              title: 'Customer Feedback',
              sorting: false,
              render: (rowData) => {
                if (rowData.customerResponse.timestamp !== null) {
                  return (
                    <div>
                      <SurveyResponseModal
                        surveyCache={surveyCache}
                        setSurveyCache={setSurveyCache}
                        user={props.user}
                        consultId={rowData.id}
                        customer={true}
                      />
                    </div>
                  );
                } else {
                  return (
                    <IconButton disabled={true}>
                      <VisibilityIcon />
                    </IconButton>
                  );
                }
              },
              editable: 'never'
            },
            {
              title: 'Expert Feedback',
              sorting: false,
              render: (rowData) => {
                if (rowData.expertResponse.timestamp !== null) {
                  return (
                    <div>
                      <SurveyResponseModal
                        surveyCache={surveyCache}
                        setSurveyCache={setSurveyCache}
                        user={props.user}
                        consultId={rowData.id}
                        customer={false}
                      />
                    </div>
                  );
                } else {
                  return (
                    <IconButton disabled={true}>
                      <VisibilityIcon />
                    </IconButton>
                  );
                }
              },
              editable: 'never'
            }
          ]}
          data={consults}
          options={{
            draggable: true,
            thirdSortClick: false,
            showTitle: false,
            sorting: true,
            filtering: true,
            pageSize: 25,
            pageSizeOptions: [25, 50, 100]
          }}
        />
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth
});

export default connect(mapStateToProps)(Consults);
