import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import NativeSelect from '@material-ui/core/NativeSelect';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { _fetchUrl } from '../../../utils/api';
import DocumentTitle from '../../../partials/DocumentTitle';
import LoadingIndicator from '../../shared/LoadingIndicator';

import ProductModal from './ProductModal';
import styles from '../_styles';

class Products extends Component {
  state = {
    loading: true,
    allProducts: null,
    selectedSpecialty: null,
    selectedCategory: null,
    selectedProduct: null,
    showProductModal: false
  };

  loadProducts = () => {
    const states = {
      loading: { loading: true, allProducts: null },
      complete: { loading: false, allProducts: null }
    };

    this.setState({ loading: true }, async () => {
      try {
        const response = await _fetchUrl({
          method: 'GET',
          path: `poe/product`,
          token: this.props.user.token
        });
        if (response.status === 'ok') {
          return this.setState({ ...states.complete, allProducts: response.products });
        }
        return this.setState({ ...states.complete });
      } catch (error) {
        return this.setState({ ...states.complete });
      }
    });
  };
  componentDidMount = () => this.loadProducts();

  showProductModal = () =>
    this.setState((prevState) => ({
      showProductModal: true,
      selectedProduct: {
        specialty: prevState.selectedSpecialty,
        category: prevState.selectedCategory
      }
    }));
  closeProductModal = () => this.setState({ showProductModal: false });
  afterSaveProduct = () => {
    this.closeProductModal();
    this.loadProducts();
  };
  handleSelectSpecialty = (event) =>
    this.setState({ selectedSpecialty: event.target.value, selectedCategory: null });
  handleSelectCategory = (event) => this.setState({ selectedCategory: event.target.value });
  handleSelectProduct = (productId) => () => {
    const { allProducts, selectedSpecialty, selectedCategory } = this.state;
    const selectedProduct = allProducts[selectedSpecialty][selectedCategory].find(
      (p) => p.id === productId
    );

    this.setState({ showProductModal: true, selectedProduct });
  };

  render() {
    const { classes, theme, user } = this.props;
    const {
      loading,
      allProducts,
      selectedSpecialty,
      selectedCategory,
      selectedProduct,
      showProductModal
    } = this.state;

    if (loading) {
      return <LoadingIndicator message="Loading Products..." />;
    }

    const specialties = Object.keys(allProducts).sort();
    let categories = [];
    let products = [];

    if (selectedSpecialty && Object.keys(allProducts).includes(selectedSpecialty)) {
      const keys = Object.keys(allProducts[selectedSpecialty]) || [];
      categories = keys.sort();
    }

    if (
      selectedSpecialty &&
      selectedCategory &&
      Object.keys(allProducts).includes(selectedSpecialty) &&
      Object.keys(allProducts[selectedSpecialty]).includes(selectedCategory)
    ) {
      const keys = allProducts[selectedSpecialty][selectedCategory] || [];
      products = keys.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });
    }

    return (
      <Fragment>
        <DocumentTitle title={`Admin: Products:`} />
        <Typography variant="h6">Products</Typography>

        {showProductModal && (
          <ProductModal
            allProducts={allProducts}
            selectedProduct={selectedProduct}
            handleClose={this.closeProductModal}
            afterSaveProduct={this.afterSaveProduct}
            token={user.token}
          />
        )}
        <Button
          onClick={this.showProductModal}
          className={classes.addButton}
          variant="contained"
          color="primary"
        >
          Add Product
        </Button>
        <br />

        <FormControl className={classes.marginTop}>
          <Typography variant="subtitle1">Select a Specialty:</Typography>
          <NativeSelect
            defaultValue={selectedSpecialty || ''}
            onChange={this.handleSelectSpecialty}
            input={<Input name="specialty" id="specialty" />}
          >
            <option value="" />
            {specialties.map((specialty) => (
              <option key={specialty} value={specialty}>
                {specialty}
              </option>
            ))}
          </NativeSelect>
        </FormControl>

        <FormControl className={classes.marginTop} style={{ marginLeft: theme.spacing(2) }}>
          <Typography variant="subtitle1">Select a Category:</Typography>
          <NativeSelect
            defaultValue={selectedCategory || ''}
            onChange={this.handleSelectCategory}
            input={<Input name="category" id="category" />}
          >
            <option value="" />
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </NativeSelect>
        </FormControl>

        <Paper className={classes.paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <TableRow
                  key={product.id}
                  hover
                  className={classes.pointer}
                  onClick={this.handleSelectProduct(product.id)}
                >
                  <TableCell component="th" scope="row">
                    {product.id}
                  </TableCell>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>{product.description}</TableCell>
                  <TableCell>{product.type}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Products));
