const FETCH_TIMEOUT_MS = 30000;

const _fetchUrl = async ({ method, path, token, body, fileUpload }) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/v1/${path}`;
  const options = {
    method,
    headers: { mode: 'cors', 'x-uri': process.env.REACT_APP_API_BASE_URL },
    body: fileUpload ? body : JSON.stringify(body)
  };

  if (!fileUpload) {
    options.headers['content-type'] = 'application/json';
  }

  if (token) {
    options.headers['authorization'] = `Bearer ${token}`;
  }

  const promises = [
    fetch(url, options).then((r) => {
      if (timer) {
        clearTimeout(timer);
      }
      return r.json();
    })
  ];

  let timer;
  if (!fileUpload) {
    promises.push(
      new Promise((resolve, reject) => {
        return (timer = setTimeout(reject, FETCH_TIMEOUT_MS, {
          method,
          url,
          token,
          body,
          status: 'error',
          statusText: 'Gateway Timeout',
          message: 'Failed to Fetch'
        }));
      })
    );
  }

  try {
    return await Promise.race(promises);
  } catch (err) {
    return err;
  }
};

export { _fetchUrl };
