import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';

const CustomerResponse = (props) => {
  const { adequacyScore, experienceRating, recommendations, timestamp } = props;

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      component={Paper}
      style={{ padding: '1rem' }}
      spacing={1}
    >
      <Typography variant="h4" align="center">
        Customer Response
      </Typography>
      <Grid item container justifyContent="flex-start">
        <Typography variant="body1">Adequacy Score</Typography>
        <Typography variant="body1">{adequacyScore}</Typography>
      </Grid>
      <Divider />
      <Grid item container justifyContent="flex-start">
        <Typography variant="body1">Experience Ratings:</Typography>
        <Typography variant="body1">{experienceRating}</Typography>
      </Grid>
      <Divider />
      <Grid item container justifyContent="flex-start">
        <Typography variant="body1">Time Stamp: </Typography>
        <Typography variant="body1">
          {timestamp ? moment(timestamp).format('dddd, MMMM Do YYYY, h:mm:ss a') : 'N/a'}
        </Typography>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item sm>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">Recommendations </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">{recommendations}</Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CustomerResponse;
