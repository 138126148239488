import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import * as EmailValidator from 'email-validator';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { pickFile } from '../../../utils/pickFile.js';
import { _fetchUrl } from '../../../utils/api';
import { displayNotification } from '../../../store/actions';

import {
  Button,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import en from 'react-phone-number-input/locale/en.json';

import { guessCountry } from '../../../utils/guessCountry';
import isMobile from '../../../utils/isMobile';
import { getMedicalRecordForm } from 'eo_utils';

const useStyles = makeStyles((theme) => ({
  remove: {
    color: theme.palette.error.main,
    cursor: 'pointer'
  },
  option: {
    textTransform: 'capitalize'
  },
  textfield: {
    marginBottom: '16px'
  }
}));

const FileManager = ({ state, setState, index, ignore, setIgnore, mrRemoveFile }) => {
  const setFormDataState = (formData) => {
    state[index]['formData'] = formData;
    setState([...state]);
  };

  useEffect(() => {
    if (state[index].formData && !ignore) {
      state[index].formData.map((data) => {
        if (data.field.name === 'videoFiles' && state[index].s3Files) {
          data.value = state[index].s3Files.map((file) => file.name);
        }

        return data;
      });

      setIgnore(true);
      setFormDataState(state[index].formData);
    }
  }, [state[index].formData, state[index].s3Files]);

  const deleteSingle = useCallback(
    (idx, sub_idx) => {
      state[index].formData = state[index].formData.map((el, arr_idx) => {
        if (idx === arr_idx) {
          el.files.splice(sub_idx, 1);
          el.value.splice(sub_idx, 1);

          if (state[index].formData[arr_idx].field.name === 'videoFiles') {
            state[index].s3Files.splice(sub_idx, 1);
          }
        }

        return el;
      });

      setIgnore(true);
      setState([...state]);

      if (mrRemoveFile[`${index}_${state[index].formData[idx].field.name}`]) {
        mrRemoveFile[`${index}_${state[index].formData[idx].field.name}`](
          state[index].formData[idx].field.name,
          undefined,
          sub_idx
        );
      }
    },
    [state[index].formData, mrRemoveFile]
  );

  return state[index].formData ? (
    <div style={{ padding: 0, margin: '8px 0 24px 0' }}>
      {state[index].formData
        .filter((el) => el.value && Array.isArray(el.value))
        .map((el, idx) => {
          return (
            <div key={idx}>
              {
                // <strong>{el.field.name}: </strong>
              }
              <Fragment>
                {el.value.map((el, sub_idx) => (
                  <div key={sub_idx} style={{ margin: '8px 0' }}>
                    <span style={{ position: 'absolute', marginTop: '0px', cursor: 'pointer' }}>
                      <CloseIcon
                        onClick={() => {
                          deleteSingle(idx, sub_idx);
                        }}
                        fontSize="small"
                        color="error"
                      />
                    </span>
                    <span style={{ marginLeft: 32, opacity: 0.5 }}>{el}</span>
                  </div>
                ))}
              </Fragment>
            </div>
          );
        })}
    </div>
  ) : null;
};

const params = new URLSearchParams(window.location.search);

const CaseDetails = (props) => {
  const {
    caseDetails,
    caseDetailsIndex: index,
    labels,
    reps,
    partnerName,
    countries,
    displayNotification
  } = props;

  const [state, setState] = useState(
    caseDetails.length
      ? caseDetails
      : [
          {
            country: '',
            patientQuestion: '',
            caseDescription: '',
            category: params.get('product') || '',
            name: params.get('doctorName') || '',
            repEmail: '',
            formData: false,
            s3Files: false
          }
        ]
  );

  const [mrRemoveFile] = useState({});
  const [ignore, setIgnore] = useState(false);
  const [error, setError] = useState({});
  const [metadata, setMetadata] = useState(null);

  const isMobileMemo = useMemo(() => isMobile(), []);
  const isDisabled = useMemo(() => state.length >= 3, [state.length]);
  const countryList = useMemo(() => {
    return [{ code: '', name: '' }, ...countries.map((code) => ({ code, name: en[code] }))].sort(
      (a, b) => (a.name > b.name ? 1 : -1)
    );
  }, [countries]);

  useEffect(() => {
    if (reps) {
      const repEmail = reps.find((rep) => rep.fullName === params.get('name'))?.email;

      if (repEmail) {
        setState([
          {
            ...state[0],
            repEmail
          }
        ]);
      }
    }
  }, [reps, params]);

  // Alternatively just set to US if we don't want to derive it from the browser language
  useEffect(() => {
    if (!state[index].country) {
      setStateValue('country', guessCountry(countryList));
    }
  }, [countryList, setStateValue]);

  let productId;

  const medicalRecordForm = useMemo(() => getMedicalRecordForm(productId), [productId]);

  const loadMetadata = async () => {
    let response = await _fetchUrl({
      path: `records/metadata`
    });

    const { status, ...metadata } = response;

    if (status === 'ok') {
      setMetadata(metadata);
    }

    return metadata;
  };

  useEffect(() => {
    loadMetadata();
  }, []);

  // Update to match supported types - valid as of 05/14/23
  const supportedVideoTypes = [
    'video/mp4',
    'video/avi',
    'video/quicktime',
    'video/x-ms-wmv',
    'video/webm',
    'video/mpeg',
    'application/octet-stream',
    'video/x-matroska',
    'video/3gpp',
    'video/3gpp2'
  ];

  const supportedFileTypes = ['image/png', 'image/jpeg', 'application/pdf'];

  const classes = useStyles();

  function validate() {
    let questionMap = {
      name: 'Name',
      repEmail: 'Email',
      country: 'Country',
      category: 'Category',
      caseDescription: 'Case Description',
      patientQuestion: 'Clinical Question'
    };

    const newError = {};

    for (let idx in state) {
      let errors = {};

      for (let question in state[idx]) {
        if (!['caseDescription', 'patientQuestion'].includes(question) && idx !== '0') {
          continue;
        }

        if (questionMap[question] && !state[idx][question]) {
          errors[question] = `${questionMap[question]} is required`;
        }
      }

      // Additional validation - Email
      if (idx === '0' && !error[idx]?.repEmail && !EmailValidator.validate(state[idx]?.repEmail)) {
        errors['repEmail'] = 'Search by name, or enter in an email address';
      }

      if (Object.entries(errors).length) {
        newError[idx] = errors;
      }
    }

    if (Object.entries(newError).length) {
      setError(newError);
      return false;
    } else {
      setError({});
    }

    return true;
  }

  function handleNext() {
    if (validate(index)) {
      // Restore repname + category when cleared due to interportal navigation
      props.onNext({
        ...state.map((s) => {
          s.repEmail = state[0].repEmail;
          s.category = state[0].category;
          return s;
        })
      });
    }
  }

  function setStateValue(key, value, idx) {
    // Using set state callback returns state as object and not array
    state[idx || index][key] = value;
    setState([...state]);
  }

  function handleRepEmail(evt, newValue) {
    state[index]['repEmail'] = newValue.email;
    setState([...state]);
  }

  const handleFormData = (formData, idx) => {
    setStateValue('formData', formData, idx);
  };

  const handleS3Files = (s3Files, idx) => {
    setStateValue('s3Files', s3Files, idx);
  };

  const addCase = () => {
    setState((cases) => {
      cases.push({
        name: cases[0].name,
        repEmail: cases[0].repEmail,
        country: cases[0].country,
        category: cases[0].category,
        caseDescription: '',
        patientQuestion: '',
        formData: false,
        s3Files: false
      });

      return [...cases];
    });
  };

  function getCaseHelperHelperText(name) {
    switch (name) {
      case 'Glaukos':
        return 'Ensure the request is on-label and patient-specific.';
      default:
        return '';
    }
  }

  return (
    <React.Fragment>
      <TextField
        id="name"
        fullWidth
        margin="normal"
        className={classes.textfield}
        variant="outlined"
        value={state[index]?.name}
        onChange={(e) => setStateValue('name', e.target.value)}
        label="Surgeon Name"
        name="Name"
        error={error[index]?.name !== undefined && error[index]?.name !== ''}
        helperText={error[index]?.name}
        required
      />
      {reps.length === 0 ? (
        <TextField
          fullWidth
          margin="normal"
          name="RepEmail"
          variant="outlined"
          value={state[index]?.repEmail}
          onChange={(e) => setStateValue('repEmail', e.target.value)}
          label={labels.repField}
          error={error[index]?.repEmail !== undefined && error[index]?.repEmail !== ''}
          helperText={error[index]?.repEmail || labels.repFieldHelper || ''}
          required
        />
      ) : (
        <Autocomplete
          freeSolo
          id="rep"
          name="RepEmail"
          disableClearable
          classes={{
            option: classes.option
          }}
          value={reps?.find((v) => v.email === state[index]?.repEmail) || null}
          options={reps}
          getOptionLabel={(option) => option.fullName}
          onChange={handleRepEmail}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              value={state.repEmail}
              onChange={(e) =>
                setStateValue(
                  'repEmail',
                  reps.find((v) => v.fullName === e.target.value)?.email || e.target.value
                )
              }
              label={labels.repField}
              margin="normal"
              variant="outlined"
              error={error[index]?.repEmail !== undefined && error[index]?.repEmail !== ''}
              helperText={error[index]?.repEmail}
              InputProps={{
                ...params.InputProps,
                id: 'repFiled',
                type: 'search',
                // if safari doesn't honor this, we might have to try hacky solutions such as
                // https://stackoverflow.com/questions/43058018/how-to-disable-autocomplete-in-address-fields-for-safari
                autoComplete: 'new-password',
                form: {
                  autocomplete: 'off'
                },
                endAdornment: (
                  <>
                    <InputAdornment position="end" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                      <ArrowDropDownIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                )
              }}
              required
            />
          )}
        />
      )}

      {
        // Removed until LATAM update
        // <TextField
        //   fullWidth
        //   select
        //   margin='normal'
        //   className={classes.textfield}
        //   variant='outlined'
        //   SelectProps={{native: true}}
        //   value={state[index]?.country}
        //   onChange={e=>setStateValue('country',e.target.value)}
        //   label='Country'
        //   error={error[index]?.country !== undefined && error[index]?.country !== ''}
        //   helperText={error[index]?.country}
        //   required
        // >
        //   {
        //     countryList.map((country, idx)=><option key={idx} value={country.code}>{country.name}</option>)
        //   }
        // </TextField>
      }

      <TextField
        id="category"
        select
        fullWidth
        margin="normal"
        className={classes.textfield}
        SelectProps={{ native: true }}
        variant="outlined"
        disabled={index !== 0}
        value={state[index]?.category}
        label={labels.category}
        error={error[index]?.category !== undefined && error[index]?.category !== ''}
        helperText={error[index]?.category}
        onChange={(e) => {
          setStateValue(
            'categoryChanged',
            state[0].category && state[0].category !== e.target.value
          );
          setStateValue('category', e.target.value);
        }}
        required
      >
        <option></option>

        {props.products
          .sort(function (a, b) {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((product) => (
            <option key={product} value={product}>
              {product}
            </option>
          ))}
      </TextField>
      {state.map((newCase, idx) => {
        return (
          <div key={idx}>
            {!!idx && (
              <div>
                <span style={{ height: 31, marginTop: -16, marginRight: 8, float: 'left' }}>
                  <Typography variant="h5" style={{ float: 'left' }}>
                    Case {idx + 1}
                  </Typography>
                  <DeleteIcon
                    onClick={() => {
                      state.splice(idx, 1);
                      setState([...state]);
                    }}
                    style={{
                      cursor: 'pointer',
                      marginTop: 3,
                      marginLeft: 4
                    }}
                    fontSize="small"
                    color="error"
                  />
                </span>
                <span>
                  <Divider style={{ marginTop: 32, marginBottom: 24 }} />
                </span>
              </div>
            )}
            <TextField
              id="description"
              fullWidth
              multiline
              rows="6"
              margin="normal"
              className={classes.textfield}
              variant="outlined"
              value={newCase.caseDescription}
              onChange={(e) => setStateValue('caseDescription', e.target.value, idx)}
              label="Clinical case description"
              placeholder={labels.caseDescriptionExample}
              helperText={getCaseHelperHelperText(partnerName)}
              error={
                error[idx]?.caseDescription !== undefined && error[idx]?.caseDescription !== ''
              }
              required
            />

            <TextField
              id="question"
              fullWidth
              multiline
              rows="6"
              margin="normal"
              variant="outlined"
              value={state[idx]?.patientQuestion}
              onChange={(e) => setStateValue('patientQuestion', e.target.value, idx)}
              label="Clinical question specific to this case"
              placeholder={labels.patientQuestionExample}
              error={
                error[idx]?.patientQuestion !== undefined && error[idx]?.patientQuestion !== ''
              }
              helperText={error[idx]?.patientQuestion}
              required
            />

            <div
              onClick={async () => {
                if (!metadata) {
                  let metadata = await loadMetadata();

                  if (!metadata) {
                    displayNotification({
                      type: 'error',
                      message: 'Unable to upload records, try momentarily.'
                    });
                    return;
                  }
                }

                pickFile(
                  { multiple: true, type: [...supportedVideoTypes, ...supportedFileTypes] },
                  (files) => {
                    if (!files.length) {
                      files = [files];
                    }

                    const FILES = state[idx].formData ? state[idx].formData[0].files : [];
                    const S3FILES = state[idx].formData ? state[idx].formData[1].files : [];

                    const errors = [];

                    for (let file of files) {
                      if (
                        supportedVideoTypes.includes(file.type) &&
                        S3FILES.every((f) => f.name !== file.name)
                      ) {
                        const { size, files } = metadata.video_limits;

                        if (file.size <= size) {
                          if (S3FILES.length < files) {
                            S3FILES.push(file);
                          } else {
                            errors.push(
                              `File ${file.name} exceeds our video file limit cap of ${files} file${
                                files > 1 ? 's' : ''
                              }.`
                            );
                          }
                        } else {
                          errors.push(
                            `File ${file.name} exceeds our file size limit of ${
                              size / (1024 * 1024 * 1024)
                            } GiB`
                          );
                        }
                      }

                      if (
                        supportedFileTypes.includes(file.type) &&
                        FILES.every((f) => f.name !== file.name)
                      ) {
                        FILES.push(file);
                      }
                    }

                    if (errors.length) {
                      displayNotification({
                        type: 'error',
                        duration: Math.max(6000, 4000 * errors.length),
                        message: errors
                      });
                    }

                    if (S3FILES.length) {
                      handleS3Files(S3FILES, idx);
                    }

                    if ((FILES.length || S3FILES.length) && medicalRecordForm) {
                      const FORMDATA = [];

                      medicalRecordForm.categories.forEach((category) => {
                        let data = { field: category.fields[0] };

                        if (data.field.name === 'patientFiles') {
                          data.files = FILES;
                        } else if (data.field.name === 'videoFiles') {
                          data.files = S3FILES;
                        }

                        if (data.files) {
                          data.value = data.files.map((file) => file.name);
                        }

                        FORMDATA.push(data);
                      });

                      handleFormData(FORMDATA, idx);
                    }
                  }
                );

                setIgnore(false);
              }}
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <IconButton style={{ padding: 0, marginRight: 8 }}>
                <AttachFileIcon />
              </IconButton>
              <Typography
                color="primary"
                variant="subtitle1"
                style={{ fontWeight: 500, textTransform: 'uppercase', margin: '8px 0' }}
              >
                Attach Records
              </Typography>
            </div>

            <FileManager
              state={state}
              setState={setState}
              ignore={ignore}
              setIgnore={setIgnore}
              index={idx}
              mrRemoveFile={mrRemoveFile}
              isMobile={isMobileMemo}
            />

            <Typography variant="body1" color="textSecondary">
              {!!(
                state[idx]?.formData[0]?.files?.length || state[idx]?.formData[1]?.files?.length
              ) &&
                `I hereby accept that uploaded records and case details may be shared with
Expert Opinion expert faculty and that I have obtained the requisite patient
consent and/or I have the legal authorization under the applicable laws of my
country of residence to disclose intormation for the protection of the data subject's physical safety with other 
healthcare professionals.`}
            </Typography>
          </div>
        );
      })}

      <Box mt={2} style={{ marginTop: '8px' }}>
        <Button
          id="cd_addc"
          style={{ marginTop: 8, marginRight: 8 }}
          color="primary"
          variant="outlined"
          disabled={isDisabled}
          onClick={addCase}
        >
          {isDisabled ? 'Max number of cases reached' : 'I have another case to discuss'}
        </Button>
        <Button
          id="cd_next"
          style={{ marginTop: 8 }}
          color="primary"
          variant="contained"
          onClick={handleNext}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  displayNotification: (notification) => dispatch(displayNotification(notification))
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseDetails);
