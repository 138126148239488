import React, { useEffect, useMemo, useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import PersonIcon from '@material-ui/icons/Person';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { pickFile } from '../../utils/pickFile';
import { guessCountry } from '../../utils/guessCountry';
import autoCorrectImage from '../../utils/autoCorrectImage';
import PhoneInput, { isValidPhoneNumber, getCountries } from '../shared/PhoneInput';
import moment from 'moment-timezone';
import TimezoneList from '../shared/timezoneList';

const uploadAvatar = (image, setImage, setAvatar, setErrorFields, errorFields) => {
  pickFile({ type: 'image/*' }, async (file) => {
    if (image) {
      URL.revokeObjectURL(image);
    }

    let processedImage = await autoCorrectImage(file);

    setImage(URL.createObjectURL(processedImage));
    setAvatar(processedImage);

    setErrorFields({ ...errorFields, avatar: false });
  });
};

const FormSection = ({ children }) => (
  <Grid item sm={12} md={8} style={{ marginDown: '30px' }}>
    <Paper style={{ padding: '40px 50px' }}>
      <Grid container direction="column" spacing={3} style={{ marginBottom: '20px' }}>
        {children}
      </Grid>
    </Paper>
  </Grid>
);

const FormField = ({
  autoFocus,
  minRows,
  placeholder,
  label,
  value,
  onChange,
  type,
  error,
  helperText,
  multiline
}) => (
  <Grid item xs={12}>
    <TextField
      error={error}
      placeholder={placeholder}
      rows={minRows || 1}
      helperText={helperText}
      autoFocus={autoFocus}
      InputLabelProps={{ shrink: true }}
      label={label}
      fullWidth
      value={value}
      onChange={onChange}
      type={type || 'text'}
      multiline={multiline}
    />
  </Grid>
);

const Profile = ({
  classes,
  avatar,
  setAvatar,
  image,
  setImage,
  specialty,
  setSpecialty,
  specialties,
  title,
  setTitle,
  phone,
  setPhone,
  phoneCountry,
  setPhoneCountry,
  bio,
  setBio,
  bioLink,
  setBioLink,
  timezone,
  setTimezone,
  handleBack,
  handleNext
}) => {
  const [errorFields, setErrorFields] = useState({});

  const countries = useMemo(() => getCountries(), []);

  useEffect(() => {
    setPhoneCountry(guessCountry(countries));
  }, [countries, setPhoneCountry]);

  const handleTimezoneChange = ({ code, value }) => {
    setErrorFields({ ...errorFields, timezone: false });
    setTimezone({ code, value });
  };

  //
  // @dev - errorFields will be set numerically, falsy values (primarily undefined/false) will be used to indicate no errors.
  //        Values >=1 will be used as the element key for error message.
  //
  let ERROR_MESSAGE = {
    avatar: ['Profile headshot is required'],
    title: ['Suffix field is empty'],
    bio: ['Bio field is empty'],
    bioLink: ['Bio field is empty'],
    phone: ['Phone field is empty', 'Phone is invalid'],
    specialty: ['You must select a specialty'],
    timezone: ['Timezone field is empty', 'Timezone is invalid', 'GMT Offset is empty']
  };

  // Make index base 1
  ERROR_MESSAGE = new Proxy(ERROR_MESSAGE, {
    get(target, prop) {
      return [undefined, ...target[prop]];
    }
  });

  const validate = () => {
    let errors = {};

    if (!avatar) {
      errors.avatar = 1;
    }

    if (!title) {
      errors.title = 1;
    }

    if (!bio) {
      errors.bio = 1;
    }

    if (!bioLink) {
      errors.bioLink = 1;
    }

    if (!phone) {
      errors.phone = 1;
    } else if (!isValidPhoneNumber(phone)) {
      errors.phone = 2;
    }

    if (!specialty) {
      errors.specialty = 1;
    }

    if (!timezone?.code) {
      errors.timezone = 1;
    } else if (timezone?.code != 'other' && moment.tz.zone(timezone?.code) === null) {
      errors.timezone = 2;
    } else if (timezone?.code == 'other' && !timezone?.value) {
      errors.timezone = 3;
    }

    if (Object.keys(errors).length) {
      setErrorFields(errors);
      return;
    }

    handleNext();
  };

  return (
    <div className={classes.content}>
      <FormSection>
        <Grid container align="center" justifyContent="center" alignItems="flex-start" spacing={3}>
          <Grid item>
            <Avatar variant="square" src={image} style={{ width: '130px', height: '130px' }}>
              <PersonIcon style={{ fontSize: '2.5em' }} />
            </Avatar>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  style={{ marginTop: '5px' }}
                  color={errorFields.avatar ? 'error' : undefined}
                  variant="subtitle1"
                >
                  Profile Image
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={() =>
                    uploadAvatar(image, setImage, setAvatar, setErrorFields, errorFields)
                  }
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '10px' }}
                >
                  Upload
                </Button>
                {errorFields.avatar && (
                  <Typography variant="body2" color="error" style={{ marginTop: '16px' }}>
                    {ERROR_MESSAGE.avatar[errorFields.avatar]}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ margin: '25px 0' }} />
        <FormField
          placeholder="MD, OD, DO"
          label="Suffix"
          error={!!errorFields.title}
          helperText={errorFields.title ? ERROR_MESSAGE.title[errorFields.title] : ''}
          autoFocus={true}
          value={title}
          onChange={(evt) => {
            setErrorFields({ ...errorFields, title: false });
            setTitle(evt.target.value);
          }}
        />
        <FormField
          placeholder="https://www.vancethompsonvision.com/doctors/john-berdahl"
          label="Link to bio"
          error={!!errorFields.bioLink}
          helperText={errorFields.bioLink ? ERROR_MESSAGE.bioLink[errorFields.bioLink] : ''}
          autoFocus={false}
          value={bioLink}
          onChange={(evt) => {
            setErrorFields({ ...errorFields, bioLink: false });
            setBioLink(evt.target.value);
          }}
        />
        <FormControl
          fullWidth={true}
          style={{ padding: '0 12px', marginTop: '10px', marginBottom: '15px' }}
        >
          <PhoneInput
            onChange={(val) => {
              setPhone(val);
              setErrorFields({ ...errorFields, phone: '' });
            }}
            country={phoneCountry}
            value={phone}
            label={'Mobile Phone'}
            setCountry={setPhoneCountry}
            countries={countries}
            error={errorFields.phone !== undefined && errorFields.phone !== ''}
            helperText={errorFields.phone ? ERROR_MESSAGE.phone[errorFields.phone] : ''}
            required
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          style={{ padding: '0 12px', marginTop: '10px', marginBottom: '-10px' }}
          error={!!errorFields.timezone}
        >
          <InputLabel style={{ padding: '0 12px' }} shrink htmlFor={'timezone'}>
            Timezone{' '}
            <span className={classes.formHelpText}>{errorFields.timezone && '(Required)'}</span>
          </InputLabel>
          <TimezoneList
            selectedCode={timezone?.code || timezone || ''}
            selectedGMTValue={timezone?.value || ''}
            handleChange={handleTimezoneChange}
          />
          <FormHelperText>
            {errorFields.timezone ? ERROR_MESSAGE.timezone[errorFields.timezone] : ''}
          </FormHelperText>
        </FormControl>
        <FormControl
          fullWidth={true}
          style={{ padding: '0 12px', marginTop: '10px' }}
          error={!!errorFields.specialty}
        >
          <InputLabel style={{ padding: '0 12px' }} shrink htmlFor={'specialty'}>
            Specialty{' '}
            <span className={classes.formHelpText}>{errorFields.specialty && '(Required)'}</span>
          </InputLabel>
          <Select
            native
            value={specialty}
            onChange={(evt) => {
              setErrorFields({ ...errorFields, specialty: false });
              setSpecialty(evt.target.value);
            }}
            style={{ marginBottom: '20px' }}
            inputProps={{ name: 'specialty', id: 'specialty' }}
          >
            <option value="" disabled={true} />
            {specialties.map((specialty) => (
              <option key={specialty} value={specialty}>
                {specialty}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormField
          label="Bio"
          error={!!errorFields.bio}
          minRows={5}
          helperText={errorFields.bio ? ERROR_MESSAGE.bio[errorFields.bio] : ''}
          value={bio}
          onChange={(evt) => {
            setErrorFields({ ...errorFields, bio: false });
            setBio(evt.target.value);
          }}
          multiline
        />
      </FormSection>
      <div className={classes.actionsContainer}>
        <div>
          <Button variant="outlined" onClick={handleBack} className={classes.button}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={validate}
            className={classes.button}
            // disabled={!(specialty)}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
