import React from 'react';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import { Link } from '../../partials/Link';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import DocumentTitle from '../../partials/DocumentTitle';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FFF'
  },
  image: {
    width: '100%',
    height: '236px',
    objectFit: 'cover'
  },
  name: {
    fontWeight: 'bolder'
  },
  accordionRoot: {
    border: 'none',
    boxShadow: 'none'
  },
  details: {
    flexDirection: 'column',
    backgroundColor: '#f9f9f9'
  }
}));

const ProfileSection = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Accordion square={true} className={classes.accordionRoot}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography color="primary" variant="subtitle2">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>{children}</AccordionDetails>
    </Accordion>
  );
};

const WebProfile = ({ doctor, products }) => {
  const classes = useStyles();

  if (!doctor || !products) {
    return null;
  }

  const productsForDoctor = doctor.products.map((i) => products[i]).filter((i) => i !== undefined);

  const productCategories = [...new Set(productsForDoctor.map((p) => p.category))].sort().reverse();

  //Append the word 'and' to the category that will be in the last position for the string join
  if (productCategories.length > 1) {
    productCategories[0] = 'and ' + productCategories[0];
  }
  return (
    <div style={{ padding: '20px' }}>
      <DocumentTitle title={`${doctor.profile.name}, ${doctor.profile.title}`} />

      <Grid container spacing={3} justify="center">
        <Grid item sm={12}>
          <Typography
            gutterBottom
            align="center"
            color="primary"
            className={classes.title}
            variant="h2"
          >
            {doctor.profile.name}, {doctor.profile.title}
          </Typography>
          <Divider style={{ margin: '10px 0px' }} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '24px 0px'
            }}
          >
            <img
              style={{ maxWidth: '320px', minWidth: '200px' }}
              src={doctor.profile.avatar}
              alt={`${doctor.profile.name}, ${doctor.profile.title}`}
            />
          </div>
          <div>
            {doctor.profile.bio && (
              <React.Fragment>
                <Typography variant="h3" align="center" gutterBottom>
                  About {doctor.profile.name}
                </Typography>
                <Typography variant="h5">
                  <div style={{ whiteSpace: 'pre-wrap', padding: '0px 16px' }}>
                    {doctor.profile.bio}
                  </div>
                </Typography>
              </React.Fragment>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const MobileProfile = ({ doctor, products, match }) => {
  const classes = useStyles();
  const specializationText = React.useMemo(() => {
    if (!doctor || !products) {
      return '';
    }

    const categories = new Set();

    doctor.products.forEach((product) => {
      if (products[product] && products[product].category) {
        categories.add(products[product].category);
      }
    });

    return Array.from(categories)
      .reverse()
      .map((elm, idx) => {
        if (categories.length === 1) {
          return elm.replace(/^\w/, (c) => c.toUpperCase());
        }

        if (idx === 0) {
          return `and ${elm.replace(/^\w/, (c) => c.toUpperCase())}`;
        }

        return elm.replace(/^\w/, (c) => c.toUpperCase());
      })
      .reverse()
      .join(', ');
  }, [doctor, products]);

  if (!doctor) {
    return null;
  }

  const { profile } = doctor;

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      <img className={classes.image} src={profile.avatar} />
      <Box mt={2}>
        <Typography variant="h5" align="center" className={classes.name}>
          {profile.name}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography align="center" variant="subtitle1">
          Specializes in {specializationText} opinions.
        </Typography>
      </Box>
      <ProfileSection title="About, Certifications & Education">
        <Typography variant="h6">About</Typography>
        <div dangerouslySetInnerHTML={{ __html: profile.bio }}></div>
      </ProfileSection>
      <ProfileSection title="I'm a patient wanting a Second Opinion">
        <Typography variant="subtitle1">
          Many initial diagnoses and treatment plans are altered or refined upon receiving a second
          medical opinion. Our experts can provide an opinion on your diagnosis and treatment
          options. Their goal is to help guide and inform your next best steps, whatever they may
          be.
        </Typography>
        <Box my={2}>
          <Link base={'site'} path={`${match.url}/purchase/`}>
            <Button fullWidth variant="contained" color="primary">
              Get a Second Opinion
            </Button>
          </Link>
        </Box>
      </ProfileSection>
      {
        // <ProfileSection title="I'm doctor seeking product advice"> </ProfileSection>
      }
    </Box>
  );
};

const Profile = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <React.Fragment>
      {matches !== 'FOOBAR' ? <WebProfile {...props} /> : <MobileProfile {...props} />}
    </React.Fragment>
  );
};

export default withRouter(Profile);
