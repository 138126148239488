import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import DocumentTitle from '../../partials/DocumentTitle';
import LoadingIndicator from '../shared/LoadingIndicator';
import { _fetchUrl } from '../../utils/api';

import MedicalRecordFileComponent from './MedicalRecordFileComponent';
import VideoFileComponent from './VideoFileComponent';

import styles from './_styles';

class Opinion extends Component {
  state = {
    loading: true,
    opinion: {},
    errors: {},
    fetchError: null
  };

  componentDidMount() {
    this.getOpinion();
  }

  getOpinion = () => {
    const states = {
      loading: { loading: true, fetchError: null },
      complete: { loading: false, fetchError: null },
      error: { loading: false, fetchError: true }
    };
    this.setState(states.loading, async () => {
      try {
        const response = await _fetchUrl({
          method: 'GET',
          path: `poe/opinion/${this.props.match.params.opinionId}`,
          token: this.props.user.token
        });

        if (response.status === 'ok') {
          return this.setState((p) => ({
            ...p,
            ...states.complete,
            opinion: response.opinion,
            errors: {}
          }));
        } else {
          return this.setState(states.error);
        }
      } catch (error) {
        return this.setState(states.error);
      }
    });
  };

  afterMedicalRecordUpload = (updatedMedicalRecord) => {
    this.setState((previousState) => {
      const opinion = { ...previousState.opinion };
      opinion.medicalRecords = opinion.medicalRecords.map((medicalRecord) => {
        if (medicalRecord.id === updatedMedicalRecord.id) {
          medicalRecord.data.medicalRecords = updatedMedicalRecord.data.medicalRecords;
        }
        return medicalRecord;
      });
      return { opinion };
    });
  };

  handleMedicalRecordChange = (key, index) => (event) => {
    const { type, value } = event.target;

    this.setState((previousState) => {
      const opinion = { ...previousState.opinion };

      opinion.medicalRecords[index].provider[key] =
        type === 'date' ? moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY') : value;

      const errors = { ...previousState.errors };
      errors.medicalRecords[index].provider[key] = false;

      return { opinion, errors };
    });
  };

  uploadVideoFiles = async (medicalRecordId, files) => {
    // xx1
    files = Object.values(files);

    try {
      const signedUrlRes = await _fetchUrl({
        method: 'POST',
        token: this.props.user.token,
        path: 'poe/medicalRecord/signed-url',
        headers: { 'Content-Type': 'application/json' },
        body: {
          medicalRecordId: medicalRecordId,
          files: Object.values(files).map((file, idx) => ({
            index: idx,
            name: file.name,
            size: file.size,
            type: file.type
          }))
        }
      });

      // Handle video uploads directly
      for (let idx in signedUrlRes.SIGNED_URLS) {
        if (files[idx] && signedUrlRes.SIGNED_URLS[idx]) {
          // Set cors policy
          // https://s3.console.aws.amazon.com/s3/buckets/dev.userdata.expertopinion.md?region=us-west-2&tab=objects
          let form = new FormData();

          Object.entries(signedUrlRes.SIGNED_URLS[idx].fields).forEach(([k, v]) => {
            form.append(k, v);
          });

          form.append('file', files[idx]);

          try {
            // Figure out why fetch shows failed error but succeeds (201 empty content? is this cors related?)
            let result = await window.fetch(signedUrlRes.SIGNED_URLS[idx].url, {
              method: 'POST',
              body: form
            });

            console.log('result', result);
          } catch (err) {
            console.log('err', err);
          }
        } else console.error('Unable to get signed url for', idx);
      }

      return files;
    } catch (e) {
      console.log('error posting video', e);
      return false;
    }
  };

  afterVideoUpload = (response, idx) => {
    if (response.length) {
      this.setState((previousState) => {
        if (previousState.opinion.medicalRecords[idx].data.videoRecords) {
          previousState.opinion.medicalRecords[idx].data.videoRecords = [
            ...previousState.opinion.medicalRecords[idx].data.videoRecords,
            ...response.map((file) => ({ name: file.name, size: file.size, type: file.type }))
          ];
        } else {
          previousState.opinion.medicalRecords[idx].data.videoRecords = [
            ...response.map((file) => ({ name: file.name, size: file.size, type: file.type }))
          ];
        }

        const opinion = {
          ...previousState.opinion,
          medicalRecords: [...previousState.opinion.medicalRecords]
        };

        return { opinion };
      });
    }
  };

  render() {
    const { classes, user } = this.props;
    const { fetchError, opinion, loading } = this.state;

    if (loading) {
      return <LoadingIndicator message="Loading Opinion..." />;
    }

    if (fetchError) {
      return <h1>Error</h1>;
    }

    return (
      <Fragment>
        <DocumentTitle title={`Admin: Opinion: ${opinion.id}`} />

        <Fragment>
          {opinion.medicalRecords.map((medicalRecord, mrIndex) => {
            return (
              <div key={mrIndex}>
                <div>
                  <Typography style={{ flex: 1 }}>Medical Record ID: {medicalRecord.id}</Typography>
                </div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Paper className={classes.paper}>
                        <Typography variant="subtitle1">Medical Record Image Files</Typography>
                        <Grid container spacing={1}>
                          {medicalRecord.data &&
                          medicalRecord.data.medicalRecords &&
                          medicalRecord.data.medicalRecords.length ? (
                            medicalRecord.data.medicalRecords.map((mr, key) => (
                              <Grid item sm={4} key={key}>
                                <MedicalRecordFileComponent
                                  key={key}
                                  file={mr}
                                  medicalRecordId={medicalRecord.id}
                                  download={true}
                                  classes={classes}
                                  token={user.token}
                                />
                              </Grid>
                            ))
                          ) : (
                            <Grid item xs={12}>
                              <Typography variant="body1" className={classes.body1}>
                                Medical Records not available at this time
                              </Typography>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <MedicalRecordFileComponent
                              medicalRecordId={medicalRecord.id}
                              classes={classes}
                              disabled={!Object.prototype.hasOwnProperty.call(medicalRecord, 'id')}
                              token={user.token}
                              path={`poe/medicalRecord/${medicalRecord.id}/upload`}
                              postUpload={this.afterMedicalRecordUpload}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                      <Paper className={classes.paper}>
                        <Typography variant="subtitle1">Medical Record Video Files</Typography>
                        <Grid container spacing={1}>
                          {medicalRecord.data &&
                          medicalRecord.data.videoRecords &&
                          medicalRecord.data.videoRecords.length ? (
                            medicalRecord.data.videoRecords.map((mr, key) => (
                              <Grid item sm={4} style={{ flexBasis: 'auto' }} key={key}>
                                {mr?.type !== 'video/mp4' ? (
                                  <Grid style={{ wordWrap: 'break-word' }} item xs={12}>
                                    {mr.name} - PROCESSING
                                  </Grid>
                                ) : (
                                  <VideoFileComponent
                                    path={`poe/medicalRecord/${medicalRecord.id}/video`}
                                    file={mr}
                                    classes={classes}
                                    token={user.token}
                                    uploadFile={async (files) =>
                                      await this.uploadVideoFiles(medicalRecord.id, files)
                                    }
                                    postUpload={(response) => this.afterVideoUpload(response, key)}
                                  />
                                )}
                              </Grid>
                            ))
                          ) : (
                            <Grid item xs={12}>
                              <Typography variant="body1" className={classes.body1}>
                                Medical Records not available at this time
                              </Typography>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <VideoFileComponent
                              classes={classes}
                              token={user.token}
                              uploadFile={async (files) =>
                                await this.uploadVideoFiles(medicalRecord.id, files)
                              }
                              postUpload={(response) => this.afterVideoUpload(response, 0)}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              </div>
            );
          })}
        </Fragment>
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Opinion));
