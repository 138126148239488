import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import EODialog from '../partials/EODialog';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

// Theme provider does not seem to be reaching EODialog / Patch for now
const appTheme = createTheme({
  typography: {
    fontWeightRegular: 300
  },
  palette: {
    background: {
      default: '#ffffff',
      primary: '#f4f4f4'
    },
    primary: {
      light: '#67daff',
      main: '#03a9f4',
      dark: '#007ac1',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#ffffff',
      main: '#f9f9f9',
      dark: '#c6c6c6',
      contrastText: '#03a9f4'
    }
  }
});

const Confirm = (txt, showCancel = true) => {
  return new Promise((res) => {
    const container = document.createElement('div');
    document.body.appendChild(container);

    let handleCloseLogic = () => {
      unmountComponentAtNode(container);
      document.body.removeChild(container);
      res(false);
    };

    let handleOkayLogic = () => {
      unmountComponentAtNode(container);
      document.body.removeChild(container);
      res(true);
    };

    const dialog = (
      <ThemeProvider theme={appTheme}>
        <EODialog
          open={true}
          handleClose={handleCloseLogic}
          handleOkay={handleOkayLogic}
          showCancel={showCancel}
        >
          {txt}
        </EODialog>
      </ThemeProvider>
    );

    render(dialog, container);
  });
};

export default Confirm;
