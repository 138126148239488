/*	Check that email has @ and . and that they are not at the beginning or end or next to eachother	*/
import moment from 'moment';

export const isEmailInvalid = (email) =>
  !email.length ||
  email.indexOf('@') === -1 ||
  email.indexOf('.') === -1 ||
  email.indexOf('@.') !== -1 ||
  email.indexOf('.@') !== -1 ||
  email.indexOf('@@') !== -1 ||
  email.indexOf('..') !== -1 ||
  email.charAt(0) === '@' ||
  email.charAt(0) === '.' ||
  email.charAt(email.length - 1) === '@' ||
  email.charAt(email.length - 1) === '.';

/*	Check that phone is 10 or 11 digits after removing all non-digits	*/
export const isPhoneInvalid = (phone) => !phone || phone.replace(/\D/g, '').length < 10;

/*	Check that date is valid MM-DD-YYYY format.	*/
export const isDateInvalid = (date) => {
  // M-D-YYYY (purchase form), 'MM/DD/YYYY' (admin form provider info)
  return moment(date, ['M-D-YYYY', 'MM/DD/YYYY'], true).isValid() !== true;
};

export const isUrlInvalid = (url) => {
  try {
    new URL(url);
  } catch (_) {
    return true;
  }

  return false;
};
