import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { _fetchUrl } from '../../../utils/api';

import DocumentTitle from '../../../partials/DocumentTitle';
import { Link } from '../../../partials/Link';

import DoctorAvatarFileComponent from '../../shared/DoctorAvatarFileComponent';
import VideoFileComponent from '../VideoFileComponent';
import DoctorBio from '../../shared/DoctorBio';
import DoctorBioLink from '../../shared/DoctorBioLink';
import DoctorEmail from '../../shared/DoctorEmail';

import DoctorPhone from '../../shared/DoctorPhone';
import DoctorTimezone from '../../shared/DoctorTimezone';
import DoctorHandle from '../../shared/DoctorHandle';
import DoctorProductSelection from '../../shared/DoctorProductSelection';
import DoctorSpecialty from '../../shared/DoctorSpecialty';
import DoctorStatus from '../../shared/DoctorStatus';

import LoadingIndicator from '../../shared/LoadingIndicator';

import { calculatePatientPrice } from 'eo_utils';

import styles from '../_styles';

class Doctor extends Component {
  state = {
    loading: true,
    doctor: null,

    allProducts: null,
    selectedProductPrices: null,

    defaultPrice: null,
    defaultPatientPrice: null,

    productSelectionSubmitted: false,
    productSelectionResult: false,
    productSelectionApiError: null,
    profileSubmitted: false,
    profileResult: false,
    profileApiError: null
  };

  componentDidMount = () => {
    const states = {
      loading: { loading: true, doctor: null, allProducts: null },
      complete: { loading: false, doctor: null, allProducts: null }
    };

    this.setState(states.loading, async () => {
      try {
        const response = await _fetchUrl({
          method: 'GET',
          path: `poe/doctor/${this.props.match.params.doctorId}`,
          token: this.props.user.token
        });
        if (response.status === 'ok') {
          return this.setState(
            response.doctor.products.reduce(
              (accumulator, current) => {
                accumulator.selectedProductPrices[current] = response.doctor.price[current];
                return accumulator;
              },
              {
                ...states.complete,
                doctor: response.doctor,
                allProducts: response.products,
                selectedProductPrices: {},
                defaultPrice: response.doctor.price.default,
                defaultPatientPrice: calculatePatientPrice(response.doctor.price.default || 0)
              }
            )
          );
        }
        return this.setState({ ...states.complete });
      } catch (error) {
        return this.setState({ ...states.complete });
      }
    });
  };

  handleDefaultPriceChange = (event) => {
    this.setState({
      defaultPrice: event.target.value,
      defaultPatientPrice: calculatePatientPrice(event.target.value)
    });
  };
  handleProductSubmit = (event) => {
    event.preventDefault();

    const { allProducts, defaultPrice } = this.state;
    const prices = { default: defaultPrice };

    Object.keys(allProducts).forEach((category) => {
      allProducts[category].forEach((product) => {
        const checkedElement = document.getElementById(product.id);
        if (checkedElement && checkedElement.checked) {
          const priceElement = document.getElementById(`${product.id}-price`);
          prices[product.id] = priceElement ? parseInt(priceElement.value || 0, 10) : 0;
        }
      });
    });

    const states = {
      submitting: {
        productSelectionSubmitted: true,
        productSelectionResult: false,
        productSelectionApiError: null
      },
      complete: {
        productSelectionSubmitted: false,
        productSelectionResult: false,
        productSelectionApiError: null
      }
    };
    this.setState(states.submitting, async () => {
      try {
        const response = await _fetchUrl({
          method: 'PUT',
          path: `poe/doctor/${this.state.doctor.id}/prices`,
          body: { prices },
          token: this.props.user.token
        });
        if (response.status === 'ok') {
          return this.setState({ ...states.complete, productSelectionResult: true });
        }
        return this.setState({
          ...states.complete,
          productSelectionApiError: response.message || 'Failed to save products/prices.'
        });
      } catch (error) {
        return this.setState({ ...states.complete, productSelectionApiError: error });
      }
    });
  };

  handleUpdateDoctorStatus = (event) => {
    const status = event.target.value;
    this.setState((previousState) => ({
      doctor: { ...previousState.doctor, status }
    }));
  };

  handleUpdateDoctorPhone = (phone) =>
    this.setState((previousState) => ({
      doctor: { ...previousState.doctor, phone }
    }));

  handleUpdateDoctorTimezone = (timezone) =>
    this.setState((previousState) => ({
      doctor: { ...previousState.doctor, user: { ...previousState.doctor.user, timezone } }
    }));

  handleUpdateDoctorHandle = (handle) =>
    this.setState((previousState) => ({
      doctor: { ...previousState.doctor, handle }
    }));

  handleUpdateDoctorEmail = (email) =>
    this.setState((previousState) => ({
      doctor: { ...previousState.doctor, user: { ...previousState.doctor.user, email } }
    }));

  handleProfileUpdate = (key, value) =>
    this.setState((previousState) => ({
      doctor: {
        ...previousState.doctor,
        profile: { ...previousState.doctor.profile, [key]: value }
      }
    }));

  handleProfileSubmit = (event) => {
    event.preventDefault();

    const states = {
      submitting: { profileSubmitted: true, profileResult: false, profileApiError: null },
      complete: { profileSubmitted: false, profileResult: false, profileApiError: null }
    };
    this.setState(states.submitting, async () => {
      try {
        const body = {
          doctorData: {
            specialty: this.state.doctor.specialty,
            status: this.state.doctor.status,
            handle: this.state.doctor.handle,
            phone: this.state.doctor.phone,
            timezone:
              this.state.doctor.user.timezone.code == 'other' && this.state.doctor.user.value
                ? this.state.doctor.user.timezone
                : { code: 'America/Los_Angeles', value: '' },
            email: this.state.doctor.user.email,
            profile: this.state.doctor.profile
          }
        };
        const response = await _fetchUrl({
          method: 'PUT',
          path: `poe/doctor/${this.state.doctor.id}`,
          body: body,
          token: this.props.user.token
        });
        this.handleUpdateDoctorTimezone(body.doctorData.timezone);
        if (response.status === 'ok') {
          return this.setState({ ...states.complete, profileResult: true });
        }
        return this.setState({
          ...states.complete,
          profileApiError: response.message || 'Failed to save profile.'
        });
      } catch (error) {
        return this.setState({ ...states.complete, profileApiError: error });
      }
    });
  };

  postVideoAction = (response) => {
    this.setState((previousState) => {
      const doctor = {
        ...previousState.doctor,
        profile: { ...previousState.doctor.profile, video: response.doctor.profile.video }
      };
      return { doctor };
    });
  };
  postAvatarUpload = (avatar) => {
    this.setState((previousState) => {
      const doctor = {
        ...previousState.doctor,
        profile: { ...previousState.doctor.profile, avatar }
      };
      return { doctor };
    });
  };

  render() {
    const { classes, theme, user, products } = this.props;
    const {
      loading,
      doctor,
      allProducts,
      selectedProductPrices,
      defaultPrice,
      defaultPatientPrice,
      productSelectionSubmitted,
      productSelectionResult,
      productSelectionApiError,
      profileSubmitted,
      profileResult,
      profileApiError
    } = this.state;

    if (loading || !doctor || !allProducts) {
      return <LoadingIndicator message="Loading Doctor..." />;
    }

    const doctorVideos = ['Default'].concat(Object.keys(allProducts).sort()).map((category) => ({
      name: category,
      type: 'video/mp4',
      size: 0,
      url: doctor.profile.video[
        category.toLowerCase() === 'laser vision correction' ? 'lasik' : category.toLowerCase()
      ]
    }));
    const specialties = Object.keys(products).reduce((acc, key) => {
      const specialty =
        products[key].specialty.charAt(0).toUpperCase() + products[key].specialty.slice(1);
      if (!acc.includes(specialty)) {
        acc.push(specialty);
      }
      return acc;
    }, []);

    return (
      <Fragment>
        <DocumentTitle title={`Admin: Doctor: ${doctor.user.name}`} />
        <Link base={'site'} path={'/admin/doctors'}>
          <Typography variant="subtitle1">
            Doctor{' '}
            <Typography variant="body1">
              <span className={classes.formHelpText}>
                <ArrowBack className={classes.alignIconWithText} />
                Back to Doctors List
              </span>
            </Typography>
          </Typography>
        </Link>
        <Paper
          className={classes.paper}
          style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Handle</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Specialty</TableCell>
                <TableCell>Signed Agreement (Date)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{doctor.id}</TableCell>
                <TableCell>{doctor.handle}</TableCell>
                <TableCell>{doctor.user.email}</TableCell>
                <TableCell>{doctor.specialty}</TableCell>
                <TableCell>
                  {doctor.doctorAgreement &&
                    new Date(doctor.doctorAgreement.signedDate).toLocaleString()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Products</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <form onSubmit={this.handleProductSubmit}>
              <FormControl>
                <InputLabel shrink htmlFor={`default-price`}>
                  Default Price
                </InputLabel>
                <Input
                  id={`default-price`}
                  defaultValue={defaultPrice}
                  onChange={this.handleDefaultPriceChange}
                  type="number"
                  inputProps={{ step: 'any' }}
                />
                <FormHelperText>Patient Price: {defaultPatientPrice}</FormHelperText>
              </FormControl>
              <Grid container spacing={2}>
                {Object.keys(allProducts)
                  .sort()
                  .map((category) => (
                    <Grid item sm={4} key={category}>
                      <Paper className={classes.paper} style={{ height: '100%' }}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">{category}</FormLabel>
                          <FormGroup>
                            {allProducts[category].map((product) => (
                              <DoctorProductSelection
                                key={product.id}
                                productId={product.id}
                                productName={product.name}
                                productType={product.type}
                                enableConnect={true}
                                selected={doctor.products.includes(product.id)}
                                price={selectedProductPrices[product.id]}
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      </Paper>
                    </Grid>
                  ))}
              </Grid>
              <Button
                style={{ color: 'white', marginTop: theme.spacing(2) }}
                variant="contained"
                color="primary"
                type="submit"
                disabled={productSelectionSubmitted}
              >
                {productSelectionSubmitted ? (
                  <CircularProgress style={{ color: 'primary' }} />
                ) : (
                  <span>Save Product Settings</span>
                )}
              </Button>
              {productSelectionApiError && (
                <Typography variant="body1" className={classes.error}>
                  {productSelectionApiError}
                </Typography>
              )}
              {productSelectionResult && (
                <Typography variant="body1" className={classes.result}>
                  Products/Prices Updated!
                </Typography>
              )}
            </form>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Profile</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item sm={9}>
                <Grid container spacing={1}>
                  <Grid item sm={6}>
                    <Paper className={classes.paper}>
                      <DoctorSpecialty specialty={doctor.specialty} specialties={specialties} />
                      <DoctorStatus
                        handleUpdateDoctorStatus={this.handleUpdateDoctorStatus}
                        doctorStatus={doctor.status}
                      />
                    </Paper>
                  </Grid>
                  <Grid item sm={6}>
                    <Paper className={classes.paper}>
                      <DoctorEmail
                        handleUpdateDoctorEmail={this.handleUpdateDoctorEmail}
                        email={doctor.user.email}
                      />
                      <DoctorHandle
                        handleUpdateDoctorHandle={this.handleUpdateDoctorHandle}
                        handle={doctor.handle}
                      />
                      <DoctorPhone
                        handleUpdateDoctorPhone={this.handleUpdateDoctorPhone}
                        phone={doctor.phone || ''}
                      />
                      <DoctorTimezone
                        handleUpdateDoctorTimezone={this.handleUpdateDoctorTimezone}
                        timezone={doctor.user.timezone || ''}
                      />
                    </Paper>
                  </Grid>
                </Grid>
                {doctor.profile?.bio && (
                  <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
                    <DoctorBio
                      handleBioUpdate={this.handleProfileUpdate}
                      bio={doctor.profile.bio}
                    />
                  </Paper>
                )}
                {doctor.profile?.bioLink && (
                  <Paper className={classes.paper} style={{ marginTop: theme.spacing(2) }}>
                    <DoctorBioLink
                      handleUpdateDoctorBioLink={this.handleProfileUpdate}
                      link={doctor.profile.bioLink}
                    />
                  </Paper>
                )}
              </Grid>
              <Grid item sm={3}>
                <Paper className={classes.paper}>
                  <div style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>
                    <DoctorAvatarFileComponent
                      avatar={doctor.profile.avatar}
                      doctorName={`${doctor.profile.name}, ${doctor.profile.title}`}
                      postUpload={this.postAvatarUpload}
                      fileType={'doctorAvatar'}
                      method={'PUT'}
                      path={`poe/doctor/${doctor.id}/avatar`}
                      token={user.token}
                    />
                  </div>
                  <Typography variant="subtitle1">Doctor Videos</Typography>
                  {doctorVideos.map((video) => (
                    <Paper
                      key={video.name}
                      className={classes.paper}
                      style={{ marginTop: theme.spacing(1) }}
                    >
                      <Typography variant="subtitle1">{video.name}</Typography>
                      <VideoFileComponent
                        file={video}
                        classes={classes}
                        token={user.token}
                        path={`poe/doctor/${doctor.id}/video/${
                          video.name.toLowerCase() === 'laser vision correction'
                            ? 'lasik'
                            : video.name.toLowerCase()
                        }`}
                        postUpload={this.postVideoAction}
                      />
                    </Paper>
                  ))}
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Button
                  style={{ color: 'white', marginTop: theme.spacing(2) }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={this.handleProfileSubmit}
                  disabled={profileSubmitted}
                >
                  {profileSubmitted ? (
                    <CircularProgress style={{ color: 'primary' }} />
                  ) : (
                    <span>Save Profile</span>
                  )}
                </Button>
                {profileApiError && (
                  <Typography variant="body1" className={classes.error}>
                    {profileApiError}
                  </Typography>
                )}
                {profileResult && <Typography variant="body1">Profile Updated!</Typography>}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Doctor));
