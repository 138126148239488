import React, { useState, useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
  Box
} from '@material-ui/core';
import { getUTCOffsets, timezones } from '../../../utils/timezones';
import momentTimezone from 'moment-timezone';
import moment from 'moment';

const gmtValues = getUTCOffsets();
const tzList = timezones();

const TimePicker = ({ callDetails, newDetails, experts, updateDetails }) => {
  const setDate = (date) => {
    newDetails.date = date;
    updateDetails(newDetails);
  };

  const setHour = (hour) => {
    newDetails.time.hour = hour;
    updateDetails(newDetails);
  };

  const setMinute = (minute) => {
    newDetails.time.minute = minute;
    updateDetails(newDetails);
  };

  const setTimezone = (timezone) => {
    newDetails.timezone = timezone;
    updateDetails(newDetails);
  };

  const setExpert = (expert) => {
    newDetails.expert = expert;
    updateDetails(newDetails);
  };

  const parsedDatetime = useMemo(() => {
    return callDetails.timezone.code === 'other'
      ? moment(callDetails.date).utcOffset(callDetails.timezone.value)
      : momentTimezone.tz(callDetails.date, 'YYYY-MM-DD HH:mm A').tz(callDetails.timezone.code);
  }, [callDetails.timezone]);

  return (
    <div style={{ margin: '10px 0px' }}>
      <Box display="flex" flexDirection="column" style={{ gap: '16px' }}>
        <InputLabel>
          Date: {callDetails.date ? parsedDatetime.format('DD/MM/YYYY') : 'N/A'}
        </InputLabel>

        <TextField
          required
          InputLabelProps={{ shrink: true }}
          id="date"
          variant="outlined"
          value={newDetails.date}
          onChange={(e) => setDate(e.target.value)}
          type="date"
          placeholder="Ex: 2020-01-20"
        />

        <InputLabel>Time: {callDetails.date ? parsedDatetime.format('hh:mm A') : 'N/A'}</InputLabel>
        <div style={{ display: 'flex', gap: '8px', width: '100%' }}>
          <FormControl>
            <Select
              fullWidth
              variant="outlined"
              onChange={(e) => setHour(e.target.value)}
              value={newDetails.time.hour}
            >
              <option value=""></option>
              {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
                <MenuItem key={hour + 1} value={hour + 1}>
                  {hour + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <Select
              style={{ width: '80px' }}
              labelId="minute-label"
              variant="outlined"
              onChange={(e) => setMinute(e.target.value)}
              value={newDetails.time.minute}
            >
              <MenuItem></MenuItem>
              <MenuItem value="00">00</MenuItem>
              <MenuItem value="15">15</MenuItem>
              <MenuItem value="30">30</MenuItem>
              <MenuItem value="45">45</MenuItem>
            </Select>
          </FormControl>
        </div>
        <InputLabel>Timezone: {callDetails.timezone?.code || callDetails.timezone}</InputLabel>

        <Select
          label="Timezone"
          fullWidth
          variant="outlined"
          value={newDetails.timezone.code}
          onChange={(e) => setTimezone({ code: e.target.value, value: '' })}
        >
          {tzList.map((tz) => (
            <MenuItem key={tz.code} value={tz.code}>
              {tz.name} ({tz.code})
            </MenuItem>
          ))}
          <MenuItem key="other" value="other">
            Other
          </MenuItem>
        </Select>

        {newDetails.timezone.code === 'other' ? (
          <Select
            label="GMT Offset"
            fullWidth
            variant="outlined"
            value={newDetails.timezone.value}
            onChange={(e) => setTimezone({ value: e.target.value, code: 'other' })}
          >
            <MenuItem></MenuItem>
            {gmtValues.map((ele) => (
              <MenuItem key={ele.value} value={ele.value}>
                {ele.name}
              </MenuItem>
            ))}
          </Select>
        ) : null}

        <InputLabel>Expert: {callDetails.selectedExpert.name}</InputLabel>

        <Select
          fullWidth
          variant="outlined"
          label="Selected Expert"
          onChange={(e) => setExpert(e.target.value)}
        >
          <MenuItem></MenuItem>
          {experts.map((e) => (
            <MenuItem key={e.id} value={e.id}>
              {e.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </div>
  );
};

const CallDetails = (props) => {
  const [state, setState] = useState({
    open: false,
    isShortNotice: false,
    isReschedule: true
  });

  const [newDetails, setDetails] = useState({
    time: {
      hour: '',
      minute: ''
    },
    date: '',
    timezone: { code: '', value: '' },
    expert: ''
  });

  const onManualTimeSelected = (e) => {
    setDetails({ ...e });
  };

  const isValidReschedule = useMemo(() => {
    return (
      newDetails.time.hour &&
      newDetails.time.minute &&
      newDetails.date &&
      newDetails.expert &&
      (newDetails.timezone.code === 'other'
        ? newDetails.timezone.value !== ''
        : newDetails.timezone.code !== '')
    );
  }, [newDetails]);

  const submit = () => {
    const dateStr = `${newDetails.date} ${newDetails.time.hour}:${newDetails.time.minute}`;
    const time = (
      newDetails.timezone.code === 'other'
        ? moment(`${dateStr}`).utcOffset(newDetails.timezone.value)
        : momentTimezone.tz(dateStr, 'YYYY-MM-DD HH:mm', newDetails.timezone.code)
    ).valueOf();

    const selectedExpert = props.experts.find((e) => e.id === newDetails.expert);

    props.handleUpdate(
      time,
      {
        id: selectedExpert.id,
        name: selectedExpert.name,
        phone: selectedExpert.phone,
        email: selectedExpert.email,
        autopicked: false,
        request: Date.now()
      },
      state.isReschedule,
      state.isShortNotice,
      newDetails.timezone
    );
  };

  if (props.consult === null) {
    return null;
  }

  const details = props.consult.callDetails;

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        disableEscapeKeyDown={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Schedule Consult</DialogTitle>

        <DialogContent>
          <TimePicker
            experts={props.experts}
            callDetails={details}
            newDetails={newDetails}
            updateDetails={onManualTimeSelected}
          />

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.isShortNotice}
                  color="primary"
                  value={state.isShortNotice}
                  onChange={(e) => {
                    e.persist();
                    setState((p) => ({ ...p, isShortNotice: e.target.checked }));
                  }}
                  name="Reschedule"
                />
              }
              label="Customer Rescheduled Short Notice"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Box m={2} width="100%" display="flex" flexDirection="column" style={{ gap: '8px' }}>
            <Button
              fullWidth
              disabled={!isValidReschedule}
              onClick={submit}
              variant="contained"
              color="primary"
            >
              Update
            </Button>
            <Button fullWidth onClick={props.handleClose} variant="outlined" color="primary">
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CallDetails;
