import React, { useCallback } from 'react';
import { Grid, FormControl, TextField, MenuItem } from '@material-ui/core';
import { getCountryCallingCode } from 'react-phone-number-input/input';

const CountrySelect = (props) => {
  const { value, onChange, setInputFocus, setCountry, variant, ...rest } = props;

  const onChange_ = useCallback(
    (event) => {
      const value = event.target.value;
      onChange(value === 'ZZ' ? undefined : value);
      setCountry(value === 'ZZ' ? undefined : value);
      setInputFocus(true);
    },
    [onChange]
  );

  const { ...safeRest } = rest;

  // "ZZ" means "International".
  // (HTML requires each `<option/>` have some string `value`).
  return (
    <Grid item style={{ marginRight: '8px' }}>
      <FormControl style={{ width: '100%' }}>
        <TextField
          {...safeRest}
          select
          label="Code"
          variant={variant}
          value={value || 'ZZ'}
          SelectProps={{
            renderValue: (val) => `${'+' + getCountryCallingCode(val)}`
          }}
          onChange={onChange_}
        >
          {props.options.map(({ value, label, divider }) => (
            <MenuItem
              key={divider ? '|' : value || 'ZZ'}
              value={divider ? '|' : value || 'ZZ'}
              disabled={divider ? true : false}
              style={divider ? DIVIDER_STYLE : undefined}
            >
              {`${label} (+${getCountryCallingCode(value)})`}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </Grid>
  );
};

const DIVIDER_STYLE = {
  fontSize: '1px',
  backgroundColor: 'currentColor',
  color: 'inherit'
};

export function CountrySelectWithIcon({ value, options, arrowComponent: Arrow, ...rest }) {
  return (
    <div className="PhoneInputCountry">
      <CountrySelect {...rest} value={value} options={options} />
      <Arrow />
    </div>
  );
}

CountrySelectWithIcon.defaultProps = {
  arrowComponent: () => <div className="PhoneInputCountrySelectArrow" />
};

export default CountrySelect;
