import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { logout, displayNotification, loadAuth } from '../store/actions';

import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';

import logo from './logo.png';
import { Link } from './Link';
import { _fetchUrl } from '../utils/api';

const styles = (theme) => ({
  ctaButton: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main
    }
  },
  logo: { height: '26px', marginTop: theme.spacing(1) },
  getAnExpert: { marginLeft: theme.spacing(3), textAlign: 'left', flex: 1 },
  flex: { flex: 1 },
  fullWidth: { width: '100%' },
  textRight: { textAlign: 'right' }
});

const LogoImg = ({ classes }) => (
  <img src={logo} alt="Trusted advice when you need it most" className={classes.logo} />
);

const LoginBtn = ({ classes, mobile, logout, user }) => {
  const loginBtn = (
    <Link key={'/login'} base={'site'} path={'/login'} className={mobile ? classes.fullWidth : ''}>
      <Button
        color={mobile ? 'primary' : 'secondary'}
        variant={mobile ? 'text' : 'contained'}
        fullWidth={mobile}
      >
        Log in
      </Button>
    </Link>
  );
  const logoutBtn = (
    <Button onClick={logout} color="primary" variant="text" fullWidth={true}>
      Log out
    </Button>
  );

  return user ? logoutBtn : loginBtn;
};

const DeskTopNavBar = ({
  isLoginPage,
  classes,
  user,
  open,
  anchorEl,
  pathname,
  openMenu,
  closeMenu,
  logout,
  simple
}) => (
  <Fragment>
    {window._eo.isMobileApp ? (
      <LogoImg classes={classes} />
    ) : (
      <Link base={'site'} path={'/'}>
        <LogoImg classes={classes} />
      </Link>
    )}

    {!simple && (
      <span className={classes.getAnExpert} style={{ visibility: 'hidden' }}>
        <Link base={'site'} path={'/experts/ophthalmology'}>
          <Button color="secondary" variant="outlined" className={classes.ctaButton}>
            Get an Expert Opinion
          </Button>
        </Link>
      </span>
    )}
    {user && user.type ? (
      <Fragment>
        <Button
          onClick={openMenu}
          color="secondary"
          variant="outlined"
          className={classes.ctaButton}
          style={{ textAlign: 'right' }}
        >
          Welcome, {user.salutation}
          <Icon>expand_more</Icon>
        </Button>
        <Menu
          id="account-menu"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          onClose={closeMenu}
        >
          {user.type.includes('D2D_EXPERT') && pathname.indexOf('/expert') !== 0 && (
            <MenuItem onClick={closeMenu}>
              <Link base={'site'} path={'/expert'} className={classes.fullWidth}>
                <Button color="primary" variant="text" fullWidth={true}>
                  Consults
                </Button>
              </Link>
            </MenuItem>
          )}
          {user.type.includes('EO_ADMIN') && (
            <MenuItem onClick={closeMenu}>
              <Link base={'site'} path={'/admin'} className={classes.fullWidth}>
                <Button color="primary" variant="text" fullWidth={true}>
                  Admin Portal
                </Button>
              </Link>
            </MenuItem>
          )}
          {user.type.includes('PARTNER_MANAGER') && (
            <MenuItem onClick={closeMenu}>
              <Link base={'site'} path={'/partner'} className={classes.fullWidth}>
                <Button color="primary" variant="text" fullWidth={true}>
                  Partner Portal
                </Button>
              </Link>
            </MenuItem>
          )}
          {pathname.indexOf('/user') !== 0 && (
            <MenuItem onClick={closeMenu}>
              <Link base={'site'} path={'/user/settings'} className={classes.fullWidth}>
                <Button color="primary" variant="text" fullWidth={true}>
                  User Settings
                </Button>
              </Link>
            </MenuItem>
          )}
          {!isLoginPage && (
            <MenuItem onClick={closeMenu}>
              <LoginBtn classes={classes} user={user} logout={logout} />
            </MenuItem>
          )}
        </Menu>
      </Fragment>
    ) : (
      !isLoginPage && !simple && <LoginBtn classes={classes} />
    )}
  </Fragment>
);
const MobileNavBar = ({
  isLoginPage,
  classes,
  user,
  open,
  anchorEl,
  pathname,
  openMenu,
  closeMenu,
  logout
}) => {
  const menuItems = [];

  menuItems.push(
    <MenuItem key={1} onClick={closeMenu}>
      <Link base={'site'} path={'/about'} className={classes.fullWidth}>
        <Button color="primary" variant="text" fullWidth={true}>
          About
        </Button>
      </Link>
    </MenuItem>
  );
  menuItems.push(
    <MenuItem key={2} onClick={closeMenu}>
      <Link base={'site'} path={'/contact-us'} className={classes.fullWidth}>
        <Button color="primary" variant="text" fullWidth={true}>
          Contact
        </Button>
      </Link>
    </MenuItem>
  );

  if (user && user.type) {
    if (user.type.includes('D2D_EXPERT') && pathname.indexOf('/expert') !== 0) {
      menuItems.push(
        <MenuItem key={4} onClick={closeMenu}>
          <Link base={'site'} path={'/expert'} className={classes.fullWidth}>
            <Button color="primary" variant="text" fullWidth={true}>
              Consults
            </Button>
          </Link>
        </MenuItem>
      );
    }
    if (user.type.includes('EO_ADMIN') && pathname.indexOf('/admin') !== 0) {
      menuItems.push(
        <MenuItem key={5} onClick={closeMenu}>
          <Link base={'site'} path={'/admin'} className={classes.fullWidth}>
            <Button color="primary" variant="text" fullWidth={true}>
              Admin Portal
            </Button>
          </Link>
        </MenuItem>
      );
    }
    if (pathname.indexOf('/user') !== 0) {
      menuItems.push(
        <MenuItem key={6} onClick={closeMenu}>
          <Link base={'site'} path={'/user/settings'} className={classes.fullWidth}>
            <Button color="primary" variant="text" fullWidth={true}>
              User Settings
            </Button>
          </Link>
        </MenuItem>
      );
    }

    !isLoginPage &&
      menuItems.push(
        <MenuItem key={7} onClick={closeMenu}>
          <LoginBtn classes={classes} user={user} logout={logout} mobile />
        </MenuItem>
      );
  } else {
    !isLoginPage &&
      menuItems.push(
        <MenuItem key={8} onClick={closeMenu}>
          <LoginBtn classes={classes} mobile />
        </MenuItem>
      );
  }

  return (
    <Fragment>
      <span className={classes.flex}>
        {window._eo.isMobileApp ? (
          <LogoImg classes={classes} />
        ) : (
          <Link base={'site'} path={'/'}>
            <LogoImg classes={classes} />
          </Link>
        )}
      </span>
      {!isLoginPage && (
        <Fragment>
          <IconButton
            size="medium"
            color="secondary"
            className={classes.textRight}
            onClick={openMenu}
            aria-owns={anchorEl ? 'simple-menu' : null}
            aria-haspopup="true"
          >
            <Icon>menu</Icon>
          </IconButton>
          <Menu id="simple-menu" anchorEl={anchorEl} open={open} onClose={closeMenu}>
            {menuItems}
          </Menu>
        </Fragment>
      )}
    </Fragment>
  );
};

class NavBar extends Component {
  state = {
    anchorEl: null
  };

  componentDidMount = () => {
    this.props.loadAuth();
  };

  openMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorEl: null });
  };

  logout = async () => {
    this.closeMenu();
    await _fetchUrl({
      method: 'DELETE',
      path: 'auth/logout',
      token: this.props.user.token
    });
    this.props.logout();
    this.props.displayNotification({ message: 'You have been logged out.' });
    return this.props.history.push(window._eo.isMobileApp ? '/login' : '/');
  };

  render() {
    const { user, classes, location, simple } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const isLoginPage = location.pathname.indexOf('/login') !== -1;

    return (
      <AppBar position="static" elevation={0} color="primary">
        <Toolbar>
          <Hidden xsDown>
            <DeskTopNavBar
              simple={simple}
              isLoginPage={isLoginPage}
              classes={classes}
              user={user}
              open={open}
              anchorEl={anchorEl}
              pathname={location.pathname}
              openMenu={this.openMenu}
              closeMenu={this.closeMenu}
              logout={this.logout}
            />
          </Hidden>
          <Hidden smUp>
            <MobileNavBar
              isLoginPage={isLoginPage}
              classes={classes}
              user={user}
              open={open}
              anchorEl={anchorEl}
              pathname={location.pathname}
              openMenu={this.openMenu}
              closeMenu={this.closeMenu}
              logout={this.logout}
            />
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

const NavBarComponent = withRouter(withStyles(styles, { withTheme: true })(NavBar));

const mapStateToProps = (state) => ({
  user: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  displayNotification: (notification) => dispatch(displayNotification(notification)),
  loadAuth: () => dispatch(loadAuth())
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBarComponent);
