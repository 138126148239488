export const parseExpertSurveyResult = (result, dateFormatter) => {
  const ts = result.timestamp ? dateFormatter(result.timestamp) : '';

  if (result.shareNumber) {
    return [result.complexityRating, result.feedback, result.recommendations, ts];
  }

  if (result.complexityRating) {
    return [result.complexityRating, result.feedback, result.recommendations, ts];
  }

  if (result['48f48b4e-0ec7-4929-a44e-33ef82b4a6ba']) {
    return [
      parseInt(result['c271c1bc-69e8-447b-ade6-a6440f62bc1f'], 10) + 1, //complexity
      result['72dbf1f0-d0cd-4b11-b32e-047c230ab99c'], //feedback
      result['393c2381-4d65-410b-a634-2e4a18c0ae61'], //proactive recommendations
      ts
    ];
  }

  return ['', '', '', ''];
};

export const parseCustomerSurveyResult = (result, dateFormatter) => {
  const ts = result.timestamp ? dateFormatter(result.timestamp) : '';

  if (result.adequacyScore) {
    return [
      result.adequacyScore,
      result.experienceRating,
      result.confidenceRating || '',
      result.recommendations,
      ts
    ];
  }

  if (result['0bb0d959-f127-460f-a5e2-faa7f9409dcb']) {
    return [
      parseInt(result['2d9fdd5d-c126-4d4f-a93c-ec8862777b8f'], 10) + 1,
      parseInt(result['b58dc835-98e0-4b51-bf2c-209a7b2db2fe'], 10) + 1,
      parseInt(result['44affc24-b2e8-44c6-a5d8-6df9fb71e5be'], 10) + 1,
      result['0bb0d959-f127-460f-a5e2-faa7f9409dcb'],
      ts
    ];
  }

  return ['', '', '', '', ''];
};

// 0bb0d959-f127-460f-a5e2-faa7f9409dcb: "Excellent Service-very helpful!!"
// 2d9fdd5d-c126-4d4f-a93c-ec8862777b8f: "2"
// 44affc24-b2e8-44c6-a5d8-6df9fb71e5be: "0"
// b58dc835-98e0-4b51-bf2c-209a7b2db2fe: "2"
// timestamp: 1652491953510
