const styles = (theme) => ({
  root: {
    //backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
    padding: theme.spacing(3),
    textAlign: 'center'
  },
  headline: {
    margin: theme.spacing(3)
  },
  image: {
    display: 'none'
  },
  form: {
    //maxWidth: theme.breakpoints.values.sm,
    //margin: '0 auto'
  },
  buttonRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    maxWidth: theme.breakpoints.values.sm
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '&:nth-of-type(2)': {
      marginLeft: theme.spacing(2)
      //marginRight: theme.spacing(2),
    },
    '& a': {
      //color: theme.palette.common.white
    }
  },
  decisionButton: {
    marginBottom: theme.spacing(2)
  },
  error: {
    clear: 'right',
    color: theme.palette.error.main
  }
});

export default styles;
