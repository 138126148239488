import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const ProgressButton = (props) => {
  return (
    <Button {...props}>
      {props.disabled ? (
        <CircularProgress style={{ height: '26px', width: '26px' }} />
      ) : (
        props.children
      )}
    </Button>
  );
};

export default ProgressButton;
