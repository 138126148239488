import ExpertList from './ExpertList';
import { connect } from 'react-redux';

import { displayNotification } from '../../store/actions';

const mapStateToProps = (state) => ({
  user: state.auth,
  urls: state.webData.urls,
  products: state.webData.products,
  doctors: Object.keys(state.webData.doctors).reduce((acc, doctor) => {
    //Only show platform doctors
    if (
      state.webData.doctors[doctor].status &&
      state.webData.doctors[doctor].status === 'platform'
    ) {
      acc[doctor] = state.webData.doctors[doctor];
    }
    return acc;
  }, {})
});

const mapDispatchToProps = (dispatch) => ({
  displayNotification: (notification) => dispatch(displayNotification(notification))
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpertList);
