import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const DocumentMeta = ({ name, content }) => (
  <Helmet>
    <meta name={name} content={content} />
  </Helmet>
);

DocumentMeta.propTypes = {
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

export default DocumentMeta;
