import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme/theme';

import moment from 'moment';

import ApprovalDetails from './components/ApprovalDetails';
import RequestDetails from './components/RequestDetails';
import CallDetails from './components/CallDetails';
import ExpertResponse from './components/ExpertResponse';
import CustomerResponse from './components/CustomerResponse';
import StatusFlags from './components/StatusFlags';
import AuditLog from './components/AuditLog';

const useStyles = makeStyles(() => ({
  mainContainer: {
    padding: '1rem',
    flexWrap: 'nowrap'
  },
  mainHeading: {
    fontSize: '1.25rem',
    marginBottom: '0.7rem'
    // marginTop: '0.5rem',
  },
  subHeading: {
    marginBottom: '1.0rem'
  },
  details: {
    fontSize: '0.9rem',
    marginBottom: '0.5rem'
  }
}));

const Details = ({ details }) => {
  const {
    id,
    status,
    created,
    medicalRecordId,
    opinionId,
    partnerId,
    approval,
    requestDetails,
    callDetails,
    customerResponse,
    expertResponse,
    statusFlags,
    auditLog
  } = details;

  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      style={{ height: '100%', backgroundColor: '#F0F2F5' }}
      className={classes.mainContainer}
    >
      <Grid item sm={12} md={9} lg={7}>
        <Grid container>
          <Grid item sm={6}>
            <Typography variant="h4" className={classes.subHeading}>
              Audit Log
            </Typography>
          </Grid>
          <Grid item sm={6} style={{ marginTop: 6, textAlign: 'right' }}>
            <Typography variant="h4" style={{ fontSize: '14px', opacity: '.8' }}>
              Consult ID: {id}
            </Typography>
          </Grid>
        </Grid>

        <AuditLog auditLog={auditLog} />
      </Grid>

      <Grid item sm={12} md={9} lg={7}>
        <ApprovalDetails {...approval} />
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="flex-start"
        style={{ marginTop: '2rem' }}
      >
        <Grid item sm={12} md={9} lg={7}>
          <RequestDetails {...requestDetails} />
        </Grid>
        <Grid item sm={12} md={9} lg={7}>
          <CallDetails {...callDetails} />
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-start"
          style={{ marginTop: '2rem' }}
        >
          <Grid item sm={12} md={9} lg={7}>
            <ExpertResponse {...expertResponse} />
          </Grid>
          <Grid item sm={12} md={9} lg={7}>
            <CustomerResponse {...customerResponse} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container sm={12} md={9} lg={7} style={{ marginTop: '2rem' }}>
        <StatusFlags {...statusFlags} />
      </Grid>
      <Grid item style={{ padding: '30px 5px' }}>
        <Typography variant="h4" className={classes.mainHeading}>
          Details
        </Typography>

        <Grid container direction="column">
          <Grid item>
            <Typography variant="h4" className={classes.details}>{`Status: ${status}`}</Typography>
            <Typography variant="h4" className={classes.details}>{`Created At: ${moment(
              created
            ).format('dddd, MMMM Do YYYY, h:mm:ss a')}`}</Typography>
          </Grid>

          <Typography
            variant="h4"
            className={classes.details}
          >{`Medical ID: ${medicalRecordId} `}</Typography>
          <Typography
            variant="h4"
            className={classes.details}
          >{`Opinion ID: ${opinionId} `}</Typography>
          <Typography
            variant="h4"
            className={classes.details}
          >{`Partner ID: ${partnerId} `}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const ThemedDetails = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Details {...props} />
    </ThemeProvider>
  );
};

export default Details;
