import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';
import { pdfjs, Document, Page } from 'react-pdf';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import VideoPlayer from '../../partials/VideoPlayer';

import { connect } from 'react-redux';
import { openFileViewer } from '../../store/actions';

import pdfplaceholder from './pdf-placeholder.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = (theme) => ({
  file: {
    cursor: 'pointer',
    display: 'block',
    outline: `1px solid ${theme.palette.text.primary}`,
    maxWidth: 200
  },
  metaData: {
    marginBottom: theme.spacing(2)
  },
  hidePdfPreview: {
    cursor: 'pointer',
    overflow: `scroll`
  },
  root: {},
  row: {},
  paper: {}
});

class FilePreViewer extends Component {
  static propTypes = {
    openFileViewer: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    file: PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }).isRequired,
    styles: PropTypes.object,
    hidePdfPreview: PropTypes.bool
  };

  handleOpenFileViewer = (file) => (event) => {
    event.preventDefault();
    this.props.openFileViewer(file);
  };

  render() {
    const { classes, file, CloseIcon, styles, hidePdfPreview, simple, width } = this.props;

    if (Object.keys(file).length === 0) {
      return null;
    }

    if (hidePdfPreview && file.type.indexOf('application') !== -1) {
      return (
        <div onClick={this.handleOpenFileViewer(file)} className={classes.hidePdfPreview}>
          <Typography variant="body1">{file.name}</Typography>
          <Typography variant="body1" style={{ fontSize: '75%' }}>
            {filesize(file.size || 0)}
          </Typography>
        </div>
      );
    }

    if (!file.type) {
      return null;
    }

    return (
      <Fragment>
        {['video/mp4', 'video/ogg', 'video/webm'].includes(file.type) && (
          <VideoPlayer
            type={'inline'}
            autoplay={false}
            open={false}
            sources={[{ src: file.url, type: file.type || 'video/mp4' }]}
          />
        )}
        {file.type.indexOf('image') !== -1 && (
          <img
            onClick={this.handleOpenFileViewer(file)}
            id="openFileViewer"
            className={classes.file}
            style={styles}
            src={file.url}
            alt={file.name}
          />
        )}
        {file.type.indexOf('application') !== -1 && (
          <Document
            error={<img className={classes.file} src={pdfplaceholder} />}
            onClick={this.handleOpenFileViewer(file)}
            id="openFileViewer"
            className={classes.file}
            style={styles}
            file={file.url}
          >
            <Page pageNumber={1} width={width ? width : 250} />
          </Document>
        )}
        {!simple && file.name && file.size > 0 && (
          <div className={classes.metaData}>
            {CloseIcon ? (
              <div>
                <div style={{ float: 'left', height: '0px' }}>
                  <CloseIcon />
                </div>
                <div style={{ marginLeft: '53px', paddingTop: '8px' }}>
                  <div style={{ width: '86%', wordWrap: 'break-word', fontSize: '.8em' }}>
                    {file.name}
                  </div>
                  <div style={{ fontSize: '.7em', opacity: '.9' }}>({filesize(file.size)})</div>
                </div>
              </div>
            ) : (
              <div>
                <div style={{ wordWrap: 'break-word', fontSize: '.8em' }}>{file.name}</div>
                <div style={{ fontSize: '.7em', opacity: '.9' }}>({filesize(file.size)})</div>
              </div>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  openFileViewer: (file) => dispatch(openFileViewer(file))
});

const FilePreViewerComponent = withStyles(styles, { withTheme: true })(FilePreViewer);
export default connect(mapStateToProps, mapDispatchToProps)(FilePreViewerComponent);
