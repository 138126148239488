import React, { Component } from 'react';
import EOPartnerships from './EOPartnerships';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return { products: state.webData.products };
};
const mapDispatchToProps = () => ({});

class EOPartnershipsContainer extends Component {
  render() {
    return (
      <React.Fragment>
        <EOPartnerships {...this.props} />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EOPartnershipsContainer);
