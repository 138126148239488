import AdminPortal from './AdminPortal';
import { connect } from 'react-redux';
import { authPing, displayNotification } from '../../store/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  authPing: (params) => dispatch(authPing(params)),
  displayNotification: (notification) => dispatch(displayNotification(notification))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPortal);
