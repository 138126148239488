import React, { useState, useMemo } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { guessTimeZoneWithGMT } from '../../../utils/guessCountry';
import { makeStyles } from '@material-ui/core/styles';
import TimezoneList from '../../shared/timezoneList';
import Confirm from '../../../utils/confirm';
import moment from 'moment';

function isWeekend(date) {
  if (date.day() === 0 || date.day() === 6) {
    return true;
  }

  return false;
}

function isWeekendOrNextDay(date) {
  if (isWeekend(date)) {
    return true;
  }

  const today = moment();

  if (today.day() + 1 === date.day()) {
    return true;
  }

  //if today is friday, saturday, or sunday, and the date is monday, it's next day
  if ((isWeekend(today) || today.day() === 5) && date.day() === 1) {
    return true;
  }

  return false;
}

const useStyles = makeStyles(() => ({
  spacing: {
    marginBottom: '8px'
  }
}));

const Availability = (props) => {
  const { availabilityInfo } = props;

  const [timezone, setTimezone] = useState(availabilityInfo.timezone || guessTimeZoneWithGMT);
  const [records] = useState(availabilityInfo.records || '');
  const [recordMeeting] = useState(availabilityInfo.recordMeeting || false);
  const [videoCall] = useState(availabilityInfo.voiceCall === false || false);
  const [reason, setReason] = useState(availabilityInfo.reason || '');

  const [date1, setDate1] = useState(
    availabilityInfo.availability
      ? availabilityInfo.availability[0]
      : { dow: '', time: '', text: '' }
  );
  const [date2, setDate2] = useState(
    availabilityInfo.availability
      ? availabilityInfo.availability[1]
      : { dow: '', time: '', text: '' }
  );
  const [date3, setDate3] = useState(
    availabilityInfo.availability
      ? availabilityInfo.availability[2]
      : { dow: '', time: '', text: '' }
  );

  const classes = useStyles();

  const nextDay = useMemo(() => {
    if (
      date1.text.indexOf('urgent') !== -1 ||
      date2.text.indexOf('urgent') !== -1 ||
      date3.text.indexOf('urgent') !== -1
    ) {
      return true;
    }
    return false;
  }, [date1, date2, date3]);

  const ChoicePicker = ({
    id,
    index,
    date,
    time,
    onDateChange,
    onTimeChange,
    label,
    allowAllTimes
  }) => {
    const m = moment();

    const dates = React.useMemo(() => {
      const toRet = [];
      let count = 1;

      while (toRet.length < 7) {
        let date = m.clone();
        date.add(count++, 'days');
        let isUrgent = false;

        if (isWeekendOrNextDay(date)) {
          isUrgent = true;
        }

        toRet.push({ date, isUrgent });
      }

      return toRet;
    }, [allowAllTimes, m]);

    return (
      <Box display="flex" flexDirection="column">
        {
          // label && <Typography variant='body1'>{label}</Typography>
        }

        <Box display="flex" style={{ gap: '16px' }}>
          <TextField
            id={id}
            select
            fullWidth
            margin="normal"
            className={classes.spacing}
            variant="outlined"
            SelectProps={{ native: true }}
            InputLabelProps={{
              shrink: true
            }}
            value={date}
            onChange={onDateChange}
            label={label || 'Day'}
          >
            <option value=""></option>
            {dates.map((a) => {
              return (
                <option
                  key={a.date.valueOf()}
                  value={a.date.format('dddd, MMMM Do')}
                >{`${a.date.format('ddd, MMM Do')} ${a.isUrgent ? '(Urgent)' : ''}`}</option>
              );
            })}
          </TextField>

          <Box minWidth={115}>
            <TextField
              id={id + '_2'}
              select
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              SelectProps={{ native: true }}
              value={time}
              onChange={onTimeChange}
              label="Time"
            >
              <option value=""></option>
              {(() => {
                const elements = [];
                const taken = [];
                let current = '';

                switch (index) {
                  case 1:
                    current = date1.dow;
                    taken.push(date2.dow + date2.time);
                    taken.push(date3.dow + date3.time);
                    break;
                  case 2:
                    current = date2.dow;
                    taken.push(date1.dow + date1.time);
                    taken.push(date3.dow + date3.time);
                    break;
                  case 3:
                    current = date3.dow;
                    taken.push(date1.dow + date1.time);
                    taken.push(date2.dow + date2.time);
                    break;
                }

                [
                  '6:00 AM',
                  '7:00 AM',
                  '8:00 AM',
                  '9:00 AM',
                  '10:00 AM',
                  '11:00 AM',
                  '12:00 PM',
                  '1:00 PM',
                  '2:00 PM',
                  '3:00 PM',
                  '4:00 PM',
                  '5:00 PM',
                  '6:00 PM',
                  '7:00 PM',
                  '8:00 PM',
                  '9:00 PM'
                ].map((k, i) => {
                  let disabled = taken.includes(current + k) ? true : false;
                  elements.push(
                    <option value={k} key={i} disabled={disabled}>
                      {k}
                    </option>
                  );
                });

                return elements;
              })()}
            </TextField>
          </Box>
        </Box>
      </Box>
    );
  };

  const areDatesSet = () => {
    if (!date1.dow || !date2.dow || !date3.dow || !date1.time || !date2.time || !date3.time) {
      return false;
    }

    return true;
  };

  function handleNext() {
    if (
      !timezone ||
      !timezone.code ||
      (timezone.code == 'other' && !timezone.value) ||
      !areDatesSet()
    ) {
      Confirm('All fields are required.', false);
      return;
    }

    if (nextDay && !reason) {
      Confirm('Please tell us why you need an urgent appointment', false);
      return;
    }

    props.onNext({
      voiceCall: !videoCall,
      timezone,
      records,
      recordMeeting,
      availability: [date1, date2, date3],
      nextDay,
      reason: nextDay ? reason : null
    });
  }

  function handleBack() {
    props.onBack({
      voiceCall: !videoCall,
      timezone,
      records,
      recordMeeting,
      availability: [date1, date2, date3],
      nextDay,
      reason: nextDay ? reason : null
    });
  }

  const setDate = (setter) => (e) => {
    const target = e.target;
    setter(() => ({
      dow: target.value,
      time: '',
      text: target.options[target.selectedIndex].text.toLowerCase()
    }));
  };

  const setTime = (setter) => (e) => {
    const target = e.target;
    setter((p) => ({ ...p, time: target.value }));
  };

  return (
    <React.Fragment>
      <Typography variant="body1" style={{ marginBottom: '8px' }}>
        Enter three 1-hour time slots for a phone call. Place a hold on your calendar for all times
        submitted. Call is booked for 30 minutes.
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TimezoneList
            required={true}
            isInputDesign={true}
            selectedCode={timezone?.code}
            selectedGMTValue={timezone?.value}
            handleChange={setTimezone}
          ></TimezoneList>
        </Grid>

        <Grid item xs={12}>
          <ChoicePicker
            id="slot1"
            index={1}
            allowAllTimes={nextDay}
            label="Time Slot 1*"
            date={date1.dow}
            time={date1.time}
            onDateChange={setDate(setDate1)}
            onTimeChange={setTime(setDate1)}
          />
        </Grid>
        <Grid item xs={12}>
          <ChoicePicker
            id="slot2"
            index={2}
            allowAllTimes={nextDay}
            label="Time Slot 2*"
            date={date2.dow}
            time={date2.time}
            onDateChange={setDate(setDate2)}
            onTimeChange={setTime(setDate2)}
          />
        </Grid>
        <Grid item xs={12}>
          <ChoicePicker
            id="slot3"
            index={3}
            allowAllTimes={nextDay}
            label="Time Slot 3*"
            date={date3.dow}
            time={date3.time}
            onDateChange={setDate(setDate3)}
            onTimeChange={setTime(setDate3)}
          />
        </Grid>
      </Grid>

      {nextDay && (
        <TextField
          id="urgent"
          fullWidth
          margin="normal"
          style={{ marginTop: '24px' }}
          variant="outlined"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Ex: I have see the patient late tomorrow afternoon."
          label="Reason for the urgent consult request."
          required
        />
      )}

      <Box display="flex" mt={2} style={{ marginTop: '24px' }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleBack}
          style={{ marginRight: '8px' }}
        >
          Back
        </Button>
        <Button id="available_next" color="primary" variant="contained" onClick={handleNext}>
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default Availability;
