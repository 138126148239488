import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const FormSection = ({ children }) => (
  <Grid item sm={12} md={8}>
    <Paper>
      <Box p={4}>
        <Grid container style={{ gap: '16px' }} direction="column">
          {children}
        </Grid>
      </Box>
    </Paper>
  </Grid>
);

const FormField = ({
  autoFocus,
  label,
  value,
  onChange,
  type,
  error,
  helperText,
  multiline,
  autoComplete
}) => (
  <Grid item xs={12}>
    <TextField
      error={error}
      helperText={helperText}
      autoFocus={autoFocus}
      InputLabelProps={{ shrink: true }}
      label={label}
      fullWidth
      value={value}
      onChange={onChange}
      type={type || 'text'}
      multiline={multiline}
      autoComplete={autoComplete}
    />
  </Grid>
);

const Account = ({
  classes,
  name,
  setName,
  password1,
  setPassword1,
  password2,
  setPassword2,
  handleNext
}) => {
  const [errorFields, setErrorFields] = useState({});

  // @dev - errorFields will be set numerically, falsy values (primarily undefined/false) will be used to indicate no errors.
  //        Values >=1 will be used as the element key for error message.

  let ERROR_MESSAGE = {
    name: ['Name field is empty', 'Name appears to be invalid'],
    password1: ['Password field is empty'],
    password2: ['Verify password field is empty', 'Password does not match']
  };

  // Make index base 1
  ERROR_MESSAGE = new Proxy(ERROR_MESSAGE, {
    get(target, prop) {
      return [undefined, ...target[prop]];
    }
  });

  const validate = () => {
    let errors = {};

    if (!name) {
      errors.name = 1;
    } else if (name.includes('@')) {
      errors.name = 2;
    }

    if (!password1) {
      errors.password1 = 1;
    }

    if (!password2) {
      errors.password2 = 1;
    } else if (password1 !== password2) {
      errors.password2 = 2;
    }

    if (Object.keys(errors).length) {
      setErrorFields({ ...errorFields, ...errors });
    } else {
      handleNext();
    }
  };

  return (
    <Grid className={classes.content}>
      <FormSection>
        <Typography>Enter in your name, and your desired password.</Typography>
        <FormField
          label="Name"
          error={!!errorFields.name}
          helperText={errorFields.name ? ERROR_MESSAGE.name[errorFields.name] : ''}
          autoFocus={true}
          value={name}
          onChange={(evt) => {
            setErrorFields({ ...errorFields, name: false });
            setName(evt.target.value);
          }}
        />
        <FormField
          label="Password"
          type="password"
          error={!!errorFields.password1}
          helperText={errorFields.password1 ? ERROR_MESSAGE.password1[errorFields.password1] : ''}
          value={password1}
          autoComplete="new-password"
          onChange={(evt) => {
            setErrorFields({ ...errorFields, password1: false });
            setPassword1(evt.target.value);
          }}
        />
        <FormField
          label="Verify Password"
          type="password"
          error={!!errorFields.password2}
          helperText={errorFields.password2 ? ERROR_MESSAGE.password2[errorFields.password2] : ''}
          value={password2}
          onChange={(evt) => {
            setErrorFields({ ...errorFields, password2: false });
            setPassword2(evt.target.value);
          }}
        />
      </FormSection>
      <div className={classes.actionsContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={validate}
          className={classes.button}
          disabled={!(name && password1 && password2)}
        >
          Next
        </Button>
      </div>
    </Grid>
  );
};

export default Account;
