import Doctor from './Doctor';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  user: state.auth,
  products: state.webData.products
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Doctor);
