import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import { isEmailInvalid } from '../../../utils/formValidation';
import { _fetchUrl } from '../../../utils/api';

import styles from '../_styles';

class AddDoctor extends Component {
  state = {
    submitted: false,
    error: null,
    formErrors: {}
  };

  handleChange = (key) => (event) => {
    const value = event.target.value;

    return this.setState((prevState) => ({
      [key]: value,
      formErrors: { ...prevState.formErrors, [key]: false }
    }));
  };
  handleAddDoctorSubmit = (event) => {
    event.preventDefault();
    this.setState({ formErrors: {} });

    const { name, email, specialty } = this.state;
    let hasErrors = false;

    if (!name || !name.length) {
      hasErrors = true;
      this.setState((prevState) => ({
        formErrors: { ...prevState.formErrors, name: true }
      }));
    }
    if (!email || isEmailInvalid(email)) {
      hasErrors = true;
      this.setState((prevState) => ({
        formErrors: { ...prevState.formErrors, email: true }
      }));
    }
    if (!specialty || !specialty.length) {
      hasErrors = true;
      this.setState((prevState) => ({
        formErrors: { ...prevState.formErrors, specialty: true }
      }));
    }

    if (!hasErrors) {
      const states = {
        submitting: { submitted: true, error: null },
        complete: { submitted: false, error: null }
      };

      this.setState(states.submitting, async () => {
        try {
          const response = await _fetchUrl({
            method: 'POST',
            path: `poe/doctor/`,
            body: { doctor: { name, email, specialty } },
            token: this.props.token
          });
          if (response.status === 'ok') {
            return this.props.afterAddDoctor(response.doctorId);
          }
          return this.setState({ ...states.complete, error: response.message || response.status });
        } catch (error) {
          return this.setState({ ...states.complete, error });
        }
      });
    }
  };

  render() {
    const { classes, handleClose, specialties } = this.props;
    const { submitted, error, formErrors } = this.state;

    return (
      <Dialog open={true} onClose={handleClose}>
        <form onSubmit={this.handleAddDoctorSubmit}>
          <DialogTitle>Add Doctor</DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} error={formErrors.name} className={classes.formControl}>
              <InputLabel shrink htmlFor={'name'}>
                Name <span className={classes.formHelpText}>{formErrors.name && '(Required)'}</span>
              </InputLabel>
              <Input autoFocus={true} onChange={this.handleChange('name')} />
            </FormControl>
            <FormControl fullWidth={true} error={formErrors.email} className={classes.formControl}>
              <InputLabel shrink htmlFor={'email'}>
                Email{' '}
                <span className={classes.formHelpText}>{formErrors.email && '(Required)'}</span>
              </InputLabel>
              <Input onChange={this.handleChange('email')} />
            </FormControl>
            <FormControl
              fullWidth={true}
              error={formErrors.specialty}
              className={classes.formControl}
            >
              <InputLabel shrink htmlFor={'specialty'}>
                Specialty{' '}
                <span className={classes.formHelpText}>{formErrors.specialty && '(Required)'}</span>
              </InputLabel>
              <Select
                native
                value={this.state.specialty || ''}
                onChange={this.handleChange('specialty')}
                inputProps={{ name: 'specialty', id: 'specialty' }}
              >
                <option value="" disabled={true} />
                {specialties.map((specialty) => (
                  <option key={specialty} value={specialty}>
                    {specialty}
                  </option>
                ))}
              </Select>
            </FormControl>
            <DialogActions>
              <div style={{ margin: '0', textAlign: 'right' }}>
                <Button
                  className={classes.saveButton}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitted}
                >
                  {submitted ? <CircularProgress /> : <span>Save Doctor</span>}
                </Button>
                {error && (
                  <Typography variant="body1" className={classes.error}>
                    {error}
                  </Typography>
                )}
              </div>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(AddDoctor));
