import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const StatusFlags = (props) => {
  const {
    callCompleted,
    invoiceTracked,
    recordingDownloaded,
    recordingUploaded,
    zoomCustomerAccepted,
    zoomExpertAccepted,
    zoomInvite
  } = props;
  return (
    <Grid container direction="column" component={Paper} style={{ padding: '1rem' }} spacing={1}>
      <Grid item>
        <Typography variant="h4" align="left">
          Status Flags
        </Typography>
      </Grid>
      <Grid item container justifyContent="flex-start">
        <Typography variant="body1">Call Completed: {callCompleted ? 'True' : 'False'}</Typography>
      </Grid>
      <Divider />
      <Grid item container justifyContent="flex-start">
        <Typography variant="body1">
          Invoice Tracked: {invoiceTracked ? 'True' : 'False'}
        </Typography>
      </Grid>
      <Divider />
      <Grid item container justifyContent="flex-start">
        <Typography variant="body1">
          Recording Downloaded: {recordingDownloaded ? 'True' : 'False'}
        </Typography>
      </Grid>
      <Divider />
      <Grid item container justifyContent="flex-start">
        <Typography variant="body1">
          Recording Uploaded: {recordingUploaded ? 'True' : 'False'}
        </Typography>
      </Grid>
      <Divider />
      <Grid item container justifyContent="flex-start">
        <Typography variant="body1">
          Zoom Customer Accepted: {zoomCustomerAccepted ? 'True' : 'False'}
        </Typography>
      </Grid>
      <Divider />
      <Grid item container justifyContent="flex-start">
        <Typography variant="body1">
          Zoom Expert Accepted: {zoomExpertAccepted ? 'True' : 'False'}
        </Typography>
      </Grid>
      <Divider />
      <Grid item container justifyContent="flex-start">
        <Typography variant="body1">Zoom Invite: {zoomInvite ? 'True' : 'False'}</Typography>
      </Grid>
      <Divider />
    </Grid>
  );
};

export default StatusFlags;
