import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowBack from '@material-ui/icons/ArrowBack';

import DocumentTitle from '../../partials/DocumentTitle';
import { Link } from '../../partials/Link';
import { _fetchUrl } from '../../utils/api';
import LoadingIndicator from '../shared/LoadingIndicator';

import AddVisit from './AddVisit';
import styles from './_styles';

class OpinionsList extends Component {
  state = {
    loading: true,
    patient: {},
    showAddVisit: false
  };

  componentDidMount() {
    const states = {
      loading: { loading: true, patient: {}, error: null },
      complete: { loading: false, patient: {}, error: null }
    };

    this.setState(states.loading, async () => {
      try {
        const response = await _fetchUrl({
          method: 'GET',
          path: `poe/patient/${this.props.match.params.patientId}`,
          token: this.props.user.token
        });
        if (response.status === 'ok') {
          return this.setState({
            ...states.complete,
            patient: {
              ...response.patient,
              opinions: response.patient.opinions || [],
              subscriptions: response.patient.subscriptions.map((subscription) => ({
                ...subscription,
                productName: this.props.webData.subscriptions[subscription.productId].name,
                doctorName: this.props.webData.doctors[subscription.doctorId].profile.name
              }))
            }
          });
        }
        return this.setState({ ...states.complete, error: response.message });
      } catch (error) {
        return this.setState({ ...states.complete, error });
      }
    });
  }

  showAddVisit = () => this.setState({ showAddVisit: true });
  closeAddVisit = () => this.setState({ showAddVisit: false });
  afterAddVisit = (newOpinionId) => {
    this.closeAddVisit();
    this.props.history.push(`${this.props.location.pathname}/${newOpinionId}`);
  };

  render() {
    const { classes, theme, history, location, user } = this.props;
    const { loading, patient, showAddVisit } = this.state;

    if (loading) {
      return <LoadingIndicator message="Loading Patient..." />;
    }

    return (
      <Fragment>
        <DocumentTitle title={`Admin: Patient: ${patient.name}: Opinions`} />
        <Link base={'site'} path={'/admin/patients'}>
          <Typography variant="subtitle1">
            Patient{' '}
            <Typography variant="body1" style={{ display: 'inline' }}>
              <span style={{ fontSize: '75%' }}>
                <ArrowBack style={{ position: 'relative', top: '5px' }} />
                Back to Patients List
              </span>
            </Typography>
          </Typography>
        </Link>
        <Paper className={classes.paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Date of Birth</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Created</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{patient.id}</TableCell>
                <TableCell>{patient.name}</TableCell>
                <TableCell>{patient.email}</TableCell>
                <TableCell>{patient.dob}</TableCell>
                <TableCell>{patient.phone}</TableCell>
                <TableCell>{new Date(patient.created).toLocaleString()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Typography variant="subtitle1">Opinions</Typography>
        {patient.subscriptions && patient.subscriptions.length > 0 && (
          <Button
            onClick={this.showAddVisit}
            className={classes.addButton}
            variant="contained"
            color="primary"
          >
            Create Connect Visit
          </Button>
        )}
        {showAddVisit && (
          <AddVisit
            patient={patient}
            afterAddVisit={this.afterAddVisit}
            handleClose={this.closeAddVisit}
            token={user.token}
          />
        )}
        <Paper className={classes.paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Doctor</TableCell>
                <TableCell>Diagnosis</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Updated</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patient.opinions
                .sort((a, b) => b.lastUpdated - a.lastUpdated)
                .map((opinion) => (
                  <TableRow
                    key={opinion.id}
                    hover
                    className={classes.pointer}
                    opinionid={opinion.id}
                    onClick={() => {
                      history.push(`${location.pathname}/${opinion.id}`.replace(/\/\/+/g, '/'));
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {opinion.id}
                    </TableCell>
                    <TableCell>{opinion.doctorId}</TableCell>
                    <TableCell>{opinion.productName}</TableCell>
                    <TableCell>{opinion.type}</TableCell>
                    <TableCell>{new Date(opinion.created).toLocaleString()}</TableCell>
                    <TableCell>{new Date(opinion.lastUpdated).toLocaleString()}</TableCell>
                    <TableCell>
                      <strong>{opinion.status.s[opinion.status.active].name}</strong>
                      {opinion.status.s[opinion.status.active].details.length > 0 && (
                        <div style={{ marginLeft: theme.spacing(1) }}>
                          <strong>Details</strong>
                          {opinion.status.s[opinion.status.active].details.map((detail, idx) => (
                            <Typography key={idx}>
                              {detail.detail}{' '}
                              <span style={{ fontSize: '75%' }}>
                                ({new Date(detail.date).toLocaleString()})
                              </span>
                            </Typography>
                          ))}
                        </div>
                      )}
                      {opinion.type === 'EO_CONNECT' && (
                        <div style={{ marginLeft: theme.spacing(1) }}>
                          <strong>Visits ({opinion.visits.length})</strong>
                          {opinion.visits.map((visit) => (
                            <Typography key={visit.medicalRecordId}>
                              <span style={{ textTransform: 'capitalize' }}>
                                {visit.status.toLowerCase()}
                              </span>
                            </Typography>
                          ))}
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(OpinionsList));
