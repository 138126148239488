import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';

const eoLink = (props) => {
  let newLinkPath =
    (props.base === 'app'
      ? process.env.REACT_APP_WEBAPP_BASE_URL || ''
      : process.env.REACT_APP_WEBSITE_BASE_URL || '') +
    props.path.replace(/(https?:\/\/)|(\/)+/g, '$1$2'); // Remove any double slashes after protocol
  newLinkPath = newLinkPath.replace(/\/$/, '');

  const newLinkParser = document.createElement('a');
  newLinkParser.href = newLinkPath;

  const newLinkProtocolHostnameAndPort = `${newLinkParser.protocol}//${newLinkParser.hostname}:${newLinkParser.port}`;
  const currentLinkProtocolHostnameAndPort = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

  let to = {
    pathname: newLinkParser.pathname
  };

  if (props.to) {
    to.state = props.to;
  }

  if (currentLinkProtocolHostnameAndPort !== newLinkProtocolHostnameAndPort) {
    return (
      <a href={newLinkPath} onClick={props.onClick} className={props.className} style={props.style}>
        {props.children}
      </a>
    );
  }
  if (props.navLink) {
    return (
      <NavLink
        to={to}
        onClick={props.onClick}
        className={props.className}
        style={props.style}
        exact={props.exact}
        activeClassName="is-active"
      >
        {props.children}
      </NavLink>
    );
  }

  return (
    <Link to={to} onClick={props.onClick} className={props.className} style={props.style}>
      {props.children}
    </Link>
  );
};

eoLink.propTypes = {
  debug: PropTypes.bool,
  base: PropTypes.oneOf(['app', 'site']).isRequired,
  path: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  state: PropTypes.object,
  style: PropTypes.object
};

export { eoLink as Link };
