import React from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';

import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';

import '../../node_modules/video.js/dist/video-js.min.css';

const styles = (theme) => ({
  videoWrapper: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[5],
    left: '50%',
    position: 'absolute',
    top: '30%',
    transform: 'translate(-50%,-50%)',
    width: '50vw'
  }
});

class VideoPlayer extends React.Component {
  static defaultProps = {
    type: 'modal',
    onClose: () => {},
    autoplay: true,
    controls: true,
    fullScreen: true,
    fluid: true
  };
  static propTypes = {
    type: PropTypes.oneOf(['modal', 'inline']),
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    autoplay: PropTypes.bool,
    controls: PropTypes.bool,
    fullScreen: PropTypes.bool,
    fluid: PropTypes.bool,
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
      })
    ).isRequired
  };

  videoNode = React.createRef();

  componentDidMount() {
    if (this.props.type === 'inline') {
      this.initializeComponent();
    }
  }

  initializeComponent = () => {
    if (this.videoNode.current) {
      this.player = videojs(this.videoNode.current, this.props);
    }
  };
  destroyComponent = () => {
    if (this.player) {
      this.player.dispose();
      this.player = null;
    }

    this.props.onClose();
  };

  render() {
    const { classes, open, type } = this.props;
    const player = (
      <div data-vjs-player>
        <video
          ref={this.videoNode}
          className={`video-js ${type === 'inline' ? 'vjs-big-play-centered' : ''}`}
        ></video>
      </div>
    );

    if (type === 'inline') {
      return player;
    }

    return (
      <Modal
        open={open}
        onBackdropClick={this.destroyComponent}
        onClose={this.destroyComponent}
        onRendered={this.initializeComponent}
      >
        <div className={classes.videoWrapper}>{player}</div>
      </Modal>
    );
  }
}

export default withStyles(styles, { withTheme: true })(VideoPlayer);
