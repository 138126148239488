import React, { Component } from 'react';
import ContactUsForm from './ContactUsForm';
import { connect } from 'react-redux';

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

class ContactUsFormContainer extends Component {
  render() {
    return (
      <React.Fragment>
        <ContactUsForm {...this.props} />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsFormContainer);
