import React from 'react';
import EOList from './EOList';

function upperCase(word) {
  const re = /(\b[a-z](?!\s))/g;
  return word.replace(re, (x) => x.toUpperCase());
}

const productLookup = {};

function createKey(category, name) {
  return `${category}-${name}`;
}

function cacheProduct(product) {
  const key = createKey(product.category, product.name);

  productLookup[key] = product;
  return key;
}

function getProductFromCache(key) {
  return productLookup[key];
}

const ProductSelect = (props) => {
  const productOptions = React.useMemo(() => {
    return props.products.reduce((acc, item) => {
      acc[item.category] = acc[item.category] || [];
      acc[item.category].push({
        label:
          item.name.toLowerCase() === 'general'
            ? upperCase(`${item.category}: ${item.name}`)
            : item.name,
        value: cacheProduct(item)
      });

      return acc;
    }, {});
  }, [props.products]);

  const lookupProduct = (value) => () => {
    return props.onSelected(getProductFromCache(value));
  };

  const selected = createKey(props.category, props.name);

  return (
    <EOList
      initialValue={selected}
      title={props.title}
      options={productOptions}
      onSelected={lookupProduct}
    />
  );
};

export default ProductSelect;
