import React from 'react';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { _fetchUrl } from '../../utils/api';

import ProgressButton from '../../partials/ProgressButton';

const OTPForm = (props) => {
  const [code, setCode] = React.useState('');
  const [error, setError] = React.useState('');
  const [sendingCode, setSendingCode] = React.useState(false);

  const token = React.useMemo(() => {
    if (!props.location.search) {
      //redirect
      return props.redirect();
    }

    const sessionToken = new URLSearchParams(props.location.search).get('token');

    if (!sessionToken) {
      return redirectToLogin();
    }

    return sessionToken;
  }, [props.location.search]);

  const validateCodeAndSend = () => {
    if (code === '') {
      setError('Login code cannot be blank');
      return;
    }

    setError('');
    setSendingCode(true);
  };

  const redirectToLogin = () => {
    props.history.push('/login');
  };

  const submitCode = () => {
    _fetchUrl({
      method: 'POST',
      path: 'auth/login/otp',
      token: token,
      body: { otp: code }
    })
      .then((response) => {
        setSendingCode(false);

        if (response.status !== 'ok') {
          setError('The login code was not valid or expired. Request a new code.');

          return;
        }

        props.afterLogin({
          email: response.email,
          name: response.name,
          token: response.token,
          type: response.type,
          userId: response.userId,
          doctorId: response.doctorId,
          patientId: response.patientId,
          timezone: response.timezone
        });
      })
      .catch((e) => {
        console.error(e.message);
        setSendingCode(false);
      });
  };

  React.useEffect(() => {
    if (!sendingCode) {
      return;
    }

    submitCode();
  }, [sendingCode, submitCode]);

  React.useEffect(() => {
    if (props.user) {
      return props.redirect();
    }
  }, [props.user]);

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="column" style={{ gap: '12px' }} maxWidth={600}>
        <Typography variant="h3">Login Verification Code</Typography>
        <Typography>
          To finish logging in enter in the code that was just emailed to you. If you need to
          request a new code, go back and enter your email and password again. If have problems
          logging in, please contact{' '}
          <a href="mailto://care@expertopinion.md">care@expertopinion.md</a>.
        </Typography>
        <TextField
          fullWidth
          value={code}
          label="Login Code"
          onChange={(e) => setCode(e.target.value)}
          error={error !== ''}
          helperText={error}
        />
        <Box mt={2} display="flex" justifyContent="flex-end" style={{ gap: '18px' }}>
          <Button color="primary" variant="text" onClick={redirectToLogin}>
            Request a new code
          </Button>
          <ProgressButton
            onClick={validateCodeAndSend}
            disabled={sendingCode}
            variant="contained"
            color="primary"
          >
            Send Code
          </ProgressButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default OTPForm;
