import React from 'react';
import DocumentTitle from '../../partials/DocumentTitle';

const Privacy = () => (
  <div style={{ margin: '20px' }}>
    <DocumentTitle title="Privacy Policy" />
    <div style={{ padding: '20px', maxWidth: '80%' }}>
      <p>Expert Opinion MD Privacy Policy</p>
      <p>April 23, 2019</p>
      <p>
        Please note that by using this website (the “Site”), the services provided by Expert Opinion
        MD , Inc. (“Company”, “we”, “our” or “us”), you are accepting the practices described in
        this Privacy Policy. If you do not agree to this Privacy Policy, please do not use the Site
        and/or the Company’s services.
      </p>
      <p>
        This Privacy Policy only addresses the use and disclosure of information we collect from
        you. When you are on the Site, you can be directed to other sites that are beyond our
        control, and Company is not responsible for the privacy practices of third parties or the
        content of linked websites. We recommend you read the posted privacy policy whenever
        interacting with any website.
      </p>
      <p>
        We do not knowingly collect or maintain personally identifiable information from persons
        under 18 years old, and no part of our Site is directed to persons under 18. If Company
        learns that personally identifiable information of persons less than 18 years old has been
        collected without verifiable parental or guardian consent, then Company will take the
        appropriate steps to delete this information.{' '}
      </p>
      <p>
        Company reserves the right to update this Privacy Policy from time to time. Please visit
        this page periodically so that you will be apprised of any changes. If you have any
        questions about this Privacy Policy, you may contact us at care@expertopinion.md
      </p>
      <p>Please read the Privacy Policy carefully.</p>
      <p>INFORMATION COLLECTED</p>
      <p>
        The following describes the types of personal and other information the Company may collect
        about you, and how the Company may use and maintain that information, including, but not
        limited to:
      </p>
      <p>
        User Identity. We ask that you register with the Site and provide us with your email
        address, a password, your first and last name and other contact information and personal
        details such as to utilize our service. Your User Identity is primarily used for
        communication purposes as well as rendering of specific services you request. This includes,
        but is not limited to Patient Name, Contact Phone, Contact Email, Patient Date of Birth,
        Patient’s Originating Doctor Name, Patient’s Originating Clinic Name, Patients Originating
        Clinic Address, and Date of Patient’s last visit with the Originating Doctor. We will keep
        this information indefinitely unless specifically requested otherwise by the Patient or
        User.
      </p>

      <p>
        User Medical Records. In order for the medical professionals to provide expert opinions
        regarding your condition, you are, upon purchase, opting into consent for the Company to
        request a medical records transfer on your behalf from your originating physician office to
        the destination physician office who will be providing the expert opinion to you.
      </p>

      <p>
        Communication. If you correspond with us via email or our secure portal, we will keep this
        information indefinitely unless specifically requested otherwise by the Patient or User.
      </p>

      <p>
        Website Data Collection. As per global standard website practices, the Company collects
        information about Users’ navigation of our Site(s). This information allows the Company to
        better optimize User experiences. Your IP address is used to help identify you and to gather
        broad demographic information as well as optimize the software service we provide. We also
        collect cookies in some cases such as to remember your Site preferences, improve our
        marketing, and improve our overall service and user experience. If you reject cookies, then
        your use of the Site may yield a subpar use of service user experience. None of the cookies
        personally identifies you and you are able to learn more here.{' '}
      </p>
      <p>
        No mobile information will be shared with third parties/affiliates for marketing/promotional
        purposes. All the above categories exclude text messaging originator opt-in data and
        consent; this information will not be shared with any third parties.
      </p>
      <p>INFORMATION DISCLOSURE</p>
      <p>The following describes information the Company may disclose about you:</p>
      <p>
        We will not license, sell, rent or share or otherwise disclose any of the personally
        identifiable information that we collect about you, except when we effectuate the medical
        records transfer to the physician of your choosing on our Site.
      </p>
      <p>
        We may disclose the results of aggregated data about you. This aggregate data can be in the
        form of Site demographic data as well as high level information about specific medical
        conditions. For example, we might disclose to a third party that 30% of our Users transacted
        for Glaucoma expert opinions or that 20% of our Users are from New York State. Both of the
        aforementioned examples are purely fictional in nature.
      </p>

      <p>
        User name and password is not disclosed to any third parties at all, even in aggregate form.
      </p>

      <p>
        We may disclose information about you as part of a merger, acquisition or other sale or
        transfer of its assets or business. Company does not guarantee that any entity receiving
        such information in connection with one of these transactions will comply with all terms of
        this policy.
      </p>

      <p>
        We may disclose information in court or in the stages leading to possible legal action
        arising from improper use the Site, or its services, or due to a dispute you initiate in
        connection with the related services. In all cases, any disclosure would be only to the
        extent permitted by law.
      </p>

      <p>
        We may disclose information to the government or to third parties under certain
        circumstances, such as in connection with illegal activity on our Site or to respond to a
        subpoena, court order or other legal process.{' '}
      </p>

      <p>
        Company reserves the right to release information that we collect to law enforcement or
        other government officials, as we, in our sole and absolute discretion, deem necessary or
        appropriate.
      </p>

      <p>
        If you use our Site or services outside of the United States, information that we collect
        about you will be transferred to servers inside the United States, which may involve the
        transfer of information out of countries located in the European Economic Area (note the EU
        is not defined as a service area currently within our Terms of Use). By allowing Company to
        collect information about you, you consent to such transfer and processing of your data.
      </p>
      <p>Health Information Rights</p>
      <p>Users have certain rights regarding health information that we maintain.</p>
      <p>Right to Access Health Information</p>
      <p>
        Users have the right to review or obtain copies of their medical and health information
        records. Your request to review and/or obtain a copy of your protected health information
        records must be made in writing. We may charge a fee for the costs of producing, copying and
        mailing your requested information, but we will tell you the cost in advance.
      </p>

      <p>Right to Amend Your Protected Health Information</p>
      <p>
        If you feel that your information (including protected health information) maintained by us
        is incorrect or incomplete, you may request that we amend the information. Your request must
        be made in writing and must include the reason you are seeking a change. We may deny your
        request if, for example, you ask to amend a record that is already accurate and complete. If
        we deny your request to amend, we will notify you in writing. You then have the right to
        submit to us a written statement of disagreement with our decision and we have the right to
        rebut that statement.
      </p>

      <p>Right to Receive an Accounting of Disclosures </p>
      <p>
        You have the right to request that we provide you with an accounting of certain disclosures
        of your PHI. To request an accounting, you must submit your request, in writing, to our
        Compliance Office at care@expertopinion.md. Your request must state a time period for the
        accounting. The accounting may not include dates prior to March, 2018 (company founding).
        Your request should indicate in what form you want the accounting (for example, on paper,
        electronically). The first accounting in a 12-month period is free.{' '}
      </p>
      <p>
        Right to Request a Restriction on the Use and Disclosure of Your Protected Health
        Information
      </p>
      <p>
        You have the right to request that we restrict or limit how we use or disclose your
        protected health information for treatment, payment or healthcare operations. By law, we may
        not agree to your request. If we do agree, we will comply with your request unless the
        information is needed for an emergency. Your request for a restriction must be made in
        writing. In your request, you must tell us: (1) what information you want to limit; (2)
        whether you want to limit how we use or disclose your information, or both; and (3) to whom
        you want the restrictions to apply.
      </p>

      <p>Right to Receive Confidential Communications</p>
      <p>
        You have the right to request that we use a certain method to communicate with you or that
        we send information about you to a certain location if the communication could endanger you.
        Your request to receive confidential communications must be made in writing. Your request
        must clearly state that all or part of the communication from us could endanger you. We will
        accommodate all reasonable requests. Your request must specify how or where you wish to be
        contacted.
      </p>

      <p>Right to a Paper Copy of This Privacy Policy </p>
      <p>
        You have a right at any time to request a paper copy of this Privacy Policy, even if you had
        previously agreed to receive an electronic copy.
      </p>

      <p>Right to File a Complaint </p>
      <p>
        You have the right to file a complaint if you believe we are not in compliance with our
        Notice of Privacy Practices and the Healthcare Information Portability and Accountability
        Act (HIPAA) or if you believe your privacy rights have been violated. Your complaint can be
        submitted, in writing, to our Compliance Office at care@expertopinion.md. Your complaint can
        be anonymous. We value your opinion and we will not retaliate against you in any manner for
        filing a complaint. You also have a right to file a complaint with the Secretary of the
        Department of Health and Human Services.
      </p>

      <p>Contact Information for Exercising Your Rights</p>
      <p>
        You may exercise any of the rights described above by contacting our Compliance Office at
        care@expertopinion.md.
      </p>

      <p>SECURITY</p>
      <p>
        In order to protect our users from the unauthorized release of Private Health Information
        (PHI), ExpertOpinion.md uses unique identifiers (email and password) to authenticate the
        user's identity and verify the user is authorized to view the area of expertopinion.md (and
        data) they are requesting. All information transmitted to and from expertopinion.md is
        encrypted before it is sent using Secure Sockets Layer (SSL) technology or similar industry
        standard encryption technology.
      </p>
      <p> </p>
      <p>
        In addition, expertopinion.md takes the following steps to protect the data we collect
        against unauthorized access: Encrypting all data we store, verifying proper credentials
        before accessing sensitive data, logging and alerting all unauthorized or suspicious
        attempts to our systems
      </p>

      <p>
        You should keep in mind that expertopinion.md and our services are run on software, hardware
        and networks, any component of which may, from time to time, require maintenance or
        experience problems or breaches of security beyond our control. Be aware that despite our
        use of standard industry practices and the guidelines outlined in this Privacy Policy, no
        data transmission over the internet or encryption method can be guaranteed to be 100%
        secure.
      </p>
      <p> </p>
      <p>GOVERNING LAW</p>
      <p>
        By choosing to visit this Site, use our services, or otherwise provide information to
        Company, you agree that any dispute over privacy or the terms contained in this Privacy
        Policy will be governed by the laws of the State of Washington. You also agree to abide by
        any limitation on damages contained in our Terms of Use or other agreement that we have with
        you.
      </p>

      <p>
        This privacy statement has been prepared based on provisions of multiple legislations,
        including the U.S. Health Insurance Portability and Accountability Act of 1996 (“HIPAA”),
        and the U.S. Health Information Technology for Economic and Clinical Health Act (“HITECH”).{' '}
      </p>
    </div>
  </div>
);

export default Privacy;
