import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const DocumentTitle = (props) => (
  <Helmet>
    <title>{`Expert Opinion ${props.title.length ? ' :: ' + props.title : ''}`}</title>
  </Helmet>
);

DocumentTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default DocumentTitle;
