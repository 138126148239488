import React, { useCallback, useState } from 'react';
import EODialog from '../../partials/EODialog';
import EOTable from '../../partials/EOTable';
import { _fetchUrl } from '../../utils/api';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';

const SurveyResponseModal = ({ user, consultId, surveyCache, setSurveyCache, customer }) => {
  const [surveyData, setSurveyData] = useState(surveyCache[consultId]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadSurveyData = useCallback(() => {
    if (!user || !user.token || surveyData) {
      return;
    }

    setLoading(true);

    _fetchUrl({
      method: 'GET',
      path: `poe/partner/consult/${consultId}/survey`,
      token: user.token
    }).then((r) => {
      if (r.status === 'ok') {
        setSurveyData(r.survey_data);
        setSurveyCache((cache) => ({ ...cache, [consultId]: r.survey_data }));
      } else {
        console.error('Error loading partners:', r.error);
      }
      setLoading(false);
    });
  }, [user, open]);

  return (
    <div>
      <EODialog open={open} handleClose={() => setOpen(false)} handleOkay={() => setOpen(false)}>
        <div>
          <EOTable
            title={customer ? 'Customer Response' : 'Expert Response'}
            isLoading={loading}
            columns={[
              { title: 'Question', field: 'question' },
              {
                title: 'Answer',
                field: 'answer',
                render: (rowData) => (rowData.answer ? rowData.answer : 'N/a')
              }
            ]}
            data={
              surveyData
                ? customer
                  ? [...surveyData.customer.global, ...surveyData.customer.local]
                  : [...surveyData.expert.global, ...surveyData.expert.local]
                : []
            }
            options={{
              paging: false,
              sorting: false,
              search: false
            }}
          />
        </div>
      </EODialog>
      <IconButton
        onClick={() => {
          setOpen(true);
          loadSurveyData();
        }}
        style={{ cursor: 'pointer' }}
      >
        <VisibilityIcon />
      </IconButton>
    </div>
  );
};

export default SurveyResponseModal;
