import React, { useState, useEffect, useMemo } from 'react';
import ManageSurveysDP from './ManageSurveysDP';
import EOTable from '../../../partials/EOTable.js';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import { _fetchUrl } from '../../../utils/api';
import { copyToClipboard } from '../../../utils/copyToClipboard';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

const ManageSurveys = (props) => {
  let { partnerId, products, token } = props;

  const [surveys, setSurveys] = useState([]);
  const [currentSurvey, setCurrentSurvey] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [updatedQuestions, setUpdatedQuestions] = useState({});

  const classes = useStyles();

  let fetchSurveys = () => {
    _fetchUrl({
      method: 'GET',
      path: `poe/partner/${partnerId}/surveys`,
      token
    }).then((r) => {
      if (r.status === 'ok') {
        if (!r.surveys.find((survey) => survey.version === 0)) {
          r.surveys.push({
            id: 'draft',
            version: 0,
            questions: { customer: [], expert: [] }
          });
        }

        setSurveys(r.surveys);
        setUpdatedQuestions({});
      } else {
        console.error('Error loading surveys:', r.error);
      }
    });
  };

  // Load survey
  useEffect(() => {
    fetchSurveys();
  }, [partnerId]);

  useEffect(() => {
    if (surveys.length) {
      let latest = surveys.reduce((prev, cur) => (prev.version > cur.version ? prev : cur));
      if (latest.version !== 0) {
        setCurrentSurvey(latest);
      }
    }
  }, [surveys]);

  useEffect(() => {
    if (currentSurvey) {
      setQuestions(currentSurvey.questions);
    }
  }, [currentSurvey]);

  const formattedSurveyData = useMemo(() => {
    let hasDraft = false;

    let data = surveys.map((survey) => {
      if (survey.version === 0) {
        hasDraft = true;
      }

      let source =
        survey.version === 0 && updatedQuestions[partnerId]
          ? { id: survey.id, version: survey.version, questions: updatedQuestions[partnerId] }
          : survey;

      return {
        id: source.id,
        version: source.version === 0 ? 'draft' : source.version,
        customer_length: source.questions.customer?.length || 0,
        expert_length: source.questions.expert?.length || 0,
        customer: source.questions.customer,
        expert: source.questions.expert
      };
    });

    if (!hasDraft) {
      data.push({
        id: 'draft',
        version: 'draft',
        customer_length: 0,
        expert_length: 0,
        customer: [],
        expert: []
      });
    }

    data.unshift(data.splice(data.length - 1, 1)[0]);

    return data;
  }, [surveys, currentSurvey, updatedQuestions]);

  const draft = useMemo(() => {
    return formattedSurveyData.filter((survey) => survey.version === 'draft')[0];
  }, [formattedSurveyData]);

  const columns = [
    {
      field: 'label',
      title: 'Question',
      width: 30
    },
    {
      field: 'type',
      title: 'Type',
      lookup: { RADIO: 'RADIO', TEXTFIELD: 'TEXTFIELD' },
      initialEditValue: 'RADIO',
      width: 10
    },
    {
      field: 'optional',
      title: 'Optional',
      lookup: { true: 'Yes', false: 'No' },
      initialEditValue: false,
      width: 7,
      render: (rowData) => (rowData.optional ? 'Yes' : 'No')
    },
    {
      field: 'multiline',
      title: 'Multiline',
      lookup: { true: 'Yes', false: 'No' },
      initialEditValue: false,
      width: 7,
      render: (rowData) =>
        rowData.type === 'TEXTFIELD' ? (rowData.multiline ? 'Yes' : 'No') : 'N/a'
    },
    {
      field: 'options',
      title: 'Options',
      width: 30,
      render: (rowData) => {
        if (rowData.options && Array.isArray(rowData.options)) {
          if (rowData.options.length) {
            return (
              <ul>
                {rowData.options.map((option, idx) => {
                  return <li key={idx}>{option}</li>;
                })}
              </ul>
            );
          } else {
            return <p>No options</p>;
          }
        } else {
          return 'N/a';
        }
      }
    },
    {
      field: 'products',
      title: 'Products',
      width: 40,
      render: (rowData) => {
        if (rowData.products?.length) {
          return (
            <ul>
              {rowData.products.map((product, idx) => {
                return <li key={idx}>{product}</li>;
              })}
            </ul>
          );
        } else {
          return <p>All</p>;
        }
      },
      editComponent: (props) => {
        return (
          <Select
            multiple
            value={props.value || []}
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
            input={<Input id="select-multiple-chip" />}
            className={classes.select}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
          >
            {products?.map((product) => (
              <MenuItem key={product} value={product}>
                {product}
              </MenuItem>
            ))}
          </Select>
        );
      }
    },
    {
      field: 'internalOnly',
      title: 'Internal',
      lookup: { true: 'Yes', false: 'No' },
      initialEditValue: false,
      width: 7,
      render: (rowData) => (rowData.internalOnly ? 'Yes' : 'No')
    }
  ];

  if (partnerId === 'default') {
    columns.splice(1, 0, {
      field: 'global',
      title: 'Global',
      width: 30,
      lookup: { true: 'Yes', false: 'No' },
      initialEditValue: false,
      render: (rowData) => (rowData.global ? 'Yes' : 'No')
    });
  }

  return (
    <div>
      {currentSurvey && (
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '-10px' }}
        >
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Version
          </Typography>
          <Typography variant="h6" color="primary" style={{ fontWeight: 'bold' }}>
            {currentSurvey.version}
          </Typography>
        </Breadcrumbs>
      )}
      <EOTable
        style={{ marginTop: '25px' }}
        title="Customer"
        data={questions.customer}
        columns={columns}
        pageSize={questions.customer?.length}
        localization={{
          body: {
            emptyDataSourceMessage: 'No survey questions, using default questions.'
          }
        }}
      ></EOTable>

      <EOTable
        style={{ marginTop: '25px', marginBottom: '25px' }}
        title="Expert"
        data={questions.expert}
        columns={columns}
        pageSize={questions.expert?.length}
        localization={{
          body: {
            emptyDataSourceMessage: 'No survey questions, using default questions.'
          }
        }}
      ></EOTable>

      <EOTable
        style={{ marginTop: '25px', marginBottom: '25px' }}
        title="All Surveys"
        data={formattedSurveyData}
        columns={[
          {
            field: 'id',
            title: 'ID',
            width: 10,
            render: (rowData) => (
              <Tooltip title={rowData.id} arrow>
                <span style={{ cursor: 'pointer' }} onClick={() => copyToClipboard(rowData.id)}>
                  {rowData.id.substring(0, 6) + '...'}
                </span>
              </Tooltip>
            )
          },
          {
            field: 'version',
            title: 'Version',
            width: 7
          },
          {
            field: 'customer_length',
            title: 'Total Customer Questions',
            width: 16
          },
          {
            field: 'expert_length',
            title: 'Total Expert Questions',
            width: 16
          }
        ]}
        pageSize={formattedSurveyData.length}
        localization={{
          body: {
            emptyDataSourceMessage: 'No survey questions, using default questions.'
          }
        }}
        detailPanel={(rowData) => {
          return (
            <ManageSurveysDP
              rowData={rowData}
              draft={draft}
              updatedQuestions={updatedQuestions}
              setUpdatedQuestions={setUpdatedQuestions}
              partnerId={partnerId}
              token={token}
              fetchSurveys={fetchSurveys}
              columns={columns}
              products={products}
              questions={questions}
            />
          );
        }}
      ></EOTable>
    </div>
  );
};

export default ManageSurveys;
