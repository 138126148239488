import React, { useState, useEffect, useMemo } from 'react';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { _fetchUrl } from '../../../utils/api';
import { isUrlInvalid } from '../../../utils/formValidation';

const FormField = ({ autoFocus, label, value, onChange, type, error, helperText }) => (
  <Grid item xs={12} style={{ marginTop: '10px' }}>
    <TextField
      error={error}
      helperText={helperText}
      autoFocus={autoFocus}
      InputLabelProps={{ shrink: true }}
      label={label}
      fullWidth
      value={value}
      onChange={onChange}
      type={type || 'text'}
    />
  </Grid>
);

const ManageReports = ({ partnerId, token, displayNotification }) => {
  const [currentInternalUrl, setCurrentInternalUrl] = useState('');
  const [internalUrl, setInternalUrl] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [errorFields, setErrorFields] = useState({});

  const buttonDisabled = useMemo(() => {
    return isSubmitting || currentInternalUrl === internalUrl;
  }, [isSubmitting, currentInternalUrl, internalUrl]);

  // @dev - errorFields will be set numerically, falsy values (primarily undefined/false) will be used to indicate no errors.
  //        Values >=1 will be used as the element key for error message.

  let ERROR_MESSAGE = {
    internal: ['Internal Url field must be a URL']
  };

  // Make index base 1
  ERROR_MESSAGE = new Proxy(ERROR_MESSAGE, {
    get(target, prop) {
      return [undefined, ...target[prop]];
    }
  });

  useEffect(() => {
    _fetchUrl({
      method: 'GET',
      path: `poe/partner/${partnerId}/reports`,
      token
    }).then((r) => {
      if (r.status === 'ok') {
        if (r.internalUrl) {
          setCurrentInternalUrl(r.internalUrl);
          setInternalUrl(r.internalUrl);
        }
      } else {
        console.error('r', r);
      }
    });
  }, [partnerId, token]);

  const handleSubmit = async () => {
    if (internalUrl && isUrlInvalid(internalUrl)) {
      setErrorFields({ ...errorFields, internal: 1 });
    }

    if (Object.values(errorFields).some((val) => val)) {
      return;
    }

    setIsSubmitting(true);

    const result = await _fetchUrl({
      method: 'PUT',
      path: `poe/partner/${partnerId}/reports`,
      token,
      body: {
        internalUrl
      }
    });

    setIsSubmitting(false);

    switch (result.status) {
      case 'ok':
        displayNotification({ type: 'success', message: 'Saved' });
        break;
      default:
        setErrorText(result.message);
        break;
    }
  };

  // Set box shadow to match table shadow for consistency
  return (
    <Paper
      style={{
        margin: '25px 0',
        padding: '25px',
        minHeight: '200px',
        boxShadow:
          '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
      }}
    >
      <Alert
        style={errorText ? { marginBottom: '20px', marginTop: '10px' } : { display: 'none' }}
        variant="filled"
        severity="error"
        onClose={() => {
          setErrorText('');
        }}
      >
        {errorText}
      </Alert>
      <Typography
        gutterBottom
        variant="h5"
        align="left"
        color="primary"
        style={{ marginBottom: '20px' }}
      >
        Reports
      </Typography>
      <FormField
        label="Internal"
        error={!!errorFields.internal}
        helperText={errorFields.internal ? ERROR_MESSAGE.internal[errorFields.internal] : ''}
        autoFocus={true}
        value={internalUrl}
        onChange={(evt) => {
          setErrorFields({ ...errorFields, internal: false });
          setInternalUrl(evt.target.value);
        }}
      />
      <Grid container justifyContent="flex-end">
        <Button
          onClick={handleSubmit}
          style={{ color: 'white', marginTop: '20px' }}
          disabled={buttonDisabled}
          type="submit"
          variant="contained"
          color="primary"
        >
          {isSubmitting ? <CircularProgress style={{ color: 'primary' }} /> : <span>Save</span>}
        </Button>
      </Grid>
    </Paper>
  );
};

export default ManageReports;
