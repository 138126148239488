import React, { useState, useEffect } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(2),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  }
});

const URL_BASE = `${process.env.REACT_APP_API_BASE_URL}/v1/partner`;

const Confirmation = (props) => {
  const { classes, match } = props;

  const [state, setState] = useState({
    processing: true,
    isApiError: false,
    alreadyScheduled: false,
    notAvailable: false,
    suggestedAlternateTime: false,
    errorMessage: '',
    date: null
  });

  const [token, setToken] = useState(null);

  useEffect(() => {
    if (
      match &&
      match.params &&
      match.params.token &&
      match.params.token != 'suggested_alternate_time'
    ) {
      setToken(match.params.token);
    } else {
      setState({ sending: false, isError: false, suggestedAlternateTime: true });
    }
  }, [match]);

  useEffect(() => {
    if (token) {
      confirmScheduleApi(token);
    }
  }, [token]);

  const confirmScheduleApi = async (token) => {
    return window
      .fetch(`${URL_BASE}/confirm`, {
        method: 'POST',
        body: JSON.stringify({
          token
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((r) => r.json())
      .then((r) => {
        if (r.status !== 'ok') {
          if (r.status === 'This consult request has already been scheduled.') {
            setState({ sending: false, isError: false, alreadyScheduled: true });
          } else if (r.status === 'not_available') {
            setState({ sending: false, isError: false, notAvailable: true });
          } else {
            setState({ sending: false, isError: true, errorMessage: r.status });
          }
        } else {
          setState({ sending: false, isError: false, date: r.time });
        }
      });
  };

  return (
    <div className={classes.root}>
      {state.alreadyScheduled && (
        <Alert severity="warning">
          <AlertTitle>This consult has already been scheduled</AlertTitle>
          <Typography variant="body1">
            Aw, snap! This consult has already been accepted by another Expert. We’ll let you know
            as soon as another opportunity comes up.
            <p>
              If you have additional questions, reach out to{' '}
              <a href="mailto:aidan@expertopinion.md">Aidan</a>.
            </p>
          </Typography>
        </Alert>
      )}
      {state.isError && (
        <Alert severity="error">
          <AlertTitle>Could not confirm your availability</AlertTitle>
          <Typography variant="body1">
            Unfortunately, we weren't able to confirm your availability. This may happen for a few
            reasons:
            <ul>
              <li>The scheduling link has expired (most common).</li>
              <li>The scheduling link was not valid (less common).</li>
            </ul>
            <strong>System Response:</strong> <i>{state.errorMessage}</i>
            <p>
              If you have additional questions, reach out{' '}
              <a href="mailto:aidan@expertopinion.md">Aidan</a>.
            </p>
          </Typography>
        </Alert>
      )}
      {state.processing && (
        <div>
          Confirming
          <div>
            <LinearProgress />
          </div>
        </div>
      )}
      {state.notAvailable && (
        <Alert severity="success">
          <AlertTitle>Not Available</AlertTitle>
          <Typography variant="body1">
            Thank you for letting us know you're not available. We'll catch you on the next consult!
            Any questions? Email <a href="mailto:aidan@expertopinion.md">Aidan</a>.
          </Typography>
        </Alert>
      )}
      {state.suggestedAlternateTime && (
        <Alert severity="success">
          <AlertTitle>Suggested Alternate Time</AlertTitle>
          <Typography variant="body1">
            Thank you for letting us know when you're available. We'll catch you on the next
            consult! Any questions? Email <a href="mailto:aidan@expertopinion.md">Aidan</a>.
          </Typography>
        </Alert>
      )}
      {state.date && (
        <Alert severity="success">
          <AlertTitle>Availability Confirmed</AlertTitle>
          <Typography variant="body1">
            Thank you. Your availabilty has been confirmed. You will receive a meeting invite
            shortly. If you need to cancel or reschedule, reach out to{' '}
            <a href="mailto:aidan@expertopinion.md">Aidan</a>.
            <p>
              <strong>Consult Time: </strong>
              {new Date(state.date).toString()}
            </p>
          </Typography>
        </Alert>
      )}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Confirmation);
