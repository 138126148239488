import React, { useState, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import { _fetchUrl } from '../../utils/api';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  tabPanel: {
    width: '100%'
  }
}));

function VerticalTabs({ urlArray }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        style={{ display: urlArray.length === 1 ? 'none' : 'inheritied' }}
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        {urlArray
          .filter((partner) => !!partner.url)
          .map((partner, idx) => (
            <Tab key={idx} label={partner.name} {...a11yProps(idx)} />
          ))}
      </Tabs>
      {urlArray
        .filter((partner) => !!partner.url)
        .map((partner, idx) => (
          <TabPanel key={idx} className={classes.tabPanel} value={value} index={idx}>
            <iframe
              src={partner.url}
              style={{ border: '0', height: '100vh', width: '100%' }}
              allowFullScreen
            ></iframe>
          </TabPanel>
        ))}
    </div>
  );
}

const SingleReport = ({ url }) => {
  return (
    <iframe
      src={url}
      style={{ border: '0', height: '100vh', width: '100%' }}
      allowFullScreen
    ></iframe>
  );
};

const NoReportUrl = () => (
  <Grid item sm={12} md={8} style={{ width: '100%', margin: '0px auto 30px auto' }}>
    <Paper style={{ padding: '25px' }}>
      <div>No report url has been set.</div>
    </Paper>
  </Grid>
);

const LookerStudio = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [urlArray, setUrlArray] = useState([]);

  const isAdmin = useMemo(() => props?.user?.type?.includes('EO_ADMIN'), [props.user]);

  useEffect(() => {
    if (!props?.user?.token) {
      return;
    }

    _fetchUrl({
      method: 'GET',
      path: isAdmin && !props.partnerOnly ? 'poe/partner/looker-studio' : 'partner/looker-studio',
      token: props.user.token
    }).then((r) => {
      if (r.status === 'ok') {
        setUrlArray(r.URL);
        setLoaded(true);
      } else {
        console.error('Error loading partner looker studio URLs:', r);
      }
    });
  }, [props.user, props.partnerOnly]);

  return loaded ? (
    props.partnerOnly ? (
      urlArray[0]?.url ? (
        <SingleReport url={urlArray[0]?.url} />
      ) : (
        <NoReportUrl />
      )
    ) : // Not partner only
    urlArray.length ? (
      <VerticalTabs {...props} urlArray={urlArray} />
    ) : (
      <NoReportUrl />
    )
  ) : (
    // Not Loaded
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <CircularProgress />
    </div>
  );
};

export default LookerStudio;
