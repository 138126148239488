import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { Box, Typography, withStyles, responsiveFontSizes, createTheme } from '@material-ui/core';

let theme = createTheme({
  typography: {
    fontWeightRegular: 300,
    useNextVariants: true
  },
  overrides: {
    MuiStepLabel: {
      label: {
        fontSize: '20px'
      }
    }
  },
  palette: {
    background: {
      default: '#ffffff'
    },
    primary: {
      main: '#03a9f4',
      contrastText: '#f9f9f9'
    },
    secondary: {
      main: '#f9f9f9',
      contrastText: '#03a9f4'
    }
  }
});

theme = responsiveFontSizes(theme);

const styles = {
  root: {
    backgroundColor: 'white'
  },
  logo: {
    height: '30px'
  },
  eologo: {
    // top: '15px',
    height: '30px'
    // position: 'absolute',
    // right: '10px',
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    width: '100%'
  },
  container: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: '100px'
  },
  stepper: {
    padding: '0px'
  },
  paper: {
    margin: '20px',
    padding: '20px'
  },
  title: {
    //padding: theme.spacing(1)
  },
  footer: {
    minHeight: '40px',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    bottom: '0',
    color: 'white',
    padding: '10px'
  },
  card: {
    width: '150px',
    '@media (max-width:600px)': {
      width: '130px'
    },
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  // typography: {
  //   // fontWeight: 'bolder',
  //   '@media (max-width:600px)': {
  //     fontSize: '.65rem'
  //   },
  // },
  media: {
    height: '125px'
  },
  buttonArea: {
    marginTop: '20px'
  },
  mainBody: {
    flexGrow: '1',
    marginBottom: '75px',
    padding: theme.spacing(2)
  }
};

const Main = (props) => {
  const { classes, setState } = props;
  const [choice, setChoice] = useState('');

  const history = useHistory();

  useEffect(() => {
    setState(choice);
  }, [choice]);

  return (
    <React.Fragment>
      <div className={classes.title}>
        <Typography variant="h4">Expert Scheduling Portal</Typography>
        <Box mt={1} mb={4} maxWidth={740}>
          <Typography variant="body1" style={{ marginBottom: '10px' }}>
            This service connects you to an industry Expert with product-specific knowledge to
            answer pre-op, post-op and patient-selection questions.
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            For EOMD partners, you will be redirected to their dedicated secure portal
          </Typography>
        </Box>
      </div>
      <FormControl variant="outlined" style={{ width: '740px' }}>
        <InputLabel>Select product manufacturer</InputLabel>
        <Select
          value={choice}
          onChange={(e) => {
            if (e.target.value === 'OTHER') {
              setChoice(e.target.value);
            } else if (e.target.value) {
              history.push(`/${e.target.value}`);
            }
          }}
          label="Select product manufacturer"
        >
          <MenuItem value=""></MenuItem>
          {/*<MenuItem value='askbausch'>Acufocus</MenuItem>*/}
          <MenuItem value="alconexpert">Alcon</MenuItem>
          {/*<MenuItem value='askbausch'>Bausch + Lomb</MenuItem>*/}
          {/* <MenuItem value=''>Cornea Gen</MenuItem> */}
          <MenuItem value="glaukosguide">GLAUKOS</MenuItem>
          <MenuItem value="alconexpert">Ivantis</MenuItem>
          {/*<MenuItem value='askjnj'>Johnson & Johnson</MenuItem>*/}
          {/* <MenuItem value=''>STAAR Surgical</MenuItem> */}
          <MenuItem value="askus">ZEISS Meditec</MenuItem>
          <MenuItem value="OTHER">OTHER</MenuItem>
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

const Landing = (props) => {
  const { classes, name, options, setState } = props;

  return (
    <div className={classes.mainBody}>
      <Main
        name={name}
        classes={classes}
        setState={setState}
        search={props.location.search}
        options={options}
      />
    </div>
  );
};

export default withRouter(withStyles(styles)(Landing));
