import React, { Fragment } from 'react';
import filesize from 'filesize';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import FilePreViewer from '../shared/FilePreViewer';
import BaseFileComponent from '../shared/BaseFileComponent';

class MedicalRecordFileComponent extends BaseFileComponent {
  state = { ...this.state, file: this.props.file };

  postUpload = (response) => {
    this.props.postUpload(response.medicalRecord);
  };

  renderFileComponent = () => {
    const { disabled, download, classes, token, path } = this.props;
    const { file, fileData, submitted, result, error } = this.state;

    if (disabled) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Typography>Must save medical record before adding files.</Typography>
        </div>
      );
    }

    if (download) {
      return <FilePreViewer file={file} hidePdfPreview={true} styles={{ maxWidth: `100%` }} />;
    }

    if (fileData && fileData.length) {
      return (
        <Fragment>
          <Typography variant="body1" className={classes.alignIconWithText}>
            <CloudUpload style={{ marginRight: '5px' }} />
            {fileData.map((file, key) => (
              <span key={key}>{file.name}</span>
            ))}
          </Typography>
          <Typography variant="body1" style={{ fontSize: '75%' }}>
            {fileData.map((file, key) => (
              <span key={key}>{filesize(file.size || 0)}</span>
            ))}
          </Typography>
          <div style={{ textAlign: 'center' }}>
            <Button
              style={{ color: 'white', margin: '10px 0' }}
              variant="contained"
              color="primary"
              onClick={this.uploadFile(
                this[`medicalRecordFile-${this.props.medicalRecordId}`],
                `medicalRecordFile-${this.props.medicalRecordId}`,
                token,
                path,
                this.postUpload
              )}
              disabled={submitted || !fileData.length}
            >
              {submitted ? <CircularProgress /> : <span>Upload File</span>}
            </Button>
            {!submitted && (
              <Typography
                variant="body1"
                className={classes.pointer}
                onClick={this.resetFile(`medicalRecordFile-${this.props.medicalRecordId}`)}
                style={{ fontSize: '75%' }}
              >
                Reset
              </Typography>
            )}
          </div>
        </Fragment>
      );
    }

    return (
      <div style={{ textAlign: 'center' }}>
        <Button
          style={{ margin: '10px 0' }}
          variant="contained"
          onClick={(e) => e.currentTarget.nextSibling.click()}
        >
          Attach File
        </Button>
        <input
          style={{ display: 'none' }}
          type={'file'}
          onChange={this.selectFile(`medicalRecordFile-${this.props.medicalRecordId}`)}
          ref={(ref) => (this[`medicalRecordFile-${this.props.medicalRecordId}`] = ref)}
        />
        <Typography variant="body1" className={classes.error}>
          {error}
        </Typography>
        {result && <Typography variant="body1">File Uploaded!</Typography>}
      </div>
    );
  };

  render() {
    return <Paper className={this.props.classes.tightPaper}>{this.renderFileComponent()}</Paper>;
  }
}

export default MedicalRecordFileComponent;
