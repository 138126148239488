import moment from 'moment-timezone';

let _timezones = null;
export function timezones() {
  if (!_timezones) {
    _timezones = [
      {
        name: 'Hawaii',
        value: getOffsetForTimeZoneValue('Pacific/Honolulu'),
        code: 'Pacific/Honolulu'
      },
      {
        name: 'Alaska',
        value: getOffsetForTimeZoneValue('America/Anchorage'),
        code: 'America/Anchorage'
      },
      {
        name: 'Pacific',
        value: getOffsetForTimeZoneValue('America/Los_Angeles'),
        code: 'America/Los_Angeles'
      },
      {
        name: 'Mountain',
        value: getOffsetForTimeZoneValue('America/Denver'),
        code: 'America/Denver'
      },
      {
        name: 'Central',
        value: getOffsetForTimeZoneValue('America/Chicago'),
        code: 'America/Chicago'
      },
      {
        name: 'Eastern',
        value: getOffsetForTimeZoneValue('America/New_York'),
        code: 'America/New_York'
      }
    ];
  }
  return _timezones;
}

export function getOffsetForTimeZoneValue(value) {
  return moment.tz(value).format('Z');
}

let _offsets = null;
export function getUTCOffsets() {
  if (!_offsets) {
    _offsets = [];

    // Create a set to track unique offset values
    const uniqueOffsetValues = new Set();

    moment.tz.names().forEach((zoneName) => {
      const offsetValue = getOffsetForTimeZoneValue(zoneName);
      const offsetString = `GMT ${offsetValue}`;

      // Check if we've already added this offset value
      if (!uniqueOffsetValues.has(offsetValue)) {
        uniqueOffsetValues.add(offsetValue);
        _offsets.push({
          value: offsetValue, // String value, e.g., "+03:00"
          name: offsetString // String representation, e.g., "GMT +03:00"
        });
      }
    });

    // Sort offsets by their numeric value
    _offsets.sort(
      (a, b) => moment.duration(a.value).asMinutes() - moment.duration(b.value).asMinutes()
    );
  }

  return _offsets;
}
