import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { authPing } from '../../store/actions';

import { parseFullName } from 'parse-full-name';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import PostAddIcon from '@material-ui/icons/PostAdd';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import { _fetchUrl } from '../../utils/api';
import FilePreViewer from '../shared/FilePreViewer';

import { Alert, AlertTitle } from '@material-ui/lab';

const styles = () => ({
  root: {
    padding: '10px'
  },
  row: {},
  paper: {
    padding: '10px'
    //margin: '0px 5px'
  },
  file: {
    height: '100px',
    width: '70px'
  }
});

const SurveyList = (props) => {
  const { surveys } = props;

  if (surveys.length === 0) {
    return <Typography variant="body1">No Surveys Waiting</Typography>;
  }

  const getSurveyUrl = (survey) =>
    `${window.location.origin}/${survey.partnerId}/form/${survey.surveyToken}`;

  return (
    <div>
      <SurveyRowHeader />
      {surveys.map((survey) => (
        <SurveyRow
          key={survey.id}
          customerName={survey.customerName}
          callDate={survey.callDate}
          surveyUrl={getSurveyUrl(survey)}
        ></SurveyRow>
      ))}
    </div>
  );
};

const SurveyRowHeader = () => (
  <Box flexDirection="row" display="flex" alignItems="left" justifyContent="space-between">
    <Typography variant="subtitle2" style={{ flex: 2 }}>
      Doctor
    </Typography>
    <Typography variant="subtitle2" style={{ flex: 2 }}>
      Consult Date{' '}
    </Typography>
    <Typography variant="subtitle2" style={{ flex: 1 }}>
      Survey
    </Typography>
  </Box>
);

const SurveyRow = (props) => (
  <Box flexDirection="row" display="flex" alignItems="center" justifyContent="space-between">
    <Typography align="left" style={{ flex: 2 }}>
      Dr. {parseFullName(props.customerName).last}
    </Typography>
    <Typography align="left" style={{ flex: 2 }}>
      {moment(props.callDate).format('M/DD, h:mma')}
    </Typography>
    <Box flex="1">
      <IconButton variant="contained" color="primary">
        <Link target="_blank" rel="noopener" href={props.surveyUrl}>
          <PostAddIcon />
        </Link>
      </IconButton>
    </Box>
  </Box>
);

const ConsultList = (props) => {
  const { consults, onDetailedView } = props;

  if (consults.length === 0) {
    return <Typography variant="body1">No Pending Consults</Typography>;
  }

  return (
    <div>
      <ConsultRowHeader />
      {consults.map((consult) => (
        <ConsultRow
          key={consult.id}
          customerName={consult.customerName}
          callDate={consult.callDate}
          videoUrl={consult.videoUrl}
          hasRecords={consult.hasRecords}
          isVoice={consult.voiceCall}
          phone={consult.phoneNumber}
          onClick={() => onDetailedView(consult)}
        ></ConsultRow>
      ))}
    </div>
  );
};

const ConsultRowHeader = () => (
  <Box flexDirection="row" display="flex" alignItems="left" justifyContent="space-between">
    <Typography variant="subtitle2" style={{ flex: 2 }}>
      Doctor
    </Typography>
    <Typography variant="subtitle2" style={{ flex: 2 }}>
      Consult Date
    </Typography>
    <Typography variant="subtitle2" style={{ flex: 1 }}>
      Details
    </Typography>
  </Box>
);

const ConsultRow = (props) => (
  <Box flexDirection="row" display="flex" alignItems="center" justifyContent="space-between">
    <Typography align="left" style={{ flex: 2 }}>
      Dr. {parseFullName(props.customerName).last}
    </Typography>
    <Typography align="left" style={{ flex: 2 }}>
      {moment(props.callDate).format('M/DD, h:mma')}
    </Typography>
    <IconButton variant="contained" color="primary" onClick={props.onClick}>
      <InfoIcon />
    </IconButton>
  </Box>
);

const ConsultDetailedView = ({ consult, classes }) => {
  return (
    <Box>
      <Box>
        <Typography variant="body1">{consult.customerName}</Typography>
        <Typography>{moment(consult.callDate).format('MMM DD, YYYY h:mma')}</Typography>
        <Typography variant="body1">
          <a href={`tel://${consult.phoneNumber}`}>{consult.phoneNumber}</a>
        </Typography>
        <Typography variant="body1">{consult.category}</Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="body2" gutterBottom>
          {consult.caseDescription}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {consult.patientQuestion}
        </Typography>
        <Typography>Call Doctor: {`tel://${consult.phoneNumber}`}</Typography>
      </Box>
      <Typography variant="body1" gutterBottom>
        Attached Records
      </Typography>
      <Box p={1} flexDirection="row" display="flex" alignItems="center" style={{ gap: '10px' }}>
        {consult.medicalRecordData.map((mr, idx) => {
          return <FilePreViewer width={70} classes={classes} key={idx} file={mr} simple />;
        })}
      </Box>
      <Box p={1}>
        <Typography variant="body1">Video URL</Typography>
        <Link target="_blank" rel="noopener" href={consult.videoUrl}>
          {consult.videoUrl}
        </Link>
      </Box>
      <Box p={1}>
        <Typography variant="body1">Videos</Typography>
        {consult.videos
          .filter((video) => Object.keys(video).length)
          .map((mr, idx) => {
            return (
              <div key={idx} style={{ padding: 10 }}>
                {typeof mr === 'string' ? (
                  <div>{mr} - PROCESSING</div>
                ) : (
                  <FilePreViewer width={70} classes={classes} file={mr} simple />
                )}
              </div>
            );
          })}
      </Box>
    </Box>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const D2DExpertPortal = (props) => {
  const { classes, user } = props;
  const [consults, setConsults] = useState([]);
  const [showDetails, setShowDetails] = useState({ show: false, consult: null });
  const [error, setError] = useState(false);

  const fetchConsults = useCallback(
    (auth) => {
      if (!auth || !auth.token) {
        return;
      }

      _fetchUrl({
        method: 'GET',
        path: `doctor/consults`,
        token: auth.token
      }).then((r) => {
        if (r.status === 'ok') {
          setConsults(r.consults);
        } else {
          setError(true);
        }
      });
    },
    [user]
  );

  useEffect(() => {
    props.authPing({
      type: ['D2D_EXPERT'],
      success: (auth) => {
        fetchConsults(auth);
      },
      redirect: props.history.location
    });
  }, []);

  const surveys = useMemo(() => {
    return consults.filter((a) => a.status === 'CALL_COMPLETE');
  }, [consults]);

  const upcomingConsults = useMemo(() => {
    return consults
      .filter((a) => a.status === 'CALL_SCHEDULED')
      .sort((a, b) => a.callDate - b.callDate);
  }, [consults]);

  const nextConsult = useMemo(() => {
    if (upcomingConsults.length > 0) {
      return upcomingConsults[0];
    }
    return null;
  }, [upcomingConsults]);

  const handleClose = () => {
    setShowDetails({ show: false, consult: null });
  };

  const showConsultDetail = (consult) => {
    setShowDetails({
      show: true,
      consult
    });
  };

  return (
    <div className={classes.root}>
      {error && (
        <Alert severity="error" onClose={() => setError(false)}>
          <AlertTitle>Unable to load consults</AlertTitle>
          Try again momentarily or{' '}
          <button
            style={{
              background: 'none',
              border: 'none',
              padding: 0,
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
            onClick={() => {
              fetchConsults();
            }}
          >
            click here
          </button>{' '}
          to retry
        </Alert>
      )}
      <Dialog
        fullScreen
        open={showDetails.show}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Consult Detail
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ marginTop: '60px', padding: '10px' }}>
          {showDetails.consult && (
            <ConsultDetailedView classes={classes} consult={showDetails.consult} />
          )}
        </div>
      </Dialog>
      <Typography variant="h4">Consults</Typography>
      <Typography variant="body1">
        All times are local ({Intl.DateTimeFormat().resolvedOptions().timeZone}){' '}
      </Typography>
      <Divider />
      <Box flexDirection="row" display="flex" flexWrap="wrap" justifyContent="space-between">
        <Box m={1} flexGrow={1}>
          <Typography variant="h6" gutterBottom>
            Next Consult
          </Typography>
          <Paper className={classes.paper}>
            {nextConsult ? (
              <ConsultDetailedView classes={classes} consult={nextConsult} />
            ) : (
              <Typography variant="body1">No Pending Consults</Typography>
            )}
          </Paper>
        </Box>

        <Box m={1} flexGrow={1}>
          <Typography variant="h6" gutterBottom>
            Scheduled Consults
          </Typography>
          <Paper className={classes.paper}>
            <ConsultList onDetailedView={showConsultDetail} consults={upcomingConsults} />
          </Paper>
        </Box>
        <Box m={1} flexGrow={1}>
          <Typography variant="h6" gutterBottom>
            Pending Surveys
          </Typography>
          <Paper className={classes.paper}>
            <SurveyList surveys={surveys} />
          </Paper>
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  authPing: (params) => dispatch(authPing(params))
});

const PortalComponent = withStyles(styles, { withTheme: true })(D2DExpertPortal);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalComponent));
