import React from 'react';
import DocumentTitle from '../../partials/DocumentTitle';

const Terms = () => (
  <div style={{ margin: '20px' }}>
    <DocumentTitle title="Terms of Use" />
    <div style={{ padding: '20px' }}>
      <p>EXPERT OPINION MD, INC.</p>

      <p>SITE TERMS OF USE</p>

      <p>EFFECTIVE APRIL 1, 2019</p>

      <p>CURRENTLY FOR USE ONLY BY CUSTOMERS RESIDING IN THE USA, CANADA AND MEXICO</p>

      <p>
        1. Welcome to the Expert Opinion MD website (this “Site”). The terms and conditions set
        forth below, as they may be hereafter revised or updated from time-to-time by Expert Opinion
        (these “Terms”), govern use of this Site by you (“Customer”) and any or all related services
        provided by, through or in relation to this Site by Expert Opinion, Inc., a State of
        Delaware USA corporation with offices in the State of Washington USA (“Company”), and/or any
        of its independent medical service providers offering services by means of this Site (the
        “Service Providers”). Your use of any portion of this Site or any related services (the
        “Services”) accepts without limitation or qualification these Terms for such use and the
        Services, and creates a binding agreement with Company based on these Terms. Therefore,
        please read these Terms carefully:
      </p>

      <p>
        2. NATURE OF THE SERVICES. This Site provides for medical record reviews and opinions by
        Company’s medical Service Providers for certain diagnoses described elsewhere on this Site.
        Biographies, qualifications and certifications for these Service Providers are also set
        forth elsewhere on this Site, and although each of these Service Providers may be a
        certified doctor or other medical specialist, this Site and the provision of Services
        hereunder shall NOT constitute the provision of online medical services, and use of this
        Site does NOT create a doctor-patient (or medical professional-patient) relationship of any
        type. This Site is designed to help customers understand their options in conjunction with
        their own community doctors by allowing a Service Provider on this Site to perform
        electronic record reviews and to provide online opinions for certain diagnoses addressed on
        this Site. This Site is designed to support a specific need of customers who seek to confirm
        or contest earlier diagnoses as set forth in customers’ own medical records provided by
        their own community doctors, but not replace the relationship that already exists between
        customers of this Site and those community doctors. Opinions provided hereunder do NOT
        constitute medical care or treatment, and such opinions must be reviewed and evaluated by
        both you and your own community doctor, as such doctor shall always be deemed to have full
        responsibility for your diagnosis and care.
      </p>

      <p>
        This site provides for teleconferencing services. All users agree to comply with all laws,
        medical statutes, governing board rules, or other regulations applicable to you as a
        consumer or healthcare provider or other professional using the Website. Customer will never
        have a doctor-patient relationship with Company. Company does not practice medicine or any
        other licensed profession and offers no medical or other professional services. Service
        Provider is solely responsible for all agreements, consents, notices and any other
        interactions with its Customers or other consumers. Service Provider is solely responsible
        for all billing to and collections from Customers or other consumers for services provided.
        Company shall have no liability of any kind to Service Provider concerning any amounts owed
        by any Customer or other consumers to Service Provider.
      </p>
      <p>
        Message frequency varies per user. Message and data rates may apply. Text HELP for help.
        Text STOP to unsubscribe. Carriers are not liable for delayed or undelivered messages.
      </p>

      <p>
        3. USE AND PROVISION OF SERVICES. To use any of the Services provided by this Site, you must
        select a Service Provider on this Site for the diagnoses you wish to have reviewed, if such
        diagnoses are available. You must then register on this Site for such service, pay the
        compensation set forth for that Service Provider and diagnosis in one of the manners
        prescribed by this Site, and promptly arrange for (or help Company arrange for) the
        applicable medical review records to be transferred to the Company, for review,
        summarization and re-transmittal to the applicable Service Provider. By registering for the
        Services, Customer expressly agrees that such registration will constitute Customer’s
        agreement and authorization for Company to receive the applicable medical files from
        Customer’s own community doctor or clinic, and also agrees to promptly complete such
        necessary additional paperwork as Company may reasonably request. Company targets (but does
        not guarantee) a normal completion of the Services within TEN (10) business days after the
        date Company receives a complete, reviewable set of medical records in the English language,
        and Customer has made full payment hereunder. If for whatever reason your medical records
        are not in the English language, Company will endeavor to use medically accredited third
        party translators at Customer’s sole cost and risk, and Customer must pay for the cost of
        such translations prior to Company beginning any of the Services. The compensation payable
        hereunder is full and complete compensation, and is NOT subject to refund under any
        circumstances, unless Company terminates its agreement with you as set forth below,
        including because it or a Service Provider is unable at any time to perform or complete in a
        timely fashion the Services for any reason, or is unable to obtain your applicable medical
        records within THIRTY (30) business days of your registration, payment and receipt of the
        applicable medical records. In such case, Company and Customer will discuss alternative
        Service Providers on this Site, but if there is no agreement for an alternative provision of
        the Services, Customer’s payment already made will be fully refunded. It will be your
        responsibility to pursue any third-party insurance reimbursement at your expense without
        input or guidance from the Company. Company warrants that the Service Provider selected by
        you will be the person actually performing the Services, unless the parties agree otherwise
        should the selected Service Provider be unavailable. By registering for Services hereunder,
        you authorize Company, Service Providers, employees, contractors and agents to review,
        distill, summarize, discuss,copy and transmit your medical files, as Company deems
        appropriate. Opinions provided will be in accordance with Company’s customary guidelines
        from time-to-time, will be electronically signed by the Service Provider, and will be
        delivered by the Service Provider to Company for review and re-transmittal to Customer. As
        the Services are based on written medical record reviews hereunder, any other direct
        communications (written, verbal or otherwise) between Customer and the Service Provider will
        not be permitted, unless expressly approved by Company, in writing, on a case-by-case basis.
      </p>

      <p>
        4. LIMITATIONS OF LIABILITY. TO THE MAXIMUM EXTENT PERMTTED BY APPLICABLE LAW, YOU AGREE TO
        RELEASE, INDEMNIFY AND HOLD HARMLESS COMPANY AND ITS SERVICE PROVIDERS, DIRECTORS, OFFICERS,
        EMPLOYEES, CONTRACTORS AND AGENTS FROM ANY AND ALL LIABILITY RELATING TO THIS SITE AND THE
        PROVISION OF SERVICES HEREUNDER. COMPANY AND THE SERVICE PROVIDERS WILL NOT, UNDER ANY
        CIRCUMSTANCES, BE LIABLE FOR CONSEQUENTIAL, INCIDENTAL, SPECIAL, PUNITIVE, OR ANY DIRECT OR
        INDIRECT DAMAGES (INCLUDING DAMAGES FOR LOST PROFITS OR ANTICIPATED REVENUES, BUSINESS
        INTERRUPTION, OR LOSS OF BUSINESS INFORMATION OR LOSS OF USE) ARISING OUT OF OR RELATED TO
        THIS SITE OR THE SERVICES, OR FOR ANY DAMAGES WHATEVER ARISING OUT OF OR IN CONNECTION WITH
        ANY MALFUNCTIONS, FILE OR SERVICE DELAYS, LOSS OF DATA OR INTERRUPTION OF SERVICE HEREUNDER,
        EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR IF SUCH POSSIBILITY WAS REASONABLY
        FORESEEABLE. UNDER NO CIRCUMSTANCES WILL THE TOTAL CUMULATIVE LIABILITY TO YOU FOR ANY AND
        ALL CLAIMS AND DAMAGES ARISING IN RELATION TO THE USE OF THIS SITE (OR THE SERVICES OF
        COMPANY OR ANY SERVICE PROVIDER(S) HEREUNDER), EXCEED THE GREATER OF $25 OR THE COMPENSATION
        YOU PAID TO COMPANY FOR THE SERVICES HEREUNDER. IF ANY JURISDICTIONS DO NOT ALLOW ANY SUCH
        LIMITATION OR EXCLUSION OF LIABILITY AS SET FORTH IN THESE TERMS, THE LIMITATION OR
        EXCLUSION HEREUNDER WILL BE UPHELD TO THE EXTENT PERMITTED BY APPLICABLE LAW.
      </p>

      <p>
        DISCLAIMERS. COMPANY AND THE SERVICE PROVIDERS DO NOT WARRANT THE ACCURACY, COMPLETENESS OR
        TIMELINESS OF SERVICES PROVIDED OR TO BE PROVIDED HEREUNDER, AND (A) ALL FILES, OPINIONS OR
        OTHER DATA AND INFORMATION PROVIDED BY COMPANY OR ANY SERVICE PROVIDER HEREUNDER ARE
        PROVIDED “AS IS”, “WITH ALL FAULTS”, “AS AVAILABLE” AND WITHOUT WARRANTY OR COMMITMENT OF
        ANY KIND, AND (B) TO THE MAXIMUM EXTENT PERMITTED BY LAW, ANY AND ALL REPRESENTATIONS OR
        WARRANTIES OF ANY KIND WHATSOEVER (INCLUDING EXPRESS, IMPLIED OR STATUTORY WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, ACCURACY OR SATISFACTORY QUALITY),
        WHETHER DEALING WITH DATA OR OTHERWISE BY COMPANY OR ANY SERVICE PROVIDER, ARE EXPRESSLY
        EXCLUDED. NO ORAL OR WRITTEN ADVICE OR INFORMATION PROVIDED BY COMPANY OR ANY SERVICE
        PROVIDER WILL CREATE A WARRANTY, AND CUSTOMER IS NOT ENTITLED TO RELY ON ANY SUCH ADVICE OR
        INFORMATION. THIS SITE AND THE SERVICES ARE NOT WARRANTED TO BE UNINTERRUPTED OR WITHOUT
        ERROR, AND THE INFORMATION AND SERVICES PROVIDED HEREUNDER BY COMPANY OR ANY SERVICE
        PROVIDER MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS FOR WHICH THEY WILL NOT BE
        RESPONSIBLE. ALL SERVICE PROVIDERS PROVIDING SERVICES HEREUNDER ARE INDEPENDENT OF COMPANY,
        AND ARE INDEPENDENT CONTRACTORS. THE SERVICE PROVIDERS ARE NOT EMPLOYEES, AGENTS, PARTNERS,
        CO-VENTURERS OR REPRESENTATIVES OF COMPANY, AND ARE NOT AUTHORIZED TO MAKE REPRESENTATIONS,
        NEGOTIATE OR MAKE CONTRACTS ON BEHALF OF COMPANY, OR IN ANY WAY ACT ON COMPANY’S BEHALF.
        COMPANY WILL NOT BE RESPONSIBLE FOR THE ACTS OR OMISSIONS OF ANY SERVICE PROVIDER UNDER ANY
        CIRCUMSTANCE. YOU UNDERSTAND AND ACCEPT ALL RISKS RELATED TO PROVIDING INFORMATION OR
        DEALING OVER THE INTERNET, AND AGREE THAT COMPANY AND ALL SERVICE PROVIDERS WILL HAVE NO
        LIABILITY WHATEVER FOR ANY BREACH OF INTERNET, DATA OR OTHER SECURITY, UNLESS DUE TO
        COMPANY’S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.
      </p>

      <p>
        5. RIGHTS TO SITE CONTENT. Company retains all title and intellectual property rights
        (including all trademark rights and copyrights) in and to this Site, the Services and all
        related business activity, and all matters relating to them, including the name “Expert
        Opinion MD”, and any suggestions or improvements made to Company or any Service Provider by
        Customer hereunder. These Terms grant you no rights to any such intellectual property,
        except for the limited rights expressly granted herein. No information, data or content from
        this Site will be copied or distributed by Customer or other users of this Site by any
        means, except with the written consent of Company on a case-by-case basis or as expressly
        permitted by these Terms.
      </p>

      <p>
        6. MODIFICATIONS. Company reserves the right, at its sole discretion, to create, maintain,
        expand and/or modify this Site or any portions thereof, and to otherwise modify at any time
        any or all procedures, forms or any provisions of this Site or these Terms (or referenced
        privacy or HIPAA terms or conditions) that may be necessary, in Company’s sole opinion.
        Company also reserves the right, at its sole discretion, to distill, summarize or otherwise
        modify Customer electronic records received, and to modify medical specialties or diagnoses
        encompassed by this Site. From time-to-time, Company reserves the right to change this Site
        and these Terms at its sole discretion. In the event of any such change, Company will inform
        you by posting the revised Terms on this Site, and setting out their effective date. Those
        changes will go into effect on the effective date set out near the top of the revised Terms.
        By continuing to use this Site or any of the Services, you accept unconditionally and agree
        to all such changes in the revised Terms. If you do not agree to such changes, you must
        discontinue use of this Site.
      </p>

      <p>
        7. NO UNLAWFUL OR PROHIBITED USE. This Site is provided to you solely for use in obtaining
        the Services from Company and the Service Providers hereunder. As a condition to your use of
        this Site, you agree and warrant that you will not use this Site for any purpose that is
        unlawful or prohibited by these Terms. You will not use this Site in any manner that could
        damage, overburden, impair or disable this Site, interfere with any other person’s use of
        this Site, or violate or infringe upon any person’s intellectual property rights, privacy or
        other legal rights. You agree to not obtain or attempt to obtain any materials or
        information through any means not intentionally made available or provided for through this
        Site, including by the use of robots or data mining, or similar gathering or extraction
        tools. You agree to indemnify Company and all Service Providers for all direct and indirect
        damages resulting from your failure to comply with these Terms, including those in this
        section.
      </p>

      <p>
        8. TERMINATION RIGHTS. The Services to be provided by means of this Site may change from
        time-to-time, and Company reserves the right at any time to terminate any of the Services
        for which you may have registered by means of this Site for any or no reason by giving you
        notice of such termination to your email, and providing a refund of the sum paid by you for
        the terminated Service, if any. Company also reserves the right to terminate its agreement
        with you for the Services and provide such refund because it is unable at any time to
        perform or complete the Services for any reason, is unable to receive your applicable
        medical records within THIRTY (30) business days of your registration and payment, or if
        Company questions your credit card payment for any reason. The foregoing is full and final
        settlement for any such termination by Company.
      </p>

      <p>
        9. PRIVACY. Company transmits and stores your data in accordance with all applicable laws,
        and is HIPAA-compliant. Company accepts only encrypted documents from customers, transmits
        data via SSL encryption, and stores data in a HIPAA-compliant fashion with a third party
        online service provider using encryption-at-rest. You agree to the use of your data in
        accordance with Company’s privacy policies (as they may exist from time-to-time), and – if
        you become aware of any authorized use of your password or of your account, you agree to
        notify Company immediately in writing. In order to help protect your data and privacy, you
        agree to not share your Site password with others, and to protect its use.
      </p>

      <p>
        10. CONTACTS. As part of the registration you make on this Site, you may provide Company
        with an email address or other address, and in such event, you agree to receive
        communications from us at that email or other address, and also agree that Company’s email
        communications are deemed to be in writing. Any email communications to Company should be
        sent to the contact address set forth elsewhere on this Site.
      </p>

      <p>
        11. LAW AND ARBITRATION. These Terms will be interpreted in accordance with the laws of the
        State of Washington USA, excluding conflict of laws provisions. Subject to applicable laws
        and to the provisions below dealing with equitable relief, any controversy, claim and/or
        dispute arising out of or relating to these Terms and/or the Services or the breach hereof
        or subject matter hereof (including any action in tort or any question as to the existence
        of these Terms) will be referred to and finally and exclusively settled by binding
        arbitration in Seattle, Washington USA in accordance with the then-existing Arbitration
        Rules of the American Arbitration Association (“AAA”), and judgment upon the award rendered
        by the AAA may be entered in any court having applicable jurisdiction. Written notice of
        demand for arbitration will be given to the other parties and to the AAA within ONE (1) year
        after the controversy, claim or dispute has arisen or been barred. All controversies, claims
        and/or disputes will be resolved on the basis of document submission alone by ONE (1)
        arbitrator selected by mutual agreement of the parties or, failing that agreement within 45
        days after written notice demanding arbitration, by the AAA. All decisions hereunder will be
        in writing, and the arbitrator will provide reasons for the decision. If any arbitration or
        action at law or in equity is necessary to enforce or interpret the terms of these Terms or
        to protect the rights obtained under these Terms, the prevailing party will be entitled to
        such reasonable attorneys' fees and costs as the arbitrator may award. Customer acknowledges
        that a breach of the intellectual property or Site use provisions of these Terms would cause
        Company irreparable harm, and in the event of such a breach, Company will be entitled to
        injunctive and other equitable relief at any location or court, without posting bond or
        proving that damages would not be an adequate remedy. CUSTOMER HEREBY IRREVOCABLY WAIVES ANY
        RIGHT IT MAY HAVE TO A JURY TRIAL, AND TO JOIN CLAIMS WITH THOSE OF OTHERS IN THE FORM OF A
        CLASS ACTION OR SIMILAR PROCEEDING, AND AGREES THAT ALL CLAIMS ARISING IN RELATION TO THIS
        SITE, THE SERVICES OR THESE TERMS MUST BE INDIVIDUALLY ASSERTED BY MEANS OF THIS
        ARBITRATION. .
      </p>

      <p>
        12. CONCLUDING PROVISIONS. As used in these Terms, except to the extent the context
        otherwise requires, (1) the meanings of defined terms will be equally applicable to both the
        singular and plural forms of the terms defined; (2) references to agreements and other
        documents will be deemed to include all subsequent amendments and other written
        modifications expressly provided for hereunder; (3) any titles or headings are for
        convenience of reference only, and will not affect the construction or interpretation of
        these Terms; (4) the words “hereof” and “hereunder” and the like refer to these Terms as a
        whole, and not merely to the specific section or clause in which the respective word
        appears; (5) the words “including” and “excluding” and the like will be deemed to always be
        followed by the words “without limitation”; (6) any reference to “days”, “months” or
        “quarters” will mean calendar days, months or quarters, unless otherwise expressly stated;
        (7) the words “will”, “must” and “shall” are used interchangeably herein, with the words
        “will” and “must” always meaning the obligatory “shall”; and (8) the words “you” and “your”
        refer to Customer. No provision of these Terms will be construed against or interpreted to
        the disadvantage of any of the parties by any court or other authority by reason of that
        party having drafted or proposed such provision. Company and Service Providers will NOT be
        deemed to be in default hereunder to the extent that any delay or failure in the performance
        of its obligations results from any of the following circumstances: Acts of God, acts of
        civil or military authority, labor disturbances, strikes, lockouts, fires or explosions,
        earthquakes, floods or bad weather, communication or computer failures or delays, illness or
        any other cause beyond its reasonable control. Customer represents and warrants that it has
        fully familiarized itself with these Terms; that the acceptance, agreement to, delivery and
        performance of these Terms by has been duly authorized, does not require any additional
        approvals, and that such signing does not violate any law or regulation, or result in a
        breach of, or constitute a default under, any material agreement; and that these Terms are
        (when accepted by Company) a legal, valid and binding obligation upon it, subject to
        applicable laws. Customer represents and warrants that it is of legal age to contract, and
        agrees to do all things reasonably necessary to effectuate the intent and purposes of these
        Terms, and to act in good faith. Each of the parties will perform their obligations
        hereunder as independent parties. These Terms will not create any agency, joint venture or
        partnership relationship, and neither party will have the authority to make representations,
        negotiate or enter into any contract for or on behalf of, or create any obligations for, the
        other party. There are no third party beneficiaries, except as expressly set forth in these
        Terms, and except for the Service Providers (who shall be third party beneficiaries of all
        limitations of liability, warranty disclaimers, indemnities and hold harmless provisions and
        the like referencing them). Any remedies in these Terms available to Company are cumulative
        and in addition to those provided by law, unless otherwise expressly provided. All waivers
        will be in writing. No failure or delay in exercising any right hereunder by Company will
        operate as a waiver, unless expressly provided otherwise, nor will any partial exercise
        thereof preclude any further exercise of rights hereunder by Company. If any provision of
        these Terms is held to be unenforceable to any extent, it will nonetheless be enforced to
        the fullest extent allowed by law, and the validity and force of the remainder of this
        Agreement will not be affected thereby. The provisions of these Terms dealing with
        liabilities, conflict resolution, governing law, proprietary rights, payments,
        confidentiality, arbitration and other similar types of clauses will survive the expiration
        or termination of these Terms, or any agreement between the parties. No variation or
        modification of these Terms will be valid unless it is in writing and signed by a corporate
        officer of the Company. Each provision of these Terms, including those that provide for a
        limitation of liability, disclaimer of warranties, or exclusion of damages is to allocate
        the risks between the parties. The provisions of these Terms will control all inconsistent
        terms in any business form hereafter supplied by a signatory or any Service Provider, and
        such business form will be null and void with respect to these Terms. This is the entire
        agreement of the parties, and supersedes all prior discussions, negotiations, agreements and
        understandings (verbal or written) with respect to the subject matter of these Terms and
        this Site.
      </p>
    </div>
  </div>
);

export default Terms;
