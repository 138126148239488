import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';

import { connect } from 'react-redux';
import { clearNotifications } from '../store/actions';

const styles = (theme) => ({
  message: {
    color: theme.palette.common.white
  }
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

class Notification extends Component {
  static defaultProps = {
    defaultDuration: 5000
  };
  static propTypes = {
    classes: PropTypes.object.isRequired,
    clearNotifications: PropTypes.func.isRequired,
    defaultDuration: PropTypes.number.isRequired,
    notification: PropTypes.shape({
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      duration: PropTypes.number,
      type: PropTypes.string
    }).isRequired
  };

  renderMessage = (message) => {
    const { classes } = this.props;

    if (Array.isArray(message)) {
      return message.map((message, index) => (
        <div key={index} className={classes.message} id="message-id">
          {message}
        </div>
      ));
    } else {
      return (
        <span className={classes.message} id="message-id">
          {message}
        </span>
      );
    }
  };

  render() {
    const { clearNotifications, defaultDuration, notification } = this.props;

    return (
      <Snackbar
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={clearNotifications}>
            <CloseIcon />
          </IconButton>
        ]}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={notification.duration || defaultDuration}
        ContentProps={{ 'aria-describedby': 'message-id' }}
        message={this.renderMessage(notification.message)}
        onClose={clearNotifications}
        open={
          notification.message &&
          (typeof notification.message === 'string'
            ? notification.message.length > 0
            : !!notification.message.length)
        }
      >
        {notification.type && (
          <Alert onClose={clearNotifications} severity={notification.type}>
            {this.renderMessage(notification.message)}
          </Alert>
        )}
      </Snackbar>
    );
  }
}

const mapStateToProps = (state) => ({
  notification: state.notification
});

const mapDispatchToProps = (dispatch) => ({
  clearNotifications: () => dispatch(clearNotifications())
});

const NotificationComponent = withStyles(styles, { withTheme: true })(Notification);
export default connect(mapStateToProps, mapDispatchToProps)(NotificationComponent);
