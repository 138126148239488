import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import { _fetchUrl } from '../../../utils/api';
import styles from '../_styles';

class DisableDoctor extends Component {
  state = {
    submitted: false,
    error: null
  };

  handleDisableDoctor = (event) => {
    event.preventDefault();

    const states = {
      submitting: { submitted: true, error: null },
      complete: { submitted: false, error: null }
    };

    this.setState(states.submitting, async () => {
      try {
        const response = await _fetchUrl({
          method: 'DELETE',
          path: `poe/doctor/${this.props.doctor.id}`,
          token: this.props.token
        });
        if (response.status === 'ok') {
          return this.props.afterDisableDoctor();
        }
        return this.setState({ ...states.complete, error: response.message || response.status });
      } catch (error) {
        return this.setState({ ...states.complete, error });
      }
    });
  };

  render() {
    const { classes, theme, handleClose, doctor } = this.props;
    const { submitted, error } = this.state;
    return (
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle>Disable Doctor</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Are you sure you want to disable this doctor?</Typography>
          <Typography variant="body1" style={{ marginTop: theme.spacing(1) }}>
            <strong>Name</strong>: {doctor.user.name}
          </Typography>
          <Typography variant="body1">
            <strong>Email</strong>: {doctor.user.email}
          </Typography>
          <Typography variant="body1">
            <strong>Specialty</strong>: {doctor.specialty}
          </Typography>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleDisableDoctor}
              disabled={submitted}
            >
              {submitted ? <CircularProgress /> : <span>Disable Doctor</span>}
            </Button>
            {error && (
              <Typography variant="body1" className={classes.error}>
                {error}
              </Typography>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(DisableDoctor));
