import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import { Link } from '../../partials/Link';
import { _fetchUrl } from '../../utils/api';
import { isEmailInvalid } from '../../utils/formValidation';

class Login extends Component {
  state = {
    email: '',
    password: '',
    errors: {},
    showLogin: true
  };

  componentDidUpdate = () => {
    //redirectLoggedInUser();
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
      errors: { ...this.state.errors, [name]: false },
      loginError: false
    });
  };

  handleSubmit = (ev) => {
    ev.preventDefault();

    const email = this.state.email.trim();
    const password = this.state.password.trim();
    let hasError = false;

    if (isEmailInvalid(email)) {
      hasError = true;
      this.setState({
        errors: Object.assign(this.state.errors, {
          email: true
        })
      });
    }

    if (password.length < 1) {
      hasError = true;
      this.setState({
        errors: Object.assign(this.state.errors, {
          password: true
        })
      });
    }

    if (!hasError) {
      const states = {
        submitting: { loginSubmitted: true, loginError: false, disabledUserError: false },
        complete: { loginSubmitted: false, loginError: false, disabledUserError: false }
      };
      this.setState(states.submitting, async () => {
        try {
          const response = await _fetchUrl({
            method: 'POST',
            path: 'auth/login',
            body: { email, password, isMobileApp: window._eo.isMobileApp }
          });

          if (response.status === 'redirect_otp') {
            const { history } = this.props;
            return history.push(`/login/otp?token=${response.token}`);
          }

          if (response.status === 'disabled') {
            return this.setState({ ...states.complete, disabledUserError: true });
          }
          return this.setState({ ...states.complete, loginError: true });
        } catch (error) {
          return this.setState({ ...states.complete, loginError: true });
        }
      });
    } else {
      if (this.state.errors.email) {
        document.getElementById('email').focus();
      } else {
        document.getElementById('password').focus();
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { email, password, errors, loginSubmitted, loginError, disabledUserError } = this.state;

    return (
      <div style={{ maxWidth: '460px' }}>
        <Typography variant="h3" className={classes.headline}>
          Sign In
        </Typography>
        <Typography variant="body1">
          If you are a rep or a doctor seeking guidance access our service through your portal link.
          Email <a href="mailto:care@expertopinion.md">care@expertopinion.md</a> for questions.
        </Typography>
        <form className={classes.form} onSubmit={this.handleSubmit}>
          <FormControl margin="normal" fullWidth={true} error={errors.email}>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              id="email"
              value={email}
              onChange={this.handleChange('email')}
              autoFocus
              inputProps={{ autoComplete: 'username' }}
            />
            {errors.email && <FormHelperText>Email is required</FormHelperText>}
          </FormControl>
          <FormControl margin="normal" fullWidth={true} error={errors.password}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={this.handleChange('password')}
              inputProps={{ autoComplete: 'current-password' }}
            />
            {errors.password && <FormHelperText>Password is required</FormHelperText>}
          </FormControl>

          <div className={classes.buttonRoot}>
            <Button className={classes.button} color="secondary">
              <Link base={'site'} path={'/login/resetpassword'}>
                Forgot password
              </Link>
            </Button>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              type="submit"
              disabled={loginSubmitted}
            >
              {loginSubmitted ? (
                <CircularProgress style={{ height: '26px', width: '26px' }} />
              ) : (
                <span>Log in</span>
              )}
            </Button>
          </div>

          {loginError && (
            <Typography className={classes.error} align="right">
              Invalid username or password
            </Typography>
          )}
          {disabledUserError && (
            <Typography className={classes.error} align="right">
              Error accessing your account. Please contact{' '}
              <a href="mailto://care@expertopinion.md" style={{ color: 'inherit' }}>
                care@expertopinion.md
              </a>{' '}
              for assistance.
            </Typography>
          )}
        </form>
      </div>
    );
  }
}

export default Login;
