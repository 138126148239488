import React, { Component } from 'react';
import GetStarted from './GetStarted';
import { connect } from 'react-redux';

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

class GetStartedContainer extends Component {
  render() {
    return (
      <React.Fragment>
        <GetStarted {...this.props} />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetStartedContainer);
