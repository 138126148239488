import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import { formatPhoneNumber } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  accordionDetails: {
    flexDirection: 'column',
    alignItems: 'left'
  }
}));

const RequestDetails = (props) => {
  const {
    timestamp,
    customer: { name, email, phone, countryCode, adminPhone, adminCountryCode },
    experts,
    nextDay,
    notes,
    reason,
    recordMeeting,
    voiceCall,
    category,
    repEmail,
    caseDescription,
    patientQuestion
  } = props;

  const nationalPhone = useMemo(() => formatPhoneNumber(phone), [phone]);
  const nationalAdminPhone = useMemo(() => formatPhoneNumber(adminPhone), [adminPhone]);

  const formattedCountryCode = useMemo(() => {
    if (!countryCode) {
      return 'N/a';
    }

    return en[countryCode] ? `${countryCode} (${en[countryCode]})` : countryCode;
  }, [countryCode]);

  const formattedAdminCountryCode = useMemo(() => {
    if (!adminCountryCode) {
      return 'N/a';
    }

    return en[adminCountryCode]
      ? `${adminCountryCode} (${en[adminCountryCode]})`
      : adminCountryCode;
  }, [adminCountryCode]);

  const classes = useStyles();

  let orgNotes = notes;

  if (!orgNotes) {
    orgNotes = `${caseDescription} ${patientQuestion}`;
  }

  return (
    <Grid container component={Paper} direction="column" style={{ padding: '1rem' }} spacing={1}>
      <Grid item>
        <Typography variant="h4" align="left">
          Request Details
        </Typography>
      </Grid>
      <Grid item container>
        <Grid item sm>
          <Accordion expanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Customer</Typography>

              <Typography className={classes.secondaryHeading}>{name}</Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetails }}>
              <Typography variant="body1">Email: {email}</Typography>
              <Typography variant="body1">
                Phone: {nationalPhone ? nationalPhone : phone}
              </Typography>
              <Typography variant="body1">Country Code: {formattedCountryCode}</Typography>
              <Typography variant="body1">
                Admin Phone: {nationalAdminPhone ? nationalAdminPhone : adminPhone}
              </Typography>
              <Typography variant="body1">
                Admin Country Code: {formattedAdminCountryCode}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Grid item>
        <Grid direction="column" container justifyContent="center">
          <Grid container justifyContent="flex-start">
            <Typography variant="body1">Rep Email: {repEmail}</Typography>
          </Grid>
          <Divider />
          <Grid container justifyContent="flex-start">
            <Typography variant="body1">Category: {category}</Typography>
          </Grid>
          <Divider />
          <Grid container justifyContent="flex-start">
            <Typography variant="body1">Reason: {reason}</Typography>
          </Grid>
          <Divider />
          <Grid container justifyContent="flex-start">
            <Typography variant="body1">Voice Call: {voiceCall ? 'True' : 'False'}</Typography>
          </Grid>
          <Divider />
          <Grid container justifyContent="flex-start">
            <Typography variant="body1">
              Record Meeting: {recordMeeting ? 'True' : 'False'}
            </Typography>
          </Grid>
          <Divider />
          <Grid container justifyContent="flex-start">
            <Typography variant="body1">Next Day: {nextDay ? 'True' : 'False'}</Typography>
          </Grid>
          <Divider />
          <Grid container justifyContent="flex-start">
            <Typography variant="body1">
              Timestamp: {moment(timestamp).format('dddd, MMMM Do YYYY, h:mm:ss a')}
            </Typography>
          </Grid>
          <Divider />
        </Grid>
      </Grid>
      <Grid item>
        <Accordion expanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Notes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">{orgNotes}</Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid container item justifyContent="center">
        <Typography variant="h6">Experts</Typography>
      </Grid>
      <Divider />
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {experts.map((expert) => (
                <TableRow key={expert.id}>
                  <TableCell>{expert.email}</TableCell>
                  <TableCell>{expert.id}</TableCell>
                  <TableCell>{expert.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default RequestDetails;
