import React, { useState, useEffect } from 'react';

import { _fetchUrl } from '../../../utils/api';
import Confirm from '../../../utils/confirm';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import ManageProductsTable from './ManageProductsTable';

const ProductGroup = ({ name, doctors, onSelected }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          {doctors.map((doc, idx) => {
            return (
              <FormGroup key={idx} row>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={doc.isChecked}
                      value={doc.id}
                      onChange={onSelected}
                    />
                  }
                  label={doc.name}
                />
              </FormGroup>
            );
          })}
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

function getDoctorsForProduct(doctors, products) {
  return doctors.reduce((acc, doc) => {
    const i = { name: doc.name, id: doc.id, isChecked: products.includes(doc.id) };

    acc.push(i);

    return acc;
  }, []);
}

const ManageProducts = (props) => {
  const [doctors, setDoctors] = useState([]);
  const [products, setProducts] = useState({});
  const [loading, setLoading] = useState(true);

  const { partnerId, token } = props;

  useEffect(() => {
    setLoading(true);

    if (!partnerId) {
      return;
    }

    _fetchUrl({
      method: 'GET',
      path: `partner/${partnerId}?type=portal&cacheControl=nocache`
    }).then((res) => {
      if (res.status === 'ok') {
        setDoctors(res.partner.doctors);
        setProducts(res.partner.products);
        setLoading(false);
      }
    });
  }, [partnerId]);

  const saveProducts = async () => {
    const res = await Confirm('This will update the product information table. Are you sure?');

    if (res === false) {
      return;
    }

    _fetchUrl({
      method: 'PUT',
      path: `poe/partner/${partnerId}/products`,
      token,
      body: {
        products
      }
    }).then((r) => {
      if (r.status === 'ok') {
        props.displayNotification({ type: 'success', message: 'Products Updated' });
        setProducts(r.products);
      }
    });
  };

  const exportProductMatrix = () => {
    _fetchUrl({
      method: 'GET',
      path: `partner/${partnerId}?type=portal`,
      token
    }).then((r) => {
      if (r.status === 'ok') {
        const prods = r.partner.products;
        setProducts(prods);

        const productNames = Object.keys(prods);

        const header = ['#doctors', ...productNames].join(',');
        const data = [header];

        doctors.forEach((doctor) => {
          const line = [`"${doctor.name}"`];

          productNames.forEach((name) => {
            if (products[name].includes(doctor.id)) {
              line.push('x');
            } else {
              line.push('');
            }
          });

          data.push(line.join(','));
        });

        const text = data.join('\n');

        const link = document.createElement('a');
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        link.setAttribute('download', 'product_matrix.csv');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  const doctorClicked = (key) => (e, v) => {
    if (v === true) {
      products[key].push(e.target.value);
    } else {
      products[key] = products[key].filter((i) => i !== e.target.value);
    }

    setProducts({ ...products });
  };

  return loading ? (
    <div style={{ textAlign: 'center' }}>
      <CircularProgress />
    </div>
  ) : (
    <div>
      <div style={{ marginTop: '10px', marginBottom: '40px' }}>
        <div style={{ margin: '10px 0px' }}>
          <Button variant="contained" color="primary" onClick={saveProducts}>
            Publish Product Mapping
          </Button>
          <Button
            style={{ marginLeft: '10px' }}
            variant="contained"
            color="secondary"
            onClick={exportProductMatrix}
          >
            Export Product Matrix
          </Button>
        </div>

        {partnerId &&
          Object.keys(products).map((key) => {
            const productDocs = getDoctorsForProduct(doctors, products[key]);
            return (
              <ProductGroup
                onSelected={doctorClicked(key)}
                key={key}
                name={key}
                doctors={productDocs}
              />
            );
          })}
      </div>

      <div>
        <ManageProductsTable
          partnerId={partnerId}
          token={token}
          products={products}
          setProducts={setProducts}
        />
      </div>
    </div>
  );
};

export default ManageProducts;
