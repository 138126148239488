import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Done from '@material-ui/icons/Done';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
// import GroupAddIcon from '@material-ui/icons/GroupAdd';
// import VideocamIcon from '@material-ui/icons/Videocam';
// import AssignmentIcon from '@material-ui/icons/Assignment';

import Partners from '../shared/Partners';
import PhoneInput, { isValidPhoneNumber } from '../shared/PhoneInput';
import { getCountries } from 'react-phone-number-input';

import { isEmailInvalid } from '../../utils/formValidation';
import isMobile from '../../utils/isMobile';
import { _fetchUrl } from '../../utils/api';
import { guessCountry } from '../../utils/guessCountry';

const HEIGHT = 195 * 0.8,
  WIDTH = 165 * 0.8,
  SM_RATIO = 0.65,
  MEDIA_RATIO = 0.65;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '25px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '5px',
      marginTop: '10px'
    }
  },
  card: {
    width: WIDTH,
    height: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: WIDTH * SM_RATIO
    }
  },
  media: {
    height: HEIGHT * MEDIA_RATIO,
    objectFit: 'fit',
    objectPosition: '50% 20%',
    [theme.breakpoints.down('sm')]: {
      height: HEIGHT * (MEDIA_RATIO * 1.5) * SM_RATIO
    }
  },
  paper: {
    padding: '40px 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 30px'
    }
  }
}));

const FormSection = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Grid item sm={12} md={8} style={{ width: '100%', margin: '0px auto 30px auto' }}>
      <Paper className={classes.paper}>
        <Typography
          gutterBottom
          variant="h4"
          align="center"
          color="primary"
          style={{ marginBottom: '25px' }}
        >
          {title}
        </Typography>
        <Grid container direction="column" spacing={3} style={{ marginBottom: '15px' }}>
          {children}
        </Grid>
      </Paper>
    </Grid>
  );
};

const FormField = ({ id, autoFocus, label, value, onChange, type, error, helperText }) => (
  <Grid item xs={12}>
    <TextField
      id={id}
      error={error}
      helperText={helperText}
      autoFocus={autoFocus}
      InputLabelProps={{ shrink: true }}
      label={label}
      fullWidth
      value={value}
      onChange={onChange}
      type={type || 'text'}
      multiline
    />
  </Grid>
);

const SubmitSuccess = () => (
  <Grid
    container
    direction="column"
    alignItems="center"
    style={{ marginTop: '40px', padding: '10px 25px', textAlign: 'left' }}
  >
    <div>
      <Typography gutterBottom style={{ color: '#000' }} variant="h2">
        Submission Complete
        <Done
          style={{
            color: '#82E67E',
            fontSize: '1em',
            verticalAlign: 'text-bottom',
            marginLeft: '10px',
            marginBottom: '5px'
          }}
        />
      </Typography>

      <Typography id="success" gutterBottom variant="h5">
        You're all set.
      </Typography>
      <Typography gutterBottom variant="h5">
        We will reach out to you shortly.
      </Typography>
      <Typography gutterBottom variant="h5">
        If you have any questions, you can email us at{' '}
        <a href="mailto:care@expertopinion.md">care@expertopinion.md</a>.
      </Typography>
    </div>
  </Grid>
);

const ErrorText = ({ result }) => (
  <Grid
    style={{
      marginBottom: '25px',
      backgroundColor: 'red',
      padding: '10px',
      border: '1px solid #cecece',
      borderRadius: '5px',
      display: result ? 'block' : 'none'
    }}
    item
    xs={12}
  >
    <Typography variant="button" color="secondary">
      {Array.isArray(result) ? result.map((error, idx) => <div key={idx}>{error}</div>) : result}
    </Typography>
  </Grid>
);

const ProductSupportForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [phone, setPhone] = useState('');
  const [rep, setRep] = useState('');
  const [manufacturerName, setManufacturerName] = useState('');
  const [message, setMessage] = useState('');

  const isMobileMemo = useMemo(() => isMobile(), []);
  const countries = useMemo(() => getCountries(), []);

  const PRODUCT_MANUFACTURER = useMemo(() => {
    return [
      'Alcon Surgical',
      'Alcon Vision Care',
      'Bausch & Lomb Surgical',
      'Bausch & Lomb Retina',
      'ZEISS',
      'Glaukos',
      'Other'
    ];
  }, []);

  useEffect(() => {
    setCountry(guessCountry(countries));
  }, [setCountry, countries]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [errorFields, setErrorFields] = useState({});

  //
  // @dev - errorFields will be set numerically, falsy values (primarily undefined/false) will be used to indicate no errors.
  //        Values >=1 will be used as the element key for error message.
  //
  let ERROR_MESSAGE = {
    name: ['Name field is empty'],
    email: ['Email field is empty', 'Invalid Email'],
    phone: ['Phone field is empty', 'Invalid phone number'],
    rep: ['Representative field is empty'],
    manufacturer: ['Manufacturer field is empty'],
    manufacturerOther: ['Manufacturer name field is empty'],
    message: ['Message field is empty']
  };

  // Make index base 1
  ERROR_MESSAGE = new Proxy(ERROR_MESSAGE, {
    get(target, prop) {
      return [undefined, ...target[prop]];
    }
  });

  const handleSubmit = () => {
    onSubmit();
  };

  const submitForm = async (body) => {
    setIsSubmitting(true);

    const result = await _fetchUrl({
      method: 'POST',
      path: 'product-support',
      body: {
        ...body,
        manufacturer: manufacturer === 'Other' ? manufacturerName : manufacturer
      }
    });

    setIsSubmitting(false);

    switch (result.status) {
      case 'ok':
        setSubmitSuccess(true);
        break;
      default:
        setErrorText(result?.errors?.length ? result.errors : result.message);
        break;
    }
  };

  const onSubmit = async () => {
    setErrorText('');

    const formData = {
      name,
      email,
      countryCode: country,
      phone,
      rep,
      manufacturer,
      message
    };

    const errors = {};

    // Ensure form data is not blank, if so set error 1
    // this is the first element in ERROR_MESSAGE thanks to
    // getter proxy.
    Object.keys(formData).forEach((field) => {
      switch (field) {
        case 'phone':
          if (!formData[field]) {
            errors[field] = 1;
          } else if (!isValidPhoneNumber(formData[field])) {
            errors[field] = 2;
          }
          break;
        case 'email':
          if (!formData[field]) {
            errors[field] = 1;
          } else if (isEmailInvalid(formData[field])) {
            errors[field] = 2;
          }
          break;
        case 'manufacturer':
          if (!formData[field]) {
            errors[field] = 1;
          } else if (formData[field] === 'Other' && !manufacturerName) {
            errors['manufacturerOther'] = 2;
          }
          break;
        default:
          if (!formData[field]) {
            errors[field] = 1;
          }
      }
    });

    // No empty fields
    if (Object.keys(errors).length) {
      setErrorFields({ ...errorFields, ...errors });
    }

    // No other kinds of errors
    else if (Object.values(errorFields).every((val) => val === false)) {
      setIsSubmitting(true);
      await submitForm(formData);
      setIsSubmitting(false);
    }
  };

  const classes = useStyles();

  return submitSuccess ? (
    <SubmitSuccess />
  ) : (
    <div className={classes.content}>
      <ErrorText result={errorText} />
      <FormSection title="Speak to an Expert peer">
        <Typography
          gutterBottom
          variant="body1"
          align="left"
          style={{ marginTop: '35px', marginBottom: '15px' }}
        >
          By partnering with Expert Opinion, our Partners are offering HCPs, like yourself, a
          private way to discuss their technology with a qualified and independent peer Expert.
        </Typography>
        <Typography
          gutterBottom
          variant="body1"
          align="left"
          style={{ marginTop: '15px', marginBottom: '15px' }}
        >
          If you are looking to address a specific query relating to one of our Partners now, please
          complete the form below and we will connect you with the right Partner immediately.
        </Typography>
        <Typography
          gutterBottom
          variant="body1"
          align="left"
          style={{ marginTop: '15px', marginBottom: '15px' }}
        >
          If you would like to become an Expert, would like to see another partner here, or have any
          other inquiries, please email care@expertopinion.md.
        </Typography>

        <Typography
          gutterBottom
          variant="h5"
          align="center"
          color="primary"
          style={{ marginTop: '35px', marginBottom: '15px' }}
        >
          Our Partners
        </Typography>

        <Partners compact={true} shadow={false} />
        <Typography
          gutterBottom
          variant="h5"
          align="center"
          color="primary"
          style={{ marginTop: '35px' }}
        >
          Basic Information
        </Typography>
        <FormField
          id="name"
          label={"Doctor's Name"}
          error={!!errorFields.name}
          helperText={errorFields.name ? ERROR_MESSAGE.name[errorFields.name] : ''}
          autoFocus={isMobileMemo ? false : true}
          value={name}
          onChange={(evt) => {
            setErrorFields({ ...errorFields, name: false });
            setName(evt.target.value);
          }}
        />
        <FormField
          id="email"
          label={"Doctor's Email"}
          error={!!errorFields.email}
          helperText={errorFields.email ? ERROR_MESSAGE.email[errorFields.email] : ''}
          value={email}
          onChange={(evt) => {
            setErrorFields({ ...errorFields, email: false });
            setEmail(evt.target.value);
          }}
        />
        <FormControl
          id="phone"
          fullWidth={true}
          style={{ padding: '0 12px', marginTop: '10px', marginBottom: '15px' }}
        >
          <PhoneInput
            onChange={(val) => {
              setErrorFields({ ...errorFields, phone: false });
              setPhone(val);
            }}
            country={country}
            value={phone}
            setCountry={setCountry}
            error={!!errorFields.phone}
            helperText={errorFields.phone ? ERROR_MESSAGE.phone[errorFields.phone] : ''}
            label={"Doctors's Phone"}
            required
          />
        </FormControl>
        <FormField
          id="rep"
          label={"Representative's Name"}
          error={!!errorFields.rep}
          helperText={errorFields.rep ? ERROR_MESSAGE.rep[errorFields.rep] : ''}
          value={rep}
          onChange={(evt) => {
            setErrorFields({ ...errorFields, rep: false });
            setRep(evt.target.value);
          }}
        />

        <FormControl
          id="manufacturer"
          fullWidth={true}
          error={!!errorFields.manufacturer}
          style={{ padding: '0 12px', marginTop: '10px', marginBottom: '15px' }}
        >
          <InputLabel
            id="manufacturer-select-label"
            error={!!errorFields.manufacturer}
            shrink={true}
            style={{ padding: '0 16px' }}
          >
            Manufacturer
          </InputLabel>

          <Select
            value={manufacturer}
            onChange={(e) => {
              setErrorFields({ ...errorFields, manufacturer: false });
              setManufacturer(e.target.value);
            }}
            error={!!errorFields.manufacturer}
            className={classes.select}
          >
            {PRODUCT_MANUFACTURER.map((product) => (
              <MenuItem key={product} value={product}>
                {product}
              </MenuItem>
            ))}
          </Select>
          {!!errorFields.manufacturer && (
            <FormHelperText>{ERROR_MESSAGE.manufacturer[errorFields.manufacturer]}</FormHelperText>
          )}
        </FormControl>

        {manufacturer === 'Other' && (
          <FormField
            id="manufacturerName"
            label={"Manufacturer's Name"}
            error={!!errorFields.manufacturerOther}
            helperText={
              errorFields.manufacturerOther
                ? ERROR_MESSAGE.manufacturerOther[errorFields.manufacturerOther]
                : ''
            }
            value={manufacturerName}
            onChange={(evt) => {
              setErrorFields({ ...errorFields, manufacturerOther: false });
              setManufacturerName(evt.target.value);
            }}
          />
        )}

        <Typography
          gutterBottom
          variant="h5"
          align="center"
          color="primary"
          style={{ marginTop: '45px' }}
        >
          How can we help?
        </Typography>
        <FormField
          id="help"
          error={!!errorFields.message}
          helperText={errorFields.message ? ERROR_MESSAGE.message[errorFields.message] : ''}
          value={message}
          onChange={(evt) => {
            setErrorFields({ ...errorFields, message: false });
            setMessage(evt.target.value);
          }}
          type="tel"
          multiline
        />
      </FormSection>
      <div style={{ textAlign: 'center', marginBottom: '75px' }}>
        <Button
          id="submit"
          onClick={handleSubmit}
          style={{ color: 'white' }}
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          color="primary"
        >
          {isSubmitting ? <CircularProgress style={{ color: 'primary' }} /> : <span>Submit</span>}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  products: state.webData.products,
  doctors: Object.keys(state.webData.doctors).reduce((acc, doctor) => {
    //Only show platform doctors
    if (
      state.webData.doctors[doctor].status &&
      state.webData.doctors[doctor].status === 'platform'
    ) {
      acc[doctor] = state.webData.doctors[doctor];
    }
    return acc;
  }, {})
});

export default connect(mapStateToProps)(ProductSupportForm);
