/* Taken from: https://docs.trackjs.com/tracker/framework-integrations#reactredux */
import { TrackJS } from 'trackjs';

const trackJsLogger = (store) => (next) => (action) => {
  try {
    return next(action);
  } catch (error) {
    if (process.env.REACT_APP_EO_ENV !== 'dev') {
      if (store.getState) {
        TrackJS.track({ state: store.getState(), error });
      } else {
        TrackJS.track({ error });
      }
    }
  }
};

export default trackJsLogger;
