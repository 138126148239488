import React, { useState } from 'react';

import { Box, Button, FormGroup, FormControlLabel, Checkbox, TextField } from '@material-ui/core';

import { isEmailInvalid } from '../../../utils/formValidation';
import PhoneInput, { isValidPhoneNumber } from '../../shared/PhoneInput';

import Confirm from '../../../utils/confirm';

const params = new URLSearchParams(window.location.search);

const ContactInfo = (props) => {
  const { contactInfo, defaultCountry, countries, classes, onNext, onBack, labels } = props;

  const [email, setEmail] = useState(contactInfo.email || params.get('doctorsEmail') || '');
  const [country, setCountry] = useState(contactInfo.country || '');
  const [phone, setPhone] = useState(contactInfo.phone || params.get('doctorsPhone') || '');
  const [adminCountry, setAdminCountry] = useState(contactInfo.adminCountry || '');
  const [adminPhone, setAdminPhone] = useState(contactInfo.adminPhone || '');
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState({});
  const [showPhoneWarning, setShowPhoneWarning] = useState(false);

  function handleBack() {
    onBack({ country, adminCountry: adminCountry || defaultCountry, email, phone, adminPhone });
  }

  function handleNext() {
    let errors = {};

    if (email === '') {
      errors['email'] = 'Email is required';
    } else if (isEmailInvalid(email)) {
      errors['email'] = 'Invalid email address';
    }

    if (!phone) {
      errors['phone'] = 'Phone is required';
    }

    if (!adminPhone) {
      errors['adminPhone'] = 'Admin phone is required';
    }

    if (Object.entries(errors).length !== 0) {
      setError(errors);
      return;
    }

    //warn the user if the phone number comes back invalid. Let them go forward if they don't bother to fix it.
    if (!isValidPhoneNumber(phone)) {
      if (!showPhoneWarning) {
        setShowPhoneWarning(true);
        return Confirm(
          'Double check your phone number is correct, before continuing to the next step.',
          false
        );
      }
    }

    setError({});

    onNext({
      countryCode: country || defaultCountry,
      adminCountryCode: adminCountry || defaultCountry,
      email,
      phone,
      adminPhone
    });
  }

  return (
    <React.Fragment>
      <Box mt={1} style={{ marginBottom: '16x' }}>
        <PhoneInput
          id="phone"
          onChange={setPhone}
          country={country}
          defaultCountry={defaultCountry}
          value={phone}
          setCountry={setCountry}
          countries={countries}
          error={error.phone !== undefined && error.phone !== ''}
          helperText={error.phone}
          label="Mobile Phone"
          variant="outlined"
          required
        />
      </Box>

      <TextField
        id="email"
        fullWidth
        margin="normal"
        style={{ marginBottom: '24px' }}
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        label="Your Email"
        error={error.email !== undefined && error.email !== ''}
        helperText={error.email}
        required
      />

      <Box mt={1} style={{ marginBottom: '24px' }}>
        <PhoneInput
          id="adminphone"
          onChange={setAdminPhone}
          country={adminCountry}
          defaultCountry={defaultCountry}
          value={adminPhone}
          setCountry={setAdminCountry}
          countries={countries.data}
          error={error.adminPhone !== undefined && error.adminPhone !== ''}
          helperText={error.adminPhone}
          label="Clinic / Admin Phone"
          variant="outlined"
          required
        />
      </Box>

      <FormGroup style={{ marginTop: '24px', marginBottom: '8px' }}>
        <FormControlLabel
          control={
            <Checkbox
              id="confirm"
              style={{ alignSelf: 'flex-start', padding: '0px 8px' }}
              checked={confirm}
              color="primary"
              value={confirm}
              onChange={(e) => {
                setConfirm(e.target.checked);
              }}
              name="confirm"
            />
          }
          label={labels.indemnity}
        />
      </FormGroup>

      <div className={classes.buttonArea}>
        <Button
          style={{ marginRight: '8px' }}
          variant="outlined"
          color="primary"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          id="submit"
          color="primary"
          variant="contained"
          disabled={!confirm}
          onClick={handleNext}
        >
          Request Consult
        </Button>
      </div>
    </React.Fragment>
  );
};

//todo: add a field to send schedule request to my admin

export default ContactInfo;
