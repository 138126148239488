import React from 'react';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { _fetchUrl } from '../../utils/api';

const SUBMISSION_STATES = {
  SUBMITTING: 'SUBMITTING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS'
};

const RegisterAccount = (props) => {
  const { afterLogin } = props;

  const [email, setEmail] = React.useState('');
  const [code, setCode] = React.useState('');
  const [submissionState, setSubmissionState] = React.useState('');
  const [error, setError] = React.useState('');

  const [password, setPassword] = React.useState({
    password1: '',
    password2: ''
  });

  const passwordError = React.useMemo(() => {
    const { password1, password2 } = password;

    if (password1 === '' && password2 === '') {
      return 'Set a password.';
    }

    if (password1 !== password2) {
      return "Passwords don't match.";
    }

    return '';
  }, [password]);

  const registerButtonDisabled = React.useMemo(() => {
    if (passwordError !== '' || code === '' || email === '') {
      return true;
    }

    return false;
  }, [passwordError, code, email]);

  React.useEffect(() => {
    if (submissionState !== SUBMISSION_STATES.SUBMITTING) {
      return;
    }

    _fetchUrl({
      method: 'POST',
      path: 'auth/register',
      body: { email, code, password: password.password1 }
    })
      .then((res) => {
        if (res.status === 'ok') {
          afterLogin({
            email: res.email,
            name: res.name,
            token: res.token,
            type: res.type,
            userId: res.userId,
            doctorId: res.doctorId,
            patientId: res.patientId
          });

          setSubmissionState(SUBMISSION_STATES.SUCCESS);
        } else {
          setSubmissionState(SUBMISSION_STATES.ERROR);
          setError('Unable to register. Try again.');
        }
      })
      .catch(() => {
        setError('Unable to register. Try again.');
        setSubmissionState(SUBMISSION_STATES.ERROR);
      });
  }, [submissionState, email, code, password.password1, afterLogin]);

  const handleRegister = () => {
    if (registerButtonDisabled) {
      return;
    }

    setSubmissionState(SUBMISSION_STATES.SUBMITTING);
  };

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="column" style={{ gap: '12px' }}>
        <Typography variant="h3">Register Your Account</Typography>
        <Typography variant="body1">
          Welcome to Expert Opinion. To set up your account, enter in your email, invite code, and
          password. Click "Register" to set up and login to your account.
        </Typography>

        {error !== '' && (
          <Alert severity="error" style={{ textAlign: 'left' }}>
            <AlertTitle>Unable to Register.</AlertTitle>
            <p>We were unable to register your account. Check the following, and try again:</p>
            <ul>
              <li>You have the correct invite code. It is case sensitive.</li>
              <li>You are using the same email address we have on file.</li>
              <li>You have been instructed to register your account.</li>
            </ul>
            If the problem persists after verifying the above, contact{' '}
            <a href="mailto://care@expertopinion.md">care@expertopinion.md</a> for help.
          </Alert>
        )}

        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
        />

        <TextField
          label="Invite Code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          fullWidth
          helperText="This code is provided to you by Expert Opinion"
        />

        <TextField
          label="New Password"
          helperText={passwordError}
          value={password.password1}
          onChange={(e) => setPassword({ ...password, password1: e.target.value })}
          fullWidth
          type="password"
        />

        <TextField
          label="New Password (again)"
          value={password.password2}
          onChange={(e) => setPassword({ ...password, password2: e.target.value })}
          fullWidth
          type="password"
        />

        <Box display="flex" flexDirection="row">
          <Box flexGrow={1}></Box>
          <Button
            onClick={handleRegister}
            disabled={registerButtonDisabled}
            variant="outlined"
            color="primary"
          >
            {submissionState === SUBMISSION_STATES.SUBMITTING ? (
              <CircularProgress style={{ height: '26px', width: '26px' }} />
            ) : (
              <span>Register</span>
            )}
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default RegisterAccount;
