import React, { Fragment, useCallback, useMemo, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import isMobile from '../../utils/isMobile';

import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';

const FormField = ({ autoFocus, label, value, onChange, type, error, helperText, multiline }) => (
  <Grid item xs={12}>
    <TextField
      error={error}
      helperText={helperText}
      autoFocus={autoFocus}
      InputLabelProps={{ shrink: true }}
      label={label}
      fullWidth
      value={value}
      onChange={onChange}
      type={type || 'text'}
      multiline={multiline}
    />
  </Grid>
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ServiceProviderAgreement = ({
  agreed,
  setAgreed,
  signedAuthority,
  setSignedAuthority,
  classes,
  name,
  handleBack,
  handleNext
}) => {
  const [errorFields, setErrorFields] = useState({});

  // @dev - errorFields will be set numerically, falsy values (primarily undefined/false) will be used to indicate no errors.
  //        Values >=1 will be used as the element key for error message.

  let ERROR_MESSAGE = {
    signedAuthority: ['Name field is empty', `Name mismatch - does not match ${name}`]
  };

  // Make index base 1
  ERROR_MESSAGE = new Proxy(ERROR_MESSAGE, {
    get(target, prop) {
      return [undefined, ...target[prop]];
    }
  });

  const [open, setOpen] = useState(false);

  const isMobileMemo = useMemo(() => isMobile(), []);

  const frameHeight = useMemo(() => {
    return isMobileMemo ? 'calc(100vh - 300px)' : '100vh';
  }, [isMobileMemo]);

  const Frame = useCallback(
    () => (
      <iframe
        title="spa"
        style={{ width: '100%', height: frameHeight }}
        src="https://docs.google.com/document/d/e/2PACX-1vSX3F1rLiPoSgD8K0b_8QjfTSpaWdngMsxJO9Ug1mpw9eNnFaiKUn7GLJktTKhWEQ/pub?embedded=true"
      />
    ),
    [frameHeight]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Grid container>
      <Grid item md={8} sm={12}>
        <Paper>
          <Box
            my={2}
            p={2}
            display="flex"
            flexDirection="column"
            style={{ gap: '16px' }}
            width={'100%'}
          >
            <Typography variant="body1">
              Review the Service Provider Agreement. To sign, type your name, click the box, and
              click next.
            </Typography>

            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    Service agreement
                  </Typography>
                </Toolbar>
              </AppBar>
              <div
                style={{
                  marginTop: '60px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  padding: '10px'
                }}
              >
                <Frame />
                <Typography variant="h6">
                  <EditIcon /> Sign Here
                </Typography>
                <FormControl component="fieldset">
                  <FormField
                    label="Your Full Name"
                    error={!!errorFields.signedAuthority}
                    helperText={
                      errorFields.signedAuthority
                        ? ERROR_MESSAGE.signedAuthority[errorFields.signedAuthority]
                        : ''
                    }
                    autoFocus={true}
                    value={signedAuthority}
                    onChange={(evt) => {
                      setErrorFields({ ...errorFields, signedAuthority: false });
                      setSignedAuthority(evt.target.value);
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agreed}
                        color="primary"
                        onChange={(event) => setAgreed(event.target.checked)}
                      />
                    }
                    label="I agree to the terms of the Expert Opinion's Service Provider Agreement"
                  />
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (signedAuthority.toLowerCase() !== name.toLowerCase()) {
                      setErrorFields({ ...errorFields, signedAuthority: 2 });
                    } else {
                      setOpen(false);
                    }
                  }}
                  className={classes.button}
                  disabled={!agreed || !signedAuthority}
                >
                  Continue
                </Button>
              </div>
            </Dialog>

            {isMobileMemo ? (
              <Fragment>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                  className={classes.button}
                  disabled={!!(agreed || signedAuthority)}
                >
                  {agreed || signedAuthority ? 'SIGNED' : 'Service Agreement'}
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <Frame />
                <Typography variant="h6">
                  <EditIcon /> Sign Here
                </Typography>
                <FormControl component="fieldset">
                  <FormField
                    label="Your Full Name"
                    error={!!errorFields.signedAuthority}
                    helperText={
                      errorFields.signedAuthority
                        ? ERROR_MESSAGE.signedAuthority[errorFields.signedAuthority]
                        : ''
                    }
                    autoFocus={true}
                    value={signedAuthority}
                    onChange={(evt) => {
                      setErrorFields({ ...errorFields, signedAuthority: false });
                      setSignedAuthority(evt.target.value);
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agreed}
                        color="primary"
                        onChange={(event) => setAgreed(event.target.checked)}
                      />
                    }
                    label="I agree to the terms of the Expert Opinion's Service Provider Agreement"
                  />
                </FormControl>
              </Fragment>
            )}

            <Box display="flex" flexDirection="row">
              <Button variant="outlined" onClick={handleBack} className={classes.button}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (signedAuthority.toLowerCase() !== name.toLowerCase()) {
                    setErrorFields({ ...errorFields, signedAuthority: 2 });
                  } else {
                    handleNext();
                  }
                }}
                className={classes.button}
                disabled={!agreed || !signedAuthority}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ServiceProviderAgreement;
