import React from 'react';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { _fetchUrl } from '../../utils/api';

const SUBMISSION_STATES = {
  SUBMITTING: 'SUBMITTING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS'
};

const ResetPassword = (props) => {
  const { afterLogin } = props;

  const [email, setEmail] = React.useState('');
  const [code, setCode] = React.useState('');
  const [submissionState, setSubmissionState] = React.useState('');
  const [error, setError] = React.useState('');
  const [requestError, setRequestError] = React.useState('');
  const [requestStatus, setRequestStatus] = React.useState('');

  const [password, setPassword] = React.useState({
    password1: '',
    password2: ''
  });

  const passwordError = React.useMemo(() => {
    const { password1, password2 } = password;

    if (password1 === '' && password2 === '') {
      return 'Set a password.';
    }

    if (password1 !== password2) {
      return "Passwords don't match.";
    }

    return '';
  }, [password]);

  const buttonDisabled = React.useMemo(() => {
    if (passwordError !== '' || code === '' || email === '') {
      return true;
    }

    return false;
  }, [passwordError, code, email]);

  React.useEffect(() => {
    if (submissionState !== SUBMISSION_STATES.SUBMITTING) {
      return;
    }

    _fetchUrl({
      method: 'POST',
      path: 'auth/password',
      body: { email, accessCode: code, password: password.password1 }
    })
      .then((res) => {
        if (res.status === 'ok') {
          afterLogin({
            email: res.email,
            name: res.name,
            token: res.token,
            type: res.type,
            userId: res.userId,
            doctorId: res.doctorId,
            patientId: res.patientId
          });

          setSubmissionState(SUBMISSION_STATES.SUCCESS);
        } else {
          setSubmissionState(SUBMISSION_STATES.ERROR);
          setError('Unable to reset password. Try again.');
        }
      })
      .catch(() => {
        setError('Unable to reset password. Try again.');
        setSubmissionState(SUBMISSION_STATES.ERROR);
      });
  }, [submissionState, afterLogin, email, password.password1]);

  const handleSubmit = () => {
    if (buttonDisabled) {
      return;
    }

    setSubmissionState(SUBMISSION_STATES.SUBMITTING);
  };

  const handleRequest = () => {
    if (!email) {
      setRequestError('Email is required');
      return;
    }

    _fetchUrl({
      method: 'POST',
      path: 'auth/password/accesscode',
      body: {
        email: email
      }
    }).then((res) => {
      console.log(res);
      if (res.status === 'ok') {
        setRequestStatus('Reset Code requested. Check your email for the code.');
      }
    });
  };

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="column" style={{ gap: '12px' }}>
        <Typography variant="h3">Reset Your Password</Typography>
        <Typography variant="body1">
          Reset your password by requesting a reset code to your email. Once you have that code,
          fill out the form to set your new password.
        </Typography>

        {error !== '' && (
          <Alert severity="error" style={{ textAlign: 'left' }}>
            <AlertTitle>Unable to Reset Your Password.</AlertTitle>
            <p>We were unable to reset your password. Check the following, and try again:</p>
            <ul>
              <li>You have the correct reset code. It is case sensitive.</li>
              <li>Your reset code hasn't expired. It is only valid for 15 minutes.</li>
              <li>You are using the same email address we have on file.</li>
            </ul>
            If the problem persists after verifying the above, contact{' '}
            <a href="mailto://care@expertopinion.md">care@expertopinion.md</a> for help.
          </Alert>
        )}

        <TextField
          error={requestError !== ''}
          label="Email"
          value={email}
          required
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          helperText={requestStatus || requestError}
        />

        <Box display="flex" flexDirection="row">
          <Box flexGrow={1}></Box>
          <Button disabled={email === ''} onClick={handleRequest} variant="text" color="primary">
            Request Reset Code
          </Button>
        </Box>

        <TextField
          label="Reset Code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          fullWidth
          required
        />

        <TextField
          label="New Password"
          helperText={passwordError}
          value={password.password1}
          onChange={(e) => setPassword({ ...password, password1: e.target.value })}
          fullWidth
          type="password"
          required
        />

        <TextField
          label="New Password (again)"
          value={password.password2}
          onChange={(e) => setPassword({ ...password, password2: e.target.value })}
          fullWidth
          type="password"
          required
        />

        <Box display="flex" flexDirection="row">
          <Box flexGrow={1}></Box>
          <Button
            onClick={handleSubmit}
            disabled={buttonDisabled}
            variant="outlined"
            color="primary"
          >
            {submissionState === SUBMISSION_STATES.SUBMITTING ? (
              <CircularProgress style={{ height: '26px', width: '26px' }} />
            ) : (
              <span>Reset Password</span>
            )}
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ResetPassword;
