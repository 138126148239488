/* eslint-disable no-useless-escape */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const styles = (theme) => ({
  root: {
    width: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
});

const Expander = (props) => (props.open ? <ExpandLess /> : <ExpandMore />);

class EOList extends React.Component {
  state = {
    open: -1
  };

  handleClick = (idx) => () => {
    if (this.state.open === idx) {
      idx = -1;
    }

    this.setState(() => ({ open: idx }));
  };

  render() {
    const { options, classes, onSelected, title, initialValue } = this.props;
    let { open } = this.state;

    return (
      <List
        component="nav"
        className={classes.root}
        subheader={
          <ListSubheader disableGutters color="primary" component="div">
            {title}
          </ListSubheader>
        }
      >
        {Object.keys(options)
          .sort()
          .map((cat, idx) => (
            <Fragment key={idx}>
              <ListItem disableGutters button onClick={this.handleClick(idx)}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'h5' }}
                  style={{ textTransform: 'capitalize' }}
                  primary={cat}
                />
                <Expander open={idx === open} />
              </ListItem>
              <Collapse in={idx === open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {options[cat]
                    .sort((a, b) =>
                      /\: general/i.test(a.label)
                        ? -1
                        : /\: general/i.test(b.label)
                          ? 1
                          : a.label.localeCompare(b.label)
                    )
                    .map((item, idx) => (
                      <ListItem
                        selected={item.value === initialValue}
                        key={idx}
                        button
                        className={classes.nested}
                        onClick={onSelected(item.value)}
                      >
                        <ListItemText
                          primaryTypographyProps={{ variant: 'h6' }}
                          style={{ textTransform: 'capitalize' }}
                          primary={item.label}
                        />
                      </ListItem>
                    ))}
                </List>
              </Collapse>
            </Fragment>
          ))}
      </List>
    );
  }
}

EOList.propTypes = {
  options: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onSelected: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  initialValue: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(EOList);
