import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

import Account from './Account';
import Profile from './Profile';
import ServiceProviderAgreement from './ServiceProviderAgreement';
import Payment from './Payment';
import MobileApp from './MobileApp';

import { _fetchUrl } from '../../utils/api';
import { guessTimeZone } from '../../utils/guessCountry';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  errorText: {
    backgroundColor: theme.palette.error.light,
    marginBottom: '20px',
    padding: '10px',
    border: '1px solid #cecece',
    borderRadius: '5px'
  },
  stepper: {
    backgroundColor: theme.palette.background.primary
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  content: {
    padding: '5px',
    width: '100%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main
  },
  body1: {
    fontSize: '1rem',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: '300',
    lineHeight: '1.5',
    letterSpacing: '0.00938em',
    marginTop: '18px'
  }
}));

function getSteps() {
  return ['Account Information', 'Service Provider Agreement', 'Profile Information'];
}

const Onboard = ({ match }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const { token } = match.params;

  const [errorArray, setErrorArray] = useState([]);

  // Account information
  const [name, setName] = useState('');
  const [password1, setPassword1] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  // SPA
  const [agreed, setAgreed] = useState(false);
  const [signedAuthority, setSignedAuthority] = useState('');
  // Profile information
  const [avatar, setAvatar] = useState(''); // replace
  const [image, setImage] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [title, setTitle] = useState('');
  const [phone, setPhone] = useState('');
  const [timezone, setTimezone] = useState(guessTimeZone() || '');
  const [phoneCountry, setPhoneCountry] = useState('');
  const [bio, setBio] = useState('');
  const [bioLink, setBioLink] = useState('');
  const [specialties, setSpecialties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  // Payment information
  const [price, setPrice] = useState(900);

  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [tokenUsed, setTokenUsed] = useState(false);

  const loadSpecialties = () => {
    const response = _fetchUrl({
      method: 'GET',
      path: `product/specialties/${token}`
    });

    response.then((res) => {
      if (res.status === 'ok') {
        setSpecialties(res.specialties);
        setLoadingError(false);
        setLoading(false);
      } else if (res.status === 'Onboarding has already completed') {
        setTokenUsed(true);
        setLoading(false);
      } else {
        setLoadingError(true);
        setLoading(false);
        console.error('Invalid response', res);
      }
    });

    response.catch((err) => {
      setLoadingError(true);
      setLoading(false);
      console.error('err', err);
    });
  };

  const submitForm = () => {
    setSubmitting(true);

    const formData = new FormData();

    formData.append('avatar', avatar);
    formData.append('token', token);
    formData.append('name', name);
    formData.append('password', password1);
    formData.append('agreed', agreed);
    formData.append('signedAuthority', signedAuthority);
    formData.append('specialty', specialty);
    formData.append('title', title);
    formData.append('phone', phone);
    formData.append('phoneCountry', phoneCountry);
    formData.append('bio', bio);
    formData.append('bioLink', bioLink);
    formData.append('timezone', JSON.stringify(timezone));
    formData.append('price', price);

    const response = _fetchUrl({
      method: 'POST',
      path: `auth/onboard`,
      body: formData,
      fileUpload: true
    });

    response.then((res) => {
      if (res.status === 'ok') {
        setSubmitted(true);
        setLoadingError(false);
        setLoading(false);
      } else {
        setLoading(false);
        if (res.status === 'error') {
          setErrorArray(res.errors);
        } else {
          console.error('Invalid response', res);
        }
      }

      setSubmitting(false);
    });

    response.catch((err) => {
      setLoadingError(true);
      setLoading(false);
      setSubmitting(false);
      console.error('err', err);
    });
  };

  useEffect(() => {
    loadSpecialties();
  }, []);

  const ErrorText = ({ array }) => (
    <div style={{ padding: '20px 20px 0 20px', display: array.length ? 'block' : 'none' }}>
      <Grid className={classes.errorText} item xs={12}>
        <ul>
          {array.map((el, idx) => (
            <li key={idx}>
              <Typography>{el}</Typography>
            </li>
          ))}
        </ul>
      </Grid>
    </div>
  );

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Account
            classes={classes}
            name={name}
            setName={setName}
            password1={password1}
            setPassword1={setPassword1}
            password2={password2}
            setPassword2={setPassword2}
            handleNext={handleNext}
          />
        );
      case 1:
        return (
          <ServiceProviderAgreement
            classes={classes}
            agreed={agreed}
            setAgreed={setAgreed}
            signedAuthority={signedAuthority}
            setSignedAuthority={setSignedAuthority}
            name={name}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case 2:
        return loadingError ? (
          <Button
            variant="contained"
            color="secondary"
            disabled={loading}
            onClick={() => {
              setLoading(true);
              loadSpecialties();
            }}
          >
            Reload profile form
          </Button>
        ) : loading ? (
          <div>Loading...</div>
        ) : (
          <Profile
            classes={classes}
            specialties={specialties}
            avatar={avatar}
            setAvatar={setAvatar}
            image={image}
            setImage={setImage}
            specialty={specialty}
            setSpecialty={setSpecialty}
            title={title}
            setTitle={setTitle}
            phone={phone}
            setPhone={setPhone}
            timezone={timezone}
            setTimezone={setTimezone}
            bio={bio}
            setBio={setBio}
            bioLink={bioLink}
            setBioLink={setBioLink}
            phoneCountry={phoneCountry}
            setPhoneCountry={setPhoneCountry}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case 3:
        return (
          <Payment
            classes={classes}
            price={price}
            setPrice={setPrice}
            submitting={submitting}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      submitForm();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /*
  const handleReset = () => {
    setActiveStep(0);
  };
  */

  return token ? (
    submitted ? (
      <MobileApp classes={classes} name={name} setName={setName} />
    ) : (
      <div className={classes.root}>
        <ErrorText array={errorArray} />
        {!tokenUsed && !loading && (
          <Box px={3} pt={3}>
            <Typography gutterBottom variant="h3">
              Create Your Profile
            </Typography>
            <Typography gutterBottom variant="h6">
              Welcome to Expert Opinion. Complete the 3-step finish setting up your account.
            </Typography>
          </Box>
        )}

        <Backdrop className={classes.backdrop} open={submitting || loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        {tokenUsed ? (
          <div style={{ margin: '30px' }}>
            <Alert style={{ paddingTop: '20px' }} severity="error">
              <AlertTitle>Could not load onboarding form</AlertTitle>
              <div className={classes.body1}>
                <strong>System Response:</strong> <i>Onboarding has already completed</i>
                <p>
                  If you have additional questions, reach out to{' '}
                  <a href="mailto://abb@expertopinion.md">Aidan</a>.
                </p>
              </div>
            </Alert>
          </div>
        ) : loading ? (
          <div></div>
        ) : (
          <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>{getStepContent(index, classes)}</StepContent>
              </Step>
            ))}
          </Stepper>
        )}
      </div>
    )
  ) : (
    <Redirect to="/404" />
  );
};

export default Onboard;
