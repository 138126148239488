export const pickFile = (config, cb) => {
  config = Object.assign(
    {
      multiple: false
    },
    config
  );

  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.style.display = 'none';

  if (config.multiple) input.setAttribute('multiple', config.multiple);
  if (config.type) input.setAttribute('accept', config.type);

  input.onchange = (e) => {
    let path = e.composedPath ? e.composedPath() : e.path;
    cb(path[0].files.length === 1 ? path[0].files[0] : path[0].files);
  };

  document.body.appendChild(input);
  input.click();
  document.body.removeChild(input);
};
