import React, { Component, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ProductSelect from '../../partials/ProductSelect';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';

import DoctorList from './DoctorList';
import DocumentTitle from '../../partials/DocumentTitle';

import { productToUrlKey, urlParamsToKey } from '../../utils/productUrls';

const styles = (theme) => ({
  root: {
    fontWeight: theme.typography.fontWeightLight,
    '@media (max-width: 650px)': {
      fontSize: '1.2em'
    }
  },
  list: {
    marginTop: '75px',
    '@media (max-width: 650px)': {
      marginTop: '20px'
    }
  }
});

// https://stackoverflow.com/a/12646864
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

class ExpertList extends Component {
  state = {
    tabState: this.props.match.params && this.props.match.params.name ? 1 : 0
  };

  onTabChanged = (event, value) => this.setState({ tabState: value });

  productSelected = (product) =>
    this.setState({ tabState: product ? 1 : 0 }, () => {
      const url = `/experts/${
        product ? productToUrlKey(product) : this.props.match.params.specialty
      }`;
      if (this.props.history.pathname !== url) {
        this.props.history.push(url);
      }
    });

  render() {
    const { tabState } = this.state;
    const { products, doctors, urls, user } = this.props;
    const { params } = this.props.match;

    if (Object.keys(products).length === 0) {
      return null;
    }

    let doctorsForProduct = [];

    const productForUrl =
      products[urls[urlParamsToKey(params.specialty, params.category, params.name)]];

    let [name, category, specialty] = ['', '', params.specialty];

    if (productForUrl) {
      doctorsForProduct = productForUrl.doctors.map((id) => doctors[id]).filter((doc) => doc);
      name = productForUrl.name;
      category = productForUrl.category;
    }

    const productName = (name.toLowerCase() === 'general' ? `${category}: ${name}` : name)
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    let sampleDoctors = [];
    if (doctorsForProduct.length === 0) {
      const docs = Object.keys(doctors).filter(
        (doctor) => doctors[doctor].specialty.toLowerCase() === specialty.toLowerCase()
      );
      shuffleArray(docs);
      sampleDoctors = docs.slice(0, 8).map((key) => this.props.doctors[key]);
    }

    const doctorsHtml = doctorsForProduct.length ? (
      <DoctorList
        productName={productName}
        specialty={specialty}
        match={this.props.match}
        displayNotification={this.props.displayNotification}
        doctors={doctorsForProduct}
        productId={productForUrl.id}
        user={user}
      />
    ) : (
      <Fragment>
        <Typography
          gutterBottom
          className={this.props.classes.root}
          style={{ padding: '25px 10px' }}
          variant="h5"
        >
          {productName
            ? `No experts available for ${productName}.`
            : 'Find an expert by choosing the category from the left.'}
        </Typography>
        {sampleDoctors.length > 0 && (
          <div style={{ pointerEvents: 'none', filter: 'saturate(10%) opacity(25%)' }}>
            <DoctorList
              productName={productName}
              match={this.props.match}
              doctors={sampleDoctors}
              sampleDoctors={true}
            />
          </div>
        )}
      </Fragment>
    );

    const filteredProducts = Object.keys(products)
      .map((id) => products[id])
      .filter((i) => i.specialty === specialty);

    return (
      <div style={{ padding: '20px' }}>
        <DocumentTitle title="Find an Expert" />
        <Typography
          gutterBottom
          className={this.props.classes.root}
          style={{ padding: '5px 10px', textTransform: 'capitalize' }}
          variant="h3"
        >
          Find an Expert in {specialty}
        </Typography>
        <Divider />
        <Grid container>
          <Hidden mdUp>
            <Tabs
              value={tabState}
              onChange={this.onTabChanged}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Expert Topics" />
              <Tab label="Experts" />
            </Tabs>
            <Grid item xs={12}>
              {tabState === 0 && (
                <ProductSelect
                  title={'Pick by Category'}
                  onSelected={this.productSelected}
                  products={filteredProducts}
                  category={category}
                  name={name}
                />
              )}
              {tabState === 1 && doctorsHtml}
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item sm={4}>
              <ProductSelect
                title={'Pick by Category'}
                onSelected={this.productSelected}
                products={filteredProducts}
                category={category}
                name={name}
              />
            </Grid>
            <Grid item sm={8}>
              {doctorsHtml}
            </Grid>
          </Hidden>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ExpertList);
