import React, { Component } from 'react';
import LandingPage from './Landing';
import ProductSupportForm from './ProductSupportForm';
import { connect } from 'react-redux';

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

class ProductSupportFormContainer extends Component {
  state = {
    choice: '',
    select: false
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const select = query.get('select');

    console.log('select', select);

    if (select === 'true') {
      this.setState({ select: true });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.select === true && this.state.choice === '' ? (
          <LandingPage setState={(choice) => this.setState({ choice })} />
        ) : (
          <ProductSupportForm {...this.props} />
        )}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSupportFormContainer);
