import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import DocumentTitle from '../../partials/DocumentTitle';
import { _fetchUrl } from '../../utils/api';
import { guessTimeZoneWithGMT } from '../../utils/guessCountry';
import TimezoneList from '../shared/timezoneList';

const styles = (theme) => {
  const css = {
    root: {
      margin: theme.spacing(2.5),
      marginBottom: 75
    },
    paper: {
      overflowX: 'auto',
      padding: theme.spacing(2)
    },
    error: {
      color: theme.palette.error.main
    }
  };
  return css;
};

class UserSettings extends Component {
  state = {
    password: {
      submitted: false,
      apiError: null,
      errors: {}
    },
    timezone: {
      submitted: false,
      apiError: null,
      errors: {}
    },
    timezoneValue:
      Object.keys(this.props?.user?.timezone || {}).length > 0
        ? this.props?.user?.timezone
        : guessTimeZoneWithGMT()
  };

  componentDidMount() {
    this.props.authPing({
      redirect: this.props.history.location
    });
  }

  handleFormChange = (value) => (event) => {
    this.setState({
      [value]: value === 'timezoneValue' ? event : event.target.value,
      errors: { ...this.state.errors, [value]: false }
    });
  };

  handleSubmitChangeTimezone = (event) => {
    event.preventDefault();

    const { timezoneValue } = this.state;

    let errors = {};
    if (
      !timezoneValue ||
      !Object.keys(timezoneValue || {}).length ||
      timezoneValue === 'Choose a value'
    ) {
      errors = { ...errors, timezone: 'Required' };
    } else if (
      (timezoneValue.code != 'other' && moment.tz.zone(timezoneValue.code) === null) ||
      (timezoneValue.code == 'other' && !timezoneValue.value)
    ) {
      errors = { ...errors, timezone: 'Invalid timezone provided' };
    }

    this.setState((prevState) => ({
      ...prevState,
      timezone: {
        ...prevState.timezone,
        errors
      }
    }));

    if (!Object.keys(errors).length) {
      const token = this.props.user.token;
      const states = {
        submitting: { timezone: { submitted: true, apiError: null, errors: {} } },
        complete: { timezone: { submitted: false, apiError: null, errors: {} } }
      };
      this.setState(
        (prevState) => ({
          ...prevState,
          timezone: {
            ...prevState.timezone,
            ...states.submitting.timezone
          }
        }),
        async () => {
          try {
            const response = await _fetchUrl({
              method: 'PUT',
              path: 'auth/timezone',
              body: { timezone: timezoneValue },
              token
            });
            if (response.status === 'ok') {
              return this.setState(
                (prevState) => ({
                  ...prevState,
                  timezone: {
                    ...prevState.timezone,
                    ...states.complete.timezone
                  }
                }),
                () => {
                  this.props.changeTimezone(timezoneValue);
                  this.props.displayNotification({ message: 'Your timezone has been changed.' });
                }
              );
            }

            return this.setState((prevState) => ({
              ...prevState,
              timezone: {
                ...prevState.timezone,
                ...states.complete.timezone,
                apiError: response.status
              }
            }));
          } catch (error) {
            return this.setState((prevState) => ({
              ...prevState,
              timezone: {
                ...prevState.timezone,
                ...states.complete.timezone,
                apiError: error
              }
            }));
          }
        }
      );
    }
  };

  handleSubmitChangePassword = (event) => {
    event.preventDefault();

    const { oldPassword, newPassword, confirmPassword } = this.state;

    let errors = {};

    if (!oldPassword || !oldPassword.length) {
      errors = { ...errors, oldPassword: 'Required' };
    }
    if (!newPassword || !newPassword.length) {
      errors = { ...errors, newPassword: 'Required' };
    }
    if (!confirmPassword || !confirmPassword.length) {
      errors = { ...errors, confirmPassword: 'Required' };
    }
    if (newPassword !== confirmPassword) {
      errors = { ...errors, confirmPassword: 'Password does not match' };
    }
    if (newPassword === oldPassword) {
      errors = { ...errors, newPassword: 'New password must be different than old password' };
    }

    this.setState({ password: { errors } });

    if (!Object.keys(errors).length) {
      const token = this.props.user.token;
      const states = {
        submitting: { password: { submitted: true, apiError: null, errors: {} } },
        complete: { password: { submitted: false, apiError: null, errors: {} } }
      };
      this.setState(states.submitting, async () => {
        try {
          const response = await _fetchUrl({
            method: 'PUT',
            path: 'auth/password',
            body: { oldPassword, newPassword, confirmPassword },
            token
          });
          if (response.status === 'ok') {
            return this.setState(
              {
                ...this.state,
                password: { ...this.state.password, ...states.complete.password }
              },
              () => {
                this.props.displayNotification({ message: 'Your password has been changed. ' });
              }
            );
          }
          return this.setState((prevState) => ({
            ...prevState,
            password: {
              ...prevState.password,
              ...states.complete.password,
              apiError: response.message
            }
          }));
        } catch (error) {
          return this.setState((prevState) => ({
            ...prevState,
            password: {
              ...prevState.password,
              ...states.complete.password,
              apiError: error
            }
          }));
        }
      });
    }
  };

  render() {
    const { classes, user } = this.props;
    const { password, timezone } = this.state;

    if (!user) {
      return null;
    }

    return (
      <div className={classes.root}>
        <DocumentTitle title={`User Settings`} />
        <Typography gutterBottom variant="h4">
          User Settings
        </Typography>

        <Grid container spacing={1} style={{ marginBottom: '16px' }}>
          <Grid item sm={4}>
            <Paper className={classes.paper}>
              <Typography gutterBottom variant="subtitle1">
                Change Password
              </Typography>
              <form onSubmit={this.handleSubmitChangePassword} noValidate autoComplete="off">
                <TextField
                  type="password"
                  label="Old Password"
                  error={!!password.errors.oldPassword}
                  helperText={password.errors.oldPassword || ' '}
                  className={classes.textField}
                  onChange={this.handleFormChange('oldPassword')}
                  fullWidth={true}
                  margin={'normal'}
                />
                <TextField
                  type="password"
                  label="New Password"
                  error={!!password.errors.newPassword}
                  helperText={password.errors.newPassword || ' '}
                  className={classes.textField}
                  onChange={this.handleFormChange('newPassword')}
                  fullWidth={true}
                  margin={'normal'}
                />
                <TextField
                  type="password"
                  label="Confirm New Password"
                  error={!!password.errors.confirmPassword}
                  helperText={password.errors.confirmPassword || ' '}
                  className={classes.textField}
                  onChange={this.handleFormChange('confirmPassword')}
                  fullWidth={true}
                  margin={'normal'}
                />
                <Button
                  style={{ color: 'white', margin: '10px 0' }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={password.submitted}
                >
                  {password.submitted ? <CircularProgress /> : <span>Change Password</span>}
                </Button>
                {password.apiError && (
                  <Typography variant="body1" className={classes.error}>
                    {password.apiError}
                  </Typography>
                )}
              </form>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item sm={4}>
            <Paper className={classes.paper}>
              <Typography gutterBottom variant="subtitle1">
                Change Timezone
              </Typography>
              <form onSubmit={this.handleSubmitChangeTimezone} noValidate autoComplete="off">
                <FormControl variant="outlined" error={!!timezone.errors.timezone} fullWidth>
                  <TimezoneList
                    selectedCode={this.state.timezoneValue?.code || ''}
                    selectedGMTValue={this.state.timezoneValue?.value || ''}
                    handleChange={this.handleFormChange('timezoneValue')}
                  />
                  <FormHelperText>{timezone.errors.timezone || ''}</FormHelperText>
                </FormControl>
                <Button
                  style={{ color: 'white', margin: '10px 0' }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={timezone.submitted}
                >
                  {timezone.submitted ? <CircularProgress /> : <span>Change Timezone</span>}
                </Button>
                {timezone.apiError && (
                  <Typography variant="body1" className={classes.error}>
                    {timezone.apiError}
                  </Typography>
                )}
              </form>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(UserSettings));
