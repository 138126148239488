import Profile from './Profile';
import { connect } from 'react-redux';

import { displayNotification } from '../../store/actions';

const mapStateToProps = (state, ownProps) => {
  const doctorHandle = ownProps.match.params.doctorHandle;
  const doctorId =
    doctorHandle && state.webData.handles
      ? state.webData.handles[doctorHandle]
      : ownProps.match.params.doctorId;

  return {
    //user: state.auth,
    //urls: state.webData.urls,
    products: state.webData.products,
    doctor: state.webData.doctors[doctorId]
    //doctors: state.webData.doctors
  };
};

const mapDispatchToProps = (dispatch) => ({
  displayNotification: (notification) => dispatch(displayNotification(notification))
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
