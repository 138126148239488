import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';

const EODialog = (props) => {
  const { open, handleClose, handleOkay, title, children, showCancel, fullWidth, maxWidth } = props;

  const handleCloseShim = (evt, reason) => {
    if (reason !== 'backdropClick') {
      handleClose(evt, reason);
    }
  };

  return (
    <Dialog
      fullWidth={fullWidth || true}
      maxWidth={maxWidth || 'xs'}
      open={open}
      onClose={handleCloseShim}
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {showCancel && (
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        )}
        <Button onClick={handleOkay} variant="contained" color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EODialog;
