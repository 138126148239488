import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

const DoctorHandle = ({ handle, handleUpdateDoctorHandle }) => (
  <FormControl fullWidth={true} margin={'normal'}>
    <Typography variant="subtitle1">Handle</Typography>
    <Input value={handle} onChange={(event) => handleUpdateDoctorHandle(event.target.value)} />
  </FormControl>
);

DoctorHandle.propTypes = {
  handle: PropTypes.string.isRequired,
  handleUpdateDoctorHandle: PropTypes.func.isRequired
};

export default DoctorHandle;
