import { TextField } from '@material-ui/core';
import React, { useEffect, forwardRef, useState } from 'react';
import { getCountryCallingCode, formatPhoneNumber } from 'react-phone-number-input';

const Input = (props, ref) => {
  let { inputFocus, setInputFocus, inputCountry, label, variant, error, ...otherProps } = props;
  let quickRef = React.createRef();
  const [value, setValue] = useState(otherProps.value);
  const [callingCode, setCallingCode] = useState('');
  const [firstRender, setFirstRender] = useState(true);
  const [style, setStyle] = useState({});

  useEffect(() => {
    setFirstRender(false);
  }, []);

  useEffect(() => {
    let marginTop = 0;

    if (error) {
      marginTop += 22;
    }

    if (!variant && !label) {
      marginTop += 15;
    }

    setStyle({ marginTop: marginTop + 'px' });
  }, [error, variant, label]);

  useEffect(() => {
    setCallingCode(inputCountry ? getCountryCallingCode(inputCountry) : '');
  }, [inputCountry]);

  // Prevent user from including country code
  useEffect(() => {
    let formattedNumber = formatPhoneNumber(otherProps.value);

    if (formattedNumber) {
      return setValue(formattedNumber);
    }

    // Strip country code
    let phone = otherProps.value.replace(new RegExp('^(' + callingCode + ' )'), '');

    // Handle formatting all 1s for consistancy
    if (inputCountry === 'US' && /^1\d+$/.test(phone)) {
      let manualFormat = '';

      for (let i = 0, len = Math.min(phone.length, 10); i < len; i++) {
        if (i === 2) {
          manualFormat = '(' + manualFormat + phone[i] + ') ';
        } else if (i === 6) {
          manualFormat += '-' + phone[i];
        } else {
          manualFormat += phone[i];
        }
      }

      phone = manualFormat;
    }

    setValue(phone);
  }, [otherProps.value]);

  useEffect(() => {
    if (!firstRender) {
      setValue('');
    }
  }, [inputCountry]);

  useEffect(() => {
    if (inputFocus === true) {
      quickRef.current.querySelector('input').focus();
    }
  }, [inputFocus]);

  return (
    <TextField
      {...otherProps}
      InputLabelProps={variant ? {} : { shrink: true }}
      style={style}
      error={error}
      value={value}
      inputRef={ref}
      ref={quickRef}
      label={label}
      variant={variant}
      onBlur={() => {
        setInputFocus(false);
      }}
    />
  );
};

export default forwardRef(Input);
