import { productToUrlKey } from '../../utils/productUrls';

// Action Constants
const SAVE_WEBDATA = 'SAVE_WEBDATA';

// Action Creators
export const saveWebDataToStore = (webData) => ({
  type: SAVE_WEBDATA,
  webData
});

const initState = {
  products: {},
  subscriptions: {},
  doctors: {},
  handles: {},
  urls: {}
};

// Redux Store Reducers
export function reducer(state = initState, action) {
  switch (action.type) {
    case SAVE_WEBDATA: {
      const { products, subscriptions, doctors, handles } = action.webData;

      return {
        ...state,
        doctors,
        products,
        subscriptions,
        handles,
        urls: Object.keys(products).reduce((acc, id) => {
          acc[productToUrlKey(products[id])] = id;
          return acc;
        }, {})
      };
    }
    default:
      return state;
  }
}
