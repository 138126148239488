import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

const DoctorSpecialty = ({ specialty, specialties }) => (
  <FormControl fullWidth={true} margin={'normal'}>
    <Typography variant="subtitle1">Specialty</Typography>
    <Select
      native
      value={specialty || ''}
      disabled
      inputProps={{ name: 'specialty', id: 'specialty' }}
    >
      <option value="" disabled={true} />
      {specialties.map((specialty) => (
        <option key={specialty} value={specialty}>
          {specialty}
        </option>
      ))}
    </Select>
  </FormControl>
);

DoctorSpecialty.propTypes = {
  specialty: PropTypes.string.isRequired,
  specialties: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
};

export default DoctorSpecialty;
