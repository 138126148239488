import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import Error from '@material-ui/icons/Error';

import { _fetchUrl } from '../../utils/api';
import { isDateInvalid, isUrlInvalid } from '../../utils/formValidation';

import FormField from '../shared/FormField';
import FilePreViewer from '../shared/FilePreViewer';
import Confirm from '../../utils/confirm';

const styles = (theme) => ({
  root: {
    display: 'flex'
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  paperError: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    boxShadow:
      '0px 1px 5px 0px rgba(255,0,0,0.2), 0px 2px 2px 0px rgba(255,0,0,0.14), 0px 3px 1px -2px rgba(255,0,0,0.12)'
  },
  formControl: {
    marginBottom: theme.spacing(2),
    '&:last-of-type': { marginBottom: 0 }
  },
  clearFilesTxt: {
    display: 'inline',
    cursor: 'pointer',
    marginLeft: theme.spacing(1)
  },
  primaryBtnTxt: { color: 'white' },
  icon: {
    color: '#82E67E',
    fontSize: '20em',
    flex: 1
  },
  errorIcon: {
    fontSize: '16px',
    marginRight: '5px'
  },
  error: {
    color: theme.palette.error.main
  },
  file: {
    display: 'block',
    outline: `1px solid ${theme.palette.text.primary}`,
    maxWidth: 200,
    marginBottom: theme.spacing(2),
    '&:last-child': { marginBottom: 0 }
  },
  iconButton: {
    backgroundColor: 'rgba(255, 255, 255, .1)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, .2)'
    }
  }
});

// Validation values - removed 'video/ogg' / 'video/x-flv' due to issue with conversion
const supportedVideoTypes = [
  'video/mp4',
  'video/avi',
  'video/quicktime',
  'video/x-ms-wmv',
  'video/webm',
  'video/mpeg',
  'application/octet-stream',
  'video/x-matroska',
  'video/3gpp',
  'video/3gpp2'
];

const MAX_VIDEO_FILES = process.env.MAX_VIDEO_FILES || 10;
const MAX_VIDEO_SIZE = process.env.MAX_VIDEO_SIZE || 5000000 * 100;

const uncommonType = (file) => {
  // Handle uncommon file extensions
  let ext = file.name.split('.').pop();

  switch (ext) {
    case 'flv':
      return 'video/x-flv';
  }
};

class MedicalRecordFile extends Component {
  state = {};

  updateState = (key, val) => {
    let newVal = undefined;
    if (val) {
      newVal = Array.isArray(val) ? val : [val];
    }
    return this.setState(
      {
        [key]: newVal
      },
      () => this.props.handleFileMount({ key, val: newVal })
    );
  };

  componentDidMount() {
    const { field, removeFileCb } = this.props;

    if (removeFileCb && typeof removeFileCb === 'function') {
      removeFileCb(field.name, this.removeFile);
    }

    if (field.value) {
      return this.updateState(field.name, field.value);
    }
  }

  handleChange = (key, id) => async (event) => {
    const fileInput = document.getElementById(`${key}-input-${id}`);
    let oldFiles = Object.values(fileInput.files);
    const newFiles = Object.values(event.target.files);
    const nonDuplicates = [];

    for (let category in this.state) {
      oldFiles = Object.values(this.state[category]);
    }

    let errors = [];

    for (let file1 of newFiles) {
      let validFile = true;

      // Video file validation
      if (key === 'videoFiles') {
        if (supportedVideoTypes.includes(file1.type || uncommonType(file1))) {
          await new Promise((resolve) => {
            // validate length
            let video = document.createElement('video');
            video.preload = 'metadata';

            // HTML video tag does not support format unable to get metadata of video
            video.onerror = () => {
              // Unable to fetch video length for this particular format - no client side length validation performed
              // console.log('err', err);
              // console.log('video.error.code', video.error.code, 'video.error.message', video.error.message);
              resolve();
            };

            video.onloadedmetadata = function () {
              window.URL.revokeObjectURL(video.src);
              if (video.duration < 5) {
                errors.push(`${file1.name} must be at least 5 seconds`);
                validFile = false;
              }
              resolve();
            };

            video.src = URL.createObjectURL(file1);

            // 1mb * 100 = 100mb limit
            if (file1.size > MAX_VIDEO_SIZE) {
              errors.push(`${file1.name} is too large`);
              validFile = false;
            }
          });
        } else {
          errors.push(`${file1.name} is not supported`);
          validFile = false;
        }
      }

      if (!validFile) {
        oldFiles = oldFiles.filter((file) => file !== file1);
      } else if (oldFiles.every((file2) => file1.name !== file2.name)) {
        nonDuplicates.push(file1);
      }
    }

    oldFiles = [...oldFiles, ...nonDuplicates];

    // if(oldFiles.length > MAX_VIDEO_FILES) {
    //   errors.push(`Max number of files is ${MAX_VIDEO_FILES}`);
    // }

    if (errors.length) {
      return Confirm(
        [
          <strong key={-1}>
            <div>The following errors have occured:</div>
          </strong>,
          <br key={-2} />,
          errors.map((error, idx) => <div key={idx}>{error}</div>)
        ],
        false
      );
    }

    fileInput.value = '';

    this.updateState(key, oldFiles);
  };

  removeFiles = (key, id) => () => {
    document.getElementById(`${key}-input-${id}`).value = '';
    return this.updateState(key, []);
  };

  removeFile = (key, id, idx) => {
    const fileInput = document.getElementById(`${key}-input-${id}`);
    let files = Object.values(fileInput.files);

    for (let category in this.state) {
      files = Object.values(this.state[category]);
    }

    files.splice(idx, 1);
    fileInput.value = '';

    this.updateState(key, files);
  };

  render() {
    const { id, field, classes, errors } = this.props;
    const files = this.state[field.name] || [];

    return (
      <FormControl
        className={classes.formControl}
        error={errors[field.name]}
        style={{ display: 'block' }}
      >
        <Typography
          gutterBottom
          variant="subtitle1"
          className={errors[field.name] ? classes.error : ''}
        >
          {errors[field.name] && <Error className={classes.errorIcon} />}
          {field.label}
          {errors[field.name] && ' (required)'}
          {files.length > 0 && (
            <Typography
              className={classes.clearFilesTxt}
              variant="caption"
              onClick={this.removeFiles(field.name, id)}
            >
              Clear
            </Typography>
          )}
        </Typography>

        {files.length > 0 && (
          <Paper
            className={classes.paper}
            style={{ display: 'flex', flexWrap: 'wrap', gap: '12px' }}
          >
            {Array.from(files).map((file, key) => (
              <div key={`${file.name}-${key}`} style={{ position: 'relative', width: 232 }}>
                <FilePreViewer
                  CloseIcon={() => (
                    <IconButton
                      className={classes.iconButton}
                      aria-label="delete"
                      onClick={() => this.removeFile(field.name, id, key)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  )}
                  file={{
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    url: file.url || URL.createObjectURL(file)
                  }}
                />
              </div>
            ))}
          </Paper>
        )}

        <Button
          id={field.name}
          onClick={(e) => e.currentTarget.nextSibling.click()}
          className={classes.primaryBtnTxt}
          color="primary"
          variant="contained"
          component="span"
        >
          Select {field.type}
        </Button>
        <input
          style={{ display: 'none' }}
          id={`${field.name}-input-${id}`}
          type={'file'}
          accept={field.accept}
          multiple={field.type === 'files'}
          onChange={this.handleChange(field.name, id)}
          ref={(ref) => (this[field.name] = ref)}
        />
      </FormControl>
    );
  }
}

const getValue = (field) => {
  if (field.fields) {
    return field.fields.reduce((acc, field) => {
      if (field.fields) {
        acc[field.name] = field.fields.reduce((acc, f) => {
          acc[f.name] = getValue(f);
          return acc;
        }, {});
        return acc;
      }
      acc[field.name] = field.value;
      return acc;
    }, {});
  }
  return field.value;
};

class MedicalRecord extends Component {
  state = {
    errors: {},
    ...this.props.formFields.categories.reduce(
      (acc, cat) => ({
        ...acc,
        ...cat.fields.reduce((acc, field) => {
          acc[field.name] = getValue(field);
          return acc;
        }, {})
      }),
      {}
    )
  };

  handleFileMount = ({ key, val }) => {
    this[key] = val ? { files: val } : undefined;
    return this.setState({
      errors: {
        ...this.state.errors,
        [key]: undefined
      }
    });
  };

  handleChange =
    ({ key, type }) =>
    (event) => {
      let val = event.target.value;
      if (type === 'checkbox') {
        val = { ...this.state[key], [event.target.value]: event.target.checked };
      }
      return this.setState({
        [key]: val || undefined,
        errors: {
          ...this.state.errors,
          [key]: undefined
        }
      });
    };

  handleSubHeaderChange = ({ header, subHeader, value, fieldName }) =>
    this.setState((prevState) => {
      const newState = {
        ...prevState,
        errors: {
          ...prevState.errors,
          [header]: undefined,
          [fieldName]: undefined
        }
      };

      if (header === subHeader) {
        newState[subHeader] = {
          ...(prevState[subHeader] || {}),
          [fieldName]: value[subHeader][fieldName] || undefined
        };
      } else {
        newState[header] = {
          ...(prevState[header] || {}),
          [subHeader]: {
            ...(prevState[header][subHeader] || {}),
            [fieldName]: value[subHeader][fieldName] || undefined
          }
        };
      }

      return newState;
    });

  handleSubmit = (id, cb) => (event) => {
    event.preventDefault();
    this.setState({ apiResult: false, apiError: null });

    const {
      productId,
      formFields,
      medicalRecordId,
      signedToken,
      token,
      path = 'records',
      afterSave = () => {}
    } = this.props;

    const setFieldError = (field) => {
      hasErrors = true;
      return this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          [field]: true
        }
      }));
    };

    const s3_files = [];

    const validateField = ({ id, field, state = {} }) => {
      let required = !field.optional;
      const stateField = state[field.name];

      switch (field.type) {
        case 'header':
        case 'fileSubHeader':
        case 'subHeader':
          return field.fields.forEach((f) => validateField({ id, field: f, state: stateField }));
        case 'file':
        case 'files': {
          const elementInput = document.getElementById(`${field.name}-input-${id}`);
          const thisInput = this[field.name];

          let files = [];
          if (elementInput && elementInput.files && elementInput.files.length > 0) {
            elementInput.files.forEach((file) => {
              if (field.name === 'videoFiles') {
                if (supportedVideoTypes.includes(file.type || uncommonType(file))) {
                  s3_files.push(file);
                }
              } else {
                files.push(file);
              }
            });
          } else if (thisInput && thisInput.files && thisInput.files.length > 0) {
            thisInput.files.forEach((file) => {
              if (field.name === 'videoFiles') {
                if (supportedVideoTypes.includes(file.type || uncommonType(file))) {
                  s3_files.push(file);
                }
              } else {
                files.push(file);
              }
            });
          }

          // Ignore patientFiles require if videoFiles are present
          if (required && field.name === 'patientFiles') {
            const videoFileInput = document.getElementById(`videoFiles-input-${id}`);
            const thisVideoFileInput = this['videoFiles'];
            let videoFilesHasFile = false;
            if (videoFileInput && videoFileInput.files && videoFileInput.files.length > 0) {
              videoFilesHasFile = true;
            } else if (
              thisVideoFileInput &&
              thisVideoFileInput.files &&
              thisVideoFileInput.files.length > 0
            ) {
              videoFilesHasFile = true;
            }
            required = !videoFilesHasFile;
          }

          if (required && !files.length) {
            return setFieldError(field.name);
          }

          return formBody.push({
            field,
            files: files,
            value: Array.from(files).map((file) => {
              body.append(field.name, file);
              return file.name;
            })
          });
        }
        case 'checkbox':
          if (
            required &&
            (!stateField || !Object.keys(stateField).some((key) => stateField[key]))
          ) {
            return setFieldError(field.name);
          }
          return formBody.push({
            field: { ...field, dataType: 'JSON' },
            value: JSON.stringify(
              field.options.reduce((accumulator, option) => {
                accumulator[option.name] = stateField ? stateField[option.name] : false;
                return accumulator;
              }, {})
            )
          });
        case 'date':
          if (required && isDateInvalid(stateField)) {
            return setFieldError(field.name);
          }
          return formBody.push({ field, value: stateField });
        // Using uri instead of url to prevent default browser validation on url types
        case 'uri':
          if (stateField !== undefined && isUrlInvalid(stateField)) {
            return setFieldError(field.name);
          }
          break;
        default:
          if (required && (!stateField || !stateField.trim().length)) {
            return setFieldError(field.name);
          }
          return formBody.push({ field, value: stateField });
      }
    };

    let hasErrors = false;
    const body = new FormData();
    const formBody = [];

    formFields.categories
      .reduce((acc, category) => acc.concat(category.fields), [])
      .forEach((field) => validateField({ id, field, state: this.state }));

    if (!hasErrors) {
      if (s3_files.length > MAX_VIDEO_FILES) {
        return Confirm(
          [
            <strong key={-1}>
              <div>The following errors have occured:</div>
            </strong>,
            <br key={-2} />,
            <div key={-3}>Exceeded max amount of videos: {MAX_VIDEO_FILES}</div>
          ],
          false
        );
      }

      if (typeof cb === 'function') {
        return cb(formBody, s3_files, this.state, this.removeFile);
      } else {
        body.append('formHeader', JSON.stringify({ medicalRecordId, productId, signedToken }));
        body.append('formBody', JSON.stringify(formBody));
      }

      const states = {
        submitting: { submitted: true, apiResult: false, apiError: null },
        complete: { submitted: false, apiResult: false, apiError: null }
      };

      this.setState(states.submitting, async () => {
        try {
          // Handle other uploads
          const response = await _fetchUrl({
            method: 'POST',
            token,
            path: path,
            body,
            fileUpload: true
          });
          if (response.status === 'ok') {
            if (s3_files && s3_files.length) {
              // setStatusMessage(`Submitting - Case ${i+1} - Videos`);

              try {
                const signedUrlRes = await _fetchUrl({
                  method: 'POST',
                  // token,
                  path: 'records/signed-url',
                  headers: { 'Content-Type': 'application/json' },
                  body: {
                    medicalRecordId,
                    signedToken,
                    files: s3_files.map((file, idx) => ({
                      index: idx,
                      name: file.name,
                      size: file.size,
                      type: file.type
                    }))
                  }
                });

                if (signedUrlRes.status === 'ok') {
                  // Handle video uploads directly
                  for (let idx in signedUrlRes.SIGNED_URLS) {
                    if (s3_files[idx] && signedUrlRes.SIGNED_URLS[idx]) {
                      // Set cors policy
                      // https://s3.console.aws.amazon.com/s3/buckets/dev.userdata.expertopinion.md?region=us-west-2&tab=objects
                      let form = new FormData();

                      Object.entries(signedUrlRes.SIGNED_URLS[idx].fields).forEach(([k, v]) => {
                        form.append(k, v);
                      });

                      form.append('file', s3_files[idx]);

                      try {
                        // Figure out why fetch shows failed error but succeeds (201 empty content? is this cors related?)
                        await window.fetch(signedUrlRes.SIGNED_URLS[idx].url, {
                          method: 'POST',
                          body: form
                        });
                      } catch (err) {
                        console.log('err', err);
                      }
                    } else console.error('Unable to get signed url for', idx);
                  }

                  if (signedUrlRes.errors) {
                    afterSave(signedUrlRes.errors);
                  }
                } else if (signedUrlRes.status === 'error') {
                  afterSave(signedUrlRes.message);
                }
              } catch (e) {
                // setUploadingVideoError(true);
                console.log('error posting video', e);
              }
            }

            this.setState({ ...states.complete, apiResult: true }, () => {
              afterSave(true);
            });
          } else {
            this.setState({ ...states.complete, apiError: response.message });
          }
        } catch (error) {
          this.setState({ ...states.complete, apiError: error });
        }
      });
    }
  };

  formField = ({ medicalRecordId, field, idx, classes, theme, errors, removeFileCb }) => {
    switch (field.type) {
      case 'file':
      case 'files':
        return (
          <MedicalRecordFile
            key={idx}
            id={medicalRecordId}
            field={field}
            classes={classes}
            errors={errors}
            handleFileMount={this.handleFileMount}
            removeFileCb={removeFileCb}
          />
        );
      case 'fileSubHeader':
        return (
          <Paper key={`${field.name}-${idx}`} className={classes.paper}>
            <Typography variant="subtitle1">{field.label}</Typography>
            {field.fields.map((f, fieldIdx) =>
              this.formField({ field: f, idx: fieldIdx, classes, theme, errors, medicalRecordId })
            )}
          </Paper>
        );
      default:
        return (
          <FormField
            key={`${field.name}-${idx}`}
            idx={idx}
            field={field}
            errors={errors}
            handleChange={this.handleChange}
            handleSubHeaderChange={this.handleSubHeaderChange}
            header={field.name}
            initialValue={this.state[field.name]}
          />
        );
    }
  };

  render() {
    const { medicalRecordId, classes, theme, formFields, cb, removeFileCb } = this.props;

    const { errors, submitted, apiError, apiResult } = this.state;

    const formErrors = Object.keys(errors).filter((error) => !!errors[error]);

    return (
      <form onSubmit={this.handleSubmit(medicalRecordId, cb)}>
        {formFields.directions &&
          formFields.directions.map((direction, idx) => (
            <Typography
              key={idx}
              gutterBottom
              variant={direction.variant}
              dangerouslySetInnerHTML={{ __html: direction.content }}
            />
          ))}
        {formFields.categories.map((category, idx) => (
          <Paper key={idx} className={classes.paper}>
            {category.name && (
              <Typography gutterBottom variant="h6" style={{ textTransform: 'capitalize' }}>
                {category.name}
              </Typography>
            )}
            {category.directions &&
              category.directions.map((direction, idx) => (
                <Typography
                  key={idx}
                  gutterBottom
                  variant={direction.variant}
                  dangerouslySetInnerHTML={{ __html: direction.content }}
                />
              ))}
            {category.fields.map((field, idx) =>
              this.formField({ medicalRecordId, field, idx, classes, theme, errors, removeFileCb })
            )}
          </Paper>
        ))}

        <Box my={2} maxWidth={600}>
          <Typography variant="body1">
            I hereby accept that uploaded records and case details may be shared with Expert Opinion
            expert faculty and that I have obtained the requisite patient consent and/or I have the
            legal authorization under the applicable laws of my country of residence to disclose
            information for the protection of the data subject’s physical safety with other
            healthcare professionals.
          </Typography>
        </Box>

        <FormControl fullWidth={true} style={{ marginTop: this.props.theme.spacing(2) }}>
          {cb ? (
            <Button
              className={classes.primaryBtnTxt}
              type="submit"
              variant="contained"
              color="primary"
            >
              Attach
            </Button>
          ) : (
            <Button
              className={classes.primaryBtnTxt}
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitted}
            >
              {submitted ? <CircularProgress /> : <span>Securely Transmit Medical Records</span>}
            </Button>
          )}
          {apiResult && <Typography>Records uploaded!</Typography>}
          {apiError && (
            <Typography className={classes.error}>Records upload error: {apiError}</Typography>
          )}
          {formErrors.length > 0 && (
            <Paper className={classes.paperError}>
              <Typography variant="body1" style={{ color: 'red' }}>
                Please correct the form error{formErrors.length > 1 ? 's' : ''} above.
              </Typography>
            </Paper>
          )}
        </FormControl>
      </form>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(MedicalRecord));
