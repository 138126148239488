import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux';
import { closeFileViewer } from '../../store/actions';

const styles = (theme) => ({
  fileViewerOuterWrapper: {
    position: `fixed`,
    zIndex: 10001,
    left: 0,
    top: 0,
    width: `100%`,
    height: `100%`,
    overflow: `auto`,
    backgroundColor: `rgba(0,0,0,.5)`
  },
  fileViewerInnerWrapper: {
    position: `relative`,
    width: `90%`,
    margin: `${theme.spacing(2)}px auto`,
    textAlign: `center`
  },
  closeBtn: {
    cursor: `pointer`,
    margin: `${theme.spacing(1)}px 0`
  },
  iframe: {
    width: `100%`,
    height: `100vh`,
    border: `none`
  },
  image: {
    width: `100%`
  }
});

class FileViewer extends Component {
  static propTypes = {
    closeFileViewer: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    file: PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }).isRequired
  };

  componentDidMount() {
    window.addEventListener('keydown', this.closeFileViewer);
    document.addEventListener('click', this.closeFileViewer);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.closeFileViewer);
    document.removeEventListener('click', this.closeFileViewer);
  }

  closeFileViewer = (event) => {
    event.preventDefault();
    if (
      event.type === 'keydown' &&
      (event.keyCode === 27 ||
        event.key.toUpperCase() === 'ESCAPE' ||
        event.code.toUpperCase() === 'ESCAPE')
    ) {
      return this.props.closeFileViewer();
    }
    if (
      event.type === 'click' &&
      event.target.id !== 'openFileViewer' &&
      event.target.id !== 'closeFileViewer'
    ) {
      return this.props.closeFileViewer();
    }
  };

  render() {
    const { classes, file, closeFileViewer } = this.props;

    return (
      <div className={classes.fileViewerOuterWrapper}>
        <div className={classes.fileViewerInnerWrapper}>
          <Button
            variant="contained"
            color="primary"
            className={classes.closeBtn}
            onClick={() => closeFileViewer()}
          >
            Close
          </Button>
          {file.type.indexOf('image') !== -1 && (
            <img id="closeFileViewer" src={file.url} className={classes.image} alt={file.name} />
          )}
          {file.type.indexOf('application') !== -1 && (
            <div id="closeFileViewer">
              <iframe src={file.url} className={classes.iframe} title={file.name} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const FileViewerComponent = withStyles(styles, { withTheme: true })(FileViewer);

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  closeFileViewer: () => dispatch(closeFileViewer())
});
export default connect(mapStateToProps, mapDispatchToProps)(FileViewerComponent);
