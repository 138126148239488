import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Done from '@material-ui/icons/Done';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideocamIcon from '@material-ui/icons/Videocam';
import AssignmentIcon from '@material-ui/icons/Assignment';

import DoctorSelect from '../PartnerPortals/shared/DoctorSelect';
import PhoneInput, { isValidPhoneNumber } from '../shared/PhoneInput';
import { getCountries } from 'react-phone-number-input';

import { isEmailInvalid, isDateInvalid } from '../../utils/formValidation';
import isMobile from '../../utils/isMobile';
import { _fetchUrl } from '../../utils/api';
import { guessCountry } from '../../utils/guessCountry';

const HEIGHT = 195 * 0.8,
  WIDTH = 165 * 0.8,
  SM_RATIO = 0.75,
  MEDIA_RATIO = 0.75;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '25px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '5px',
      marginTop: '10px'
    }
  },
  card: {
    width: WIDTH,
    height: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: WIDTH * SM_RATIO
    }
  },
  media: {
    height: HEIGHT * MEDIA_RATIO,
    objectFit: 'fit',
    objectPosition: '50% 20%',
    [theme.breakpoints.down('sm')]: {
      height: HEIGHT * (MEDIA_RATIO * 1.5) * SM_RATIO
    }
  },
  paper: {
    padding: '40px 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 30px'
    }
  }
}));

const FormSection = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Grid item sm={12} md={8} style={{ width: '100%', margin: '0px auto 30px auto' }}>
      <Paper className={classes.paper}>
        <Typography
          gutterBottom
          variant="h4"
          align="center"
          color="primary"
          style={{ marginBottom: '25px' }}
        >
          {title}
        </Typography>
        <Grid container direction="column" spacing={3} style={{ marginBottom: '15px' }}>
          {children}
        </Grid>
      </Paper>
    </Grid>
  );
};

const FormField = ({ autoFocus, label, value, onChange, type, error, helperText, multiline }) => (
  <Grid item xs={12}>
    <TextField
      error={error}
      helperText={helperText}
      autoFocus={autoFocus}
      InputLabelProps={{ shrink: true }}
      label={label}
      fullWidth
      value={value}
      onChange={onChange}
      type={type || 'text'}
      multiline={multiline}
    />
  </Grid>
);

const SubmitSuccess = () => (
  <Grid
    container
    direction="column"
    alignItems="center"
    style={{ marginTop: '40px', padding: '10px 25px', textAlign: 'left' }}
  >
    <div>
      <Typography gutterBottom style={{ color: '#000' }} variant="h2">
        Submission Complete
        <Done
          style={{
            color: '#82E67E',
            fontSize: '1em',
            verticalAlign: 'text-bottom',
            marginLeft: '10px',
            marginBottom: '5px'
          }}
        />
      </Typography>

      <Typography gutterBottom variant="h5">
        You're all set.
      </Typography>
      <Typography gutterBottom variant="h5">
        We will reach out to you shortly.
      </Typography>
      <Typography gutterBottom variant="h5">
        If you have any questions, you can email us at{' '}
        <a href="mailto:care@expertopinion.md">care@expertopinion.md</a>.
      </Typography>
    </div>
  </Grid>
);

const ErrorText = ({ result }) => (
  <Grid
    style={{
      marginBottom: '25px',
      backgroundColor: 'red',
      padding: '10px',
      border: '1px solid #cecece',
      borderRadius: '5px',
      display: result ? 'block' : 'none'
    }}
    item
    xs={12}
  >
    <Typography variant="button" color="secondary">
      {Array.isArray(result) ? result.map((error, idx) => <div key={idx}>{error}</div>) : result}
    </Typography>
  </Grid>
);

const BookExpertForm = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [date, setDate] = useState('');
  const [selectedDoctors, setSelectedDoctors] = useState(new Set());
  const [message, setMessage] = useState('');

  const isMobileMemo = useMemo(() => isMobile(), []);
  const countries = useMemo(() => getCountries(), []);

  useEffect(() => {
    setCountry(guessCountry(countries));
  }, [setCountry, countries]);

  const filteredDoctors = useMemo(() => {
    return Object.keys(props.doctors || []).map((id) => {
      return {
        id,
        bio: props.doctors[id].profile.bio,
        avatar: props.doctors[id].profile.avatar,
        name: props.doctors[id].profile.name
      };
    });
  }, [props.doctors]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [errorFields, setErrorFields] = useState({});

  // @dev - errorFields will be set numerically, falsy values (primarily undefined/false) will be used to indicate no errors.
  //        Values >=1 will be used as the element key for error message.

  let ERROR_MESSAGE = {
    name: ['Name field is empty'],
    email: ['Email field is empty', 'Invalid Email'],
    phone: ['Phone field is empty', 'Invalid phone number'],
    message: ['Message field is empty'],
    date: ['Date field is empty', 'Invalid Date', 'Booking date should be a future date'],
    doctor: ['You must select at least one Expert']
  };

  // Make index base 1
  ERROR_MESSAGE = new Proxy(ERROR_MESSAGE, {
    get(target, prop) {
      return [undefined, ...target[prop]];
    }
  });

  const handleSubmit = () => {
    onSubmit();
  };

  const submitForm = async (body) => {
    setIsSubmitting(true);

    const result = await _fetchUrl({
      method: 'POST',
      path: 'book-expert',
      body: {
        ...body,
        date: body.date.getTime()
      }
    });

    setIsSubmitting(false);

    switch (result.status) {
      case 'ok':
        setSubmitSuccess(true);
        break;
      default:
        setErrorText(result?.errors?.length ? result.errors : result);
        break;
    }
  };

  const onSubmit = async () => {
    setErrorText('');

    const formData = {
      name,
      email,
      countryCode: country,
      phone,
      message,
      date,
      selectedDoctors: [...selectedDoctors]
    };

    const errors = {};

    // Ensure form data is not blank, if so set error 1
    // this is the first element in ERROR_MESSAGE thanks to
    // getter proxy.
    Object.keys(formData).forEach((field) => {
      switch (field) {
        case 'phone':
          if (!formData[field]) {
            errors[field] = 1;
          } else if (!isValidPhoneNumber(formData[field])) {
            errors[field] = 2;
          }
          break;
        case 'email':
          if (!formData[field]) {
            errors[field] = 1;
          } else if (isEmailInvalid(formData[field])) {
            errors[field] = 2;
          }
          break;
        case 'date':
          if (date === null) {
            errors[field] = 1;
          } else if (isDateInvalid(date)) {
            errors[field] = 3;
          }
          break;
        case 'selectedDoctors':
          // Nothing - allow no doctors to be selected
          break;
        default:
          if (!formData[field]) {
            errors[field] = 1;
          }
      }
    });

    // No empty fields
    if (Object.keys(errors).length) {
      setErrorFields({ ...errorFields, ...errors });
    }

    // No other kinds of errors
    else if (Object.values(errorFields).every((val) => val === false)) {
      setIsSubmitting(true);
      await submitForm(formData);
      setIsSubmitting(false);
    }
  };

  const classes = useStyles();

  return submitSuccess ? (
    <SubmitSuccess />
  ) : (
    <div className={classes.content}>
      <ErrorText result={errorText} />
      <FormSection title="Book Expert(s) for my business">
        <Typography
          gutterBottom
          variant="h5"
          align="left"
          color="primary"
          style={{ marginTop: '45px', marginBottom: '40px' }}
        >
          Submit an Expert request for the following:
        </Typography>

        <div style={{ width: '90%', margin: '0 auto' }}>
          <Grid container justifyContent="space-between" spacing={4}>
            <Grid item xs={3}>
              <Grid container style={{ textAlign: 'center', padding: '10px 0' }}>
                <Grid item xs={12}>
                  <RecordVoiceOverIcon color="primary" style={{ fontSize: 40, marginTop: 10 }} />
                </Grid>
                <Grid item xs={12}>
                  Speak at your event (video or in-person)
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container style={{ textAlign: 'center', padding: '10px 0' }}>
                <Grid item xs={12}>
                  <GroupAddIcon color="primary" style={{ fontSize: 50 }} />
                </Grid>
                <Grid item xs={12}>
                  Join an <br />
                  Advisory board
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container style={{ textAlign: 'center', padding: '10px 0' }}>
                <Grid item xs={12}>
                  <VideocamIcon color="primary" style={{ fontSize: 50 }} />
                </Grid>
                <Grid item xs={12}>
                  Have a video call discussion
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container style={{ textAlign: 'center', padding: '10px 0' }}>
                <Grid item xs={12}>
                  <AssignmentIcon color="primary" style={{ fontSize: 40, marginTop: 10 }} />
                </Grid>
                <Grid item xs={12}>
                  Conduct a <br />
                  survey
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <Typography
          gutterBottom
          variant="h5"
          align="left"
          color="primary"
          style={{ marginTop: '45px' }}
        >
          Basic Information
        </Typography>
        <FormField
          label="Contact Name"
          error={!!errorFields.name}
          helperText={errorFields.name ? ERROR_MESSAGE.name[errorFields.name] : ''}
          autoFocus={isMobileMemo ? false : true}
          value={name}
          onChange={(evt) => {
            setErrorFields({ ...errorFields, name: false });
            setName(evt.target.value);
          }}
        />
        <FormField
          label="Contact Email"
          error={!!errorFields.email}
          helperText={errorFields.email ? ERROR_MESSAGE.email[errorFields.email] : ''}
          value={email}
          onChange={(evt) => {
            setErrorFields({ ...errorFields, email: false });
            setEmail(evt.target.value);
          }}
        />
        <FormControl
          fullWidth={true}
          style={{ padding: '0 12px', marginTop: '10px', marginBottom: '15px' }}
        >
          <PhoneInput
            onChange={(val) => {
              setErrorFields({ ...errorFields, phone: false });
              setPhone(val);
            }}
            country={country}
            value={phone}
            setCountry={setCountry}
            error={!!errorFields.phone}
            helperText={errorFields.phone ? ERROR_MESSAGE.phone[errorFields.phone] : ''}
            label="Contact Phone"
            required
          />
        </FormControl>
        <Typography
          gutterBottom
          variant="h5"
          align="left"
          color="primary"
          style={{ marginTop: '50px', marginBottom: '-10px' }}
        >
          Booking date
        </Typography>
        <div style={{ padding: '0 12px' }}>
          <TextField
            label="MM-DD-YYYY"
            id="bookingDate"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            error={!!errorFields.date}
            helperText={errorFields.date ? ERROR_MESSAGE.date[errorFields.date] : ''}
          />
        </div>

        <Typography
          gutterBottom
          variant="h5"
          align="left"
          color="primary"
          style={{ marginTop: '55px' }}
        >
          Select your Expert(s)
        </Typography>
        <div>
          <DoctorSelect
            selected={selectedDoctors}
            setSelected={setSelectedDoctors}
            classes={classes}
            doctors={filteredDoctors}
            pickForMe={false}
            useDoctorNameAsLabel={true}
            maxSize={filteredDoctors.length}
          ></DoctorSelect>
        </div>

        <Typography
          gutterBottom
          variant="h5"
          align="left"
          color="primary"
          style={{ marginTop: '65px' }}
        >
          How can we help?
        </Typography>
        <FormField
          error={!!errorFields.message}
          helperText={errorFields.message ? ERROR_MESSAGE.message[errorFields.message] : ''}
          value={message}
          onChange={(evt) => {
            setErrorFields({ ...errorFields, message: false });
            setMessage(evt.target.value);
          }}
          type="tel"
          multiline
        />
      </FormSection>
      <div style={{ textAlign: 'center', marginBottom: '75px' }}>
        <Button
          onClick={handleSubmit}
          style={{ color: 'white' }}
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          color="primary"
        >
          {isSubmitting ? <CircularProgress style={{ color: 'primary' }} /> : <span>Submit</span>}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  doctors: Object.keys(state.webData.doctors).reduce((acc, doctor) => {
    //Only show platform doctors
    if (
      state.webData.doctors[doctor].status &&
      state.webData.doctors[doctor].status === 'platform'
    ) {
      acc[doctor] = state.webData.doctors[doctor];
    }
    return acc;
  }, {})
});

export default connect(mapStateToProps)(BookExpertForm);
