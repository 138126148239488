import React, { Component, Fragment } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import Error from '@material-ui/icons/Error';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  formControl: {
    marginBottom: theme.spacing(2),
    '&:last-of-type': { marginBottom: 0 }
  },
  clearFilesTxt: {
    display: 'inline',
    cursor: 'pointer',
    marginLeft: theme.spacing(1)
  },
  primaryBtnTxt: { color: theme.palette.common.white },
  error: {
    color: theme.palette.error.main
  },
  errorIcon: {
    fontSize: '16px',
    marginRight: theme.spacing(1)
  },
  label: { fontSize: `${1 + 1 / 3}rem`, whiteSpace: 'nowrap' },
  checkBox: { fontSize: `${1}rem` }
});

const FormHeader = ({
  classes,
  disabled,
  error,
  field,
  handleChange,
  handleSubHeaderChange,
  initialValue,
  theme
}) => (
  <Paper className={classes.paper}>
    <Typography variant="subtitle1">{field.label}</Typography>
    {field.fields.map((f, fieldIdx) => (
      <FormField
        key={fieldIdx}
        classes={classes}
        idx={fieldIdx}
        field={f}
        theme={theme}
        rowsMax={f.rowsMax}
        errors={error}
        handleChange={handleChange}
        disabled={disabled}
        initialValue={initialValue}
        handleSubHeaderChange={handleSubHeaderChange}
        header={field.name}
      />
    ))}
  </Paper>
);

class FormSubheader extends Component {
  state = {
    data: {
      [this.props.header]: {
        [this.props.field.name]: {}
      }
    }
  };

  handleChange =
    ({ key, type }) =>
    (event) => {
      const fieldName = event.target.id || event.target.name;
      let newKeyVal = event.target.value;

      if (type === 'checkbox') {
        newKeyVal = {
          ...this.state.data[this.props.header][this.props.field.name][event.target.value],
          [event.target.value]: event.target.checked
        };
      }

      const data = {
        ...this.state.data,
        [this.props.header]: {
          ...this.state.data[this.props.header],
          [this.props.field.name]: {
            ...this.state.data[this.props.header][this.props.field.name],
            [key]:
              type === 'checkbox'
                ? {
                    ...this.state.data[this.props.header][this.props.field.name][key],
                    ...newKeyVal
                  }
                : newKeyVal
          }
        }
      };
      return this.setState({ data }, () => {
        this.props.handleSubHeaderChange({
          header: this.props.header,
          subHeader: this.props.field.name,
          value: this.state.data[this.props.header],
          fieldName
        });
      });
    };

  render() {
    const { classes, disabled, error, field, handleSubHeaderChange, header, initialValue, theme } =
      this.props;
    return (
      <Paper className={classes.paper}>
        <Typography variant="subtitle1">{field.label}</Typography>
        {field.fields.map((f, fieldIdx) => (
          <FormField
            key={fieldIdx}
            classes={classes}
            disabled={disabled}
            errors={error}
            field={f}
            handleChange={this.handleChange}
            handleSubHeaderChange={handleSubHeaderChange}
            header={header}
            idx={fieldIdx}
            initialValue={
              (initialValue && initialValue[field.name] && initialValue[field.name][f.name]) ||
              initialValue[f.name]
            }
            rowsMax={f.rowsMax}
            theme={theme}
          />
        ))}
      </Paper>
    );
  }
}

const FormCheckbox = ({ classes, idx, field, error, initialValue, handleChange, theme }) => {
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <FormControl
      className={classes.formControl}
      fullWidth={xsOnly || field.options.length > 3}
      style={{ display: xsOnly || field.options.length > 3 ? 'flex' : 'initial' }}
      error={error}
    >
      <Typography variant="subtitle1" className={error ? classes.error : ''}>
        {error && <Error className={classes.errorIcon} />}
        {field.label}
        {field.optional ? ' (Optional)' : ''} {field.helpText} {error && '(required)'}
      </Typography>
      {field.options.map((option, id) => (
        <FormControlLabel
          label={option.label}
          key={id}
          classes={{ label: classes.checkBox }}
          control={
            <Checkbox
              id={field.name}
              onChange={handleChange({ key: field.name, type: field.type, idx })}
              value={option.name}
              checked={Boolean(initialValue && initialValue[option.name])}
              color="primary"
            />
          }
        />
      ))}
    </FormControl>
  );
};

const FormRadio = ({ classes, idx, field, error, initialValue, handleChange, theme }) => {
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <FormControl
      className={classes.formControl}
      fullWidth={xsOnly || field.options.length > 3}
      error={error}
    >
      <Typography variant="subtitle1" className={error ? classes.error : ''}>
        {error && <Error className={classes.errorIcon} />}
        {field.label}
        {field.optional ? ' (Optional)' : ''} {field.helpText}{' '}
        {error && !field.optional && '(required)'}
      </Typography>
      <RadioGroup
        id={field.name}
        name={field.name}
        onChange={handleChange({ key: field.name, type: field.type, idx })}
        value={initialValue || ''}
        style={{ display: field.options.length > 3 ? 'inherit' : 'initial' }}
      >
        {field.options.map((option) => (
          <FormControlLabel
            label={option.label}
            key={option.name}
            classes={{ label: classes.checkBox }}
            value={option.name}
            control={<Radio color="primary" />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
const FormSelect = ({ classes, idx, field, error, initialValue, handleChange }) => (
  <FormControl className={classes.formControl} fullWidth={true} error={error}>
    <Typography variant="subtitle1" className={error ? classes.error : ''}>
      {error && <Error className={classes.errorIcon} />}
      {field.label}
      {field.optional ? ' (Optional)' : ''} {field.helpText}{' '}
      {error && !field.optional && '(required)'}
    </Typography>
    <NativeSelect
      defaultValue={initialValue || ''}
      onChange={handleChange({ key: field.name, type: field.type, idx })}
      input={<Input name={field.name} id={field.name} />}
    >
      <option value="" disabled />
      {field.options.map((option) => (
        <option key={option.name} value={option.name}>
          {option.label}
        </option>
      ))}
    </NativeSelect>
  </FormControl>
);

const FormInput = ({ classes, idx, field, error, handleChange, initialValue }) => (
  <FormControl className={classes.formControl} fullWidth={true} error={error}>
    <InputLabel shrink htmlFor={field.name} formlabelclasses={{ root: classes.label }}>
      {error && <Error className={classes.errorIcon} />}
      {field.label}
      {field.optional ? ' (Optional)' : ''} {field.helpText}{' '}
      {error && !field.optional && '(required)'}
    </InputLabel>
    <Input
      id={field.name}
      type={field.type || 'text'}
      value={initialValue || ''}
      multiline={field.rowsMax > 1}
      rowsMax={field.rowsMax}
      inputProps={{ step: 'any', min: field.min, max: field.max }}
      onChange={handleChange({ key: field.name, type: field.type, idx })}
    />
  </FormControl>
);

const FormDisabled = ({ theme, field, initialValue }) => (
  <Fragment>
    <Typography variant="subtitle1" style={{ marginTop: theme.spacing(2) }}>
      <strong>{field.label}</strong>
    </Typography>
    {(field.type === 'radio' || field.type === 'select') && (
      <Typography variant="body1">
        {field.options.find((option) => option.name === initialValue).label}
      </Typography>
    )}
    {field.type === 'checkbox' &&
      field.options
        .filter((option) => initialValue && initialValue[option.name])
        .map((option) => (
          <Typography key={option.name} variant="body1">
            {option.label}
          </Typography>
        ))}
    {field.type !== 'radio' && field.type !== 'select' && field.type !== 'checkbox' && (
      <Typography variant="body1">{initialValue}</Typography>
    )}
  </Fragment>
);

const FormField = ({
  classes,
  disabled,
  errors,
  field,
  handleChange,
  handleSubHeaderChange,
  header,
  idx,
  initialValue,
  theme
}) => {
  switch (field.type) {
    case 'checkbox':
      return disabled ? (
        <FormDisabled
          key={`${field.name}-${idx}`}
          theme={theme}
          field={field}
          initialValue={initialValue}
        />
      ) : (
        <FormCheckbox
          key={`${field.name}-${idx}`}
          classes={classes}
          disabled={disabled}
          error={errors[field.name]}
          field={field}
          handleChange={handleChange}
          handleSubHeaderChange={handleSubHeaderChange}
          header={header}
          idx={idx}
          initialValue={initialValue}
          theme={theme}
        />
      );
    case 'radio':
      return disabled ? (
        <FormDisabled
          key={`${field.name}-${idx}`}
          theme={theme}
          field={field}
          initialValue={initialValue}
        />
      ) : (
        <FormRadio
          key={`${field.name}-${idx}`}
          classes={classes}
          disabled={disabled}
          error={errors[field.name]}
          field={field}
          handleChange={handleChange}
          handleSubHeaderChange={handleSubHeaderChange}
          header={header}
          idx={idx}
          initialValue={initialValue}
          theme={theme}
        />
      );
    case 'select':
      return disabled ? (
        <FormDisabled
          key={`${field.name}-${idx}`}
          theme={theme}
          field={field}
          initialValue={initialValue}
        />
      ) : (
        <FormSelect
          key={`${field.name}-${idx}`}
          classes={classes}
          disabled={disabled}
          error={errors[field.name]}
          field={field}
          handleChange={handleChange}
          handleSubHeaderChange={handleSubHeaderChange}
          header={header}
          idx={idx}
          initialValue={initialValue}
          theme={theme}
        />
      );
    case 'header':
      return (
        <FormHeader
          key={`${field.name}-${idx}`}
          classes={classes}
          disabled={disabled}
          error={errors}
          field={field}
          handleChange={handleChange}
          handleSubHeaderChange={handleSubHeaderChange}
          header={header}
          idx={idx}
          initialValue={initialValue}
          theme={theme}
        />
      );
    case 'subHeader':
      return (
        <FormSubheader
          key={`${field.name}-${idx}`}
          classes={classes}
          disabled={disabled}
          error={errors}
          field={field}
          handleChange={handleChange}
          handleSubHeaderChange={handleSubHeaderChange}
          header={header}
          idx={idx}
          initialValue={initialValue}
          theme={theme}
        />
      );
    default:
      return disabled ? (
        <FormDisabled
          key={`${field.name}-${idx}`}
          theme={theme}
          field={field}
          initialValue={initialValue}
        />
      ) : (
        <FormInput
          key={`${field.name}-${idx}`}
          classes={classes}
          disabled={disabled}
          error={errors[field.name]}
          field={field}
          handleChange={handleChange}
          handleSubHeaderChange={handleSubHeaderChange}
          header={header}
          idx={idx}
          initialValue={initialValue}
          theme={theme}
        />
      );
  }
};

export default withStyles(styles, { withTheme: true })(FormField);
