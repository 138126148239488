import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import TimezoneList from './timezoneList';

const DoctorTimezone = ({ timezone, handleUpdateDoctorTimezone }) => (
  <FormControl fullWidth={true} margin={'normal'}>
    <TimezoneList
      label={true}
      selectedCode={timezone?.code || timezone || ''}
      selectedGMTValue={timezone?.value || ''}
      handleChange={handleUpdateDoctorTimezone}
    />
  </FormControl>
);

DoctorTimezone.propTypes = {
  timezone: PropTypes.object.isRequired,
  handleUpdateDoctorTimezone: PropTypes.func.isRequired
};

export default DoctorTimezone;
