import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

const DoctorPhone = ({ phone, handleUpdateDoctorPhone }) => (
  <FormControl fullWidth={true} margin={'normal'}>
    <Typography variant="subtitle1">Phone</Typography>
    <Input value={phone} onChange={(event) => handleUpdateDoctorPhone(event.target.value)} />
  </FormControl>
);

DoctorPhone.propTypes = {
  phone: PropTypes.string.isRequired,
  handleUpdateDoctorPhone: PropTypes.func.isRequired
};

export default DoctorPhone;
