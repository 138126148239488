import React from 'react';
import Typography from '@material-ui/core/Typography';

import { parseFullName } from 'parse-full-name';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import { makeStyles } from '@material-ui/core/styles';

import { Link } from '../../partials/Link';
import { textSplit } from '../../utils/textSplit';

const HEIGHT = 195,
  WIDTH = 165,
  MEDIA_RATIO = 0.75,
  XS_RATIO = 0.6, // .8 to keep 2 columns on screens like iphoneSE
  SM_RATIO = 0.7,
  MD_RATIO = 0.9,
  LG_RATIO = 1;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: WIDTH,
    display: 'grid',
    '& > div': {
      display: 'grid'
    },
    [theme.breakpoints.down('lg')]: {
      minHeight: HEIGHT * LG_RATIO,
      width: WIDTH * LG_RATIO
    },
    [theme.breakpoints.down('md')]: {
      minHeight: HEIGHT * MD_RATIO,
      width: WIDTH * MD_RATIO
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: HEIGHT * SM_RATIO,
      width: WIDTH * SM_RATIO
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: HEIGHT * XS_RATIO,
      width: WIDTH * XS_RATIO
    }
  },
  media: {
    height: HEIGHT * MEDIA_RATIO,
    objectFit: 'fit',
    objectPosition: '50% 20%', // 0% would be the same as top aligned. 20% seems to be a good balance between all images
    [theme.breakpoints.down('lg')]: {
      height: HEIGHT * (MEDIA_RATIO * 1) * LG_RATIO
    },
    [theme.breakpoints.down('md')]: {
      height: HEIGHT * (MEDIA_RATIO * 1.1) * MD_RATIO
    },
    [theme.breakpoints.down('sm')]: {
      height: HEIGHT * (MEDIA_RATIO * 1.2) * SM_RATIO
    },
    [theme.breakpoints.down('xs')]: {
      height: HEIGHT * (MEDIA_RATIO * 1.5) * XS_RATIO
    }
  },
  content: {
    padding: '0',
    '&:last-child': {
      padding: '5px 3px'
    }
  },
  doctorList: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: `repeat(auto-fill, minmax(${WIDTH}px, 1fr))`,
    gridTemplateRows: `repeat(auto-fill, minmax(${HEIGHT}px, 1fr))`,
    gridRowGap: '15px',
    justifyContent: 'space-around',
    justifyItems: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(${WIDTH * LG_RATIO}px, 1fr))`,
      gridTemplateRows: `repeat(auto-fill, minmax(${HEIGHT * LG_RATIO}, 1fr))`
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(${WIDTH * MD_RATIO}px, 1fr))`,
      gridTemplateRows: `repeat(auto-fill, minmax(${HEIGHT * MD_RATIO}, 1fr))`
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(${WIDTH * SM_RATIO}px, 1fr))`,
      gridTemplateRows: `repeat(auto-fill, minmax(${HEIGHT * SM_RATIO}, 1fr))`
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(${WIDTH * XS_RATIO}px, 1fr))`,
      gridTemplateRows: `repeat(auto-fill, minmax(${HEIGHT * XS_RATIO}, 1fr))`
    }
  }
}));

const DoctorListItem = (props) => {
  const classes = useStyles();

  return (
    <Link className={classes.root} base={'site'} path={`/d/${props.doctorId}`}>
      <Card>
        <CardMedia
          className={classes.media}
          component="img"
          alt={`${props.name} profile image`}
          image={props.img}
        />
        <CardContent className={classes.content}>
          <Typography align="center" variant="subtitle2">
            {textSplit(props.name, 14)}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

const DoctorList = (props) => {
  const classes = useStyles();
  const doctors = React.useMemo(() => {
    return Object.keys(props.doctors)
      .map((key) => {
        const doctor = props.doctors[key];

        return {
          id: key,
          name: doctor.profile.name,
          img: doctor.profile.avatar,
          last: parseFullName(doctor.profile.name.replace(/\s([A-Z])\s/, ' $1. ')).last
        };
      })
      .sort((a, b) => {
        if (a.last > b.last) {
          return 1;
        } else if (a.last < b.last) {
          return -1;
        } else {
          return 0;
        }
      });
  }, [props.doctors]);

  return (
    <div className={classes.doctorList}>
      {doctors.map((doctor) => (
        <DoctorListItem doctorId={doctor.id} key={doctor.id} name={doctor.name} img={doctor.img} />
      ))}
    </div>
  );
};

export default DoctorList;

// export default withStyles(styles, { withTheme: true })(DoctorList);
