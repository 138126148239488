import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import partners_ags_1x from './logos/ags@1x.png';
import partners_ags_2x from './logos/ags@2x.png';
import partners_owl_1x from './logos/owl@1x.png';
import partners_owl_2x from './logos/owl@2x.png';

export default () => {
  const useStyles = makeStyles(() => ({
    logos: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      textAlign: 'center',
      '& > div img': {
        width: '50%',
        height: '50%',
        margin: '0 auto'
      }
    }
  }));

  const classes = useStyles();

  return (
    <Paper style={{ width: '100%' }}>
      <div className={`${classes.logos}`}>
        <div>
          <img
            src={partners_ags_1x}
            srcSet={`${partners_ags_1x} 1x, ${partners_ags_2x} 2x`}
            alt="American Glaucoma Society Logo"
          />
        </div>
        <div>
          <img
            src={partners_owl_1x}
            srcSet={`${partners_owl_1x} 1x, ${partners_owl_2x} 2x`}
            alt="Ophthalmic World Leaders Logo"
          />
        </div>
      </div>
    </Paper>
  );
};
