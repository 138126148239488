import { connect } from 'react-redux';
import LoginRoot from './LoginRoot';
import { afterLogin } from '../../store/actions';

const mapStateToProps = (state) => {
  return {
    user: state.auth
  };
};

const mapDispatchToProps = (dispatch) => ({
  afterLogin: (user) => dispatch(afterLogin(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginRoot);
