import React, { useEffect, useState, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';

import DocumentTitle from '../../partials/DocumentTitle';

import Consults from './Consults';
import LookerStudio from '../shared/LookerStudio';

const PartnerPortal = (props) => {
  const { authPing, history, user } = props;
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    authPing({
      type: ['PARTNER_MANAGER'],
      redirect: history.location
    });
  }, []);

  const isPartnerManager = useMemo(() => {
    return user?.type?.includes('PARTNER_MANAGER');
  }, [user]);

  const showLookerStudio = useMemo(() => {
    return isPartnerManager;
  }, [isPartnerManager]);

  const changeTabs = (e, v) => {
    setTabValue(v);
  };

  return (
    <div style={{ padding: 20 }}>
      <DocumentTitle title="Partner Manager" />

      <Typography gutterBottom variant="h3">
        Partner Manager Portal
      </Typography>

      <Typography variant="caption">Updated: {moment().format('llll')}</Typography>

      <div>
        <Tabs value={tabValue} onChange={changeTabs}>
          <Tab label="Consults" />
          {showLookerStudio && <Tab label="Analytics" />}
        </Tabs>

        {tabValue === 0 && <Consults {...props} />}
        {showLookerStudio && tabValue === 1 && <LookerStudio {...props} partnerOnly />}
      </div>
    </div>
  );
};

export default PartnerPortal;
