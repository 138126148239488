import React, { useState } from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import DocumentTitle from '../../partials/DocumentTitle';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/v1/spa`;

const styles = () => ({
  root: {
    margin: '50px'
  }
});

const Spa = (props) => {
  const { classes, location } = props;

  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [success, setSuccess] = useState(false);

  if (location.search.indexOf('?accept=' !== -1)) {
    if (!id) {
      const payload = location.search.split('=')[1];
      setId(window.atob(payload));
    }
  }

  const signSpa = () => {
    if (!name) {
      alert('Please enter your name.');
      return;
    }

    if (!id) {
      alert('Unable to sign your SPA. Please contact Expert Opinion at care@expertopinion.md');
    }

    window
      .fetch(`${API_URL}/${id}/accept`, {
        method: 'POST',
        body: JSON.stringify({
          name
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((r) => r.json())
      .then(() => {
        setSuccess(true);
      });
  };

  return (
    <div className={classes.root}>
      <DocumentTitle title="Service Provider Agreement" />

      {success ? (
        <React.Fragment>
          <Typography variant="h4">
            Thank you for accepting the Service Provider Agreement. You may close this window.
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography style={{ marginTop: '15px' }} variant="body2" gutterBottom>
            Review the Service Provider Agreement. To accept the Service Provider Agreement, type
            your name in the box below, and click the accept button.
          </Typography>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Service Provider Agreement</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <iframe
                title="spa"
                style={{ width: '100%', height: '70vh' }}
                src="https://docs.google.com/document/d/1Xk0j2knTjsb4OhFa1_uCpJzEBY-i77tv/edit?usp=sharing&ouid=110807463694442312967&rtpof=true&sd=true"
              ></iframe>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <Grid container direction="column" spacing={2} style={{ marginTop: '10px' }}>
            <Grid item>
              <TextField
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item>
              <Typography gutterBottom variant="body2">
                By entering my name and clicking the accept button below, I accept the terms of the
                Service Provider Agreement.
              </Typography>
              <Button variant="contained" color="primary" onClick={signSpa}>
                I accept the Service Provider Agreement
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(connect(null, null)(Spa));
