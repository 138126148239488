import React from 'react';
import { getUTCOffsets, timezones } from '../../utils/timezones';
import { Box, Grid, NativeSelect, TextField, Typography } from '@material-ui/core';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';

const gmtValues = getUTCOffsets();
const tzList = timezones();

const TimezoneList = ({
  selectedCode,
  selectedGMTValue,
  label,
  required,
  isInputDesign,
  handleChange
}) => {
  const classes = useStyles();
  return (
    <Box sx={{ my: !(label || isInputDesign) ? 3 : 0 }}>
      <Grid container spacing={!isInputDesign ? 3 : 0}>
        <Grid item xs={12}>
          {label ? <Typography variant="subtitle1">Timezone</Typography> : null}
          {isInputDesign ? (
            <TextField
              id="timezone"
              fullWidth
              select
              margin="normal"
              className={classes.spacing}
              variant="outlined"
              SelectProps={{ native: true }}
              value={selectedCode}
              onChange={(e) => {
                handleChange({
                  code: e.target.value,
                  value: tzList.find((ele) => ele.code == e.target.value)?.value || ''
                });
              }}
              label="Timezone"
              required={required}
            >
              <option value=""></option>
              {tzList.map((tz) => (
                <option key={tz.code} value={tz.code}>
                  {tz.name}
                </option>
              ))}
              <option key="other" value="other">
                Other
              </option>
            </TextField>
          ) : (
            <NativeSelect
              label="Timezone"
              fullWidth
              variant="outlined"
              value={selectedCode}
              onChange={(e) => {
                handleChange({
                  code: e.target.value,
                  value: tzList.find((ele) => ele.code == e.target.value)?.value || ''
                });
              }}
              required={required}
            >
              <option value="" disabled>
                Choose a value
              </option>
              {tzList.map((tz) => (
                <option key={tz.code} value={tz.code}>
                  {tz.name}
                </option>
              ))}
              <option key="other" value="other">
                Other
              </option>
            </NativeSelect>
          )}
        </Grid>
        {selectedCode === 'other' ? (
          <Grid item xs={12}>
            {label ? <Typography variant="subtitle1">GMT Offset</Typography> : null}
            {isInputDesign ? (
              <TextField
                id="gmtValue"
                fullWidth
                select
                margin="normal"
                className={classes.spacing}
                variant="outlined"
                SelectProps={{ native: true }}
                value={selectedGMTValue}
                onChange={(e) => {
                  handleChange({
                    code: selectedCode,
                    value: e.target.value
                  });
                }}
                label="GMT Offset"
                required={required}
              >
                <option value="" disabled></option>
                {gmtValues.map((ele) => (
                  <option key={ele.value} value={ele.value}>
                    {ele.name}
                  </option>
                ))}
              </TextField>
            ) : (
              <NativeSelect
                label="GMT Offset"
                fullWidth
                variant="outlined"
                value={selectedGMTValue}
                onChange={(e) =>
                  handleChange({
                    code: selectedCode,
                    value: e.target.value
                  })
                }
              >
                <option value="" disabled>
                  Choose a GMT offset
                </option>
                {gmtValues.map((ele) => (
                  <option key={ele.value} value={ele.value}>
                    {ele.name}
                  </option>
                ))}
              </NativeSelect>
            )}
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default TimezoneList;
