"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const momentTimezone = require("moment-timezone");
const moment = require("moment");
const migrateTime = (datesArr, creationTimestamp, originalTimezone, targetTimezone) => {
  // Extract the year from the creation timestamp
  const timestampYear = momentTimezone(creationTimestamp).year();
  const timestampMonth = momentTimezone(creationTimestamp).month();
  const convertedTimes = datesArr.map(item => {
    // Extract the date and time from the value string
    const dateTime = item.value;

    // Parse the date and time string into a moment object in the original timezone
    const originalTime = originalTimezone && originalTimezone?.code == "other" ? moment(dateTime, "dddd, MMMM Do h:mm A").utcOffset(originalTimezone.value) : momentTimezone.tz(dateTime, "dddd, MMMM Do h:mm A", typeof originalTimezone == "string" ? originalTimezone : originalTimezone?.code);

    // Extract the year and month from the original time
    const originalTimeYear = originalTime.year();
    const originalTimeMonth = originalTime.month();

    // Check for the edge case where the date is in January and the creation timestamp is in December
    if (originalTimeMonth === 0 && timestampMonth === 11 && originalTimeYear === timestampYear) {
      originalTime.year(originalTimeYear + 1);
    }

    // Convert the time to the desired timezone
    const convertedTime = targetTimezone?.code == "other" ? originalTime.clone().utcOffset(targetTimezone?.value) : originalTime.clone().tz(typeof targetTimezone == "object" ? targetTimezone.code : targetTimezone);
    return convertedTime.format("dddd, MMMM Do h:mm A");
  });
  return convertedTimes;
};
var _default = exports.default = migrateTime;
