import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  accordionDetails: {
    flexDirection: 'column',
    alignItems: 'left'
  }
}));

const CallDetails = (props) => {
  const {
    availability,
    selectedExpert: { email, name },
    date,
    country,
    timezone
  } = props;

  const classes = useStyles();
  return (
    <Grid container direction="column" component={Paper} style={{ padding: '1rem' }} spacing={1}>
      <Grid item sm>
        <Typography variant="h4" align="left">
          Call Details
        </Typography>
      </Grid>

      <Grid item container>
        <Grid item sm>
          <Typography className={classes.heading}>Selected Expert</Typography>

          <Typography className={classes.secondaryHeading}>{name ? name : 'N/a'}</Typography>
          <Typography variant="body1">Email : {email ? email : 'N/a'}</Typography>
        </Grid>
      </Grid>
      <Grid item sm>
        <Grid container direction="column" justifyContent="center">
          <Grid container justifyContent="flex-start">
            <Typography variant="body1">
              Date: {date ? moment(date).format('do MMM YYYY') : 'N/a'}
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-start">
            <Typography variant="body1">Country: {country}</Typography>
          </Grid>
          <Grid container justifyContent="flex-start">
            <Typography variant="body1">
              Timezone:{' '}
              {typeof timezone == 'object'
                ? timezone?.code == 'other'
                  ? 'Other, GMT' + timezone?.value
                  : timezone?.code
                : timezone}
            </Typography>
          </Grid>
          <Divider />
        </Grid>
      </Grid>
      <Grid container item justifyContent="center">
        <Typography variant="h6">Availability</Typography>
      </Grid>
      <Divider />
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>DOW</TableCell>

                <TableCell>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {availability.map((availible, index) => (
                <TableRow key={availible.dow + '-' + index}>
                  <TableCell>{availible.dow}</TableCell>
                  <TableCell>{availible.time}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default CallDetails;
