import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import { _fetchUrl } from '../../../utils/api';
import styles from '../_styles';

class Product extends Component {
  state = {
    submitted: false,
    error: null,
    formErrors: {},
    product: this.props.selectedProduct || {}
  };

  handleChange = (key) => (event) => {
    let value = event.target.value;
    switch (event.target.type) {
      case 'number':
        value = parseFloat(value);
        break;
      case 'checkbox':
        value = event.target.checked;
        break;
      default:
        break;
    }

    const updatedProduct = {
      [key]: value
    };
    const updatedErrors = {
      [key]: false
    };

    if (['specialty', 'category'].includes(key) && value === 'new') {
      updatedProduct.category = 'new';
    }
    if (key === 'specialty' && value !== 'new') {
      updatedProduct.category = null;
      updatedProduct.category_new = null;
      updatedProduct.specialty_new = null;
    }
    if (key === 'category' && value !== 'new') {
      updatedProduct.category_new = null;
    }
    if (key.includes('_new')) {
      updatedErrors[key.replace('_new', '')] = false;
    }

    return this.setState((prevState) => ({
      product: { ...prevState.product, ...updatedProduct },
      formErrors: { ...prevState.formErrors, ...updatedErrors }
    }));
  };
  handleProductSubmit = (event) => {
    event.preventDefault();

    this.setState(() => ({ formErrors: {} }));

    const {
      specialty,
      specialty_new,
      category,
      category_new,
      name,
      description,
      type,
      id,
      localvisitInstructions
    } = this.state.product;
    const body = {
      id,
      specialty: specialty === 'new' ? specialty_new : specialty,
      category: category === 'new' ? category_new : category,
      name,
      description,
      type,
      localvisitInstructions
    };
    let fieldsToValidate = ['specialty', 'category', 'name', 'description', 'type'];

    let hasErrors = false;
    const setError = (field) => {
      hasErrors = true;
      return this.setState((prevState) => ({
        formErrors: { ...prevState.formErrors, [field]: true }
      }));
    };

    fieldsToValidate.forEach((field) => {
      if (!body[field] || !body[field].length) {
        setError(field);
      }
    });

    if (!hasErrors) {
      const states = {
        submitting: { submitted: true, apiError: null },
        complete: { submitted: false, apiError: null }
      };

      this.setState(states.submitting, async () => {
        try {
          const response = await _fetchUrl({
            method: body.id ? 'PUT' : 'POST',
            path: `poe/product/`,
            body,
            token: this.props.token
          });
          if (response.status === 'ok') {
            return this.setState({ ...states.complete }, () => this.props.afterSaveProduct());
          }
          return this.setState({
            ...states.complete,
            apiError: response.message || response.status
          });
        } catch (error) {
          return this.setState({ ...states.complete, apiError: error });
        }
      });
    }
  };

  render() {
    const { classes, handleClose, allProducts, selectedProduct } = this.props;
    const { product, submitted, apiError, formErrors } = this.state;

    return (
      <Dialog open={true} onClose={handleClose}>
        <form onSubmit={this.handleProductSubmit}>
          <DialogTitle>{selectedProduct.id ? 'Edit an Existing' : 'Add a New'} Product</DialogTitle>
          <DialogContent>
            <FormControl
              fullWidth={true}
              error={formErrors.specialty}
              className={classes.formControl}
            >
              <InputLabel shrink htmlFor={'specialty'}>
                Specialty{' '}
                <span className={classes.formHelpText}>{formErrors.specialty && '(Required)'}</span>
              </InputLabel>
              <Select
                native
                value={product.specialty || ''}
                onChange={this.handleChange('specialty')}
                inputProps={{ name: 'specialty', id: 'specialty' }}
              >
                <option value="" disabled={true} />
                {Object.keys(allProducts)
                  .sort()
                  .map((specialty) => (
                    <option key={specialty} value={specialty}>
                      {specialty}
                    </option>
                  ))}
                <option value="new">*** Add New Specialty ***</option>
              </Select>
            </FormControl>
            {product.specialty === 'new' && (
              <FormControl fullWidth={true} error={formErrors.specialty}>
                <Input
                  value={product.specialty_new || ''}
                  placeholder="Enter new Specialty here"
                  onChange={this.handleChange('specialty_new')}
                />
              </FormControl>
            )}
            {product.specialty && product.specialty.length > 0 && (
              <Fragment>
                <FormControl
                  fullWidth={true}
                  error={formErrors.category}
                  className={classes.formControl}
                >
                  <InputLabel shrink htmlFor={'category'}>
                    Category{' '}
                    <span className={classes.formHelpText}>
                      {formErrors.category && '(Required)'}
                    </span>
                  </InputLabel>
                  {product.specialty !== 'new' && (
                    <Select
                      native
                      value={product.category || ''}
                      onChange={this.handleChange('category')}
                      inputProps={{ name: 'category', id: 'category' }}
                    >
                      <option value="" disabled={true} />
                      {Object.keys(allProducts[product.specialty] || [])
                        .sort()
                        .map((category) => (
                          <option key={category} value={category}>
                            {category}
                          </option>
                        ))}
                      <option value="new">*** Add New Category ***</option>
                    </Select>
                  )}
                </FormControl>
                {product.category === 'new' && (
                  <FormControl fullWidth={true} error={formErrors.category}>
                    <Input
                      value={product.category_new || ''}
                      placeholder="Enter new Category here"
                      onChange={this.handleChange('category_new')}
                    />
                  </FormControl>
                )}
              </Fragment>
            )}
            <FormControl fullWidth={true} error={formErrors.name} className={classes.formControl}>
              <InputLabel shrink>
                Name <span className={classes.formHelpText}>{formErrors.name && '(Required)'}</span>
              </InputLabel>
              <Input value={product.name || ''} onChange={this.handleChange('name')} />
            </FormControl>
            <FormControl
              fullWidth={true}
              error={formErrors.description}
              className={classes.formControl}
            >
              <InputLabel shrink>
                Description{' '}
                <span className={classes.formHelpText}>
                  {formErrors.description && '(Required)'}
                </span>
              </InputLabel>
              <Input
                value={product.description || ''}
                multiline
                rowsMax="12"
                onChange={this.handleChange('description')}
              />
            </FormControl>
            <FormControl fullWidth={true} error={formErrors.type} className={classes.formControl}>
              <InputLabel shrink htmlFor={'type'}>
                Type <span className={classes.formHelpText}>{formErrors.type && '(Required)'}</span>
              </InputLabel>
              <Select
                native
                value={product.type || ''}
                onChange={this.handleChange('type')}
                inputProps={{ name: 'type', id: 'type' }}
              >
                <option value="" disabled={true} />
                <option value="EO_CORE">Core</option>
                <option value="EO_CONNECT">Connect</option>
              </Select>
              {product.type === 'EO_CONNECT' && (
                <Typography variant="body1">
                  Note: Connect Products & Plans are managed in the{' '}
                  <a href="https://dashboard.stripe.com/">Stripe Dashboard</a>.
                </Typography>
              )}
            </FormControl>
            {product.type === 'EO_CONNECT' && (
              <FormControl
                fullWidth={true}
                error={formErrors.localvisitInstructions}
                className={classes.formControl}
              >
                <InputLabel shrink>
                  Local Visit Instructions{' '}
                  <span className={classes.formHelpText}>
                    {formErrors.localvisitInstructions && '(Required)'}
                  </span>
                </InputLabel>
                <Input
                  value={product.localvisitInstructions || ''}
                  multiline
                  rowsMax="12"
                  onChange={this.handleChange('localvisitInstructions')}
                />
              </FormControl>
            )}
            <DialogActions style={{ marginBottom: 0 }}>
              <div style={{ margin: '0', textAlign: 'right' }}>
                <Button
                  className={classes.saveButton}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitted}
                >
                  {submitted ? <CircularProgress /> : <span>Save Product</span>}
                </Button>
                {apiError && (
                  <Typography variant="body1" className={classes.error}>
                    {apiError}
                  </Typography>
                )}
              </div>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Product));
