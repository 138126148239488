/* global FS */
import React, { Component, useState, useEffect, Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';

import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

import DocumentTitle from '../../partials/DocumentTitle';
import DocumentMeta from '../../partials/DocumentMeta';
import NavBar from '../../partials/NavBar';
import Footer from '../../partials/Footer';
import Notification from '../../partials/Notification';
import ScrollToTop from './ScrollToTop';
import FileViewer from '../shared/FileViewer';
import AlertProvider from '../shared/Alerts';
import isMobile from '../../utils/isMobile';
import Homepage from '../WWW/Homepage';

// Lazy loaded
import AdminPortalContainer from '../AdminPortal/AdminPortalContainer';
import PartnerPortal from '../PartnerPortals/Portal';
import AlconVisionPortal from '../PartnerPortals/AlconVisionPortal';
import JJVPortal from '../PartnerPortals/JJVPortal';
import MedicalRecords from '../PartnerPortals/shared/MedicalRecord';
import Approve from '../PartnerPortals/shared/Approve';
import Confirmation from '../PartnerPortals/shared/Confirmation';
import CallComplete from '../PartnerPortals/shared/CallComplete';
import Accept from '../PartnerPortals/shared/Accept';
import SurveyForm from '../PartnerPortals/shared/Form';
import ExpertListContainer from '../Experts/ExpertListContainer';
import MedicalRecordsUploadContainer from '../MedicalRecordsPortal/MedicalRecordsUploadContainer';

import UserSettingsContainer from '../User/UserSettingsContainer';

import D2DExpertPortal from '../D2DExpertPortal';
import PartnerManagerPortalContainer from '../PartnerManagerPortal/PartnerManagerPortalContainer';
import SpaContainer from './Spa';

import Onboard from '../Onboard/OnboardContainer';

// Lazy preloaded - Those accessible from front page.
import ContactUsFormContainer from '../ContactUs/ContactUsFormContainer';
import BookExpertFormContainer from '../BookExpert/BookExpertFormContainer';
import EOPartnershipsContainer from '../EOPartnerships/EOPartnershipsContainer';
import ProductSupportFormContainer from '../ProductSupport/ProductSupportFormContainer';
import GetStartedContainer from '../GetStarted/GetStartedContainer';
import LoginContainer from '../Login/LoginContainer';
import ProfileContainer from '../Experts/ProfileContainer';
import Terms from '../WWW/Terms';
import Privacy from '../WWW/Privacy';
import About from '../WWW/About';
import NotFound from '../WWW/NotFound';

const theme = createTheme({
  typography: {
    fontWeightRegular: 300
  },
  palette: {
    background: {
      default: '#ffffff',
      primary: '#f4f4f4'
    },
    primary: {
      light: '#67daff',
      main: '#03a9f4',
      dark: '#007ac1',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#ffffff',
      main: '#f9f9f9',
      dark: '#c6c6c6',
      contrastText: '#03a9f4'
    }
  }
});

const styles = (theme) => ({
  appWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#f4f4f4'
  },
  errorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  errorIcon: { color: '#c6c6c6', fontSize: '20em' },
  bannerComponent: {
    backgroundColor: 'red',
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '0',
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  bannerComponentLink: {
    color: 'yellow',
    textDecoration: 'underline',
    fontWeight: 'bold'
  }
});

const ErrorComponent = ({ error, classes }) => (
  <div className={classes.errorWrapper}>
    <ErrorOutline className={classes.errorIcon} />
    <Typography gutterBottom variant="h4">
      An Error Has Occured
    </Typography>
    <Typography gutterBottom variant="body1">
      Our engineers have been notified and are looking into the issue.
    </Typography>
    {process.env.REACT_APP_EO_ENV !== 'prod' && <pre>{JSON.stringify(error, null, 2)}</pre>}
  </div>
);

const LocationWatcher = ({ updateParentState }) => {
  const location = useLocation();

  useEffect(() => {
    let path = location.pathname.toLowerCase();

    // Handle routes that hide footer
    if (
      [
        path.indexOf('/alcon-e5fb1f'),
        path.indexOf('/alconexpert'),
        path.indexOf('/askzeiss'),
        path.indexOf('/askus'),
        path.indexOf('/zeiss-87fda8'),
        path.indexOf('/askjjv'),
        path.indexOf('/jnj-071ddc1e'),
        path.indexOf('/glaukosguide'),
        path.indexOf('/glaukos-df2b0efe'),
        path.indexOf('/askbausch'),
        path.indexOf('/bauschlomb-3dcd4f'),
        path.indexOf('/bauschretina-cefd7c22'),
        path.indexOf('/bauschretina'),
        path.indexOf('/alconvision'),
        path.indexOf('/alcon-vision-2cf9f8'),
        path.indexOf('appointment')
      ].some((el) => el !== -1)
    ) {
      updateParentState({ hideFooter: true });
    } else {
      updateParentState({ hideFooter: false });
    }

    if (path.indexOf('/admin') === 0) {
      updateParentState({ isAdminPanel: true });
    } else {
      updateParentState({ isAdminPanel: false });
    }
  }, [location]);

  return null;
};

const BannerComponent = ({ classes }) => {
  const [show, setShow] = useState(true);

  if (process.env.REACT_APP_EO_ENV === 'prod') {
    return null;
  }

  return show ? (
    <React.Fragment>
      <DocumentMeta name="robots" content="noindex" />
      <Typography gutterBottom color="primary" variant="h4" className={classes.bannerComponent}>
        {`You are viewing the website on a [${process.env.REACT_APP_EO_ENV}] environment. `}
        <a href="https://expertopinion.md" className={classes.bannerComponentLink}>
          The real site is here.
        </a>
        <IconButton
          style={{ width: '5px', height: '5px' }}
          disableRipple
          onClick={() => setShow(false)}
        >
          <Close />
        </IconButton>
      </Typography>
    </React.Fragment>
  ) : null;
};

class App extends Component {
  state = {
    hideFooter: false,
    isAdminPanel: false,
    isMobile: false
  };

  componentDidMount = async () => {
    if (isMobile()) {
      this.setState({ isMobile: true });
    }

    if (window.FS) {
      FS.setUserVars({ version_str: process.env.REACT_APP_GIT_HASH });
    }

    try {
      const response = await fetch(process.env.REACT_APP_WEB_JSON);
      if (response.ok) {
        const webData = await response.json();
        return this.props.saveWebDataToStore(webData);
      }
      return this.setState({
        error: {
          url: response.url,
          status: response.status,
          text: response.statusText
        }
      });
    } catch (error) {
      this.setState({ error: error.toString() });
    }
  };

  render() {
    const { classes, fileViewer } = this.props;
    const { error } = this.state;

    let footer = this.state.hideFooter ? null : <Footer />;

    return (
      <Suspense fallback={<div>Loading...</div>}>
        <DocumentMeta
          name="viewport"
          content={`width=device-width, initial-scale=${
            this.state.isMobile && this.state.isAdminPanel
              ? '.5, minimum-width=1000'
              : '1, shrink-to-fit=no, viewport-fit=contain'
          }`}
        />
        <ConnectedRouter history={this.props.history}>
          <LocationWatcher updateParentState={(state) => this.setState(state)} />
          <MuiThemeProvider theme={theme}>
            <DocumentTitle title="" />
            <AlertProvider>
              <CssBaseline>
                <div
                  className={classes.appWrapper}
                  style={this.state.hideFooter ? { backgroundColor: '#fff' } : {}}
                  data-appinfo={
                    process.env.REACT_APP_EO_ENV === 'dev'
                      ? JSON.stringify(process.env)
                      : process.env.REACT_APP_GIT_HASH
                  }
                >
                  <BannerComponent classes={classes} />
                  <Notification />
                  {this.state.hideFooter ? null : <NavBar />}
                  <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                    {fileViewer.url && <FileViewer file={fileViewer} />}
                    {error ? (
                      <ErrorComponent error={error} classes={classes} />
                    ) : (
                      <ScrollToTop>
                        <Switch>
                          <Route exact path="/" component={Homepage} />
                          <Route exact path="/about" component={About} />
                          <Route exact path="/terms" component={Terms} />
                          <Route exact path="/privacy" component={Privacy} />
                          <Route path="/login" component={LoginContainer} />
                          <Route exact path="/spa" component={SpaContainer} />
                          <Route
                            exact
                            path="/consult/medical-records/:consultId"
                            component={MedicalRecords}
                          />
                          <Route exact path="/consult/approve/:token" component={Approve} />
                          <Route exact path="/consult/confirm/:token" component={Confirmation} />
                          <Route exact path="/consult/complete/:token" component={CallComplete} />
                          <Route exact path="/consult/accept/:token" component={Accept} />
                          <Route exact path="/expert" component={D2DExpertPortal} />
                          <Route exact path="/onboard/:token" component={Onboard} />

                          {['/d/:doctorId', '/@:doctorHandle'].map((path, i) => (
                            <Route
                              exact
                              path={path}
                              key={i}
                              render={(props) => <ProfileContainer {...props} />}
                            />
                          ))}

                          <Route exact path="/contact-us" component={ContactUsFormContainer} />
                          <Redirect exact from="/book-expert" to="/eo-partnerships" />
                          <Route exact path="/book-an-expert" component={BookExpertFormContainer} />
                          <Route
                            exact
                            path="/eo-partnerships"
                            component={EOPartnershipsContainer}
                          />
                          <Redirect exact from="/product-support" to="/get-started" />
                          <Route exact path="/get-started" component={GetStartedContainer} />
                          <Route
                            exact
                            path="/doctor-support"
                            component={ProductSupportFormContainer}
                          />

                          <Redirect exact from="/experts" to="/experts/ophthalmology" />
                          <Redirect
                            exact
                            from="/experts/:specialty/:category"
                            to="/experts/ophthalmology"
                          />

                          <Route
                            exact
                            path="/experts/:specialty"
                            render={(props) => <ExpertListContainer {...props} />}
                          />
                          <Route
                            exact
                            path="/experts/:specialty/:category/:name"
                            component={ExpertListContainer}
                          />
                          <Route
                            exact
                            path="/experts/:specialty/:category/:name/:doctorId"
                            component={ProfileContainer}
                          />

                          <Redirect exact from="/user" to="/user/settings" />
                          <Route exact path="/user/settings" component={UserSettingsContainer} />

                          <Route
                            path="/admin"
                            render={(props) => <AdminPortalContainer {...props} />}
                          />
                          <Route
                            path="/partner"
                            render={(props) => <PartnerManagerPortalContainer {...props} />}
                          />

                          <Route
                            exact
                            path="/records/:medicalRecordId/:productId/:signedToken"
                            component={MedicalRecordsUploadContainer}
                          />
                          <Route exact path="/404" component={NotFound} />

                          <Route
                            render={(props) => {
                              let body = null;
                              let path = props.history.location.pathname.toLowerCase();

                              if (
                                path.indexOf('/alcon-e5fb1f') !== -1 ||
                                path.indexOf('/alconexpert') !== -1
                              ) {
                                body = (
                                  <PartnerPortal
                                    partnerId="alcon-e5fb1f"
                                    name="Alcon"
                                    Form={<SurveyForm />}
                                  />
                                );
                              } else if (
                                path.indexOf('askbausch') !== -1 ||
                                path.indexOf('/bauschlomb-3dcd4f') !== -1
                              ) {
                                body = (
                                  <PartnerPortal
                                    partnerId="bauschlomb-3dcd4f"
                                    options={{ disableWeekends: true }}
                                    name="Bausch + Lomb"
                                    Form={<SurveyForm />}
                                  />
                                );
                              } else if (path.indexOf('/askzeiss') !== -1) {
                                return <Redirect to="/askus" />;
                              } else if (
                                path.indexOf('/askus') !== -1 ||
                                path.indexOf('/zeiss-87fda8') !== -1
                              ) {
                                body = (
                                  <PartnerPortal
                                    partnerId="zeiss-87fda8"
                                    name="ZEISS"
                                    options={{ disableNextDay: true }}
                                    Form={<SurveyForm />}
                                  />
                                );
                              } else if (
                                path.indexOf('/bauschretina-cefd7c22') !== -1 ||
                                path.indexOf('/bauschretina') !== -1
                              ) {
                                body = (
                                  <PartnerPortal
                                    partnerId="bauschretina-cefd7c22"
                                    name="Bausch + Lomb Retina"
                                    Form={<SurveyForm />}
                                  />
                                );
                              } else if (
                                path.indexOf('/glaukosguide') !== -1 ||
                                path.indexOf('/glaukos-df2b0efe') !== -1
                              ) {
                                body = (
                                  <PartnerPortal
                                    partnerId="glaukos-df2b0efe"
                                    name="Glaukos"
                                    Form={<SurveyForm />}
                                  />
                                );
                              } else if (
                                path.indexOf('/alcon-vision-2cf9f8') !== -1 ||
                                path.indexOf('/alconvision') !== -1
                              ) {
                                body = (
                                  <AlconVisionPortal
                                    partnerId="alcon-vision-2cf9f8"
                                    name="Alcon Vision"
                                    Form={<SurveyForm />}
                                  />
                                );
                              } else if (
                                path.indexOf('/askjjv') !== -1 ||
                                path.indexOf('/jnj-071ddc1e') !== -1
                              ) {
                                body = (
                                  <JJVPortal
                                    partnerId="jnj-071ddc1e"
                                    name="Johnson & Johnson"
                                    Form={<SurveyForm />}
                                  />
                                );
                              }

                              // Handle strange edgecase where pressing backbutton doesnt handle product-support/get-started routes
                              else if (path.indexOf('/product-support') !== -1) {
                                body = <ProductSupportFormContainer />;
                                //
                              } else if (path.indexOf('/get-started') !== -1) {
                                body = <GetStartedContainer />;
                              }

                              return body ? body : <Redirect to="/404" />;
                            }}
                          />
                        </Switch>
                      </ScrollToTop>
                    )}
                  </div>
                  {footer}
                </div>
              </CssBaseline>
            </AlertProvider>
          </MuiThemeProvider>
        </ConnectedRouter>
      </Suspense>
    );
  }
}

export default withStyles(styles, { withTheme: true })(App);
