import React, { useState, useEffect, useMemo, forwardRef } from 'react';
import { MTableBodyRow, MTableToolbar, MTableEditField } from '@material-table/core';
import EODialog from '../../../partials/EODialog';
import EOTable from '../../../partials/EOTable.js';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { _fetchUrl } from '../../../utils/api';
import { downloadFile } from '../../../utils/downloadFile';
import { pickFile } from '../../../utils/pickFile';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

// Details Panel Subcomponent
const ManageSurveysDP = (props) => {
  const {
    rowData,
    draft,
    questions,
    updatedQuestions,
    setUpdatedQuestions,
    partnerId,
    token,
    fetchSurveys,
    columns,
    products
  } = props;

  const [dialog, setDialog] = useState({ open: false, loading: false });
  const [updatingCustomerOrder, setUpdatingCustomerOrder] = useState(null);
  const [updatingExpertOrder, setUpdatingExpertOrder] = useState(null);
  const [originalCustomerOrder, setOriginalCustomerOrder] = useState(null);
  const [originalExpertOrder, setOriginalExpertOrder] = useState(null);
  const [originalQuestions, setOriginalQuestions] = useState({});
  const [draftErrors, setDraftErrors] = useState({ customer: {}, expert: {} });

  // Clone while breaking first level object references
  const customerColumns = useMemo(() => columns.map((obj) => ({ ...obj })), [columns]);
  const expertColumns = useMemo(() => columns.map((obj) => ({ ...obj })), [columns]);

  // Define edit component logic
  [
    { columns: customerColumns, type: 'customer' },
    { columns: expertColumns, type: 'expert' }
  ].forEach((obj) => {
    obj.columns.find((el) => el.field === 'label').editComponent = (props) => {
      return (
        <MTableEditField
          {...props}
          onChange={(val) => {
            props.onChange(val);

            if (val) {
              setDraftErrors((prev) => {
                delete prev[obj.type].label;
                return prev;
              });
            }
          }}
          error={!!draftErrors[obj.type]['label']}
          helperText={draftErrors[obj.type]['label']}
        />
      );
    };
    obj.columns.find((el) => el.field === 'type').editComponent = (props) => {
      return (
        <MTableEditField
          {...props}
          error={!!draftErrors[obj.type]['type']}
          helperText={draftErrors[obj.type]['type']}
        />
      );
    };
    obj.columns.find((el) => el.field === 'optional').editComponent = (props) => {
      return props.rowData.type !== 'TEXTFIELD' ? (
        <div></div>
      ) : (
        <MTableEditField
          {...props}
          error={!!draftErrors[obj.type]['optional']}
          helperText={draftErrors[obj.type]['optional']}
        />
      );
    };
    obj.columns.find((el) => el.field === 'multiline').editComponent = (props) => {
      return props.rowData.type !== 'TEXTFIELD' ? (
        <div></div>
      ) : (
        <MTableEditField
          {...props}
          error={!!draftErrors[obj.type]['multiline']}
          helperText={draftErrors[obj.type]['multiline']}
        />
      );
    };
    obj.columns.find((el) => el.field === 'options').editComponent = (props) => {
      return props.rowData.type !== 'RADIO' ? (
        <div></div>
      ) : (
        <Autocomplete
          multiple
          options={[]}
          value={props.value}
          freeSolo
          onChange={(e, val, reason) => {
            e.preventDefault();
            e.stopPropagation();
            if (['create-option', 'remove-option', 'clear'].includes(reason)) {
              if (val.length > 1) {
                setDraftErrors((prev) => {
                  delete prev[obj.type].options;
                  return prev;
                });
              }
              return props.onChange(val);
            }
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" key={index} label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!draftErrors[obj.type]['options']}
              helperText={draftErrors[obj.type]['options']}
              placeholder="Options"
            />
          )}
        />
      );
    };
  });

  const classes = useStyles();

  // Helper effect for question import / auto import
  useEffect(() => {
    let auto_fill = {};
    let remove_id = (q) => {
      delete q.id;
      return q;
    };

    if (draft.customer_length === 0) {
      auto_fill.customer = questions.customer?.map(remove_id);
    }

    if (draft.expert_length === 0) {
      auto_fill.expert = questions.expert?.map(remove_id);
    }

    if (Object.keys(auto_fill).length) {
      setOriginalQuestions({
        ...(auto_fill.customer ? { customer: [] } : null),
        ...(auto_fill.expert ? { expert: [] } : null)
      });
      setUpdatedQuestions((pState) => ({ ...pState, [partnerId]: { ...draft, ...auto_fill } }));
    }

    return () => {
      fetchSurveys();
    };
  }, []);

  const reorderSurvey = (type) => {
    let order = updatedQuestions?.[partnerId]?.[type]?.map((question) => question.id);

    _fetchUrl({
      method: 'PUT',
      path: `poe/partner/${partnerId}/survey/${type}/reorder`,
      token,
      body: order
    }).then((r) => {
      if (r.status === 'ok') {
        if (type === 'customer') {
          setUpdatingCustomerOrder(false);
        } else {
          setUpdatingExpertOrder(false);
        }
      } else {
        console.error('Error reordering survey:', r);
      }
    });
  };

  const resetSurvey = (type) => {
    if (type === 'customer') {
      setUpdatedQuestions((pState) => ({
        ...pState,
        [partnerId]: { ...pState[partnerId], customer: originalCustomerOrder }
      }));
      setUpdatingCustomerOrder(false);
    } else {
      setUpdatedQuestions((pState) => ({
        ...pState,
        [partnerId]: { ...pState[partnerId], expert: originalExpertOrder }
      }));
      setUpdatingExpertOrder(false);
    }
  };

  const updateSurvey = (data, type, resolve, reject) => {
    let errors = {};

    if (!data.label) {
      errors['label'] = 'Question label must be provided';
    }

    // Perform validation / prep
    switch (data.type) {
      case 'TEXTFIELD':
        delete data.options;
        break;
      case 'RADIO':
        delete data.multiline;
        if (!data.options || data.options.length < 2) {
          errors['options'] = 'Radio questions must contain at least two options';
        }
        break;
      default:
        errors['type'] = `Invalid question type provided: ${data.type}`;
    }

    if (Object.keys(errors).length) {
      setDraftErrors((prev) => ({ ...prev, [type]: errors }));
      return reject();
    }

    const convertToBoolean = (value) => {
      if (typeof value === 'string') {
        return value.toLowerCase() === 'true';
      }
      return value;
    };

    // Type cast from string to boolean due to how Material Table handles lookup objects
    if ('optional' in data) {
      data.optional = convertToBoolean(data.optional);
    }
    if ('multiline' in data) {
      data.multiline = convertToBoolean(data.multiline);
    }
    if ('internalOnly' in data) {
      data.internalOnly = convertToBoolean(data.internalOnly);
    }

    _fetchUrl({
      method: 'PUT',
      path: `poe/partner/${partnerId}/survey/${type}`,
      token,
      body: data
    }).then((r) => {
      if (r.status === 'ok') {
        setUpdatedQuestions((pState) => ({ ...pState, [partnerId]: r.questions }));
        resolve();
      } else {
        reject();
        console.error('Error loading partners:', r.error);
      }
    });
  };

  const deleteSurvey = (data, type, resolve, reject) => {
    _fetchUrl({
      method: 'DELETE',
      path: `poe/partner/${partnerId}/survey/${type}`,
      token,
      body: data
    }).then((r) => {
      if (r.status === 'ok') {
        setUpdatedQuestions((pState) => ({ ...pState, [partnerId]: r.questions }));
        setOriginalQuestions((pState) => {
          delete pState[type];
          return pState;
        });
        resolve();
      } else {
        reject();
        console.error('Error loading partners:', r.error);
      }
    });
  };

  const DragState = {
    row: -1,
    dropIndex: -1
  };

  const offsetIndex = (from, to, arr = []) => {
    if (from < to) {
      let start = arr.slice(0, from),
        between = arr.slice(from + 1, to + 1),
        end = arr.slice(to + 1);
      return [...start, ...between, arr[from], ...end];
    }
    if (from > to) {
      let start = arr.slice(0, to),
        between = arr.slice(to, from),
        end = arr.slice(from + 1);
      return [...start, arr[from], ...between, ...end];
    }
    return arr;
  };

  const reOrderRow = (from, to, type) => {
    if (type === 'customer' && !originalQuestions['customer']) {
      setUpdatingCustomerOrder(true);

      if (updatedQuestions?.[partnerId]?.[type]) {
        if (!originalCustomerOrder) {
          setOriginalCustomerOrder(updatedQuestions[partnerId][type]);
        }

        setUpdatedQuestions((pState) => ({
          ...pState,
          [partnerId]: {
            ...pState[partnerId],
            [type]: offsetIndex(from, to, updatedQuestions[partnerId][type])
          }
        }));
      } else {
        if (!originalCustomerOrder) {
          setOriginalCustomerOrder(draft[type]);
        }

        setUpdatedQuestions((pState) => ({
          ...pState,
          [partnerId]: { ...draft, [type]: offsetIndex(from, to, draft[type]) }
        }));
      }
    } else if (type === 'expert' && !originalQuestions['expert']) {
      setUpdatingExpertOrder(true);

      if (updatedQuestions?.[partnerId]?.[type]) {
        if (!originalExpertOrder) {
          setOriginalExpertOrder(updatedQuestions[partnerId][type]);
        }

        setUpdatedQuestions((pState) => ({
          ...pState,
          [partnerId]: {
            ...pState[partnerId],
            [type]: offsetIndex(from, to, updatedQuestions[partnerId][type])
          }
        }));
      } else {
        if (!originalExpertOrder) {
          setOriginalExpertOrder(draft[type]);
        }

        setUpdatedQuestions((pState) => ({
          ...pState,
          [partnerId]: { ...draft, [type]: offsetIndex(from, to, draft[type]) }
        }));
      }
    }
  };

  const handleOpen = () => {
    setDialog({ open: true, loading: false });
  };

  const handleClose = (response) => {
    if (response) {
      if (dialog.error) {
        setDialog({ open: false, loading: false });
      } else {
        setDialog({ open: false, loading: true });

        _fetchUrl({
          method: 'PUT',
          path: `poe/partner/${partnerId}/survey`,
          token
        }).then((r) => {
          if (r.status === 'ok') {
            setDialog({ open: false, loading: false });
            fetchSurveys();
          } else {
            setDialog({
              open: true,
              loading: false,
              error: 'Unable to update, try again momentarily.'
            });
            console.error('Error loading surveys:', r.error);
          }
        });
      }
    } else {
      setDialog({ open: false, loading: false });
    }
  };

  const exportSurvey = (event, rowData, type) => {
    const version = rowData.rowData.version;
    const data = rowData.rowData[type].map((question) => {
      delete question.id;
      return question;
    });

    downloadFile(JSON.stringify(data), `${partnerId}_${type}_${version}.json`);
  };

  const importSurvey = (type) => {
    pickFile({ type: 'application/JSON' }, async (files) => {
      for (let file of files) {
        if (type !== file.name.split('_')[1]) {
          continue;
        }

        let questions = JSON.parse(await file.text());

        questions = questions.map((question) => {
          // Ensure partner supports product else don't include
          if (question.products) {
            question.products = question.products.filter((product) => products.includes(product));
          }

          return question;
        });

        if (!originalQuestions[type]) {
          setOriginalQuestions((pState) => ({ ...pState, [type]: draft[type] }));
        }

        setUpdatedQuestions((pState) => ({
          ...pState,
          [partnerId]: { ...draft, ...pState[partnerId], [type]: questions }
        }));
      }
    });
  };

  const saveQuestions = (type) => {
    let questions =
      draft.id === 'draft' && originalQuestions[type]?.length !== 0
        ? draft[type]
        : updatedQuestions[partnerId][type];

    _fetchUrl({
      method: 'POST',
      path: `poe/partner/${partnerId}/survey/${type}/import`,
      token,
      body: questions
    }).then((r) => {
      if (r.status === 'ok') {
        setOriginalQuestions((pState) => {
          delete pState[type];
          return pState;
        });

        if (r.id) {
          setUpdatedQuestions((pState) => {
            //pState[partnerId].id = r.id;
            return { ...pState };
          });
        } else {
          setUpdatedQuestions((pState) => ({ ...pState }));
        }
      } else {
        console.error('Error saving questions:', r);
      }
    });
  };

  const clearQuestions = (type) => {
    setUpdatedQuestions((pState) => ({
      ...pState,
      [partnerId]: { ...draft, ...pState[partnerId], [type]: originalQuestions[type] }
    }));
    setOriginalQuestions((pState) => {
      delete pState[type];
      return pState;
    });
  };

  const canPublish = (rowData) => {
    let type_checks = ['customer', 'expert'].map((type) => {
      let questionUpdated = updatedQuestions?.[partnerId]?.[type];

      let updatedQuestion =
        questionUpdated &&
        updatedQuestions?.[partnerId]?.[type]?.some(
          (question) => question.products === undefined || question.products.length === 0
        );

      let originalQuestion = rowData?.[type]?.some(
        (question) => question.products === undefined || question.products.length === 0
      );

      let checks = [
        questionUpdated ? updatedQuestion : originalQuestion,
        questionUpdated?.length === 0 ? false : true,
        originalQuestions[type]?.length !== 0
      ];

      return checks.every((check) => check === true);
    });

    return type_checks.every((check) => check === true);
  };

  return (
    <div style={{ backgroundColor: '#F0F2F5', padding: '25px' }}>
      <EODialog
        open={dialog.open}
        handleClose={() => handleClose(false)}
        handleOkay={() => handleClose(true)}
        showCancel={true}
      >
        {dialog.error ? (
          dialog.error
        ) : (
          <div>
            Publish draft version? <br />
            <br />
            <b>Note:</b> This will become this partners new survey and will take effect immediately.
          </div>
        )}
      </EODialog>

      <EOTable
        style={{ marginBottom: '25px' }}
        columns={customerColumns}
        data={
          rowData.rowData.version === 'draft' && updatedQuestions[partnerId]
            ? updatedQuestions[partnerId].customer
            : rowData.rowData.customer
        }
        title="Customer"
        editable={
          rowData.rowData.version !== 'draft' && draftErrors.customer
            ? {}
            : {
                onRowAdd:
                  updatingCustomerOrder || originalQuestions['customer']
                    ? null
                    : (newData) =>
                        new Promise((resolve, reject) => {
                          updateSurvey(newData, 'customer', resolve, reject);
                        }),
                onRowUpdate: originalQuestions['customer']
                  ? null
                  : (newData) =>
                      new Promise((resolve, reject) => {
                        updateSurvey(newData, 'customer', resolve, reject);
                      }),
                onRowAddCancelled: () => setDraftErrors((prev) => ({ ...prev, customer: {} })),
                onRowUpdateCancelled: () => setDraftErrors((prev) => ({ ...prev, customer: {} })),
                onRowDelete: originalQuestions['customer']
                  ? null
                  : (oldData) =>
                      new Promise((resolve, reject) => {
                        deleteSurvey({ id: oldData.id }, 'customer', resolve, reject);
                      })
              }
        }
        components={
          rowData.rowData.version !== 'draft'
            ? {
                Toolbar: (props) => {
                  props.actions.unshift({
                    disabled: false,
                    icon: forwardRef((props, ref) => <GetAppIcon {...props} ref={ref} />),
                    onClick: (event) => exportSurvey(event, rowData, 'customer'),
                    position: 'toolbar',
                    tooltip: 'Export'
                  });

                  return <MTableToolbar {...props} />;
                }
              }
            : {
                Toolbar: (props) => {
                  if (updatingCustomerOrder) {
                    props.actions.push({
                      disabled: false,
                      icon: forwardRef((props, ref) => <SaveIcon {...props} ref={ref} />),
                      onClick: () => reorderSurvey('customer'),
                      position: 'toolbar',
                      tooltip: 'Save order'
                    });

                    props.actions.push({
                      disabled: false,
                      icon: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
                      onClick: () => resetSurvey('customer'),
                      position: 'toolbar',
                      tooltip: 'Clear order'
                    });
                  } else if (originalQuestions['customer']) {
                    props.actions.push({
                      disabled: false,
                      icon: forwardRef((props, ref) => <SaveIcon {...props} ref={ref} />),
                      onClick: () => saveQuestions('customer'),
                      position: 'toolbar',
                      tooltip: 'Save questions'
                    });

                    props.actions.push({
                      disabled: false,
                      icon: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
                      onClick: () => clearQuestions('customer'),
                      position: 'toolbar',
                      tooltip: 'Clear questions'
                    });
                  } else {
                    props.actions.unshift({
                      disabled: false,
                      icon: forwardRef((props, ref) => <GetAppIcon {...props} ref={ref} />),
                      onClick: (event) => exportSurvey(event, rowData, 'customer'),
                      position: 'toolbar',
                      tooltip: 'Export'
                    });

                    props.actions.unshift({
                      disabled: false,
                      icon: forwardRef((props, ref) => <PublishIcon {...props} ref={ref} />),
                      onClick: () => importSurvey('customer'),
                      position: 'toolbar',
                      tooltip: 'Import'
                    });
                  }

                  return <MTableToolbar {...props} />;
                },
                Row: (props) => (
                  <MTableBodyRow
                    {...props}
                    draggable="true"
                    onDragStart={() => {
                      DragState.row = props.data.tableData.index;
                    }}
                    onDragEnter={(e) => {
                      e.preventDefault();
                      if (props.data.tableData.id !== DragState.row) {
                        DragState.dropIndex = props.data.tableData.index;
                      }
                    }}
                    onDragEnd={() => {
                      if (DragState.dropIndex !== -1) {
                        reOrderRow(DragState.row, DragState.dropIndex, 'customer');
                      }
                      DragState.row = -1;
                      DragState.dropIndex = -1;
                    }}
                  />
                )
              }
        }
      />
      <EOTable
        columns={expertColumns}
        data={
          rowData.rowData.version === 'draft' && updatedQuestions[partnerId]
            ? updatedQuestions[partnerId].expert
            : rowData.rowData.expert
        }
        title="Expert"
        editable={
          rowData.rowData.version !== 'draft' && draftErrors.expert
            ? {}
            : {
                onRowAdd:
                  updatingExpertOrder || originalQuestions['expert']
                    ? null
                    : (newData) =>
                        new Promise((resolve, reject) => {
                          updateSurvey(newData, 'expert', resolve, reject);
                        }),
                onRowUpdate: originalQuestions['expert']
                  ? null
                  : (newData) =>
                      new Promise((resolve, reject) => {
                        updateSurvey(newData, 'expert', resolve, reject);
                      }),
                onRowAddCancelled: () => setDraftErrors((prev) => ({ ...prev, expert: {} })),
                onRowUpdateCancelled: () => setDraftErrors((prev) => ({ ...prev, expert: {} })),
                onRowDelete: originalQuestions['expert']
                  ? null
                  : (oldData) =>
                      new Promise((resolve, reject) => {
                        deleteSurvey({ id: oldData.id }, 'expert', resolve, reject);
                      })
              }
        }
        components={
          rowData.rowData.version !== 'draft'
            ? {
                Toolbar: (props) => {
                  props.actions.unshift({
                    disabled: false,
                    icon: forwardRef((props, ref) => <GetAppIcon {...props} ref={ref} />),
                    onClick: (event) => exportSurvey(event, rowData, 'expert'),
                    position: 'toolbar',
                    tooltip: 'Export'
                  });

                  return <MTableToolbar {...props} />;
                }
              }
            : {
                Toolbar: (props) => {
                  if (updatingExpertOrder) {
                    props.actions.push({
                      disabled: false,
                      icon: forwardRef((props, ref) => <SaveIcon {...props} ref={ref} />),
                      onClick: () => reorderSurvey('expert'),
                      position: 'toolbar',
                      tooltip: 'Save order'
                    });

                    props.actions.push({
                      disabled: false,
                      icon: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
                      onClick: () => resetSurvey('expert'),
                      position: 'toolbar',
                      tooltip: 'Clear order'
                    });
                  } else if (originalQuestions['expert']) {
                    props.actions.push({
                      disabled: false,
                      icon: forwardRef((props, ref) => <SaveIcon {...props} ref={ref} />),
                      onClick: () => saveQuestions('expert'),
                      position: 'toolbar',
                      tooltip: 'Save questions'
                    });

                    props.actions.push({
                      disabled: false,
                      icon: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
                      onClick: () => clearQuestions('expert'),
                      position: 'toolbar',
                      tooltip: 'Clear questions'
                    });
                  } else {
                    props.actions.unshift({
                      disabled: false,
                      icon: forwardRef((props, ref) => <GetAppIcon {...props} ref={ref} />),
                      onClick: (event) => exportSurvey(event, rowData, 'expert'),
                      position: 'toolbar',
                      tooltip: 'Export'
                    });

                    props.actions.unshift({
                      disabled: false,
                      icon: forwardRef((props, ref) => <PublishIcon {...props} ref={ref} />),
                      onClick: () => importSurvey('expert'),
                      position: 'toolbar',
                      tooltip: 'Import'
                    });
                  }

                  return <MTableToolbar {...props} />;
                },
                Row: (props) => (
                  <MTableBodyRow
                    {...props}
                    draggable="true"
                    onDragStart={() => {
                      DragState.row = props.data.tableData.index;
                    }}
                    onDragEnter={(e) => {
                      e.preventDefault();
                      if (props.data.tableData.id !== DragState.row) {
                        DragState.dropIndex = props.data.tableData.index;
                      }
                    }}
                    onDragEnd={() => {
                      if (DragState.dropIndex !== -1) {
                        reOrderRow(DragState.row, DragState.dropIndex, 'expert');
                      }
                      DragState.row = -1;
                      DragState.dropIndex = -1;
                    }}
                  />
                )
              }
        }
      />
      {canPublish(rowData.rowData) && (
        <Box
          style={{ marginTop: '10px' }}
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Button variant="contained" color="primary" onClick={handleOpen}>
            Publish
          </Button>
        </Box>
      )}
      <Backdrop className={classes.backdrop} open={dialog.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ManageSurveysDP;
