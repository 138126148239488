import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FilterListIcon from '@material-ui/icons/FilterList';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ProductSelect from '../../partials/ProductSelect';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DoctorList from '../Experts/DoctorList';

import banner1024 from './1024px.webp';
import banner1920 from './1920px.webp';
import banner2560 from './2560px.webp';
import banner2560_2x from './2560px@2x.webp';
import banner1920_2x from './1920px@2x.webp';
import banner1024_2x from './1024px@2x.webp';

import banner1024_jpg from './1024px.jpg';
import banner1920_jpg from './1920px.jpg';
import banner2560_jpg from './2560px.jpg';
import banner2560_2x_jpg from './2560px@2x.jpg';
import banner1920_2x_jpg from './1920px@2x.jpg';
import banner1024_2x_jpg from './1024px@2x.jpg';

const HEIGHT = 195 * 0.8,
  WIDTH = 165 * 0.8,
  SM_RATIO = 0.75,
  MEDIA_RATIO = 0.75;

const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundImage: `url(${banner1024}), url(${banner1024_jpg})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    padding: '8px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0'
    },
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url(${banner1920}), url(${banner1920_jpg})`
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${banner2560}), url(${banner2560_jpg})`
    },
    [`${theme.breakpoints.down(
      'sm'
    )} and (-webkit-min-device-pixel-ratio: 2), ${theme.breakpoints.down(
      'sm'
    )} and (min-resolution: 192dpi)`]: {
      backgroundImage: `url(${banner1024_2x}), url(${banner1024_2x_jpg})`
    },
    [`${theme.breakpoints.up('sm')} and (-webkit-min-device-pixel-ratio: 2), ${theme.breakpoints.up(
      'sm'
    )} and (min-resolution: 192dpi)`]: {
      backgroundImage: `url(${banner1920_2x}), url(${banner1920_2x_jpg})`
    },
    [`${theme.breakpoints.up('md')} and (-webkit-min-device-pixel-ratio: 2), ${theme.breakpoints.up(
      'md'
    )} and (min-resolution: 192dpi)`]: {
      backgroundImage: `url(${banner2560_2x}), url(${banner2560_2x_jpg})`
    }
  },
  right: {
    width: '450px',
    padding: '32px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 32px'
    }
  },
  chat: {
    fontSize: '1.2em',
    fontWeight: '400',
    position: 'relative',
    padding: '10px 20px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
    textAlign: 'center',
    '& a': {
      color: 'white'
    },
    '&:after': {
      content: '" "',
      position: 'absolute',
      width: 0,
      height: 0,
      left: '38px',
      right: 'auto',
      top: 'auto',
      bottom: '-40px',
      border: '22px solid',
      borderColor: `${theme.palette.primary.main} transparent transparent ${theme.palette.primary.main}`
    }
  },
  left: {
    width: '500px',
    padding: '32px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 32px'
    }
  },
  quote: {
    background: 'rgba(0,0,0,0.7)',
    fontSize: '1.6rem',
    fontWeight: '400',
    padding: '16px'
  },
  doctorName: {
    color: theme.palette.primary.main,
    fontSize: '1.7rem'
  },
  content: {
    padding: '25px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '5px',
      marginTop: '10px'
    }
  },
  card: {
    width: WIDTH,
    height: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: WIDTH * SM_RATIO
    }
  },
  media: {
    height: HEIGHT * MEDIA_RATIO,
    objectFit: 'fit',
    objectPosition: '50% 20%',
    [theme.breakpoints.down('sm')]: {
      height: HEIGHT * (MEDIA_RATIO * 1.5) * SM_RATIO
    }
  },
  paper: {
    padding: '40px 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 30px'
    }
  },
  sidebar: {
    padding: theme.spacing(2),
    marginRight: theme.spacing(1),
    height: '100%'
  },
  speciality: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '1.5rem',
    display: 'inline',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem'
    }
  },
  adaptiveHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem'
    }
  }
}));

const EOPartnerships = (props) => {
  const classes = useStyles();

  const [view, setView] = useState('module');
  const [selectedButton, setSelectedButton] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const products = useMemo(() => {
    return Object.keys(props.products)
      .map((key) => {
        const product = props.products[key];
        return {
          id: product.id,
          specialty: product.specialty,
          category: product.category,
          name: product.name,
          doctors: product.doctors
        };
      })
      .filter((a) => a.specialty === 'ophthalmology');
  }, [props.products]);

  const doctors = React.useMemo(() => {
    if (selectedButton === 0) {
      return props.doctors;
    }

    if (selectedButton === 1 && selectedProduct !== null) {
      return selectedProduct.doctors.reduce((agg, item) => {
        if (!props.doctors[item]) {
          return agg;
        }

        agg[item] = props.doctors[item];
        return agg;
      }, {});
    }

    return props.doctors;
  }, [selectedButton, props.doctors, products, selectedProduct]);

  const handleChange = (event, nextView) => {
    if (nextView === 'list') setSelectedButton(1);
    else setSelectedButton(0);

    setView(nextView);
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };

  return (
    <div>
      <div className={classes.banner}>
        <div className={classes.left}>
          <div className={classes.quote}>
            "The fastest and best peer support resource by miles."{' '}
            <span className={classes.doctorName}>Dr. Liz Yeu</span>
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.chat}>
            Schedule a demo at <a href="mailto:care@ExpertOpinion.md">care@ExpertOpinion.md</a>
          </div>
        </div>
      </div>

      <div className={classes.content}>
        <Grid item sm={12} md={8} style={{ width: '100%', margin: '0px auto 30px auto' }}>
          <Box display="flex" flexDirection="column" justifyContent="space-between" py={2} px={1}>
            <Typography variant="h4" className={classes.adaptiveHeader}>
              Expert Opinion <Box className={classes.speciality}>Partnership</Box>
            </Typography>

            <Typography variant="body1">
              <ul>
                <li>Do you want to build up your customer confidence on a new product?</li>
                <li>
                  Would your customer doctors like to speak to an independent Expert prior to their
                  first surgery?
                </li>
                <li>Would you like an Expert on-call to handle adverse event cases - same day?</li>
                <li>
                  Would you like to do trend analysis on all clinical questions and the answers
                  given by Experts?
                </li>
              </ul>
            </Typography>
            <Typography variant="body1">
              Expert Opinion works with your Medical Affairs team to handle your clinical requests
              quickly and compliantly. Medical affairs has full oversight and control of every
              inbound request. Expert Opinion handles all consult coordination gathering requests
              and Expert responses for you, identifying trends and giving your customers{' '}
              <strong>same day</strong> access to the World's best doctors.
            </Typography>
            <br />
            <Typography variant="body1">
              With an average experience rating of 4.95/5 from over 400 doctor reviews, we will give
              you, what we believe, is the best customer doctor support experience in Healthcare.
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            py={2}
            px={1}
          >
            <Typography variant="h4" className={classes.adaptiveHeader}>
              Our Experts <Box className={classes.speciality}>in Ophthalmology</Box>
            </Typography>
            <ToggleButtonGroup value={view} exclusive onChange={handleChange}>
              <ToggleButton value="list" aria-label="list">
                <Tooltip title="Filter" aria-label="filter" placement="top" arrow>
                  <FilterListIcon />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="flex-start">
            {matches && selectedButton === 1 && (
              <Paper className={classes.sidebar}>
                <ProductSelect
                  title={'Select by Speciality'}
                  onSelected={handleProductSelect}
                  products={products}
                  category=""
                  name=""
                />
              </Paper>
            )}
            <DoctorList doctors={doctors} />
          </Box>
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  doctors: Object.keys(state.webData.doctors).reduce((acc, doctor) => {
    //Only show platform doctors
    if (
      state.webData.doctors[doctor].status &&
      state.webData.doctors[doctor].status === 'platform'
    ) {
      acc[doctor] = state.webData.doctors[doctor];
    }
    return acc;
  }, {})
});

export default connect(mapStateToProps)(EOPartnerships);
