import { createTheme } from '@material-ui/core/styles';

const loginTheme = createTheme({
  palette: {
    primary: {
      light: '#03a9f4',
      main: '#03a9f4',
      dark: '#03a9f4',
      contrastText: '#FFF'
    },
    // error: {
    //   main: 'rgba(102, 51, 153, 1)'
    // },
    secondary: {
      light: '#000',
      main: '#000',
      dark: '#000',
      contrastText: '#03a9f4'
    }
  }
});

export { loginTheme };
