import React, { useEffect, useState, useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Done from '@material-ui/icons/Done';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PhoneInput, { isValidPhoneNumber } from '../shared/PhoneInput';
import { getCountries } from 'react-phone-number-input';

import { isEmailInvalid } from '../../utils/formValidation';
import isMobile from '../../utils/isMobile';
import { _fetchUrl } from '../../utils/api';
import { guessCountry } from '../../utils/guessCountry';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '25px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '5px',
      marginTop: '10px'
    }
  }
}));

const FormSection = ({ title, children }) => (
  <Grid item sm={12} md={8} style={{ width: '100%', margin: '0px auto 30px auto' }}>
    <Paper style={{ padding: '40px 50px' }}>
      <Typography
        gutterBottom
        variant="h4"
        align="center"
        color="primary"
        style={{ marginBottom: '25px' }}
      >
        {title}
      </Typography>
      <Grid container direction="column" spacing={3} style={{ marginBottom: '15px' }}>
        {children}
      </Grid>
    </Paper>
  </Grid>
);

const FormField = ({ autoFocus, label, value, onChange, type, error, helperText, multiline }) => (
  <Grid item xs={12}>
    <TextField
      error={error}
      helperText={helperText}
      autoFocus={autoFocus}
      InputLabelProps={{ shrink: true }}
      label={label}
      fullWidth
      value={value}
      onChange={onChange}
      type={type || 'text'}
      multiline={multiline}
    />
  </Grid>
);

const SubmitSuccess = () => (
  <Grid
    container
    direction="column"
    alignItems="center"
    style={{ marginTop: '40px', padding: '10px 25px', textAlign: 'left' }}
  >
    <div>
      <Typography gutterBottom style={{ color: '#000' }} variant="h2">
        Submission Complete
        <Done
          style={{
            color: '#82E67E',
            fontSize: '1em',
            verticalAlign: 'text-bottom',
            marginLeft: '10px',
            marginBottom: '5px'
          }}
        />
      </Typography>

      <Typography gutterBottom variant="h5">
        You're all set.
      </Typography>
      <Typography gutterBottom variant="h5">
        We will reach out to you shortly.
      </Typography>
      <Typography gutterBottom variant="h5">
        If you have any questions, you can email us at{' '}
        <a href="mailto:care@expertopinion.md">care@expertopinion.md</a>.
      </Typography>
    </div>
  </Grid>
);

const ErrorText = ({ result }) => (
  <Grid
    style={{
      marginBottom: '25px',
      backgroundColor: 'red',
      padding: '10px',
      border: '1px solid #cecece',
      borderRadius: '5px',
      display: result ? 'block' : 'none'
    }}
    item
    xs={12}
  >
    <Typography variant="button" color="secondary">
      {Array.isArray(result) ? result.map((error, idx) => <div key={idx}>{error}</div>) : result}
    </Typography>
  </Grid>
);

const ProductSupportForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [country, setCountry] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [errorFields, setErrorFields] = useState({});

  const isMobileMemo = useMemo(() => isMobile(), []);
  const countries = useMemo(() => getCountries(), []);

  useEffect(() => {
    setCountry(guessCountry(countries));
  }, [setCountry, countries]);

  //
  // @dev - errorFields will be set numerically, falsy values (primarily undefined/false) will be used to indicate no errors.
  //        Values >=1 will be used as the element key for error message.
  //
  let ERROR_MESSAGE = {
    name: ['Name field is empty'],
    email: ['Email field is empty', 'Invalid Email'],
    phone: ['Phone field is empty', 'Invalid phone number'],
    message: ['Message field is empty']
  };

  // Make index base 1
  ERROR_MESSAGE = new Proxy(ERROR_MESSAGE, {
    get(target, prop) {
      return [undefined, ...target[prop]];
    }
  });

  const handleSubmit = () => {
    onSubmit();
  };

  const submitForm = async (body) => {
    setIsSubmitting(true);

    const result = await _fetchUrl({
      method: 'POST',
      path: 'contact',
      body
    });

    setIsSubmitting(false);

    switch (result.status) {
      case 'ok':
        setSubmitSuccess(true);
        break;
      default:
        setErrorText(result?.errors?.length ? result.errors : result);
        break;
    }
  };

  const onSubmit = async () => {
    setErrorText('');

    const formData = {
      name,
      email,
      countryCode: country,
      phone,
      message
    };

    const errors = {};

    // Ensure form data is not blank, if so set error 1
    // this is the first element in ERROR_MESSAGE thanks to
    // getter proxy.
    Object.keys(formData).forEach((field) => {
      switch (field) {
        case 'phone':
          if (!formData[field]) {
            errors[field] = 1;
          } else if (!isValidPhoneNumber(formData[field])) {
            errors[field] = 2;
          }
          break;
        case 'email':
          if (!formData[field]) {
            errors[field] = 1;
          } else if (isEmailInvalid(formData[field])) {
            errors[field] = 2;
          }
          break;
        default:
          if (!formData[field]) {
            errors[field] = 1;
          }
      }
    });

    // No empty fields
    if (Object.keys(errors).length) {
      setErrorFields({ ...errorFields, ...errors });
    }

    // No other kinds of errors
    else if (Object.values(errorFields).every((val) => val === false)) {
      setIsSubmitting(true);
      await submitForm(formData);
      setIsSubmitting(false);
    }
  };

  const classes = useStyles();

  return submitSuccess ? (
    <SubmitSuccess />
  ) : (
    <div className={classes.content}>
      <ErrorText result={errorText} />
      <FormSection title="Basic Information">
        <FormField
          label="Contact Name"
          error={!!errorFields.name}
          helperText={errorFields.name ? ERROR_MESSAGE.name[errorFields.name] : ''}
          autoFocus={isMobileMemo ? false : true}
          value={name}
          onChange={(evt) => {
            setErrorFields({ ...errorFields, name: false });
            setName(evt.target.value);
          }}
        />
        <FormField
          label="Contact Email"
          error={!!errorFields.email}
          helperText={errorFields.email ? ERROR_MESSAGE.email[errorFields.email] : ''}
          value={email}
          onChange={(evt) => {
            setErrorFields({ ...errorFields, email: false });
            setEmail(evt.target.value);
          }}
        />
        <FormControl
          fullWidth={true}
          style={{ padding: '0 12px', marginTop: '10px', marginBottom: '15px' }}
        >
          <PhoneInput
            onChange={(val) => {
              setErrorFields({ ...errorFields, phone: false });
              setPhone(val);
            }}
            country={country}
            value={phone}
            setCountry={setCountry}
            error={!!errorFields.phone}
            helperText={errorFields.phone ? ERROR_MESSAGE.phone[errorFields.phone] : ''}
            label="Contact Phone"
            required
          />
        </FormControl>

        <FormGroup style={{ marginTop: '24px', marginBottom: '8px' }}>
          <FormControlLabel
            control={
              <Checkbox
                id="confirm"
                style={{ alignSelf: 'flex-start', padding: '0px 8px' }}
                checked={confirmed}
                color="primary"
                value={confirmed}
                onChange={(e) => {
                  setConfirmed(e.target.checked);
                }}
                name="confirm"
              />
            }
            label="I consent to receive information by text message from Expert Opinion MD. By submitting this form, you are agreeing to receive recurring messages from Expert Opinion MD related to your request for information. Message and Data rates may apply. Carriers are not liable for delayed or undelivered messages. You also confirm that you have the authority to consent to receive text messages on the number you provided. Text help for help & stop to unsubscribe."
          />
        </FormGroup>
      </FormSection>
      <FormSection title="How can we help?">
        <FormField
          error={!!errorFields.message}
          helperText={errorFields.message ? ERROR_MESSAGE.message[errorFields.message] : ''}
          value={message}
          onChange={(evt) => {
            setErrorFields({ ...errorFields, message: false });
            setMessage(evt.target.value);
          }}
          type="tel"
          multiline
        />
      </FormSection>
      <div style={{ textAlign: 'center', marginBottom: '75px' }}>
        <Button
          onClick={handleSubmit}
          style={{ color: 'white' }}
          disabled={isSubmitting || !confirmed}
          type="submit"
          variant="contained"
          color="primary"
        >
          {isSubmitting ? <CircularProgress style={{ color: 'primary' }} /> : <span>Submit</span>}
        </Button>
      </div>
    </div>
  );
};

export default ProductSupportForm;
