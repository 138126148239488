import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export const AlertContext = React.createContext();

const styles = (theme) => ({
  message: {
    color: theme.palette.common.white
  }
});

const Alert = ({ classes, duration, message, variant, open, clear }) => {
  return (
    <Snackbar
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={clear}>
          <CloseIcon />
        </IconButton>
      ]}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      variant={variant}
      autoHideDuration={duration || 5000}
      ContentProps={{ 'aria-describedby': 'message-id' }}
      message={
        <span className={classes.message} id="message-id">
          {message}
        </span>
      }
      onClose={clear}
      open={open}
    />
  );
};

const StyledAlert = withStyles(styles, { withTheme: true })(Alert);

const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({ show: false });

  const clear = () => {
    setAlert({ show: false, message: undefined });
  };

  // variant styling not yet implemented
  const showAlert = (message, variant = 'info') => {
    setAlert({
      message,
      variant,
      show: true
    });
  };

  const [state] = useState({
    // showErrorAlert: message=>showAlert(message, 'error'),
    // showSuccessAlert: message=>showAlert(message, 'success'),
    showAlert
  });

  return (
    <AlertContext.Provider value={state}>
      <StyledAlert open={alert.show} message={alert.message} clear={clear} />
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
