import React, { useState, useEffect, useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { formatPhoneNumber } from 'react-phone-number-input';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import ScheduleIcon from '@material-ui/icons/Schedule';
import CallEndIcon from '@material-ui/icons/CallEnd';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import NoMeetingRoomIcon from '@material-ui/icons/NoMeetingRoom';

import moment from 'moment';

import { _fetchUrl } from '../../../utils/api';
import Confirm from '../../../utils/confirm';
import EOTable from '../../../partials/EOTable';
import EODialog from '../../../partials/EODialog';

import { ThemedDetails } from './Details';
import CallDetails from './CallDetails';

const paperStyle = {
  padding: '10px'
};

const tableOptions = {
  thirdSortClick: false,
  showTitle: false,
  sorting: true,
  search: false,
  paging: false
};

const isDisabled = (status) => {
  switch (status) {
    case 'MA_APPROVED':
    case 'CALL_SCHEDULED':
      return false;
    default:
      return true;
  }
};

const components = {
  Toolbar: () => <div></div>,
  Container: (props) => <Paper elevation={0}>{props.children}</Paper>
};

export const getDateStr = (date) => {
  if (!date) {
    return '';
  }

  return moment(date).format('MMMM Do, YYYY h:mm a');
};

export const linkToOpinion = (consult, history) => {
  history.push(`/admin/patients/EO_INDUSTRY/opinions/${consult.opinionId}`);
};

function combineArrays(arr1, arr2) {
  let toReturn = [];

  if (arr1) {
    toReturn = toReturn.concat(arr1);
  }

  if (arr2) {
    toReturn = toReturn.concat(arr2);
  }

  return toReturn;
}

const Workflow = (props) => {
  const [consults, setConsults] = useState([]);
  const [selectedConsult, setSelectedConsult] = useState(null);
  const [scheduleCallState, setScheduleCallState] = useState({ show: false });
  const [dialog, setDialog] = useState({ open: false, response: false, consult: null });

  let warningPalette;

  makeStyles((theme) => {
    warningPalette = theme.palette.warning;
  })();

  useEffect(() => {
    if (!props.user || !props.user.token) {
      return;
    }

    _fetchUrl({
      method: 'GET',
      path: `poe/partner/consults?filter=OPEN`,
      token: props.user.token
    })
      .then((r) => {
        setConsults(r.consults);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props.user]);

  const consultsByStatus = useMemo(() => {
    return consults.reduce((agg, item) => {
      if (item.status === 'CALL_COMPLETE') {
        if (item.expertResponse.timestamp === null || item.customerResponse.timestamp === null) {
          agg['AWAITING_RESPONSE'] = agg['AWAITING_RESPONSE'] || [];
          agg['AWAITING_RESPONSE'].push(item);
        }
      }

      if (item.status === 'CALL_COMPLETE' || item.status === 'CONSULT_COMPLETE') {
        agg['NOT_CLOSED'] = agg['NOT_CLOSED'] || [];
        agg['NOT_CLOSED'].push(item);
        return agg;
      }

      if (item.status === 'CALL_SCHEDULED' || item.status === 'CALL_RESCHEDULED') {
        agg['CALL_SCHEDULED_RESCHEDULED'] = agg['CALL_SCHEDULED_RESCHEDULED'] || [];
        agg['CALL_SCHEDULED_RESCHEDULED'].push(item);
        return agg;
      }

      agg[item.status] = agg[item.status] || [];
      agg[item.status].push(item);

      return agg;
    }, {});
  }, [consults]);

  const scheduleCall = (consult) => {
    setSelectedConsult(consult);

    _fetchUrl({
      method: 'GET',
      path: `partner/${consult.partnerId}?type=portal`
    }).then((r) => {
      if (r.status === 'ok') {
        setScheduleCallState({
          show: true,
          experts: r.partner.doctors
        });
      }
    });
  };

  const onDetailsSet = async (time, expert, isRescheduled, isShortNotice, timezone) => {
    _fetchUrl({
      method: 'PUT',
      path: `poe/partner/${selectedConsult.id}?update=call`,
      token: props.user.token,
      body: {
        date: time,
        expert,
        isRescheduled: isRescheduled || false,
        isShortNotice: isShortNotice || false,
        timezone
      }
    }).then((r) => {
      if (r.status === 'ok') {
        const res = consults.map((c) => (c.id === r.consult.id ? r.consult : c));
        setConsults(res);
      } else {
        let message = r.status;

        if (r.status === 'rescheduling in progress') {
          message = 'Rescheduling is already in progress, try again momentarily';
        }

        props.displayNotification({ message });
      }

      setScheduleCallState({ show: false });
    });
  };

  const callComplete = async (consult) => {
    const res = await Confirm('Mark call as complete?');

    if (res === false) {
      return;
    }

    _fetchUrl({
      method: 'PUT',
      path: `poe/partner/${consult.id}?update=callComplete`,
      token: props.user.token
    }).then((r) => {
      if (r.status === 'ok') {
        const res = consults.map((c) => (c.id === r.consult.id ? r.consult : c));
        setConsults(res);
      } else {
        props.displayNotification({ type: 'error', message: r.status });
      }
    });
  };

  useEffect(() => {
    if (dialog.response === true) {
      _fetchUrl({
        method: 'PUT',
        path: `poe/partner/${dialog.consult.id}?update=consultClosed`,
        token: props.user.token
      }).then((r) => {
        if (r.status === 'ok') {
          const res = consults.map((c) => (c.id === r.consult.id ? r.consult : c));
          setConsults(res);
        }
      });

      setDialog({ ...dialog, response: false, consult: null });
    }
  }, [dialog.response]);

  const noShowConsult = async (consult) => {
    const res = await Confirm('Mark Consult as Customer Did Not Show?');

    if (res === false) {
      return;
    }

    _fetchUrl({
      method: 'PUT',
      path: `poe/partner/${consult.id}?update=noShow`,
      token: props.user.token
    }).then((r) => {
      if (r.status === 'ok') {
        const res = consults.map((c) => (c.id === r.consult.id ? r.consult : c));
        setConsults(res);
      }
    });
  };

  const getMailLink = (name, email, body = null) => {
    const mailto = `mailto:${email}${body ? `?body=${body}` : ''}`;
    return (
      <a href={mailto} target="_new">
        {name}
      </a>
    );
  };

  const getSurveyURL = (partnerId, token) => {
    return `${process.env.REACT_APP_WEBAPP_BASE_URL}/${partnerId}/form/${token}`;
  };

  const handleClose = (response) => {
    setDialog({ ...dialog, open: false, response });
  };

  return (
    <div>
      {scheduleCallState.show && (
        <CallDetails
          consult={selectedConsult}
          experts={scheduleCallState.experts}
          open={scheduleCallState.show}
          handleClose={() => setScheduleCallState((e) => ({ ...e, show: false }))}
          handleUpdate={onDetailsSet}
        />
      )}

      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Paper style={paperStyle}>
            <Typography variant="h5" gutterBottom>
              Awaiting Approval / Review
            </Typography>
            <EOTable
              columns={[
                { title: 'Customer', field: 'requestDetails.customer.name' },
                {
                  title: 'Request (Local Time)',
                  defaultSort: 'desc',
                  field: 'requestDetails.timestamp',
                  render: (rowData) => <span>{getDateStr(rowData.requestDetails.timestamp)}</span>
                },
                {
                  title: 'Elapsed Time',
                  field: 'requestDetails.timestamp',
                  render: (rowData) => (
                    <span>
                      {moment
                        .duration(moment(rowData.requestDetails.timestamp).diff(moment()))
                        .humanize()}
                    </span>
                  )
                },
                { title: 'Status', render: (r) => <span>{r.status}</span> },
                {
                  title: 'Company Name',
                  field: 'partnerName',
                  render: (rowData) => <Chip label={rowData.partnerName} />
                }
              ]}
              detailPanel={({ rowData }) => <ThemedDetails details={rowData} />}
              data={combineArrays(
                consultsByStatus['NEEDS_REVIEW'],
                consultsByStatus['PENDING_APPROVAL']
              )}
              options={{
                ...tableOptions,
                rowStyle: (rowData) => {
                  let notApprovedWithin3Hours =
                    !rowData.approval.timestamp &&
                    rowData.created &&
                    moment().diff(moment(rowData.created), 'hours') >= 3;

                  return notApprovedWithin3Hours
                    ? {
                        backgroundColor: warningPalette.light
                      }
                    : {};
                }
              }}
              components={components}
            />
          </Paper>
        </Grid>

        <Grid item sm={12}>
          <Paper style={paperStyle}>
            <Typography variant="h5" gutterBottom>
              Needs Scheduling
            </Typography>
            <EOTable
              actions={[
                (rowData) => ({
                  disabled: isDisabled(rowData.status),
                  icon: () => <ScheduleIcon />,
                  tooltip: 'Schedule Call',
                  onClick: (event, rowData) => {
                    scheduleCall(rowData);
                  }
                }),
                {
                  icon: () => <AttachFileIcon />,
                  tooltip: 'Access Attached Records',
                  onClick: (event, rowData) => linkToOpinion(rowData)
                }
              ]}
              columns={[
                {
                  title: 'Customer',
                  field: 'requestDetails.customer.name',
                  render: (r) =>
                    getMailLink(r.requestDetails.customer.name, r.requestDetails.customer.email)
                },
                {
                  title: 'Customer Phone',
                  field: 'requestDetails.customer.phone',
                  render: (r) => {
                    const nationalPhone = formatPhoneNumber(r.requestDetails.customer.phone);

                    return nationalPhone ? nationalPhone : r.requestDetails.customer.phone;
                  }
                },
                {
                  title: 'Company Name',
                  field: 'partnerName',
                  render: (rowData) => <Chip label={rowData.partnerName} />
                }
              ]}
              detailPanel={({ rowData }) => <ThemedDetails details={rowData} />}
              data={consultsByStatus['MA_APPROVED']}
              options={{
                ...tableOptions,
                rowStyle: (rowData) => {
                  let notScheduledWithin3Hours =
                    rowData.approval.timestamp &&
                    moment().diff(moment(rowData.approval.timestamp), 'hours') >= 3;

                  return notScheduledWithin3Hours
                    ? {
                        backgroundColor: warningPalette.light
                      }
                    : {};
                }
              }}
              components={components}
            />
          </Paper>
        </Grid>
        <Grid item sm={12}>
          <Paper style={paperStyle}>
            <Typography variant="h5" gutterBottom>
              Upcoming Calls
            </Typography>
            <EOTable
              actions={[
                () => ({
                  icon: () => <ScheduleIcon />,
                  tooltip: 'Reschedule Call',
                  onClick: (event, rowData) => {
                    scheduleCall(rowData);
                  }
                }),
                () => ({
                  icon: () => <CallEndIcon />,
                  tooltip: 'Complete Call',
                  onClick: (event, rowData) => {
                    callComplete(rowData);
                  }
                }),
                {
                  icon: () => <NoMeetingRoomIcon />,
                  tooltip: 'Customer No Show',
                  onClick: (event, rowData) => noShowConsult(rowData)
                },
                {
                  icon: () => <AttachFileIcon />,
                  tooltip: 'Access Attached Records',
                  onClick: (event, rowData) => linkToOpinion(rowData, props.history)
                }
              ]}
              columns={[
                {
                  title: 'Expert',
                  field: 'callDetails.selectedExpert.name',
                  render: (r) =>
                    getMailLink(
                      r.callDetails.selectedExpert.name,
                      r.callDetails.selectedExpert.email
                    )
                },
                {
                  title: 'Customer',
                  field: 'requestDetails.customer.name',
                  render: (r) =>
                    getMailLink(r.requestDetails.customer.name, r.requestDetails.customer.email)
                },
                {
                  title: 'Call Date (Local Time)',
                  defaultSort: 'asc',
                  field: 'callDetails.date',
                  render: (rowData) => <span>{getDateStr(rowData.callDetails.date)}</span>
                },
                {
                  title: 'Company Name',
                  field: 'partnerName',
                  render: (rowData) => <Chip label={rowData.partnerName} />
                }
              ]}
              data={consultsByStatus['CALL_SCHEDULED_RESCHEDULED']}
              options={tableOptions}
              components={components}
            />
          </Paper>
        </Grid>
        <Grid item sm={12}>
          <Paper style={paperStyle}>
            <Typography variant="h5" gutterBottom>
              Calls Awaiting Survey Data
            </Typography>
            <EOTable
              columns={[
                {
                  title: 'Expert',
                  field: 'callDetails.selectedExpert.name',
                  render: (r) =>
                    getMailLink(
                      r.callDetails.selectedExpert.name,
                      r.callDetails.selectedExpert.email,
                      encodeURI(`Survey URL: ${getSurveyURL(r.partnerId, r.expertResponse.token)}`)
                    )
                },
                {
                  title: 'Customer',
                  field: 'requestDetails.customer.name',
                  render: (r) =>
                    getMailLink(
                      r.requestDetails.customer.name,
                      r.requestDetails.customer.email,
                      encodeURI(
                        `Survey URL: ${getSurveyURL(r.partnerId, r.customerResponse.token)}`
                      )
                    )
                },

                {
                  title: 'Expert Response',
                  sorting: false,
                  render: (rowData) => (
                    <span>{`${rowData.expertResponse.timestamp !== null}`}</span>
                  ),
                  lookup: { false: 'False', true: 'True' }
                },

                {
                  title: 'Customer Response',
                  sorting: false,
                  render: (rowData) => (
                    <span>{`${rowData.customerResponse.timestamp !== null}`}</span>
                  )
                }
              ]}
              data={consultsByStatus['AWAITING_RESPONSE']}
              options={tableOptions}
              components={components}
            />
          </Paper>
        </Grid>
      </Grid>

      <EODialog
        open={dialog.open}
        handleClose={() => handleClose(false)}
        handleOkay={() => handleClose(true)}
        showCancel={true}
      >
        Close consult?
      </EODialog>
    </div>
  );
};

export default Workflow;
