import React, { Component, Fragment } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import { calculatePatientPrice } from 'eo_utils';

class DoctorProductSelection extends Component {
  state = {
    price: this.props.price,
    patientPrice: calculatePatientPrice(this.props.price),
    selected: this.props.selected
  };

  toggleProductSelected = (productId) => (event) => {
    const selected = event.target.checked;
    this.setState({ selected }, () => {
      const element = document.getElementById(`${productId}-price`);
      return selected && element ? element.focus() : null;
    });
  };

  handlePriceChange = (event) =>
    this.setState({
      price: event.target.value,
      patientPrice: calculatePatientPrice(event.target.value)
    });

  render() {
    const { enableConnect, productId, productName, productType } = this.props;
    const { price, patientPrice, selected } = this.state;

    return (
      <Fragment>
        <FormControlLabel
          label={`${productName}${productType === 'EO_CONNECT' ? ' (Connect)' : ''}`}
          control={
            <Checkbox
              checked={selected}
              color="primary"
              id={productId}
              disabled={!enableConnect && productType === 'EO_CONNECT'}
              onClick={this.toggleProductSelected(productId)}
            />
          }
        />
        {selected && productType !== 'EO_CONNECT' && (
          <FormControl style={{ width: '50%' }}>
            <InputLabel shrink htmlFor={`${productId}-price`}>
              Price
            </InputLabel>
            <Input
              id={`${productId}-price`}
              defaultValue={price}
              onChange={this.handlePriceChange}
              type="number"
              inputProps={{ step: 'any' }}
            />
            <FormHelperText>Patient Price: {!isNaN(patientPrice) && patientPrice}</FormHelperText>
          </FormControl>
        )}
      </Fragment>
    );
  }
}

export default DoctorProductSelection;
