import React from 'react';
import { connect } from 'react-redux';
import { displayNotification } from '../../store/actions';
import { _fetchUrl } from '../../utils/api';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import ProgressButton from '../../partials/ProgressButton';

const mapStateToProps = (state) => ({
  user: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  displayNotification: (notification) => dispatch(displayNotification(notification))
});

const RedirectListItem = ({ redirect }) => {
  return (
    <Box display="flex" alignItems="baseline" style={{ gap: '8px' }}>
      <Typography variant="body1">{redirect.shortName}</Typography>
      <Link href={redirect.target} variant="body2">
        {redirect.path}
      </Link>
    </Box>
  );
};

const Redirects = (props) => {
  const [urls, setUrls] = React.useState([]);
  const [redirect, setRedirect] = React.useState({
    shortName: '',
    target: ''
  });
  const [submitting, setSubmitting] = React.useState(false);

  function fetchUrls() {
    if (!props.user) {
      return;
    }

    _fetchUrl({
      method: 'GET',
      path: `poe/urls`,
      token: props.user.token
    })
      .then((r) => {
        if (r.status === 'ok') {
          setUrls(r.urls);
        }
      })
      .catch((error) => {
        console.error(error);
        props.displayNotification('Error fetching Urls');
      });
  }

  React.useEffect(() => {
    fetchUrls();
  }, [props.user]);

  React.useEffect(() => {
    if (submitting) {
      _fetchUrl({
        method: 'POST',
        path: `poe/urls`,
        token: props.user.token,
        body: {
          target: redirect.target,
          shortName: redirect.shortName
        }
      })
        .then((r) => {
          if (r.status === 'ok') {
            props.displayNotification('URL Added');
            setSubmitting(false);
            setRedirect({ target: '', shortName: '' });
            fetchUrls();
          }
        })
        .catch((error) => {
          console.error(error);
          props.displayNotification('Adding URL Error');
          setSubmitting(false);
        });
    }
  }, [submitting]);

  const setShortName = (e) => {
    const val = e.target.value;
    setRedirect({
      ...redirect,
      shortName: val
    });
  };

  const setTarget = (e) => {
    const val = e.target.value;
    setRedirect({
      ...redirect,
      target: val
    });
  };

  const onCreateUrl = () => {
    if (redirect.target !== '' && redirect.shortName !== '') {
      setSubmitting(true);
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" style={{ gap: '8px' }}>
        {urls.map((url) => (
          <RedirectListItem key={url.target} redirect={url} />
        ))}
      </Box>

      <Box mt={2} display="flex" style={{ gap: '16px' }} alignItems="baseline">
        <TextField
          label="Shortname"
          helperText="This will be part of the url alconexpert"
          onChange={setShortName}
        />
        <TextField
          label="Redirect Target"
          helperText="e.g. https://www.someurl.com/hello"
          onChange={setTarget}
        />
        <ProgressButton
          style={{ maxHeight: '40px' }}
          disabled={submitting}
          onClick={onCreateUrl}
          variant="contained"
          color="primary"
        >
          Add Redirect
        </ProgressButton>
      </Box>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Redirects);
