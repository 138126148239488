import React, { useState, useMemo } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';

import Workflow from './Workflow';
import ConsultTable from './ConsultTable';
import ManagePartner from './ManagePartner';
import LookerStudio from '../../shared/LookerStudio';

import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const IndustryForm = (props) => {
  const [tabValue, setTabValue] = useState(0);

  const changeTabs = (e, v) => {
    setTabValue(v);
  };

  const isAdmin = useMemo(() => {
    return props.user?.type?.includes('EO_ADMIN');
  }, [props.user]);

  const isPartnerManager = useMemo(() => {
    return props.user?.type?.includes('PARTNER_MANAGER');
  }, [props.user]);

  const showLookerStudio = useMemo(() => {
    return isAdmin || isPartnerManager;
  }, [isAdmin, isPartnerManager]);

  return (
    <div>
      <Typography variant="caption">Updated: {moment().format('llll')}</Typography>

      <Tabs value={tabValue} onChange={changeTabs}>
        <Tab label="Workflow" />
        <Tab label="All Consults" />
        {showLookerStudio && <Tab label="Looker Studio" />}
        <Tab label="Manage Partners" />
      </Tabs>

      {tabValue === 0 && <Workflow {...props} />}

      {tabValue === 1 && <ConsultTable {...props} />}

      {showLookerStudio && tabValue === 2 && <LookerStudio {...props} />}

      {tabValue === 3 - (showLookerStudio ? 0 : 1) && <ManagePartner {...props} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(IndustryForm);
