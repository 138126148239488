import React, { Component, Fragment } from 'react';

import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  contentEditable: {
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    boxShadow: theme.shadows[2],
    borderRadius: theme.spacing(0.5)
  }
});

class DoctorBio extends Component {
  state = {
    html: this.props.bio
  };
  contentEditable = React.createRef();

  handleChange = (event) => this.setState(() => ({ html: event.target.value }));

  handleBlur = () =>
    this.setState(
      () => ({ html: sanitizeHtml(this.state.html) }),
      this.props.handleBioUpdate('bio', this.state.html)
    );

  render = () => {
    const { classes } = this.props;
    const { html } = this.state;

    return (
      <Fragment>
        <Typography variant="subtitle1">Doctor Bio</Typography>
        <Typography variant="body1">
          Edit your public bio below. You can copy/paste from a standard document editor.
        </Typography>
        <ContentEditable
          className={classes.contentEditable}
          innerRef={this.contentEditable}
          html={html}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
      </Fragment>
    );
  };
}

export default withStyles(styles, { withTheme: true })(DoctorBio);
