import React, { useState, useEffect, useMemo } from 'react';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import GetAppIcon from '@material-ui/icons/GetApp';
import FilterListIcon from '@material-ui/icons/FilterList';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DateFnsUtils from '@date-io/date-fns';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import NativeSelect from '@material-ui/core/NativeSelect';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import moment from 'moment';

import { _fetchUrl } from '../../../utils/api';
import { downloadFile } from '../../../utils/downloadFile';
import EOTable from '../../../partials/EOTable';
import { linkToOpinion, getDateStr } from './Workflow';
import { getBillingCode } from '../../../utils/billing';
import * as survey from '../../../utils/survey';
import SurveyResponseModal from '../../shared/SurveyResponseModal';

import Confirm from '../../../utils/confirm';
import { ThemedDetails } from './Details';

function formatTimeForExport(timestamp) {
  return moment(timestamp).format('lll Z');
}

const CUSTOMER_SURVEY_ADEQUACY_IDX = 0;
const CUSTOMER_SURVEY_EXPERIENCE_IDX = 1;
const CUSTOMER_SURVEY_CONFIDENCE_IDX = 2;
const CUSTOMER_SURVEY_RECOMMENDATION_IDX = 3;
const CUSTOMER_SURVEY_TIMESTAMP_IDX = 4;

function recordToArray(header, record, companies, GLOBAL_HEADERS) {
  if (header) {
    return [
      '#id',
      'status',
      'request_date',
      'customer_name',
      'customer_email',
      'customer_phone',
      'rep_email',
      'medical_affairs_appover',
      'approval_date',
      'call_date (UTC)',
      'expert',
      'product',
      'notes (deprecated)',
      'customer_adequacy_score',
      'customer_experience_rating',
      'customer_recommendations',
      'customer_response_date',
      ...GLOBAL_HEADERS.customer.map((question) => Object.values(question)[0]),
      'expert_case_complexity_score',
      'expert_feedback',
      'expert_recommendations',
      'expert_share_phone_number',
      'expert_response_date',
      ...GLOBAL_HEADERS.expert.map((question) => Object.values(question)[0]),
      'primary_choice_expert',
      'backup_expert',
      'second_backup_expert',
      'company',
      'request_date_raw',
      'approval_date_raw',
      'call_date_raw',
      'expert_response_date_raw',
      'customer_response_date_raw',
      'call_type',
      'record_consult_requested',
      'next_day_consult_requested',
      'request_month_year',
      'customer_confidence_rating',
      'case_description',
      'patient_question',
      'billable_date',
      'billing_code',
      'is_urgent',
      'is_noshow',
      'lastminute_reschedule'
    ];
  }

  const billing = getBillingCode(record);

  const billingDate = record.expertResponse.timestamp || record.callDetails.date;

  const customerResult = survey.parseCustomerSurveyResult(
    record.customerResponse,
    formatTimeForExport
  );

  const arr = [
    record.id,
    record.status,
    formatTimeForExport(record.requestDetails.timestamp),
    record.requestDetails.customer.name,
    record.requestDetails.customer.email,
    record.requestDetails.customer.phone,
    record.requestDetails.rep.email,
    record.approval.approver,
    formatTimeForExport(record.approval.timestamp),
    record.callDetails.date ? formatTimeForExport(record.callDetails.date) : '',
    record.callDetails.selectedExpert.name,
    record.requestDetails.category,
    record.requestDetails.notes,

    customerResult[CUSTOMER_SURVEY_ADEQUACY_IDX],
    customerResult[CUSTOMER_SURVEY_EXPERIENCE_IDX],
    customerResult[CUSTOMER_SURVEY_RECOMMENDATION_IDX],
    customerResult[CUSTOMER_SURVEY_TIMESTAMP_IDX],

    ...GLOBAL_HEADERS.customer.map((question) => {
      let key = Object.keys(question)[0];

      if (record.globalSurveyId) {
        // Not submitted yet
        return record.customerResponse[key] ? record.customerResponse[key] : 'N/A';
      }

      return '';
    }),

    ...survey.parseExpertSurveyResult(record.expertResponse, formatTimeForExport),

    ...GLOBAL_HEADERS.expert.map((question) => {
      let key = Object.keys(question)[0];

      if (record.globalSurveyId) {
        // Not submitted yet
        return record.expertResponse[key] ? record.expertResponse[key] : 'N/A';
      }

      return '';
    }),

    record.requestDetails.experts[0].name,
    record.requestDetails.experts[1] ? record.requestDetails.experts[1].name : 'N/A',
    record.requestDetails.experts[2] ? record.requestDetails.experts[2].name : 'N/A',
    companies[record.partnerId] || record.partnerId,
    record.requestDetails.timestamp,
    record.approval.timestamp,
    record.callDetails.date || '',
    record.expertResponse.timestamp || '',
    record.customerResponse.timestamp || '',
    record.requestDetails.voiceCall ? 'voice' : 'video',
    record.requestDetails.recordMeeting,
    record.requestDetails.nextDay,
    moment(record.requestDetails.timestamp).format('MMM-yyyy'),
    customerResult[CUSTOMER_SURVEY_CONFIDENCE_IDX],
    record.requestDetails.caseDescription || '',
    record.requestDetails.patientQuestion || '',
    billingDate ? moment(billingDate).format('MMM-DD-yyyy') : '',
    billing.billingCode || '',
    billing.isUrgent || 'FALSE',
    billing.isNoShow || 'FALSE',
    billing.lastMinuteCount || ''
  ];

  return arr.map((a) => {
    const b = `${a}`;
    return `"${b.replace(/"/g, '""')}"`;
  });
}

function recordToBillingArray(header, record, companies, surveyQuestions) {
  if (header) {
    return [
      '#id',
      'expert',
      'customer',
      'product',
      'company',
      'call_date (UTC)',
      'billable_date',
      'billing_code',
      'lastminute_reschedule',
      'country_code',
      'rep_region',
      'invoice_amount',
      'expert_amount'
    ];
  }

  const billing = getBillingCode(record, surveyQuestions);

  if (!billing.billingCode) {
    return [];
  }

  const billingDate = record.expertResponse.timestamp || record.callDetails.date;

  const arr = [
    record.id,
    record.callDetails.selectedExpert.name,
    record.requestDetails.customer.name,
    record.requestDetails.category,
    companies[record.partnerId] || record.partnerId,
    record.callDetails.date ? formatTimeForExport(record.callDetails.date) : '',
    billingDate ? moment(billingDate).format('MMM-DD-yyyy') : '',
    billing.billingCode || '',
    billing.lastMinuteCount || '',
    record.requestDetails.customer.countryCode || '',
    record.requestDetails.rep.region || '',
    billing.invoiceAmt || '',
    billing.doctorAmount || ''
  ];

  return arr.map((a) => {
    const b = `${a}`;
    return `"${b.replace(/"/g, '""')}"`;
  });
}

const ConsultTable = (props) => {
  const [consults, setConsults] = useState([]);
  const [partnerRepEmailRegionMap, setPartnerRepEmailRegionMap] = useState(null);
  const [loadingPartnerRepEmailRegionMap, setLoadingPartnerRepEmailRegionMap] = useState(false);
  const [showDupeConsults, setShowDupeConsults] = useState(false);
  const [open, setOpen] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [billingOnly, setBillingOnly] = React.useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isRefreshingConsults, setIsRefreshingConsults] = React.useState(false);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [daysOfConsultsShown, setDaysOfConsultsShown] = useState('60');
  const [durationSurveyQuestions, setDurationSurveyQuestions] = useState({});
  const [surveyCache, setSurveyCache] = useState({});
  const [globalQuestionsCache, setGlobalQuestionsCache] = useState({
    lastFetchTime: null,
    questions: {}
  });

  const billingButtonDisabled = useMemo(() => {
    return billingOnly ? loadingPartnerRepEmailRegionMap : false;
  }, [billingOnly, loadingPartnerRepEmailRegionMap]);

  useEffect(() => {
    (async () => {
      refreshConsults(daysOfConsultsShown);
    })();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (type) => {
    setAnchorEl(null);

    switch (type) {
      case 'All':
        triggerDownloadFile();
        break;
      case 'Monthly':
        setOpen(true);
        break;
    }
  };

  const refreshConsults = (numberOfDays) => {
    if (isRefreshingConsults === false) {
      setIsRefreshingConsults(true);
      setDaysOfConsultsShown(numberOfDays);
      fetchConsults(numberOfDays);
    }
  };

  const fetchPartners = () => {
    if (!props.user || !props.user.token) {
      return;
    }

    setLoadingPartnerRepEmailRegionMap(true);

    _fetchUrl({
      method: 'GET',
      path: `poe/partner`,
      token: props.user.token
    })
      .then((r) => {
        if (r.status === 'ok') {
          const map = r.partners.map((partner) => ({
            [partner.name]: partner.reps?.reduce((a, v) => ({ ...a, [v.email]: v.region }), {})
          }));

          const processed = map.reduce((obj, item) => {
            const key = Object.keys(item)[0];
            obj[key] = item[key];
            return obj;
          }, {});

          setPartnerRepEmailRegionMap(processed);
        } else {
          console.error(r.error);
        }

        setLoadingPartnerRepEmailRegionMap(false);
      })
      .catch((e) => {
        setLoadingPartnerRepEmailRegionMap(false);
        console.error(e);
      });
  };

  const fetchConsults = (numberOfDays) => {
    if (!props.user || !props.user.token) {
      return;
    }

    _fetchUrl({
      method: 'GET',
      path: `poe/partner/consults?days=${numberOfDays}`,
      token: props.user.token
    })
      .then((r) => {
        if (r.status === 'ok') {
          setConsults(r.consults);
          setIsRefreshingConsults(false);
          const time = new Date();
          setLastFetchTime(time);
        } else {
          console.error(r.error);
          setConsults([]);
          setIsRefreshingConsults(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setConsults([]);
        setIsRefreshingConsults(false);
      });
  };

  const filteredConsults = useMemo(() => {
    return consults.filter((consult) => consult.status !== 'CLOSED_DUPLICATE');
  }, [consults]);

  const stats = useMemo(() => {
    const today = new Date();

    const statsObj = filteredConsults.reduce((agg, item) => {
      const date = new Date(item.requestDetails.timestamp);

      const isCurMonth = date.getMonth() === today.getMonth() && date.getYear() === today.getYear();

      const isApproved =
        item.status !== 'DENIED' &&
        item.status !== 'PENDING_APPROVAL' &&
        item.status !== 'NEEDS_REVIEW';

      let isLastMonth = false;

      if (!isCurMonth) {
        if (date.getMonth() === today.getMonth() - 1 && date.getYear() === today.getYear()) {
          isLastMonth = true;
        }

        // january is an edge case
        if (!isLastMonth && today.getMonth() === 0 && date.getMonth() === 11) {
          if (today.getYear() - 1 === date.getYear()) {
            isLastMonth = true;
          }
        }
      }

      agg[item.partnerName] = agg[item.partnerName] || {
        name: item.partnerName,
        mtdApproved: 0,
        mtd: 0,
        lastMonthApproved: 0,
        total: 0
      };

      isCurMonth && agg[item.partnerName].mtd++;
      isCurMonth && isApproved && agg[item.partnerName].mtdApproved++;
      isLastMonth && isApproved && agg[item.partnerName].lastMonthApproved++;

      isApproved && agg[item.partnerName].total++;

      return agg;
    }, {});

    const totals = {
      name: 'Total',
      mtd: 0,
      mtdApproved: 0,
      lastMonthApproved: 0,
      total: 0
    };

    const toReturn = Object.keys(statsObj).map((key) => {
      const value = statsObj[key];

      totals.mtd += value.mtd;
      totals.lastMonthApproved += value.lastMonthApproved;
      totals.mtdApproved += value.mtdApproved;
      totals.total += value.total;

      return value;
    });

    toReturn.push(totals);

    return toReturn;
  }, [filteredConsults]);

  const experts = useMemo(() => {
    return consults.reduce((agg, item) => {
      const ex = item.callDetails.selectedExpert;
      if (ex) {
        agg[ex.name] = ex.name;
      } else {
        agg[null] = '';
      }
      return agg;
    }, {});
  }, [consults]);

  const products = useMemo(() => {
    return consults.reduce((agg, item) => {
      agg[item.requestDetails.category] = item.requestDetails.category;
      return agg;
    }, {});
  }, [consults]);

  const companies = useMemo(() => {
    return consults.reduce((agg, item) => {
      agg[item.partnerId] = item.partnerName;
      return agg;
    }, {});
  }, [consults]);

  const consultTableTitle = useMemo(() => {
    const title =
      daysOfConsultsShown === 'ALL'
        ? 'All Consults'
        : `Consults for last ${daysOfConsultsShown} days`;

    if (showDupeConsults) {
      return title + ' Includes Duplicates';
    }

    return title;
  }, [showDupeConsults, daysOfConsultsShown]);

  useEffect(() => {
    if (!props.user || !props.user.token) {
      return;
    }

    _fetchUrl({
      method: 'GET',
      path: `poe/surveys/questions`,
      token: props.user.token
    })
      .then((res) => {
        if (res.status === 'ok') {
          const q = res.questions.reduce((acc, question) => {
            acc[question.surveyId] = question;
            return acc;
          }, {});

          setDurationSurveyQuestions(q);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [consults]);

  const fetchQuestions = (surveyId) => {
    return new Promise((resolve, reject) => {
      _fetchUrl({
        method: 'GET',
        path: `poe/survey/${surveyId}`,
        token: props.user.token
      }).then((r) => {
        if (r.status === 'ok') {
          resolve(r.survey);
        } else {
          reject();
          console.error('Error loading partners:', r.error);
        }
      });
    });
  };

  const triggerDownloadFile = async (selectedDate) => {
    setDownloading(true);

    const GLOBAL_QUESTIONS_CACHE =
      globalQuestionsCache.lastFetchTime === lastFetchTime ? globalQuestionsCache : {};

    const GLOBAL_HEADERS = { customer: [], expert: [] };

    for (let consult of filteredConsults) {
      if (consult.globalSurveyId) {
        let GLOBAL_SURVEY;

        if (!GLOBAL_QUESTIONS_CACHE[consult.globalSurveyId]) {
          GLOBAL_QUESTIONS_CACHE[consult.globalSurveyId] = await fetchQuestions(
            consult.globalSurveyId
          );
        }

        GLOBAL_SURVEY = GLOBAL_QUESTIONS_CACHE[consult.globalSurveyId];

        if (GLOBAL_SURVEY) {
          GLOBAL_SURVEY.questions.customer.forEach((question) => {
            if (question.global) {
              GLOBAL_HEADERS.customer.push({
                [question.id]: `customer_globalV${GLOBAL_SURVEY.version}_${question.label}`
              });
            }
          });

          GLOBAL_SURVEY.questions.expert.forEach((question) => {
            if (question.global) {
              GLOBAL_HEADERS.expert.push({
                [question.id]: `expert_globalV${GLOBAL_SURVEY.version}_${question.label}`
              });
            }
          });
        }
      }
    }

    let lines = filteredConsults
      .sort((a, b) => {
        return a.requestDetails.timestamp - b.requestDetails.timestamp;
      })
      .map((consult) =>
        billingOnly
          ? recordToBillingArray(
              false,
              consult,
              companies,
              durationSurveyQuestions,
              partnerRepEmailRegionMap
            ).join(',')
          : recordToArray(false, consult, companies, GLOBAL_HEADERS).join(',')
      )
      .filter((a) => a.length !== 0);

    // Additional filter when selectedDate is set
    if (selectedDate) {
      lines = lines.filter((record) => {
        // If record matches selected month / year - BillingOnly is using billing date (6) instead of calldate (5)
        let date = billingOnly ? record.split('","')[6] : record.split('","')[2];

        // if using billing date (not calldate), change formatting. Call date uses regular format
        let dateFormat = billingOnly ? 'MMM-DD-YYYY' : 'MMM DD, YYYY';

        return moment(selectedDate).month() === moment(date, dateFormat).month() &&
          moment(selectedDate).year() === moment(date, dateFormat).year()
          ? true
          : false;
      });
    }

    let headers = recordToArray(true, null, null, GLOBAL_HEADERS);

    const text =
      '﻿' +
      [billingOnly ? recordToBillingArray(true).join(',') : headers.join(','), ...lines].join('\n');

    const date = moment();

    const fileName = billingOnly
      ? selectedDate
        ? `billing-report-${moment(selectedDate).format('MMM-YYYY')}.csv`
        : `billing-report-ALL.csv`
      : `Consult-report-${date.format('YYYY-MM-DD')}.csv`;

    downloadFile(text, fileName);

    setGlobalQuestionsCache({ lastFetchTime, questions: GLOBAL_QUESTIONS_CACHE });
    handleDateChange(new Date());
    setOpen(false);
    setDownloading(false);
  };

  const closeDuplicate = async (consult) => {
    const res = await Confirm('Close this as a duplicate (It cannot be undone)?');

    if (res === false) {
      return;
    }

    _fetchUrl({
      method: 'PUT',
      path: `poe/partner/${consult.id}?update=closedDuplicate`,
      token: props.user.token
    }).then((r) => {
      if (r.status === 'ok') {
        const res = consults.map((c) => (c.id === r.consult.id ? r.consult : c));
        setConsults(res);
      }
    });
  };

  const updateConsult = (consult, updateType, value) => {
    return _fetchUrl({
      method: 'PUT',
      path: `poe/partner/${consult.id}?update=${updateType}`,
      token: props.user.token,
      body: {
        value
      }
    }).then((r) => {
      if (r.status === 'ok') {
        const res = consults.map((c) => (c.id === r.consult.id ? r.consult : c));
        setConsults(res);
      }
    });
  };

  return (
    <form>
      <EOTable
        isLoading={isRefreshingConsults}
        title="Consult Stats"
        columns={[
          { title: 'Partner Name', field: 'name' },
          { title: 'Requests Previous Month (Approved)', field: 'lastMonthApproved' },
          { title: 'Requests MTD', field: 'mtd' },
          { title: 'Approved MTD', field: 'mtdApproved' },
          { title: 'Total (All time approved)', field: 'total' }
        ]}
        data={stats}
        options={{
          paging: false,
          sorting: false,
          search: false
        }}
      />
      <div style={{ margin: '10px 0px' }} />

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleClose('All')} disabled={billingButtonDisabled}>
          All
        </MenuItem>
        <MenuItem onClick={() => handleClose('Monthly')} disabled={billingButtonDisabled}>
          Monthly
        </MenuItem>
      </Menu>

      <Box style={{ gap: '10px' }} my={1} alignItems="baseline" display="flex" flexDirection="row">
        <NativeSelect onChange={(e) => refreshConsults(e.target.value)} defaultValue="60">
          <option value="60">Recent Consults (60 Days)</option>
          <option value="ALL">All Consults</option>
        </NativeSelect>
        <Typography variant="body1">
          Last Refreshed: {lastFetchTime ? lastFetchTime.toString() : 'Never'}
        </Typography>
      </Box>

      <EOTable
        isLoading={isRefreshingConsults}
        title={consultTableTitle}
        cellEditable={{
          cellStyle: {},
          onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            if (newValue === oldValue) {
              return Promise.resolve();
            }

            if (columnDef.title === 'Rep') {
              return updateConsult(rowData, 'repEmail', newValue);
            } else if (columnDef.title === 'Status') {
              return updateConsult(rowData, 'statusUpdate', newValue);
            }

            return Promise.resolve();
          }
        }}
        actions={[
          {
            icon: () => <GetAppIcon />,
            tooltip: 'Export Data',
            isFreeAction: true,
            onClick: (e) => {
              setBillingOnly(false);
              handleClick(e);
            }
          },
          {
            icon: () => <AttachMoneyIcon />,
            tooltip: 'Export Billing Data',
            isFreeAction: true,
            onClick: (e) => {
              setBillingOnly(true);
              handleClick(e);
              if (!partnerRepEmailRegionMap) {
                fetchPartners();
              }
            }
          },
          {
            icon: () => <FilterListIcon color={showDupeConsults ? 'primary' : 'inherit'} />,
            tooltip: 'Filter Duplicate Consults',
            onClick: () => setShowDupeConsults((s) => !s),
            isFreeAction: true
          },

          {
            icon: () => <AttachFileIcon />,
            tooltip: 'Access Attached Records',
            onClick: (event, rowData) => linkToOpinion(rowData, props.history)
          },
          () => ({
            icon: () => <DeleteIcon />,
            tooltip: 'Remove as Duplicate',
            onClick: (event, rowData) => {
              closeDuplicate(rowData);
            }
          })
        ]}
        columns={[
          {
            title: 'Request Date',
            field: 'requestDetails.timestamp',
            editable: 'never',
            defaultSort: 'desc',
            filtering: false,
            render: (rowData) => <span>{getDateStr(rowData.requestDetails.timestamp)}</span>
          },
          {
            title: 'Status',
            field: 'status',
            sorting: false,
            lookup: {
              PENDING_APPROVAL: 'Pending',
              NEEDS_REVIEW: 'Needs Review',
              MA_APPROVED: 'Approved',
              DENIED: 'Denied',
              CALL_SCHEDULED: 'Call Scheduled',
              CALL_COMPLETE: 'Call Completed',
              EXPERT_SURVEY_SUBMITTED: 'Survey Submitted',
              CONSULT_COMPLETE: 'Consult Complete',
              CONSULT_CLOSED: 'Closed',
              CLOSED_DUPLICATE: 'Duplicate',
              CLOSED_NO_SHOW: 'No Show',
              CLOSED_CANCELED: 'Canceled'
            }
          },
          { title: 'Company', field: 'partnerId', lookup: companies, editable: 'never' },
          {
            title: 'Customer Doctor',
            field: 'requestDetails.customer.name',
            filtering: false,
            editable: 'never'
          },
          {
            title: 'Selected Expert',
            field: 'callDetails.selectedExpert.name',
            lookup: experts,
            editable: 'never'
          },
          {
            title: 'Call Date',
            field: 'callDetails.date',
            filtering: false,
            render: (rowData) => <span>{getDateStr(rowData.callDetails.date)}</span>,
            editable: 'never'
          },
          {
            title: 'Product',
            field: 'requestDetails.category',
            lookup: products,
            editable: 'never'
          },
          {
            title: 'Rep',
            field: 'requestDetails.rep.email',
            filtering: false,
            render: (r) => <span>{r.requestDetails.rep.email.toLowerCase()}</span>
          },
          {
            title: 'Customer Feedback',
            sorting: false,
            render: (rowData) => {
              if (rowData.customerResponse.timestamp !== null) {
                return (
                  <div>
                    <SurveyResponseModal
                      surveyCache={surveyCache}
                      setSurveyCache={setSurveyCache}
                      user={props.user}
                      consultId={rowData.id}
                      customer={true}
                    />
                  </div>
                );
              } else {
                return (
                  <IconButton disabled={true}>
                    <VisibilityIcon />
                  </IconButton>
                );
              }
            },
            editable: 'never'
          },
          {
            title: 'Expert Feedback',
            sorting: false,
            render: (rowData) => {
              if (rowData.expertResponse.timestamp !== null) {
                return (
                  <div>
                    <SurveyResponseModal
                      surveyCache={surveyCache}
                      setSurveyCache={setSurveyCache}
                      user={props.user}
                      consultId={rowData.id}
                      customer={false}
                    />
                  </div>
                );
              } else {
                return (
                  <IconButton disabled={true}>
                    <VisibilityIcon />
                  </IconButton>
                );
              }
            },
            editable: 'never'
          }
        ]}
        data={showDupeConsults ? consults : filteredConsults}
        detailPanel={({ rowData }) => <ThemedDetails details={rowData} />}
        options={{
          draggable: true,
          thirdSortClick: false,
          sorting: true,
          filtering: true,
          pageSize: 25,
          pageSizeOptions: [25, 50, 100]
        }}
      />

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant="static"
              openTo="month"
              views={['year', 'month']}
              label="Year and Month"
              helperText="Start from year selection"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
          <div>
            <Button
              variant="contained"
              color="primary"
              style={{ width: '100%' }}
              onClick={() => triggerDownloadFile(selectedDate)}
              disable={(!downloading).toString()}
            >
              Download
            </Button>
          </div>
        </div>
      </Modal>
    </form>
  );
};

export default ConsultTable;
