import React, { useEffect, useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';

import logo from './logoBlue.png';
import logo2x from './logoBlue@2x.png';
import { loginTheme } from './theme';
import styles from './styles';
import DocumentTitle from '../../partials/DocumentTitle';
import Container from '@material-ui/core/Container';
import { getQueryVariable } from '../../utils/getQueryVariable';

import Login from './Login';
import OTPForm from './OTPForm';
import RegisterAccount from './RegisterAccount';
import ResetPassword from './ResetPassword';

const LoginRoot = (props) => {
  const { classes } = props;

  useEffect(() => {
    if (props.user) {
      redirectLoggedInUser();
    }
  }, [props.user]);

  const redirectLoggedInUser = () => {
    const { user, history } = props;

    if (user && user.token) {
      let url = '/';

      if (user.type.includes('D2D_EXPERT')) {
        url = '/expert';
      } else if (user.type.includes('DOCTOR') || user.type.includes('DOCTOR_ADMIN')) {
        url = '/doctor';
      } else if (user.type.includes('PATIENT')) {
        url = '/patient';
      } else if (user.type.includes('EO_ADMIN')) {
        url = '/admin';
      } else if (user.type.includes('CLINIC_STAFF')) {
        url = '/staff';
      } else if (user.type.includes('PARTNER_MANAGER')) {
        url = '/partner';
      }

      return history.push(REDIRECT_TO || url);
    }
  };

  const REDIRECT_TO = useMemo(() => {
    return getQueryVariable('redirect', props.history.location.search);
  }, []);

  return (
    <React.Fragment>
      <MuiThemeProvider theme={loginTheme}>
        <DocumentTitle title="Log in" />

        <div className={classes.root}>
          <div style={{ margin: 'auto auto' }}>
            <Container maxWidth="md">
              <img
                src={logo}
                srcSet={`${logo} 1x, ${logo2x} 2x`}
                className={classes.image}
                alt="logo"
              />

              <Switch>
                <Route exact path="/login" render={() => <Login {...props} />} />
                <Route
                  path="/login/otp"
                  render={() => <OTPForm redirect={redirectLoggedInUser} {...props} />}
                />
                <Route
                  path="/login/register"
                  render={() => <RegisterAccount redirect={redirectLoggedInUser} {...props} />}
                />
                <Route
                  path="/login/resetpassword"
                  render={() => <ResetPassword redirect={redirectLoggedInUser} {...props} />}
                />
              </Switch>
            </Container>
          </div>
        </div>
      </MuiThemeProvider>
    </React.Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(LoginRoot);
