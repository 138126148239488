import piexif from 'piexifjs';

export default async (file) => {
  return new Promise((resolve) => {
    const fileType = file.type;

    // EXIF data is only present in these formats
    if (fileType === 'image/jpeg' || fileType === 'image/tiff') {
      const reader = new FileReader();

      reader.onload = function (event) {
        try {
          const arrayBuffer = event.target.result;
          const binaryString = Array.from(new Uint8Array(arrayBuffer))
            .map((byte) => String.fromCharCode(byte))
            .join('');

          const exifObj = piexif.load(binaryString);

          exifObj['0th'][piexif.ImageIFD.Orientation] = 1; // 1 means no rotation, flip, etc.

          const exifbytes = piexif.dump(exifObj);
          const newData = piexif.insert(exifbytes, binaryString);

          const blob = new Blob([new Uint8Array([...newData].map((char) => char.charCodeAt(0)))], {
            type: file.type
          });

          resolve(blob);
        } catch (e) {
          resolve(file);
        }
      };

      reader.readAsArrayBuffer(file);
    } else {
      resolve(file);
    }
  });
};
