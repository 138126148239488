import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';

import EOTable from '../../../partials/EOTable';

import { _fetchUrl } from '../../../utils/api';

const mapStateToProps = (state) => ({
  user: state.auth
});

function getUrl(opinion) {
  if (opinion.type === 'EO_CORE_V2') {
    return `${window.location.origin}/videoupload?token=${opinion.uploadToken}`;
  } else {
    return 'N/A';
  }
}

const T = (props) => (
  <Typography variant={props.V || 'body1'} {...props}>
    {props.children}
  </Typography>
);

const Entry = ({ title, children }) => (
  <Box display="flex" flexDirection="row" maxWidth={300} justifyContent="space-between">
    <T V="body2">{title}</T>
    <T>{children}</T>
  </Box>
);

const Q = ({ q, a }) => (
  <>
    <T V="caption" color="primary">
      {q}
    </T>
    <T gutterBottom>{a}</T>
  </>
);

const OpinionDetail = ({ opinion, onClose }) => (
  <Box display="flex" flexDirection="column" mt={2} style={{ gap: '8px' }}>
    <T V="h6">Opinion ID Details</T>
    <T V="caption">{opinion.id}</T>
    <Entry title="Created">{new Date(opinion.created).toDateString()}</Entry>
    <Entry title="Status">{opinion.status}</Entry>

    <T style={{ marginTop: '8px' }} V="h6">
      Patient Details:
    </T>
    <Entry title="First Name">{opinion.patient.first}</Entry>
    <Entry title="Last Name">{opinion.patient.last}</Entry>
    <Entry title="Date of Birth">{opinion.patient.dob}</Entry>
    <Entry title="Email">{opinion.patient.email}</Entry>
    <Entry title="Phone">{opinion.patient.phone}</Entry>

    <T style={{ marginTop: '8px' }} V="h6">
      Medical History:
    </T>
    <Entry title="Diagnosis">{opinion.history.diagnosis}</Entry>
    <Entry title="Diagnosis Date">{opinion.history.diagDate}</Entry>
    <Entry title="Last Visit Date">{opinion.history.lastVisit}</Entry>

    <T>Current Medications</T>
    <T>{opinion.history.medications}</T>
    <T>History</T>
    <T>{opinion.history.history}</T>
    <Q q="What treatments have worked?" a={opinion.history.treatmentSuccess} />
    <Q q="What treatments haven't worked?" a={opinion.history.treatmentFailure} />

    <T style={{ marginTop: '8px' }} V="h6">
      Questions For The Expert:
    </T>
    <Q q="How long have you been seeking treatment?" a={opinion.questions.seeking} />
    <Q q="What are your questions for the expert?" a={opinion.questions.condition} />
    <Q q="Any other pertinent info?" a={opinion.questions.pertinentInfo} />
    <Q q="How did you hear about this service?" a={opinion.questions.leadSource} />

    <Button variant="text" color="primary" onClick={onClose}>
      Close
    </Button>
  </Box>
);

const OpinionsList = ({ opinions, onOpinionClick }) => (
  <EOTable
    columns={[
      {
        title: 'ID',
        render: (data) => <Link onClick={() => onOpinionClick(data)}>{data.id.substr(0, 8)}</Link>
      },
      { title: 'Status', field: 'status' },
      {
        title: 'Pt Name',
        render: (data) => (
          <span>
            {data?.patient?.first || ''} {data?.patient?.last || ''}{' '}
          </span>
        )
      },
      { title: 'Pt Phone', render: (data) => <span>{data?.patient?.phone}</span> },
      { title: 'Pt Email', render: (data) => <span>{data?.patient?.email}</span> },
      { title: 'Upload Url', render: (data) => <a href={getUrl(data)}>Upload Link</a> }
    ]}
    data={opinions}
    options={{
      thirdSortClick: false,
      showTitle: false,
      sorting: false,
      search: false,
      paging: false
    }}
    components={{
      Toolbar: () => <div></div>,
      Container: (props) => <Paper elevation={0}>{props.children}</Paper>
    }}
  />
);

const OpinionsPage = (props) => {
  const [opinions, setOpinions] = React.useState([]);
  const [selectedOpinion, setSelectedOpinion] = React.useState(null);

  const paidOpinions = React.useMemo(() => {
    return opinions.filter((o) => o.type === 'EO_CORE_V2' && o.status === 'PAID');
  }, [opinions]);

  const newOpinions = React.useMemo(() => {
    return opinions.filter((o) => o.type === 'EO_CORE_V2' && o.status !== 'PAID');
  }, [opinions]);

  React.useEffect(() => {
    async function fetchOpinions() {
      if (!props.user) {
        return;
      }

      const response = await _fetchUrl({
        method: 'GET',
        path: `poe/opinion/v2`,
        token: props.user.token
      });

      if (response.status === 'ok') {
        setOpinions([...response.opinions]);
      }
    }

    fetchOpinions();
  }, [props.user]);

  return (
    <Paper>
      <Box p={2}>
        <T V="h5" gutterBottom>
          Version 2 Second Opinions
        </T>
        {selectedOpinion === null ? (
          <>
            <OpinionsList opinions={paidOpinions} onOpinionClick={setSelectedOpinion} />

            <OpinionsList opinions={newOpinions} onOpinionClick={setSelectedOpinion} />
          </>
        ) : (
          <OpinionDetail opinion={selectedOpinion} onClose={() => setSelectedOpinion(null)} />
        )}
      </Box>
    </Paper>
  );
};

export default connect(mapStateToProps)(OpinionsPage);
