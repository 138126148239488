export default (theme) => {
  const css = {
    root: {
      margin: theme.spacing(2.5),
      marginBottom: 75
    },
    paper: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      overflowX: 'auto'
    },
    file: {
      display: 'block',
      outline: `1px solid ${theme.palette.text.primary}`,
      maxWidth: 200,
      marginBottom: theme.spacing(2),
      '&:last-child': { marginBottom: 0 }
    },
    table: {
      minWidth: 700
    },
    error: {
      color: theme.palette.error.main
    },
    result: {
      marginTop: theme.spacing(2)
    },
    formControl: {
      marginBottom: theme.spacing(2),
      '&:last-of-type': { marginBottom: 0 }
    },
    label: {
      fontSize: `${1 + 1 / 3}rem`,
      whiteSpace: 'nowrap'
    },
    formHelpText: {
      fontSize: '75%'
    },
    submitBtn: {
      color: theme.palette.common.white,
      marginTop: theme.spacing(1)
    },
    saveButton: {
      color: theme.palette.common.white
    },
    addButton: {
      marginTop: theme.spacing(2)
    },
    pointer: {
      cursor: 'pointer'
    },
    marginLeft: {
      marginLeft: theme.spacing(1)
    },
    alignIconWithText: {
      display: 'inline-flex',
      verticalAlign: 'middle',
      alignItems: 'center'
    },
    marginTop: {
      marginTop: theme.spacing(2)
    },
    flex: {
      display: 'flex'
    }
  };
  css.error = { ...css.error, ...css.result };
  css.tightPaper = { ...css.paper, marginTop: 0, marginBottom: 0 };
  return css;
};
